/*
 * Mixin used by all inputs which extend the BaseInput component.
 * 
 * The mixin provides a general interface via props and some helper functions.
 * 
 * When extending the component pass the customErrorMessage and customShowErrorMessage
 * varaibles to the BaseInput component.
 * If you want to extend the validity check override the `isValid()` method.
 * To update the error messages call the `updateErrors()` method eg. on blur.
 */
export default {
    emits: ['icon-click'],
    props: {
        prependIcon: {
            type: String,
            default: null
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
        errorMessage: {
            type: String,
            default: "Napaka pri vnosu",
        },
        showErrorMessage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            customErrorMessage: null,
            customShowErrorMessage: false,
        }
    },
    methods: {
        updateErrors() {
            if (this.isValid && this.isValid instanceof Function && this.isValid()) {
                this.clearErrors();
            } else {
                this.customShowErrorMessage = true;
                this.customErrorMessage = this.errorMessage;
            }
        },
        clearErrors() {
            this.customShowErrorMessage = false;
            this.customErrorMessage = null;
        }
    },
}
