<template>
    <div>
        <div v-if="adjustmentExists">
            <div>
                <span>
                {{adjustmentSizeDisplay}}
            
                <i class="fa fa-info-circle" data-toggle="tooltip" v-bind:data-original-title="internalAdjustment.comment" v-if="internalAdjustment.comment"></i>
                            
                </span>  
            </div>
            <div>
                <span>
                    {{adjustmentPercDisplay}}
                </span>
                    |
                <span>
                    {{adjustmentAmountDisplay}}
                </span>
            </div>
        </div>
        <div v-else>
            <i class="fa fa-plus text-muted m-l-5 m-r-5"></i>
        </div>
        

    </div>

</template>

<script>
import Utils from '@/components/utils/utils.js';
import { Swal } from "@/components/utils/swal.alerts.js";
import ApiService from "@/components/utils/api.service.js";

export default {
    props: {
        transaction: Object,
        factor: Object,
        adjustment: Object,
    },
    mounted () {
      this.createInternalAdjustment();
      this.$root.$on('update-complete', data => {
        this.updateCompleteListener(data);
      });
    },
    watch: {
        sizeUpdate: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (this.factor.type=='size'){
                    if (oldValue === undefined) {
                        return;
                    }
                    if (newValue !== oldValue) {
                        this.getAdjustment();
                    }
                }
            }
        }
    },
    computed: {
        adjustmentDisplay: function () {
            if (this.internalAdjustment.adjustment_type === 'AMT') {
                return Utils.formatCurrency(this.internalAdjustment.adjustment_raw);
            } else {
                return Utils.formatPercent(this.internalAdjustment.adjustment_raw);
            }
        },
        adjustmentPercDisplay: function () {
            return Utils.formatPercent(this.internalAdjustment.adjusted_perc, false);
        },
        adjustmentAmountDisplay: function () {
            return Utils.formatCurrency(this.internalAdjustment.adjusted_value, 2);
        },
        adjustmentSizeDisplay: function () {
            if (this.factor.name_slug=='velicina') {
                return Utils.formatSize(this.internalAdjustment.value);
            }
            else {
                return this.internalAdjustment.value;
            }
        },
        adjustmentExists: function () {
            return this.internalAdjustment.id != null;
        },
        sizeUpdate: function () {
            return this.transaction.size;
        },
        postableAdjustment: function() {
        return {
            ...this.internalAdjustment,
            factor_type: this.adjustment.factor_type.id,
            transaction: this.adjustment.transaction.id,
            value: String(this.transaction.size),
            }
        },
    },
    methods : {
        formatAdjustmentNumber(num) {
            let formattedOld = num.toFixed(4);
            let formattedNew = num.toFixed(2);
    
            if (formattedOld === (formattedNew+"00")) {
                return formattedNew;
            } else {
                return formattedOld;
            }
        },
        createInternalAdjustment(){
            this.internalAdjustment = this.adjustment;
        },
        getAdjustment(){
            let url = this.url + this.internalAdjustment.id;
            ApiService
            .put(url, this.postableAdjustment)
            .then(response => {
                this.internalAdjustment = response.data;
                this.$emit("update-factor-size-complete", true);
            })
            .catch(error => {
                this.$emit("update-factor-size-complete", false);
                Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
                throw error;
            });  
      },

      refreshAdjustment(){
                this.$emit("blur-factor", {"blur": true, "adjustment": this.internalAdjustment});

            let url = this.url + this.internalAdjustment.id;
            ApiService
            .get(url, this.postableAdjustment)
            .then(response => {
                this.$emit("blur-factor", {"blur": false, "adjustment": this.internalAdjustment});
                this.internalAdjustment = response.data;
                this.$emit("update-factor-size-complete", true);
            })
            .catch(error => {
                this.$emit("blur-factor", {"blur": false, "adjustment": this.internalAdjustment});
                Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
                throw error;

            });
      },

      updateCompleteListener(adjustmentFactor){
          if (this.internalAdjustment.id == adjustmentFactor.id && adjustmentFactor.id != null) {
              this.refreshAdjustment()
          }
      }
    },
    data () {
        return {
            url: '/amas/analysis/avm/api/adjustment-factors/',
            internalAdjustment: {},
        }
    }
}
</script>
