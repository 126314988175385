<template>
    <div>
        <window-area-selector
            v-if="selecting_screenshot_area"
            @select-area="handleScreenshotAreaSelect"
        />

        <div id="open-support-modal-container"
            v-on:click.prevent="openSupportModal"
        >
            <button class="btn btn-default btn-outline">
              {{ $t("support")}}
            </button>
        </div>

        <bootstrap3-modal
            :title="$t('support:report_problem')"
            name="support-modal"
            width="50%"
            id="support-modal"
        >

            <loading 
                :active="submitting"
                :can-cancel="false" 
                :is-full-page="false"
            />

            <p class="text-center">
              {{ $t("support:report_problem:disclaimer_1")}}
            </p>
            <p class="text-center">
              {{ $t("support:report_problem:disclaimer_2")}}
            </p>

            <label>{{ $t("support:message")}}</label>
            <textarea 
                class="form-control m-b-20"
                rows="6"
                style="resize: none;"
                :placeholder=" $t('support:report_problem:instructions')"
                v-model="user_message"
            />

            <label>{{ $t("support:screenshot")}}</label>
            <div class="screenshot-img-container m-b-20">
                <loading 
                    :active="screenshot_loading"
                    :can-cancel="false" 
                    :is-full-page="false"
                />

                <img class="screenshot-img" :src="screenshot_img_url" />
                <div class="screenshot-img-overlay">
                    <div class="btn btn-default" @click="openAreaSelector">{{ $t("support:report_problem:mark_error")}}</div>
                </div>
            </div>
            
            <div class="text-center">
                <button class="btn btn-info" @click="submit">{{ $t("general:send:btn") }}</button>
            </div>
        </bootstrap3-modal>
    </div>
</template>

<script>
import html2canvas from "html2canvas";
import Loading from "vue-loading-overlay"

import { Swal } from "@/components/utils/swal.alerts.js";
import ApiService from "@/components/utils/api.service.js";
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import WindowAreaSelector from "./components/WindowAreaSelector.vue"

export default {
    components: {
        "bootstrap3-modal": Bootstrap3Modal,
        WindowAreaSelector,
        Loading,
    },
    data() {
        return {
            user_message: "",
            screenshot_img_url: null,
            screenshot_loading: false,
            selecting_screenshot_area: false,
            submitting: false,
        };
    },
    methods: {
        openSupportModal: function() {
            this.$modal.show("support-modal");
            this.takeScreenshot();
        },
        submit: function () {
            this.submitting = true;

            let formData = new FormData();
            formData.append("message", this.user_message);
            formData.append("screenshot", this.screenshot_img_url);
            formData.append("url", this.currentUrl);
            
            ApiService.post(`/api/v1/report-issue`, formData)
                .then((response) => {
                    Swal({   
                        title: this.$t("support:title:tnx"),
                        text: this.$t("support:report:success"),
                        icon: "success",
                    });
                    this.$modal.hide("support-modal");
                })
                .catch((error) => {
                    Swal({   
                        title: this.$t("general:error"),
                        text: this.$t("support:report:error"),
                        icon: "warning",
                    });
                    this.$modal.hide("support-modal");
                    throw error;
                })
                .then(() => {
                    this.submitting = false;
                });
        },

        openAreaSelector: function() {
            this.$modal.hide("support-modal");
            document.body.style.overflow = "hidden";
            this.selecting_screenshot_area = true;
        },
        handleScreenshotAreaSelect: function(area) {
            document.body.style.overflow = "auto";
            this.takeScreenshot(); // If 'area' is passed here, the image will be cropped.
            this.$modal.show("support-modal");
        },
        takeScreenshot: function(area) {
            /*
            Takes screenshot of the provided area.
            If area is null, takes screenshot of the whole visible screen.
            */
            this.screenshot_loading = true;
            if (!area) { area = this._getFullscreenArea() }
            html2canvas(document.body)
                .then((canvas) => {
                    const croppedCanvas = document.createElement("canvas");
                    croppedCanvas.width = area.width;
                    croppedCanvas.height = area.height;
                    croppedCanvas.getContext("2d").drawImage(
                        canvas,
                        area.left,
                        area.top,
                        area.width,
                        area.height,
                        0,
                        0,
                        area.width,
                        area.height
                    );
                    this.screenshot_img_url = croppedCanvas.toDataURL("image/png");
                    this.screenshot_loading = false;
                });
            this.selecting_screenshot_area = false;
        },
        _getFullscreenArea: function() {
            let { width, height } = this._getWindowWidthHeight();
            let zoom_level = Math.round(window.devicePixelRatio * 100) / 100;
            return {
                top: window.scrollY * zoom_level,
                left: 0,
                width: width * zoom_level,
                height: height * zoom_level
            }
        },
        _getWindowWidthHeight: function() {
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            return { width, height };
        }
    },
    computed: {
        currentUrl() {
            return window.location.href;
        }
    },
};
</script>

<style scoped>
#open-support-modal-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99999!important;
}
#support-modal {
    position: fixed;
    z-index: 999999!important;
}
.screenshot-img-container {
    border: 1px dashed #ccc;
    height: 250px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
}
.screenshot-img-container .screenshot-img {
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
    /* display: block; */
    /* width: 100%; */
    /* height: auto; */
    transition: .5s ease;
    backface-visibility: hidden;
}
.screenshot-img-container:hover .screenshot-img {
    opacity: 0.3;
}
.screenshot-img-container:hover .screenshot-img-overlay {
    opacity: 1;
}
.screenshot-img-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
</style>