<template>
    <div class="property-iq__real-estate-details-tab">
        
        <template v-if="enableInspectionForm">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="inpsection-form-header">
                        <div>{{ $t("property_iq:real_estate:details") }}</div>
                        <a
                            class="btn btn-info open-modal"
                            :href="`/amas/analysis/${this.session.id}/document/inspection-form/edit`"
                            v-if="session.inspection_form"
                        >
                            {{ $t("general:edit") }}
                            <i class="fa fa-edit"></i>
                        </a>
                    </h3>

                    <div v-if="!session.inspection_form" class="m-t-20 m-b-20 text-center">
                        <p>
                            {{ $t("piq:inspection_form:form_not_filled_out") }}. {{ $t("analysis:inspection_form:adjustments") }}.
                        </p>
                        <a
                            class="btn btn-info open-modal"
                            :href="`/amas/analysis/${this.session.id}/document/inspection-form/edit`"
                        >
                            {{ $t("general:fill_out:form") }}
                            <i class="fa fa-edit"></i>
                        </a>
                    </div>
                    <div v-else>
                        <inspection-form-table
                            :session_id="session.id"
                            :inspection_form="session.inspection_form"
                        />
                    </div>
                </div>
            </div>

            <hr class="m-t-40 m-b-20" />
        </template>

        <div class="row">
            <div class="col-md-12">
                <h3>{{ $t('property_iq:tabs:gurs') }}</h3>

                <real-estate-details
                    :real_estate="session.main_property.real_estate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InspectionFormTable from "@/../apps/analysis/analysis/vue/inspection_form/InspectionFormTable.vue";

import RealEstateDetails from '@/../apps/real_estate/vue/components/RealEstateDetails.vue'

export default {
    name: "RealEstateDetailsTab",
    components: {
        InspectionFormTable,
        RealEstateDetails,
    },
    props: {
        session: {
            type: Object,
            required: true,
        },
        enableInspectionForm: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.inpsection-form-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
</style>
