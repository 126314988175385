<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="road_access"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/analysis/api/${this.session.id}/road-access`)
                .then(response => {
                    let url = `/amas/analysis/${this.session.id}/document/road-access`;
                    return `
                    <a href="${url}">
                    <span 
                        class="circle circle-sm bg-success"
                        data-toggle="tooltip" data-original-title="${this.$t("task_details:status:successfully_done")}"
                    >
                        <i class="fa fa-check"></i>
                    </span>
                    </a>
                    `
                })
                .catch(error => {
                    if(error.response.status===404) {
                        return `
                        <span 
                            class="circle circle-sm bg-light"
                            data-toggle="tooltip" data-original-title="${this.$t("task_details:status:wansnt_done")}"
                        >
                            <i class="fa fa-close"></i>
                        </span>
                        `
                    } else {
                        throw error
                    }
                })
        }
    },
}
</script>