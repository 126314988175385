<template>
    <div>
        <h3>{{ $t("aa:municipality:acts") }}</h3>
        <table class="table table-condensed">
            <template v-if="formattedDedicatedUsages">
                <tbody>
                    <!-- Header row -->
                    <tr>
                        <th colspan="5">{{ $t("spatial_acts:dedicated_usage_detailed_spatial_implementation_conditions") }}</th>
                    </tr>
                    <tr>
                        <th>{{ $t("spatial_acts:dedicated_usage_tag") }}</th>
                        <th>{{ $t("spatial_acts:dedicated_usage_detailed_tag") }}</th>
                        <th>{{ $t("spatial_acts:dedicated_usage_level") }}</th>
                        <th>{{ $t("general:description") }}</th>
                        <th>{{ $t("spatial_acts:act_number") }}</th>
                    </tr>
                    <!-- Data rows -->
                    <tr v-for="(act, inx) in formattedDedicatedUsages" :key="`act_${inx}`">
                        <td>{{ act.POD_NAM_RABA_OZNAKA }}</td>
                        <td>{{ act.POD_NAM_RABA_NAZIV_DODATEK }}</td>
                        <td>{{ act.POD_NAM_RABA_RAVEN }}</td>
                        <td>{{ act.POD_NAM_RABA_NAZIV }}</td>
                        <td>{{ act.STEVILKA }}</td>
                    </tr>
                </tbody>
            </template>
        </table>

        <h3 class="m-t-40">{{ $t("aa:spatial_planning") }}</h3>
        <table class="table table-condensed">
            <tbody class="thead">
                <tr>
                    <th>{{ $t("spatial_acts:act_number") }}</th>
                    <th>{{ $t("general:name") }}</th>
                    <th>{{ $t("spatial_acts:date_accepted") }}</th>
                    <th>{{ $t("spatial_acts:date_published") }}</th>
                    <th>{{ $t("spatial_acts:date_validity_start") }}</th>
                    <th v-if="eupMap">{{ $t("spatial_acts:spatial_planning_unit_tag") }}</th>
                </tr>
            </tbody>

            <tbody v-for="(sectionActs, sectionTitle) in filteredActs" :key="`section_${sectionTitle}`">
                <tr>
                    <th colspan="6">{{ getTranslation(sectionTitle) }}</th>
                </tr>

                <tr v-for="(act, inx) in sectionActs" :key="`act_${sectionTitle}_${inx}`">
                    <td>{{ act.STEVILKA }}</td>
                    <td>
                        <a v-if="act.POSTOPEK_EPL_ID" :href="getPostopekHref(act)" target="_blank">
                            {{ act.NAZIV_AKTA }}
                        </a>
                        <template v-else>
                            {{ act.NAZIV_AKTA }}
                        </template>
                    </td>
                    <td>{{ act.DATUM_SPREJEMA }}</td>
                    <td>{{ act.DATUM_OBJAVE }}</td>
                    <td>{{ act.DATUM_ZAC_VELJAVNOSTI }}</td>
                    <td v-if="eupMap">
                        <template v-if="eupMap[act.STEVILKA]">
                            {{ eupMap[act.STEVILKA].join(", ") }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import spatialactsmixin from './spatialactsmixin';

export default {
    name: "SpatialActsPopupTable",
    props: ['acts'],
    mixins: [spatialactsmixin],
    computed: {
        filteredActs() {
            const filteredActs = {};
            
            for (const key in this.acts["acts"]) {
                if (this.acts["acts"][key].length > 0) {
                    filteredActs[key] = this.acts["acts"][key];
                }
            }
            return filteredActs;
        },
        eupMap() {
            const map = {};
            for (const eup of this.acts.opn_additional["eup"]) {
                if (!map[eup.STEVILKA])
                    map[eup.STEVILKA] = [eup.NAZIV];    
                else
                    map[eup.STEVILKA].push(eup.NAZIV);
            }
            return map;
        },
        formattedDedicatedUsages() {
            return this.acts.opn_additional["nam_rab_ppip"];
        },
    }
}
</script>

<style scoped>
/* Avoid "fat" border */
.table > tbody + tbody {
    border-top: none;
}
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 7px 4px;
}
</style>
