<template>
    <div v-if="streetViewAvailable===false">
        <slot name="fallback">
            <div class="streetview-div" :style="getStyle">
                {{ $t("google_maps:street_view:error") }}
            </div>
        </slot>
    </div>
    <div v-else :id="'panodiv'+_uid" class="streetview-div" :style="getStyle">
        <div><i class="fa fa-2x fa-spinner fa-spin"></i></div>
    </div>
</template>

<script>
import props from './KGoogleStreetview-props'

import { gmapApi } from 'vue2-google-maps'

export default {
    props: props,
    data() {
        return {
            streetViewAvailable: null,
        }
    },
    methods: {
        createPanorama(pano) {
            this.panoObject = new this.google.maps.StreetViewPanorama(
                document.getElementById("panodiv"+this._uid), {
                    pano: pano,
                });
        },
        processStreetViewData(data, status) {
            this.streetViewAvailable = status==="OK";
            if (status==="OK") {
                this.createPanorama(data.location.pano);
            }
        },
        getStreetViewData() {
            const streetViewService = new this.google.maps.StreetViewService();
            streetViewService.getPanorama(
                {
                    location: this.position,
                    source: this.source,
                },
                (data, status) => this.processStreetViewData(data, status)
            )
        }
    },
    computed: {
        google: gmapApi,
        getStyle: function(){
            return {
                "height": this.height + "px"
            }
        },
    },
    mounted() {
        this.$gmapApiPromiseLazy().then(() => {
            this.getStreetViewData()
        })
    },
}
</script>

<style>
.streetview-div {
    background-color: rgb(229, 227, 223);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>