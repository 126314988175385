import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import ApiService from "@/components/utils/api.service.js";
import Utils from "@/components/utils/utils.js";

const store = new Vuex.Store({
    state: {
        inprogressTransactionItemsIds: [],
        failedTransactionItemsIds: [],

        transactionTypeChoices: {
            sale: [],
            rent: [],
        },
        unitTypeChoices: [],
        parcelTypeChoices: [],
    },
    mutations: {
        inprogressTransactionItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.inprogressTransactionItemsIds, item_id);
        },
        inprogressTransactionItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.inprogressTransactionItemsIds, item_id);
        },
        failedTransactionItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.failedTransactionItemsIds, item_id);
        },
        failedTransactionItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.failedTransactionItemsIds, item_id);
        },

        setMetaOptions(state, val) {
            state.unitTypeChoices = val.unit_type;
            state.parcelTypeChoices = val.parcel_type;
            state.transactionTypeChoices = val.transaction_type;
        },
    },
    actions: {
        retrieveMetaOptions(context) {
            ApiService
                .get(`/amas/analysis/avm/api/meta-options`)
                .then(response => { context.commit("setMetaOptions", response.data); })
        },
    }
})
export default store;
