<template>
    <div>
        <base-table
            :items="filteredOffers"
            :columns="columns_joined"
            :getRowClass="item => item.is_accepted && colorRows ? 'success' : ''"
            :loading="loading"
        >
        
        <template v-slot:notes="{ item }">
            <div
                :class="[{
                    'custom-cursor-pointer': shouldNoteExpand(item.notes)
                }, 'notes']"
                @click="toggleNotes(item.id)"
            >
                <div v-if="expandedNotes !== item.id">
                    {{ shortenText(item.notes) }}
                </div>
                <div v-else>
                    {{ item.notes }}
                </div>
            </div>
            </template>

            <template v-slot:select="{ item }">
                <span v-if="item.is_accepted">
                    <i class="fa fa-check text-default" v-tooltip="'Ponudba je izbrana'"></i>
                </span>
                <span v-else-if="item.offer_status===2">
                    <!-- Declined offer -->
                    <i class="fa fa-close text-default" v-tooltip="'Zavrnjena ponudba'"></i>
                </span>
                <span v-else-if="item.offer_status===3">
                    <!-- Offer changed -->
                    <i class="fa fa-close text-default" v-tooltip="'Naročilo se je spremenilo'"></i>
                </span>
                <input
                    v-else
                    type="radio"
                    :value="item.id"
                    v-model="selectedOfferId"
                />
            </template>

            <template v-slot:actions="{ item }">
                <span v-if="item.offer_status===3">
                    <!-- Offer changed -->
                    <i class="fa fa-close text-default" v-tooltip="'Naročilo se je spremenilo. Oddajte novo ponudbo.'"></i>
                </span>
                <div v-else class="button-holder btn-group dropdown">
                    <button
                        class="btn btn-sm btn-info btn-outline"
                        @click="openEditOfferModal(item)"
                    >
                        Odpri
                    </button>
                    <div class="btn btn-sm btn-info btn-outline dropdown-toggle"
                        type="button" data-toggle="dropdown"
                    >
                        <span class="caret"></span>
                    </div>
                    <ul class="dropdown-menu" role="menu">
                        <li>
                            <a @click="removeOffer(item)" href="#">
                                Odstrani ponudbo
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
        </base-table>

        <div class="row" v-if="selectedOfferId || showMoveToDepositoryButton">
            <div
                v-if="selectedOfferId"
                class="pull-right col-sm-4"
            >
                <FormulateForm
                    v-model="offerValues"
                    @submit="$emit('offer-selected', selectedOfferId); selectedOfferId = null;"
                    v-tooltip="!isQuoteDueDate ? 'Ponudbo lahko izberete ko poteče rok za oddajo' : null"
                >
                    <FormulateInput
                        type="submit" label="Izberi izvajalca"
                        :disabled="!isQuoteDueDate"
                    />
                </FormulateForm>
            </div>
            <div
                v-if="showMoveToDepositoryButton"
                class="pull-right col-sm-4"
            >
                <FormulateForm
                    @submit="changeMode"
                >
                    <FormulateInput
                        type="submit"
                        label="Ustvari naročilnico"
                    />
                </FormulateForm>
            </div>
        </div>

        <!--
            START Modals
        -->
        <bootstrap3-modal
            :title="modalOfferTitle"
            name="editOfferModal"
            width="90%"
        >
            <OrderOffer
                v-if="modalOffer"
                :orderId="modalOffer.order"
                :offer="modalOffer"
                @offer-submitted="$emit('refresh-offers')"
            />
        </bootstrap3-modal>
        <!--
            END Modals
        -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service";
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import { Swal } from "@/components/utils/swal.alerts.js";

import BaseTable from "./BaseTable";
import OrderOffer from "./OrderOffer";

const notesCharLimit = 20;

import tablemixin from "./mixins/tablemixin";

export default {
    emits: [ "refresh-offers" ],
    mixins: [ tablemixin ],
    components: {
        OrderOffer,
        BaseTable,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        offers: {
            type: Array,
            required: true,
        },
        showMoveToDepositoryButton: {
            type: Boolean,
            default: false,
        },
        showOnlySelected: {
            type: Boolean,
            default: false,
        },
        colorRows: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isQuoteDueDate() {
            if (!this.order.quote_due_date ) {
                return true
            }
            let quote_date = Date.parse(this.order.quote_due_date);
            let today = new Date().getTime();
            return quote_date <= today;
        },
        filteredOffers() {
            return this.showOnlySelected ?
                this.offers.filter(offer => offer.is_accepted):
                this.offers;
        },
        modalOfferTitle() {
            if (this.modalOffer)
                return `Podrobnosti ponudbe ${this.modalOffer.id}`
            return ""
        }
    },
    methods: {
        isEqualId(id1, id2) {
            return id1 != null && id2 != null && id1==id2
        },
        toggleNotes(id) {
            this.expandedNotes = this.expandedNotes === id ? null : id;
        },
        shouldNoteExpand(text) {
            if (!text)
                return false;
            return text.length > notesCharLimit;
        },
        shortenText(text) {
            if (!text)
                return "";
            return this.shouldNoteExpand(text) ?
                text.substring(0, notesCharLimit) + '...' : text;
        },
        changeMode() {
            return ApiService
                .post(`/streamline/api/order/${this.order.selected_offer.order}/move-depository/`)
                .then(() => {
                    Swal({
                        title: "",
                        text: "Premaknjeno v naročila",
                        icon: "success",
                    });
                    this.$emit("move-depository");
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri premiku v naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
        },
        removeOffer: function(offer) {
            Swal({
                title: "Ali ste prepričani?",
                text: "Ponudba bo odstranjena iz seznama.",
                icon: "warning",
                confirmButtonText: "Da",
                cancelButtonText: "Ne",
                showCancelButton: true,
            })
            .then(val => {
                if (val.isConfirmed) {
                    ApiService
                        .delete(`/streamline/api/order-offer/${offer.id}/`)
                        .then(() => {
                            Swal({
                                title: "",
                                text: "Ponudba je bila odstranjena.",
                                icon: "success",
                            });
                            this.$emit("refresh-offers");
                        })
                        .catch(error => {
                            Swal({
                                title: "Napaka pri odstranjevanju ponudbe",
                                text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                                icon: "error",
                            })
                            throw error;
                        })
                }
            });
        },
        openEditOfferModal: function(offer) {
            this.modalOffer = offer;
            this.$modal.show("editOfferModal");
        },
    },
    data() {
        return {
            offerValues: {},
            selectedOfferId: null,
            expandedNotes: null,
            modalOffer: null,
            base_columns: [
                {
                    field: "created_at",
                    label: "Datum",

                },
                {
                    field: "created_by",
                    label: "Cenilec",
                    sort_conversion: item => `${item.created_by.first_name} ${item.created_by.last_name}`,
                },
                {
                    field: "due_date",
                    label: "Rok izdelave",
                },
                {
                    field: "offer_status",
                    label: "Status",
                    sort_conversion: item => item.offer_status == 1 ? "Sprejeto" : item.offer_status == 3 ? "Naročilo spremenjeno" : "Zavrnjeno",
                },
                {
                    field: "price",
                    label: "Cena",
                },
                {
                    field: "notes",
                    label: "Opombe",
                    sortable: false,
                    slot_name: "notes",
                },
                {
                    field: "id",
                    label: "Izberi",
                    slot_name: "select",
                    sortable: false,
                },
                {
                    field: "actions",
                    label: "Akcija",
                    sortable: false,
                    slot_name: "actions",
                },
            ],
        }
    },
}
</script>

<style scoped>

.td-selector {
    cursor: pointer;
}
.selected{
    cursor: pointer;
    background: #EFEFEF;

}
.td-selector:hover, .export-option:hover{
    background: #EFEFEF;
    
}
.blurred {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    pointer-events: none;
    color: #AAAAAA !important;
    opacity: 0.5;
}

.notes {
    max-width: 100px;
    word-wrap: break-word;
}

</style>
