<template>
    <div class="row">
        <div class="col-md-12">
            <h3>{{ $t('property_iq:tabs:similar_adverts') }}</h3>

            <adverts-table
                :listings="indexedComparableListings()"
                :comparableListingItemsIds="comparableListingItemsIds"
                @comparable-clicked="$emit('comparable-clicked', $event)"
            />

            <div class="text-center">
                <button class="btn btn-info" v-on:click.prevent="$modal.show('comparable-advers-search-modal')">
                {{ $t('comparable:edit:adverts')}}
                </button>
            </div>

            <bootstrap3-modal
                name="comparable-advers-search-modal"
                width="80%"
            >   
                <adverts-search
                    :comparableListingItemsIds="comparableListingItemsIds"
                    :mainMarker="mainMarker"
                    @comparable-clicked="$emit('comparable-clicked', $event)"
                />
            </bootstrap3-modal>

            <div class="m-t-20" v-if="comparableListings">
                <adverts-map
                    :markers="markers"
                    :height="'500px'"
                    :mainMarker="mainMarker"
                    :clickedMarkerData="clickedMarkerData"
                    :comparableListingItemsIds="comparableListingItemsIds"
                    :center="getMapCenter"
                    :maxClusterRadius="2"
                    @marker-clicked="getClickedMarkerData"
                    @comparable-clicked="$emit('comparable-clicked', $event)"
                    ref="map"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service.js'

import ComparableAdvertsTable from "./Table.vue";
import ComparableAdvertSearch from "./search/Search.vue";
import PropertyIQAdvertsMap from "./Map.vue"

import Bootstrap3Modal from '@/components/utils/Bootstrap3Modal.vue'

export default {
    name: "AdvertsTab",
    components: {
        "adverts-table": ComparableAdvertsTable,
        "adverts-search": ComparableAdvertSearch,
        "adverts-map": PropertyIQAdvertsMap,
        "bootstrap3-modal": Bootstrap3Modal
    },
    props:  {
        comparableListings: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object
        }
    },
    data: function() {
        return {
            clickedMarkerData: null,
        }
    },
    computed: {
        getMapCenter: function() {
            if(this.mainMarker && this.mainMarker.coordinates) {
                return this.mainMarker.coordinates;
            }
            return undefined;
        },
        markers: function() {
            return this.indexedComparableListings().map(i => ({
                id: i.id, idx: i.idx, gps: { lat:i.gps_point.coordinates[1], lng: i.gps_point.coordinates[0] }
            }))
        },
        comparableListingItemsIds: function() {
            return this.indexedComparableListings().map(i => i.id);
        }
    },
    methods: {
        indexedComparableListings: function() {
            return this.comparableListings.map((item, idx) => {
                let i = {...item, ...{idx: idx + 1}};
                return i
            })
        },
        getClickedMarkerData: function(clickedMarker) {
            this.clickedMarkerData = null;
            ApiService
                .get(`/amas/analysis/listing/api/${clickedMarker.id}`)
                .then(response => {
                    this.clickedMarkerData = response.data;
                    this.clickedMarkerData.idx = clickedMarker.idx;
                })
                .catch(error => {
                    throw error;
                })
        }
    },
}
</script>