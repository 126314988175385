<template>
    <div v-if="user">
        <loading 
            :active="loading_update_user"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div v-if="errors.length > 0">
            <p>{{$t("general:error_in_input")}}</p>
            <ul >
                <li
                    v-for="error in errors" v-bind:key="error"
                >
                {{error}}
                </li>

            </ul>
        </div>
       <form>
            <div class="form-group  col-md-12" >
                <p class="text-secondary">{{$t("zk_login:enter_username_password_for_zk")}}</p>
                <p class="text-secondary">{{$t("zk_login:if_not_registered_register")}} <a href="https://evlozisce.sodisce.si/esodstvo/firstLoginRegisteredUser.html">{{$t("general:here")}}</a></p>
            </div>
            <div class="form-group  col-md-6" >
                <label>{{$t("user_profile:zk_username")}}</label>
                <input type="text" class="form-control" v-model="user.profile.zk_username" required>
            </div>
            <div class="form-group  col-md-6">
                <label>{{$t("user_profile:zk_password")}}</label>
                <input type="text" class="form-control" v-model="user.profile.zk_password" required>
            </div>
            <div class="form-group m-b-10">
                <button type="submit" class="btn btn-info" v-on:click.prevent="updateUserZK()" >{{$t("general:update")}}</button>
            </div>
            <p class="text-secondary"><small>{{$t("zk_login:username_and_password_will_be_used_exclusively_for_property_iq")}}</small></p>
        </form>

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js";
import { Swal } from "@/components/utils/swal.alerts.js";


export default {
    components: {
        Loading,
    },
    props: {
        currentUser: {
            type: Object,
        },
    },
    model: {
        prop: 'currentUser',
        event: 'change'
    },
    data() {
        return {
            user: null,
            loading_update_user: false,
            errors : [],
        }
    },
    methods: {
        checkForm() {
            this.errors = [];
            if (this.user.profile.zk_username === "" || this.user.profile.zk_username == null){
                this.errors.push(this.$t('zk_login:username_required'))
            }
            if(this.user.profile.zk_password === "" || this.user.profile.zk_password == null){
                this.errors.push(this.$t('zk_login:password_required'))
            }

            return !this.errors.length;

            
        },
        updateUserZK() {     
            if (this.checkForm()){
                this.loading_update_user = true;
                ApiService
                    .patch("/api/v1/current-user-profile", {
                        zk_username: this.user.profile.zk_username,
                        zk_password: this.user.profile.zk_password
                    })
                    .then(response => {
                        this.loading_update_user = false;
                        this.user.profile = response.data;
                        this.$emit("change", this.user);

                    }).catch(error => {
                        this.loading_update_user = false;
                        Swal({
                                title: "",
                                text: "{{$t('zk_login:error_editing_profile_admins_were_notified_write_to_support')}}",
                                icon: "error",
                            })
                        throw error;
                    });
                }
        },
    },
    mounted() {
        this.user = {
            ...this.currentUser
        };
    }
}
</script>
