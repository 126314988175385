<template>
    <div>

        <div class="row">

            <!-- START: Left section -->
            <div class="col-md-3">

                <div class="white-box">
                    <h3 class="box-title m-b-0">Možne naložbe</h3>

                    <possible-investment
                        v-for="(investment, index) in Object.keys(possibleInvestments)"
                        :key="index"
                        :investment="possibleInvestments[investment]"
                        :possibleInvestment="possibleInvestments[investment]"
                        :selectedInvestment="selectedInvestments[investment]"
                        @update:selectedInvestment="$event => updateSelectedInvestment(investment, $event)"
                    />
                </div>

                
                <div class="white-box">
                    <h3 class="box-title">Primarna mešanica energije</h3>

                    <div class="energy-source">
                        <div class="energy-row-element">
                            <div><i class="fa fa-bolt"></i> Elektrika</div>
                            <div class="input-group">
                                <input class="form-control" type="number" :value="10" />
                                <div class="input-group-addon">%</div>
                            </div>
                        </div>

                        <!-- Children -->
                        <div class="energy-source">
                            <div class="energy-row-element m-l-20">
                                <div>
                                    <i class="fa fa-fire"></i>
                                    Termoelektrarna
                                </div>
                                <div class="input-group">
                                    <input class="form-control" type="number" :value="40" />
                                    <div class="input-group-addon">%</div>
                                </div>
                            </div>
                            <div class="energy-row-element m-l-20">
                                <div>
                                    <i class="mdi mdi-radioactive"></i>
                                    Jedrska elektrarna
                                </div>
                                <div class="input-group">
                                    <input class="form-control" type="number" :value="60" />
                                    <div class="input-group-addon">%</div>
                                </div>
                            </div>
                        </div>

                        <div class="energy-row-element">
                            <div>
                                <i class="fa fa-tree"></i>
                                Trda goriva
                            </div>
                            <div class="input-group">
                                <input class="form-control" type="number" :value="90" />
                                <div class="input-group-addon">%</div>
                            </div>
                        </div>
                        <div class="energy-row-element">
                            <div>
                                <i class="fa fa-sun-o"></i>
                                Sončna energija
                            </div>
                            <div class="input-group">
                                <input class="form-control" type="number" :value="0" />
                                <div class="input-group-addon">%</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <!-- END: Left section -->

            <!-- START: Middle section -->
            <div class="col-md-6">
                <div class="white-box">

                    <div>
                        <h3 class="box-title">Originalna poraba energije</h3>
    
                        <energy-meter
                            energyClass="A2"
                            :heatingEnergy="283.95"
                            :installedEnergy="428"
                            :primaryEnergy="93"
                            :co2Emissions="11.13"
                        />
                    </div>

                    <div class="m-t-40">
                        <h3 class="box-title">Preračunana poraba energije</h3>
    
                        <energy-meter
                            :energyClass="recalculatedEnergy.class"
                            :heatingEnergy="recalculatedEnergy.heatingEnergy"
                            :installedEnergy="recalculatedEnergy.installedEnergy"
                            :primaryEnergy="recalculatedEnergy.primaryEnergy"
                            :co2Emissions="recalculatedEnergy.co2Emissions"
                        />
                    </div>
                </div>
            </div>
            <!-- END: Middle section -->

            <!-- START: Right section -->
            <div class="col-md-3">
                <div class="white-box">
                    <h3 class="box-title">Donosnost naložbe</h3>

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th colspan="2" class="text-upper">Strošek naložbe</th>
                            </tr>
                            <tr>
                                <th>Naložba</th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(investment, inx) in selectedInvestmentKeys" :key="inx">
                                <td>
                                    {{ possibleInvestments[investment].label }}
                                    <br />
                                    {{ getSelectedInvestmentByOptionId(possibleInvestments[investment], selectedInvestments[investment].optionRecalculated).label }}
                                </td>
                                <td>{{ getSelectedInvestmentByOptionId(possibleInvestments[investment], selectedInvestments[investment].optionRecalculated).price|formatCurrency }}</td>
                            </tr>
                            <tr class="special-row">
                                <th>Vsota</th>
                                <th>{{ sumInvestmentPrices|formatCurrency }}</th>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-striped m-t-20">
                        <thead>
                            <tr>
                                <th colspan="2">Prihranki pri ogrevanju (na sezono)</th>
                            </tr>
                            <tr>
                                <th>Vrsta</th>
                                <th>Stroški</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Trenutni pričakovani stroški</td>
                                <td>{{ 7125|formatCurrency }}</td>
                            </tr>
                            <tr>
                                <td>Pričakovani stroški po prenovi</td>
                                <td>{{ 3750|formatCurrency }}</td>
                            </tr>
                            <tr class="special-row">
                                <th>Pričakovani prihranki</th>
                                <th>{{ 3375|formatCurrency }}</th>
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        <!-- <apexchart
                            type="line"
                            :options="apexchart_options"
                            :series="apexchart_series"
                        ></apexchart> -->

                        <div class="m-l-5 m-t-30">
                            Pričakovano število let do povračila naložbe: <b>8 let</b>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- END: Right section -->

        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from "vue-apexcharts";

import Utils from "@/components/utils/utils.js";

import PossibleInvestment from "./components/PossibleInvestment.vue";
import EnergyMeter from "./components/EnergyMeter.vue";

export default {
    name: "ESGCalculator",
    components: {
        PossibleInvestment,
        EnergyMeter,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            possibleInvestments: {},
            selectedInvestments: {},
        }
    },
    methods: {
        formatCurrency: Utils.formatCurrency,

        // fake API calls
        getPossibleInvestments() {
            this.possibleInvestments = {
                heating_pump: {
                    label: "Toplotna črpalka",
                    icon: "fa fa-bolt",
                    optionsOriginal: [
                        {
                            id: 1,
                            label: "Olje",
                        },
                        {
                            id: 2,
                            label: "Plin",
                        },
                        {
                            id: 3,
                            label: "Les",
                        },
                    ],
                    optionOriginalDefault: 1,
                    options: [
                        {
                            id: 1,
                            label: "7kw",
                            price: 7000,
                            energyHeating: 10.5,
                            energyInstalled: 20,
                            energyPrimary: 2,
                            energyCO2: 0.9,
                        },
                        {
                            id: 2,
                            label: "14kw",
                            price: 8000,
                            savings: 4000,
                            energyHeating: 13.5,
                            energyInstalled: 22,
                            energyPrimary: 3,
                            energyCO2: 1.1,
                        }
                    ],
                    optionDefault: 1,
                },
                windows: {
                    label: "Okna",
                    icon: "mdi mdi-window-maximize",
                },
                facade: {
                    label: "Prenova fasade",
                    icon: "fa fa-building",
                },
                roof: {
                    label: "Obnova strehe",
                    icon: "fa fa-home",
                },
                ventilation: {
                    label: "Prezračevalni sistem",
                    icon: "wi wi-strong-wind",
                },
                solar: {
                    label: "Sončni paneli",
                    icon: "fa fa-sun-o",
                },
            }

            // populate selected investments with default options
            for (const investment in this.possibleInvestments) {
                Vue.set(this.selectedInvestments, investment, {
                    selected: false,
                    optionOriginal: this.possibleInvestments[investment].optionOriginalDefault,
                    optionRecalculated: this.possibleInvestments[investment].optionDefault,
                });
            }
        },

        updateSelectedInvestment(investment, selectedInvestment) {
            Vue.set(this.selectedInvestments, investment, selectedInvestment);
        },
        getSelectedInvestmentByOptionId(investmentGroup, optionId) {
            return investmentGroup.options.find(option => option.id === optionId);
        },
    },
    computed: {
        selectedInvestmentKeys() {
            return Object.keys(this.selectedInvestments)
                .filter(investment => this.selectedInvestments[investment].selected);
        },
        sumInvestmentPrices() {
            return this.selectedInvestmentKeys.reduce((acc, investment) => {
                return acc + this.getSelectedInvestmentByOptionId(this.possibleInvestments[investment], this.selectedInvestments[investment].optionRecalculated).price;
            }, 0);
        },
        recalculatedEnergy() {
            return this.selectedInvestmentKeys.reduce((acc, investment) => {
                const selectedInvestment = this.getSelectedInvestmentByOptionId(this.possibleInvestments[investment], this.selectedInvestments[investment].optionRecalculated);
                return {
                    class: "A1",
                    heatingEnergy: acc.heatingEnergy - selectedInvestment.energyHeating,
                    installedEnergy: acc.installedEnergy - selectedInvestment.energyInstalled,
                    primaryEnergy: acc.primaryEnergy - selectedInvestment.energyPrimary,
                    co2Emissions: acc.co2Emissions - selectedInvestment.energyCO2,
                }
            }, {
                class: "A2",
                heatingEnergy: 283.95,
                installedEnergy: 428,
                primaryEnergy: 93,
                co2Emissions: 11.1,
            });
        },
    },
    mounted() {
        this.getPossibleInvestments();
    },
}
</script>

<style lang="scss" scoped>
.energy-source {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .energy-row-element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .input-group {
        max-width: 100px;
    }
}
.special-row {
    border-top: 2px solid #E4E7EB;
}
</style>
