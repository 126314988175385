<template>
    <div>
        <b-dropdown size="lg" variant="link">
            
            <template #button-content>
                <div
                    type="button"
                    class="btn btn-circle"
                    :class="'btn-'+rating.css"
                    v-tooltip="rating.name"
                >
                    {{ rating.label }}
                </div>
            </template>

            <b-dropdown-item
                v-for="(option, option_idx) in rating_choices"
                :key="'rating-option-'+option_idx"
                @click="$emit('select', option)"
                class="rating-option-item"
            >
                <div
                    class="btn btn-circle dropdown-toggle"
                    :class="'btn-'+option.css"
                    v-tooltip="option.name"
                    data-toggle="dropdown"
                >
                    {{ option.label }}
                </div>
                {{option.name}}
            </b-dropdown-item>
        </b-dropdown>

        <span v-if="rating_edited" class="m-l-10 rating-edited-icon">
            <i v-tooltip="'Tveganje je bilo ročno spremenjeno'" class="fa fa-pencil text-inverse"></i>
        </span>
        <span class="m-l-10 recreate-rating-icon" @click="$emit('recreate-rating-click')">
            <i v-tooltip="'Avtomatsko določi oceno tveganja'" class="fa fa-refresh text-success"></i>
        </span>

    </div>
</template>

<script>

export default {
    model: {
        prop: 'rating',
        event: 'select'
    },
    props: {
        rating: {
            type: Object,
            required: true
        },
        rating_choices: {
            type: Array,
            required: false
        },
        rating_edited: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style scoped>
.rating-edited-icon {
    cursor: help;
}
.recreate-rating-icon {
    cursor: pointer;
}
</style>