<template>
    <span class="expandable_input_wrapper">
        <b-button 
            :id="_internalid" 
            class="btn btn-inline btn-info btn-outline btn-sm"
            href="#"
            tabindex="0"
        >
            <span>{{label}}</span>
            <span v-if="displayValue!=null"><span v-if="label!=null">:</span> {{displayValue}}</span>
        </b-button>

        <b-popover
            :target="_internalid"
            placement="bottom"
            custom-class="expandable_input_wrapper__popover"
            triggers="focus"
            ref="popover"
        >
            <p class="text-center"><strong>{{label}}</strong></p>

            <slot></slot>

            <div class="text-center m-t-20">
                <a class="btn btn-xs btn-info btn-outline" v-on:click="$refs.popover.$emit('close')">{{$t("general:save")}}</a>
            </div>
        </b-popover>
    </span>
</template>

<script>
export default {
    props:  {
        label: {
            type: String,
            required: false,
        },
        displayValue: {
            required: false
        },
    },
    data: function () {
        return {
            popover_show: false,
        }
    },
    computed: {
        _internalid: function() {
            return "expandable_input_"+this._uid+"_id";
        },
    },
}
</script>

<style>
.expandable_input_wrapper > .btn {
    border-radius: 3px;
    border: 1px solid #c2c5c8;
    margin-top: 5px;
}
.expandable_input_wrapper__popover {
    padding: 10px;
    border-radius: 5px;
    width: 300px;
}
</style>