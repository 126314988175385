<template>
    <div class="container-fluid m-0 p-0">

        <div class="col-md-3 p-l-0">
        
          <search-form
              v-if="formData"
              v-model="formData"
              @change="submitSearchForm"
              @locationSelected="handleLocationSelected"
          />
        </div>

        <div class="col-md-9 p-0">
          <adverts-map
              :markers="markers"
              :mainMarker="mainMarker"
              :clickedMarkerData="clickedMarkerData"
              :comparableListingItemsIds="comparableListingItemsIds"
              :loadingMarkers="loadingMarkers"
              :center="mainMarker.coordinates"
              :zoom="15"
              :maxClusterRadius="2"
              @update:bounds="handleMapMove"
              @marker-clicked="getClickedMarkerData"
              @comparable-clicked="$emit('comparable-clicked', $event)"
              ref="map"
          >
              <template v-slot:marker-icon-content="slotProps">
                  {{ slotProps.marker.price|formatThousands }} €
              </template>
          </adverts-map>
        </div>
    </div>
</template>

<script>
import { LPopup } from 'vue2-leaflet';

import ApiService from "@/components/utils/api.service.js"

import ComparableAdvertsSearchForm from "./Form.vue";
import ComparableAdvertsTable from "../Table.vue";
import PropertyIQAdvertsMap from "../Map.vue"

export default {
    components: {
        LPopup,

        "search-form": ComparableAdvertsSearchForm,
        "adverts-map": PropertyIQAdvertsMap,
        "adverts-table": ComparableAdvertsTable,
    },
    props:  {
        comparableListingItemsIds: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object
        }
    },
    data: function () {
        return {
           formData: null,

            // Map-related props
            searchResult: [],
            markers: [],
            loadingMarkers: false,
            clickedMarkerData: null
        }
    },
    methods: {
        handleLocationSelected: function(latlng){
            this.$refs.map.$refs.map.setCenter(latlng);
            this.$refs.map.$refs.map.setZoom(12);
        },
        submitSearchForm: function(){
            this.searchResult = [];
            this.getMarkers();
        },
        handleMapMove: function(){
            this.getMarkers();
        },
        getMarkers: function(){
            this.loadingMarkers = true;
            ApiService
                .post(
                    "/amas/analysis/listing/api/search",
                    {   
                        ...this.searchReadyFormData,
                        bbox: this.$refs.map.$refs.map.getBBox().toBBoxString(),
                    },
                    {
                        params: {
                            limit: 1000,
                            fields: "id,gps_point,price"
                        }
                    }
                )
                .then(response => {
                        let existingsMarkerIds = this.searchResult.map(i => i.id)
                        let newmarkers = response.data.filter(m => !existingsMarkerIds.includes(m.id));
                        this.searchResult = this.searchResult.concat(newmarkers);
                        this.markers = this.searchResult.map(i => ({
                            id: i.id, price: i.price, gps: { lat: i.gps_point.coordinates[1], lng: i.gps_point.coordinates[0] }
                        }))
                        this.loadingMarkers = false;
                    })
                .catch(error => {
                    throw error;
                })
        },
        getClickedMarkerData: function(clickedMarker) {
            this.clickedMarkerData = null;
            ApiService
                .get(`/amas/analysis/listing/api/${clickedMarker.id}`)
                .then(response => {
                    this.clickedMarkerData = response.data;
                    this.clickedMarkerData.idx = clickedMarker.idx;
                })
                .catch(error => {
                    throw error;
                })
        },
        getDefaultFormData: function() {
            ApiService
                .get(`/propertyiq/api/listing/${this.$store.state.sessionId}/default-formdata`)
                .then(response => {
                    this.formData = response.data;
                    this.getMarkers();
                })
                .catch(error => {
                    throw error;
                })
        },
        _getLastSeenDateTimestamp: function(monthsAgo) {
            let last_seen = new Date(); // Today
            last_seen.setMonth(last_seen.getMonth() - monthsAgo);
            return last_seen.valueOf()/1000;
        }
    },
    computed: {
        searchReadyFormData: function(){
            return {
                transaction_type: "sale",
                re_class: this.formData.re_class,
                re_type: this.formData.re_type,
                size_range: this.formData.size__gte + ";" + this.formData.size__lte,
                price_range: this.formData.price__gte + ";" + this.formData.price__lte,
                last_seen_timestamp: this._getLastSeenDateTimestamp(this.formData.months_last_seen),
            }
        },
    },
    mounted() {
        this.getDefaultFormData();
    },
}
</script>
