<template>
    <div class="unit-size-estimate-indicator-pop-up">     
        <h4>
            <strong>{{$t("real_estate:size_estimate")}}: {{formatSize(unit_data.unit_size_estimate) || '/' }}</strong>
        </h4>
        <h5>
            <strong>{{$t("real_estate:size_estimate_relative_difference")}}: {{formatPerc(unit_data.unit_size_estimate_relative_difference, false) || '/' }}</strong>
        </h5>
        
        <div>
            <div>
                <strong>{{$t("real_estate:size_estimate:details") }}</strong>
            </div>
            <div>
                {{$t("ee:fields:area")}}: {{unit_data.net_unit_size||'/'|formatSize}}
            </div>
            <div>
                {{$t("real_estate:outline_size")}}: {{unit_data.building.sum_buildingparcel_size||'/'|formatSize}}
            </div>
            <div>
                {{$t("real_estate:building_height")}}: {{unit_data.building.building_height||'/'|formatMeter}}
            </div>
            
            <div>
                <data-source dataKey="KN" convertKey="real_estate_general" :lastUpdated="true" /> 
                <span class="data-source-info"> 
                    {{$t("real_estate:correction_factor")}}
                </span>
                <span>
                    <help-icon
                        class="m-l-5"
                        :slug="'amas-comparable-search-ren-povrsine'"
                    >
                    </help-icon>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Utils from '@/components/utils/utils.js';
import HelpIcon from "@/components/widgets/HelpIcon";

export default {
    components: {
        HelpIcon
    },
    props:  {
        unit_data: {
            type: Object
        }
    },
    methods: {
        formatSize: Utils.formatSize,
        formatPerc: Utils.formatPercent,
        formatMeter: Utils.formatMeter,
    },
}
</script>

<style>
.unit-size-estimate-indicator-pop-up  div {
    text-align:center!important;
}
.unit-size-estimate-indicator-pop-up  h4 {
    text-align:center!important;
}
.unit-size-estimate-indicator-pop-up  h5 {
    text-align:center!important;
}
</style>
