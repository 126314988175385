<template>
    <div>
        <!-- Namenska raba in podrobni prostorski izvedbeni pogoji -->

        <h3>{{ $t("aa:municipality:acts") }}</h3>
        <table class="table table-condensed">
            <template v-if="formattedDedicatedUsages">
                <tbody>
                    <!-- Header row -->
                    <tr>
                        <th colspan="5">{{ $t("spatial_acts:dedicated_usage_detailed_spatial_implementation_conditions") }}</th>
                    </tr>
                    <tr>
                        <th>{{ $t("aa:for_property") }}</th>
                        <th>{{ $t("spatial_acts:dedicated_usage_tag") }}</th>
                        <th>{{ $t("spatial_acts:dedicated_usage_detailed_tag") }}</th>
                        <th>{{ $t("spatial_acts:dedicated_usage_level") }}</th>
                        <th>{{ $t("general:description") }}</th>
                        <th>{{ $t("spatial_acts:act_number") }}</th>
                    </tr>
                    <!-- Data rows -->
                    <tr v-for="(act, inx) in formattedDedicatedUsages" :key="`act_${inx}`">
                        <td>{{ act.properties.join(", ") }}</td>
                        <td>{{ act.POD_NAM_RABA_OZNAKA }}</td>
                        <td>{{ act.POD_NAM_RABA_NAZIV_DODATEK }}</td>
                        <td>{{ act.POD_NAM_RABA_RAVEN }}</td>
                        <td>{{ act.POD_NAM_RABA_NAZIV }}</td>
                        <td>{{ act.STEVILKA }}</td>
                    </tr>
                </tbody>
            </template>
        </table>

        
        <h3 class="m-t-40">{{ $t("aa:spatial_planning") }}</h3>
        <table class="table table-condensed">
            <tbody class="thead">
                <tr>
                    <th>{{ $t("spatial_acts:act_number") }}</th>
                    <th>{{ $t("general:name") }}</th>
                    <th>{{ $t("spatial_acts:date_accepted") }}</th>
                    <th>{{ $t("spatial_acts:date_published") }}</th>
                    <th>{{ $t("spatial_acts:date_validity_start") }}</th>
                    <th v-if="eupMap">{{ $t("spatial_acts:spatial_planning_unit_tag") }}</th>
                </tr>
            </tbody>

            <tbody v-for="(sectionActs, sectionTitle) in filteredActs" :key="`section_${sectionTitle}`">
                <tr>
                    <th colspan="6">{{ getTranslation(sectionTitle) }}</th>
                </tr>

                <tr v-for="(act, inx) in sectionActs" :key="`act_${sectionTitle}_${inx}`">
                    <td>{{ act.STEVILKA }}</td>
                    <td>
                        <a v-if="act.POSTOPEK_EPL_ID" :href="getPostopekHref(act)" target="_blank">
                            {{ act.NAZIV_AKTA }}
                        </a>
                        <template v-else>
                            {{ act.NAZIV_AKTA }}
                        </template>
                    </td>
                    <td>{{ act.DATUM_SPREJEMA }}</td>
                    <td>{{ act.DATUM_OBJAVE }}</td>
                    <td>{{ act.DATUM_ZAC_VELJAVNOSTI }}</td>
                    <td v-if="eupMap">
                        <template v-if="eupMap[act.STEVILKA]">
                            {{ eupMap[act.STEVILKA].join(", ") }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import spatialactsmixin from './spatialactsmixin';

export default {
    props: ['acts', 'title'],
    mixins: [spatialactsmixin],
    computed: {
        filteredActs() {
            const filteredActs = {};
            for (const act of this.acts) {
                for (const key in act.result["acts"]) {
                    if (act.result["acts"][key].length > 0) {
                        filteredActs[key] = act.result["acts"][key];
                    }
                }

            }
            return filteredActs;
        },
        eupMap() {
            const map = {};
            for (const act of this.acts) {
                for (const eup of act.result.opn_additional["eup"]) {
                    if (!map[eup.STEVILKA])
                        map[eup.STEVILKA] = [eup.NAZIV];    
                    else
                        map[eup.STEVILKA].push(eup.NAZIV);
                }
            }
            return map;
        },
        formattedDedicatedUsages() {
            const additionalInfoByReKey = {};
            
            for (const entry of this.acts) {
                const re_key = entry.property_analysis.real_estate.re_key;
                
                for (const value of entry.result.opn_additional["nam_rab_ppip"]) {
                    const key = `${value.STEVILKA}_${value.POD_NAM_RABA_NAZIV_DODATEK}`
                    if (!additionalInfoByReKey[key]) {
                        value["properties"] = [re_key];
                        additionalInfoByReKey[key] = value;
                    }
                    else if (!additionalInfoByReKey[key]["properties"].includes(re_key)) {
                        additionalInfoByReKey[key]["properties"].push(re_key);
                    }
                }

            }

            return Object.values(additionalInfoByReKey);
        },
    }
}
</script>

<style scoped>
/* Avoid "fat" border */
.table > tbody + tbody {
    border-top: none;
}
.border-top {
    border-top-width: 2px;
}
.upper {
    text-transform: uppercase;
}
h3 {
    margin-left: 8px;
}
</style>
