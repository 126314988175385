<template>
    <!-- https://apexcharts.com/vue-chart-demos -->
    <div class="streamline--apexchart--container vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        
        <div><h3>{{title}}</h3></div>
        <apexchart
            height="250"
            :type="chartType"
            :options="chartOptions"
            :series="values"
        />
    </div>
    
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "StatisticsApexChart",
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            required: false,
        },
        chartType: {
            type: String,
            default: "donut",
        },
        title: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            chartOptions: {
                labels: this.labels,
                legend: {
                    show: false,
                    position: "bottom",
                    horizontalAlign: "right",
                    floating: true,
                },
            },
            
        }
    },
}
</script>