<template>
    <div class="row">
        <div class="col-md-9">
            <div class="white-box">
                <base-table
                    v-bind="$props"
                    :items="filteredItems"
                >
                    <template v-for="(inx, name) in $scopedSlots" v-slot:[name]="data">
                        <slot :name="name" v-bind="data"></slot>
                    </template>    
                </base-table>
            </div>
        </div>
        <div class="col-md-3">
            <div class="filters white-box">
                <h3>{{ $t("general:filters") }}</h3>

                <template v-for="column in columns_joined">
                    <label v-if="column.filter" :key="uniqueKey('filter', column.label)" class="m-b-10">
                        {{ column.label }}
                        
                        <!-- Multiselect type -->
                        <Multiselect
                            v-if="column.filter.type == 'multiselect'"
                            v-model="column.filter.value"
                            :options="getColumnOptions(column)"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            placeholder="Izberite"
                            :select-label="$t('general:select')"
                            :selected-label="$t('general:selected')"
                            :deselect-label="$t('general:remove')"
                        >
                            <template slot="noResult">
                                {{ $t('general:search:no_results')}}
                            </template>
                        </Multiselect>

                        <!-- Date type -->
                        <template v-if="column.filter.type == 'date'">
                            <div class="small">Od</div>
                            <date-select-widget v-model="column.filter.value.from" />
                            <div class="small">Do</div>
                            <date-select-widget v-model="column.filter.value.to" />
                        </template>

                        <!-- Number type -->
                        <template v-if="column.filter.type == 'number'">
                            <div class="small">Od</div>
                            <number-input-field
                                v-model="column.filter.value.from"
                                placeholder="Od"
                                size="sm"
                                :min="0"
                            />
                            <div class="small">Do</div>
                            <number-input-field
                                v-model="column.filter.value.to"
                                placeholder="Do"
                                size="sm"
                                :min="0"
                            />
                        </template>
                    </label>
                </template>

                <div class="m-t-5">
                    <button class="btn btn-info btn-xs" @click="applyFilters">
                        Filtriraj
                    </button>
                    <button class="btn btn-default btn-outline btn-xs m-l-5" @click="resetFilters">
                        Ponastavi
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DateSelectWidget from "@/components/widgets/DateSelectWidget.vue";
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";
import BaseTable from "./BaseTable";

import tablemixin from "./mixins/tablemixin";

export default {
    name: "FilteredTable",
    mixins: [ tablemixin ],
    components: {
        BaseTable,
        Multiselect,
        DateSelectWidget,
        NumberInputField,
    },
    data() {
        return {
            filteredItems: [],
        }
    },
    computed: {
        orderedFilteredItems() {
            if (!this.sortKey)
                return this.filteredItems;

            const sortConversion = this.getSortConversion(this.sortKey);
            return [...this.filteredItems].sort((a, b) => {
                a = sortConversion(a);
                b = sortConversion(b);
                if (a > b || !b)
                    return 1 * this.sortOrder;
                if (a < b || !a)
                    return -1 * this.sortOrder;
                return 0;
            });
        },
    },
    methods: {
        getColumnOptions(column) {
            const makeUnique = options => [...new Set(options)];
            if (column.filter.options)
                return makeUnique(column.filter.options);
            return makeUnique(this.items.map(item => {
                const sortConversion = this.getSortConversion(column.field);
                return sortConversion(item);
            }).filter(item => item));
        },
        applyFilters() {
            this.filteredItems = this.items.filter(item => {
                for (const column of this.columns_joined) {
                    if (!column.filter)
                        continue;

                    const sortConversion = this.getSortConversion(column.field);
                    const value = sortConversion(item);

                    if (column.filter.type == "multiselect") {
                        if (column.filter.value.length == 0)
                            continue;
                        if (!column.filter.value.includes(value))
                            return false;
                    } else if (column.filter.type == "date") {
                        const date = new Date(value);
                        if (column.filter.value.from !== null && column.filter.value.from >= date)
                            return false;
                        if (column.filter.value.to !== null && column.filter.value.to <= date)
                            return false;
                    } else if (column.filter.type == "number") {
                        const number = parseFloat(value);
                        if (column.filter.value.from !== null && column.filter.value.from > number)
                            return false;
                        if (column.filter.value.to !== null && column.filter.value.to < number)
                            return false;
                    }
                }
                return true;
            });
        },
        resetFilters() {
            this.filteredItems = this.items;
            for (const column of this.columns_joined) {
                if (!column.filter)
                    continue;
                if (column.filter.type == "multiselect")
                    column.filter.value = [];
                else if (column.filter.type == "date")
                    column.filter.value = { from: null, to: null };
                else if (column.filter.type == "number")
                    column.filter.value = { from: null, to: null };
            }
        },
    },
    watch: {
        items() {
            this.applyFilters();
        }
    },
    mounted() {
        this.applyFilters();
    },
}
</script>

<style scoped>
    .filters label {
        width: 100%;
    }
</style>
