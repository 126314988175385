<template>
    <div class="property-iq-start-session vld-parent">
        <loading 
            :active.sync="creatingSession"
            :can-cancel="false" 
            :is-full-page="true"
        >
            <div class="text-center">
                <h1>{{ $t('property_iq:title:wait_for:analysis')}} '{{re_key}}'</h1>
            </div>
            <svg style="width:800px; height:100%;">
                <image style="width:800px" xlink:href="@/images/report-builder.gif" />
            </svg>
        </loading>
        <div v-if="error_message">
            <p>{{error_message}}</p>

            <a
                v-if="error_status_code=='ERROR_NO_TOKENS'"
                class="btn btn-info"
                href="/"
            >
              {{ $t('general:home')}}
            </a>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from '@/components/utils/api.service.js'

export default {
    components: {
        Loading
    },
    props: {
        re_key: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            creatingSession: true,
            error_message: null,
            error_status_code: false,
        }
    },
    methods: {
        startSession: function() {
            this.creatingSession = true;
            ApiService
                .get("/amas/analysis/analysis/api/create", {
                    params: { re_key: this.re_key, created_by_process: 2}
                })
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    window.location.href = "/propertyiq/report/" + response.data.id;
                })
                .catch(error => {
                    this.creatingSession = false;
                    if(error.response && error.response.data) {
                        this.error_status_code = error.response.data.status_code;
                        this.error_message = (error.response.data.details) ? error.response.data.details : this.$t("property_iq:title:analysis:error")
                    }
                    throw error;
                })
        },
    },
    mounted() {
        this.startSession();
    },
}
</script>

<style>
    .property-iq-start-session .vld-icon {
        height: 80%;
    }
</style>