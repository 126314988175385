<template>
    <span class="help-icon" @click="openPopup">
        <i class="fa fa-info-circle"></i>
    </span>
</template>

<script>
import ApiCalls from "@/components/utils/api.calls.js"
import { Swal } from "@/components/utils/swal.alerts.js";

export default {
    props: {
        slug: {
            type: String,
            required: true
        },
    },
    data: function() {
        return {
            updated: null,
        }
    },
    methods: {
        openPopup() {
            let swal_popup = Swal({
                title: "",
                html: "<i class='fa fa-spinner fa-spin'></i>",
                confirmButtonText: this.$t("general:close"),
            });
            ApiCalls
                .getWeDocs(this.slug)
                .then(response => {
                    swal_popup.update({html: response.data});
                    document.dispatchEvent(new CustomEvent("render-katex"));
                })
                .catch(error => {
                    if(error.response && error.response.status===404) {
                        swal_popup.update({html: this.$t("widgets:error:not_found")})
                    }
                    throw error;
                })
        }
    },
}
</script>
