<template>
    <div v-if="item.highlight" class="feed-item col-md-8 highlight">
        <a v-bind:href="get_external_url(item.link)" target="_blank">
            <div class="feed-item-image-container">
                <div class="m-l-10 label label-info label-inverse feed-item-source">{{item.feed_source}}</div>
                <div class="img-gradient">
                    <h5>
                        {{item.title}}
                    </h5>
                    <p>{{item.summary.slice(0, 200)}}...
                        <a v-bind:href="get_external_url(item.link)" target="_blank"><i class="fa fa-external-link"></i> {{$t("general:open")}}</a>
                    </p>
                </div>
                <img v-bind:src="item.image_link" class="img-thumbnail">
            </div>
        </a>
        <div v-if="moderationEnabled" class="rating">
            <span class="fa fa-thumbs-down custom-cursor" v-if="enable_rating" v-on:click="rate(item.id, 'down')"></span>
            <span class="fa fa-thumbs-up custom-cursor" v-if="enable_rating" v-on:click="rate(item.id, 'up')"></span>
            <span class="rating-counter badge badge-dark badge-pill m-l-5">{{item.rating}}</span>
        </div>
    </div>
    <div v-else-if="show" class="feed-item col-md-4">
        <a v-bind:href="get_external_url(item.link)" target="_blank">
            <div class="feed-item-image-container">
                    <div class="m-l-10 label label-info label-inverse feed-item-source">{{item.feed_source}}</div>
                    <img v-bind:src="item.image_link" class="img-thumbnail">
            </div>
        </a>
        <div class="feed-item-description">
            <h5 class="m-b-0">
                <a v-bind:href="get_external_url(item.link)" target="_blank">
                    {{item.title}}
                </a>
            </h5>
            <small class="text-muted">{{$t("dashboard:published")}} {{item.date_created}}</small><br/>
            <p>{{item.summary.slice(0, 160)}}...
                <a v-bind:href="get_external_url(item.link)" target="_blank"><i class="fa fa-external-link"></i> {{$t("general:open")}}</a>
            </p>
            <div v-if="moderationEnabled" class="rating">
                <span class="fa fa-thumbs-down custom-cursor" v-if="enable_rating" v-on:click="rate(item.id, 'down')"></span>
                <span class="fa fa-thumbs-up custom-cursor" v-if="enable_rating" v-on:click="rate(item.id, 'up')"></span>
                <span class="rating-counter badge badge-dark badge-pill m-l-5">{{item.rating}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/components/utils/api.service.js'

export default {
    name: "FeedItem",
    props: ['item', 'moderationEnabled'],
    data: function() {
        return {
            show: true,
            enable_rating: true,
            rating: 0,
        }
    },
    methods: {
        get_external_url(url){
            return `/market/analytics/external-link/${url}`;
        },
        rate(item_id, rating) {
            let url = `/market/rate_feed_item/${item_id}/ratings/${rating}`;

            if(rating == "down") {
                if (this.item.rating > 0 || this.item.rating == 0) {
                    ApiService
                    .patch(url)
                    .then(response => {
                        this.item.rating = response.data.rating;
                        this.item.enable_rating = false
                    })
                    .catch(response => {
                        console.error(response);
                    });
                }
            } else if(rating == "up") {
                ApiService
                .patch(url)
                .then(response => {
                    this.item.rating = response.data.rating;
                })
                .catch(response => {console.error(response)});
            }
        },
    }
}
</script>

<style>
.feed-item {
    height: 350px;
    padding: 5px;
    border-top: 1px solid #eee !important;
    margin-top: 10px;
}

.feed-item-image-container {
    position: relative;
}

.feed-item-source {
    float: left;
    position: absolute; 
    top: 10px;
    left: 0;
}

.feed-item-description {
    height: 200px;
    overflow: hidden;
}

.feed-item .img-thumbnail {
    height: 150px;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 10px;
    object-fit: cover;
}

.feed-item.highlight {
    height: 350px;
    padding: 5px;
    margin-top: 10px;
}

.feed-item.highlight .img-thumbnail {
    height: 350px;
}

.feed-item.highlight .img-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.6));
    text-shadow: -1px 1px 3px rgba(0,0,0,.6);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.feed-item.highlight h5 {
    color: #FFFFFF;
    font-size: 18px;
}

.feed-item .rating {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #FFFFFF80;
    border-radius: 5px;
    padding: 1px;
}

.feed-item .rating-counter {
    background-color: gray !important;
}
</style>
