class Utils {
    static formatNumber(num, dec_places=2) {
        num = Number(num);
        if((!num || isNaN(num)) && num != 0){return num;}
        let res = num.toFixed(dec_places).replace(".", ",")

        let regex;
        if(res.includes(",")) {
            regex = /(\d)(?=(\d{3})+(?!\d),)/g;
        } else {
            regex = /(\d)(?=(\d{3})+(?!\d))/g;
        }
        return res.replace(regex, '$1.')
    }

    static formatThousands(num, dec_places=0) {
        if (num >= 1000) {
            num = num / 1000;
            return Utils.formatNumber(num, dec_places) + "k";
        } else {
            return Utils.formatNumber(num, dec_places);
        }
    }

    static formatSize(num, dec_places=null) {

        if((!num || isNaN(num)) && num != 0){return num;}
        if (dec_places===null) {
            dec_places = 2;
        }
        return Utils.formatNumber(num, dec_places)+" m²"
    }

    static formatMeter(num, dec_places=null) {

        if((!num || isNaN(num)) && num != 0){return num;}
        if (dec_places===null) {
            dec_places = 2;
        }
        return Utils.formatNumber(num, dec_places)+" m"
    }

    static formatCurrency(num, dec_places=2) {
        if((!num || isNaN(num)) && num != 0){return num;}
        return Utils.formatNumber(num, dec_places)+" €"
    }

    static formatCurrencym2(num, dec_places=2) {
        if((!num || isNaN(num)) && num != 0){return num;}
        return Utils.formatNumber(num, dec_places)+" €/m²"
    }

    static formatCurrencyOrNullm2(num, dec_places=2) {
        if((!num || isNaN(num)) || num == 0){ return "X/m²"}
        return Utils.formatNumber(num, dec_places)+" €/m²"
    }

    static formatPercent(num, multiply=true, dec_places=2) {
        if((!num || isNaN(num)) && num != 0){return num;}
        if(multiply){
            num = num * 100;
        }        
        return Utils.formatNumber(num, dec_places)+" %"
    }

    
    static removeFromArray(array, element) {
        let i = array.length;
        while (i--) {
            if (array[i] == element) {
                array.splice(i, 1);
            }
        }
    }

    static addToArray(array, element, unique=true) {
        if(unique){
            if(!array.includes(element)) {
                array.push(element);
            }
        } else {
            array.push(element);
        }
    }

    // ---
    // Date utils
    // ---
    static dateToTS (date) {
        return date.valueOf();
    }
    static tsToDate (ts) {
        var d = new Date(ts);
    
        return d.toLocaleDateString("sl-SI", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    }
    static tsMSToDate (ts_ms) {
        var ts = new Date(0);
        ts.setUTCSeconds(ts_ms/1000);
        var date = Utils.parseDate(Utils.tsToDate(ts));
        return date;
    }
}

export default Utils;