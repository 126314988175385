<template>
    <div>
        <k-google-map
            v-bind="$props"
            ref="map"
        />
        <button 
            type="button" 
            @click="saveCurrentMap"
            class="btn btn-outline btn-info m-t-10 waves-effect waves-light"
        >
          {{ $t("google_maps:save:map_view") }}
        </button>

        <div class="m-t-30 text-center">
            <h2>{{ $t("google_maps:map_view") }}</h2>
            <a
                v-if="currentImageUrl"
                :href="currentImageUrl"
                data-toggle="lightbox"
                data-gallery="gallery"
            >
                <img class="width-100" :src="currentImageUrl"/>
            </a>
        </div>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import KGoogleMap from './KGoogleMap'
import props from './KGoogleMap-props'

export default {
    components: {
        KGoogleMap
    },
    props: {
        ...props,
        saveImageUrl: String,
        initialImageUrl: String,
        imageSize: {
            type: String,
            default: "1200x800"
        }
    },
    data: function() {
        return {
            currentImageUrl: this.initialImageUrl
        }
    },
    methods: {
        _createStaticMapLink(mapObject) {
            return "https://maps.googleapis.com/maps/api/staticmap?" +
                "&center=" + mapObject.center.lat() + "," + mapObject.center.lng() +
                "&size=" + this.imageSize +
                "&markers=" + mapObject.center.lat() + "," + mapObject.center.lng() +
                "&zoom=" + mapObject.getZoom() + 
                "&maptype=" + mapObject.getMapTypeId() +
                "&key=" + "AIzaSyABpWn0UzbCu2CWcVo4VFNTG7OqJW5GwYA"
        },
        saveCurrentMap: function () {
            const app = this;
            Swal({   
                title: this.$t("general:action:are_you_sure"),
                text: this.$t("general:info:image_changed"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t("general:yes"),
                cancelButtonText: this.$t("general:no"),
            }).then(function(isConfirm){
                if (isConfirm.value) {
                    ApiService
                        .get(app.saveImageUrl, {
                            params: {
                                image_url: app._createStaticMapLink(app.googleMapObject),
                                target:"map"
                            }
                        })
                        .then(response => {
                            if(response.data["success"]==false){
                                Swal({   
                                    title: app.$t("general:error"),
                                    text: app.$t("general:info:image_changed_failed"),
                                    icon: 'error',
                                });
                            }else{
                                Swal({   
                                    title: app.$t("general:success"),
                                    text: app.$t("general:info:image_changed_success"),
                                    icon: 'success',
                                });
                                app.currentImageUrl = response.data.new_image_url;
                            }
                        })
                        .catch(error => {
                            throw error;
                        })
                }
            });
        },
    },
    computed: {
        googleMapObject: function(){
            return this.$refs.map.googleMapObject;
        },
    },
}
</script>
