<template>
    <div class="table-holder">
        <div class="table">
            <div :class="[{'show-actions': !isReadOnly}, 'table-row']">
                <div v-if="!isReadOnly">Akcije</div>
                <div>ID nepremičnine</div>
                <div>Vrsta nepremičnine</div>
                <div>Naslov</div>
                <div>Država</div>
            </div>
            <div
                v-for="(property, index) in properties"
                :key="index"
                class="table-row"
            >
                <div v-if="!isReadOnly" class="actions">
                    <button
                        v-tooltip="'Pridobi podatke o nepremičnini'"
                        @click="fetchRealEstate(property)"
                        class="ti ti-reload"
                    ></button>
                    <button
                        v-tooltip="'Odstrani nepremičnino'"
                        @click="removeRealEstate(index)"
                        class="ti ti-close"
                    ></button>
                </div>
                <div>
                    <input
                        type="text"
                        v-model="property.re_key"
                        class="form-control"
                        :disabled="isReadOnly"
                        @keypress.enter.prevent="() => handleRowEnter(property)"
                    />
                </div>
                <div v-if="selectOptions">
                    <select
                        v-model="property.real_estate_type"
                        class="form-control"
                        :disabled="isReadOnly"
                    >
                        <option
                            v-for="key in Object.keys(selectOptions.real_estate_type)"
                            :key="`${index}_${key}`"
                            :value="key"
                        >
                            {{ selectOptions.real_estate_type[key] }}
                        </option>
                    </select>
                </div>
                <div>
                    <input
                        type="text"
                        v-model="property.address"
                        class="form-control"
                        :disabled="isReadOnly"
                        @keypress.enter.prevent="() => handleRowEnter(property)"
                    />
                </div>
                <div>
                    <input
                        type="text"
                        v-model="property.country"
                        class="form-control"
                        :disabled="isReadOnly"
                    />
                </div>

                <loading 
                    :active="loadingFetchRealEstates.includes(property.re_key)"
                    :can-cancel="false" 
                    :is-full-page="false"
                    :height="30"
                />
            </div>
            <div v-if="!isReadOnly">
                <button
                    @click="addRow"
                    class="btn"
                    :disabled="isReadOnly"
                >
                    + Dodaj nepremičnino
                </button>
            </div>
        </div>
        <bootstrap3-modal
            title="Izbira nepremičnine"
            name="realEstateSelectModal"
            width="50%"
        >
            <div>
                <div class="address-select">
                    <loading 
                        :active="loadingFetchRealEstates.length > 0"
                        :can-cancel="false" 
                        :is-full-page="false"
                        :height="40"
                    />
                    <ul>
                        <li
                            v-for="(realEstateOption, index) in realEstateOptions"
                            :key="index"
                            @click="() => onRealEstateOptionClick(realEstateOption)"
                        >
                            <template v-if="selectingAddress">
                                {{ realEstateOption.full_address }}
                            </template>
                            <template v-else>
                                {{ realEstateOption }}
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
        </bootstrap3-modal>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import Loading from "vue-loading-overlay";

import optionsmixin from "../mixins/optionsmixin.js";

export default {
    name: "OrderFormRealEstateTable",
    mixins: [ optionsmixin ],
    components: {
        "bootstrap3-modal": Bootstrap3Modal,
        Loading,
    },
    props: {
        properties: {
            type: Array,
            required: true,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingFetchRealEstates: [],  // array of real estates that are currently being fetched
            realEstateOptions: [],
            selectingAddress: true,
            realEstateSelected: null,
        }
    },
    methods: {
        addRow() {
            this.properties.push({
                re_key: null,
                real_estate_type: 1,
                address: null,
                country: null,
            });
        },
        fetchRealEstate(property) {
            // fetch real estate by either re_key or address
            // display warning if both are empty
            this.realEstateSelected = property;

            if (property.re_key) {
                this.loadingFetchRealEstates.push(property.re_key)
                return ApiService
                    .get(`/api/v1/real-estate/details/${property.re_key}`)
                    .then(response => {
                        const real_estate = response.data
                        if (real_estate) {
                            this.autofillData(property, real_estate);
                        }
                    })
                    .catch(error => {
                        if(error.response && error.response.status===404) {
                            Swal({   
                                title: this.$t("general:warning"),
                                text: `${this.$t("real_estate:invalid_re_key:error")}: '${property.re_key}'`,
                                icon: "warning"
                            })
                            return null;
                        } else {
                            throw error;
                        }
                    })
                    .finally(() => {
                        // remove real estate from loading list
                        this.loadingFetchRealEstates.splice(this.loadingFetchRealEstates.indexOf(property.re_key), 1);
                    })
            } else if (property.address) {
                this.$modal.show("realEstateSelectModal");
                this.selectingAddress = true;
                this.loadingFetchRealEstates.push(property.address)
                return ApiService
                    .get(`/api/v1/address/search/${property.address}`)
                    .then(response => {
                        if (!response.data || response.data.length === 0) {
                            this.$modal.hide("realEstateSelectModal");
                            Swal({
                                title: this.$t("general:warning"),
                                text: `Naslov ni najden: '${property.address}'`,
                                icon: "warning"
                            })
                            return null;
                        } else {
                            this.realEstateOptions = response.data
                        }
                    })
                    .catch(error => {
                        Swal({   
                            title: this.$t("general:error"),
                            text: `Napaka pri pridobivanju podatkov o naslovu: ${property.address}`,
                            icon: "error"
                        })
                    })
                    .finally(() => {
                        // remove real estate from loading list
                        this.loadingFetchRealEstates.splice(this.loadingFetchRealEstates.indexOf(property.address), 1);
                    })
            } else {
                Swal({
                    title: "Napaka pri pridovivanju podatkov",
                    text: "Vnesite ID ali naslov nepremičnine za pridobitev podatkov.",
                    icon: "warning",
                })
            }
        },
        onRealEstateOptionClick(property) {
            if (this.selectingAddress) {
                this.selectingAddress = false;
                this.realEstateOptions = property.units;
            } else {
                this.realEstateSelected.re_key = property;
                this.fetchRealEstate(this.realEstateSelected);
                this.$modal.hide("realEstateSelectModal");
                this.realEstateSelected = null;
                this.realEstateOptions = [];
            }
        },
        removeRealEstate(index) {
            this.properties.splice(index, 1);
        },
        autofillData(property, real_estate) {
            property.real_estate_type = this._mapRealEstateType(real_estate);
            property.address = real_estate.address !== "None" ? real_estate.address : "",
            property.country = "Slovenija";
        },
        _mapRealEstateType(real_estate) {
            if (real_estate.is_parcel) {
                const _map = {
                    "Stavbno zemljišče": 9,
                    "Stavbno zemljišče - infrastruktura": 10,
                    "Gozdno zemljišče": 11,
                    "Kmetijsko zemljišče": 12,
                    "Vodno zemljišče": 13,
                    "Drugo zemljišče": 14,
                }
                return _map[real_estate.re_type]
            }
            const _map = {
                "Stanovanje": 1,
                "Hiša": 2,
                "Garaža": 3,
                "Poslovni prostor": 4,
                "Industrijska nepremičnina": 5,
                "Druga nepremičnina": 6,
                "Kmetijska nepremičnina": 7,
                "Vladna nepremičnina": 8,
            }
            return _map[real_estate.unit.property_class_group]
        },
        handleRowEnter(property) {
            if (this.isReadOnly)
                return;
            this.fetchRealEstate(property);
        },
    }
}
</script>

<style scoped>
.table-holder {
    border: 1px solid #dee2e6;
    border-radius: 7px;
    margin-bottom: 25px;
}
.table {
    margin-bottom: 0;
}
.table .table-row {
    width: 100%;
    display: grid;
    grid-template-columns: min(90px, 100%) repeat(4, 2fr);
    padding: 0 2px;
    border-bottom: 1px solid #dee2e6;
}
.table .table-row:not(.show-actions):not(:has(.actions)) {
    grid-template-columns: repeat(4, 1fr);
}
.table .table-row:not(.show-actions):not(:has(.actions)):last-child {
    border-bottom: 0;
}
.table .table-row > div {
    padding: 5px;
}

/* Actions */
.table .table-row div.actions {
    display: flex;
    justify-content: space-between;
}
.table .table-row div.actions button {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 10px;
}
.table .table-row div:first-child button:disabled {
    cursor: not-allowed;
}

/* Bottom button */
.table :last-child button {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
}

/* Address select modal */
.address-select {
    position: relative;
    min-height: 60px;
}
.address-select ul {
    padding: 0;
    background-color: #fafafa;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    max-height: 300px;
    overflow: auto;
}
.address-select ul li {
    list-style-type: none;
    padding: 8px 10px;
    cursor: pointer;
}
.address-select ul li:hover {
    background-color: #f1f1f1;
}
.address-select ul li:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}
</style>
