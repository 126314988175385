<template>
    <div>
        <div class="slider-wrapper">
            <div v-if="showInputFields" class="form-group row m-0">
                <div class="col-xs-5 col-md-5 p-0">
                    <slider-input-field
                        :id="sliderId + '_input_from'"
                        :value="fromto.from"
                        :placeholder="minLabel"
                        :inputFieldsAddon="inputFieldsAddon"
                        :validator="_validateInputValue"
                        @change="$emit('change', {from: $event, to: fromto.to})"
                    />
                </div>

                <div class="col-xs-2 col-md-2 text-center">-</div>

                <div class="col-xs-5 col-md-5 p-0">
                    <slider-input-field
                        :id="sliderId + '_input_to'"
                        :value="fromto.to"
                        :placeholder="maxLabel"
                        :inputFieldsAddon="inputFieldsAddon"
                        :validator="_validateInputValue"
                        @change="$emit('change', {from: fromto.from, to: $event})"
                    />
                </div>
            </div>

            <input type="text" :id="sliderId" :name="sliderId" value />
        </div>
    </div>
</template>

<script>
var $ = require("jquery");

import "./range-slider";

import SliderInputField from "./SliderInputField.vue";

export default {
    components: {
        SliderInputField
    },
    model: {
        prop: "fromto",
        event: "change"
    },
    props:  {
        fromto: {
            type: Object,
            required: true
        },
        initialmin: {
            type: Number,
            required: false
        },
        initialmax: {
            type: Number,
            required: false
        },
        postfix: {
            type: String,
            default: ""
        },
        minLabel: {
            type: String,
            default: "min"
        },
        maxLabel: {
            type: String,
            default: "max"
        },
        thousandsSeparator: {
            type: String,
            default: "."
        },
        step: {
            type: Number,
            default: 1
        },
        
        // ionRangeSlider - specific settings
        customIonRangeSliderSettings: {
            type: Object,
            default: () => ({})
        },
        // InputFields - related
        showInputFields: {
            type: Boolean,
            default: true
        },
        inputFieldsAddon: {
            type: String,
            default: null
        },
    },
    data: function () {
        const randomId = Math.random().toString(36).substr(2);

        return {
            sliderId: `slider-${randomId}`,

            internal_fromto: this.fromto,

            min: this.initialmin != null ? this.initialmin : this.fromto.from - this.step,
            max: this.initialmax != null ? this.initialmax : this.fromto.to + this.step,

            // Override defaults with custom settings
            ionRangeSliderSettings: Object.assign({
                type: "double",
                grid: true,
                from_fixed: false,
                to_fixed: false,
                hide_min_max: true,
                hide_from_to: false,
                force_edges: false,
                drag_interval: true,
                block: false,
                keyboard: true,
            }, this.customIonRangeSliderSettings)
        }
    },
    methods: {
        renderSlider() {
            if (this.ionRangeSlider) {
                this.ionRangeSlider.destroy();
            }
            let _sliderInstance = $(`#${this.sliderId}`).ionRangeSlider({
                ...{
                    skin: "flat",
                    from: this.fromto.from,
                    to: this.fromto.to,
                    min: this.min,
                    max: this.max,
                    prettify: this.prettifyFunction,
                    step: this.step,
                },
                ...this.ionRangeSliderSettings,
                ...{
                    // eslint-disable-next-line no-unused-vars
                    onUpdate: (val) => {
                    },
                    onFinish: (val) => {
                        this.handleSliderOnFinish(val);
                    },
                    // eslint-disable-next-line no-unused-vars
                    onChange: (val) => {
                    },
                }
            });
            this.ionRangeSlider = _sliderInstance.data("ionRangeSlider");
        },
        _validateInputValue(value) {
            let is_valid;
            if( isNaN(value) ) {
                is_valid = false;
            } else {
                is_valid = true;
                const isEmptyValue = (val) => val === null || val === "";
                value = isEmptyValue(value) ? null : Number(value);
            }
            return {
                value: value,
                is_valid: is_valid
            }
        },
        handleSliderOnFinish(event) {
            let from = event.from!==this.min ? Number(event.from): null;
            let to = event.to!==this.max ? Number(event.to): null;
            this.$emit('change', {from, to});
        },
        prettifyFunction(val) {
            if(val===this.max || val===this.min) {
                if(val===this.max) {return this.maxLabel;}
                if(val===this.min) {return this.minLabel;}
            }
            if(this.thousandsSeparator) {
                val = val.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + this.thousandsSeparator)
            }
            return val + this.postfix;
        },
        setSmartMax(max){
            let to = this.fromto.to;
            let from = this.fromto.from;
            if (to != null && max <= to) {
                max = to + this.step
            } else if (max <= from) {
                return
            }
            this.max = max;
        },
        setSmartMin(min){
            let to = this.fromto.to;
            let from = this.fromto.from;
            if (from != null && min >= from) {
                min = from - this.step
            } else if (min >= to) {
                return
            }
            this.min = min;
        },
    },
    mounted() {
        this.renderSlider();
    },
    watch: {
        fromto: {
            immediate: true,
            deep: true,
            handler(fromto, oldVal) {
                let fromChanged = oldVal && fromto.from !== oldVal.from ? true : false;
                let toChanged = oldVal && fromto.to !== oldVal.to ? true : false;

                if (fromto.from !== null && fromto.to !== null && fromto.from > fromto.to) {
                    if (fromChanged) {
                        // fromto.to = fromto.from
                        fromto.to = null;
                    } else if (toChanged) {
                        // fromto.from = fromto.to
                        fromto.from = null;
                    }
                }
                if (fromto.from != null && fromto.from >= this.max) {this.max = fromto.from + this.step;}
                if (fromto.from != null && fromto.from <= this.min) {this.min = fromto.from - this.step;}
                if (fromto.to != null && fromto.to >= this.max) {this.max = fromto.to + this.step;}
                if (fromto.to != null && fromto.to <= this.min) {this.min = fromto.to - this.step;}

                if (this.ionRangeSlider) {
                    this.ionRangeSlider.update({ from: fromto.from, to: fromto.to, min: this.min, max: this.max})
                }
            }
        },
        min: function (val) {
            if (this.ionRangeSlider) {
                this.ionRangeSlider.update({ min: val, from: this.fromto.from, to: this.fromto.to})
            }
        },
        max: function (val) {
            if (this.ionRangeSlider) {
                this.ionRangeSlider.update({ max: val, from: this.fromto.from, to: this.fromto.to })
            }
        },
        step: function (val) {
            if (this.ionRangeSlider) {
                this.ionRangeSlider.update({ step: val })
            }
        },
    },
}
</script>


<style>
@import "./range-slider.css";
.slider-wrapper {
    width: 100%;
    margin-top: var(--hist-slider-gap);
}

.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-bar,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    background-color: #2cabe3;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #2cabe3;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.42);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.42);
    z-index: 10;
}

.text-center {
    text-align: center;
}
</style>
