<template>
    <DropDownSelectInputField
        :value="value"
        :choices="choices"
        :selectFirst="selectFirst"
        :defaultDisplayIfNull="defaultDisplayIfNull"
        @input="$emit('input', $event)"
    />
</template>

<script>
import DropDownSelectInputField from "./DropDownSelectInputField.vue"

export default {
    components: {
        DropDownSelectInputField
    },
    props: {
        value: {
            required: true
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        defaultDisplayIfNull: {
            required: true
        },
        isParcel: {
            required: true
        },
    },
    computed: {
        choices: function () {
            if(this.$store) {
                return this.$store.state.realEstateTypeMap[this.isParcel ? "parcel" : "unit"];
            } else {
                // So it doesn't fail when store isn't available
                return {}
            }
        },
    }
}
</script>
