import ApiCalls from "@/components/utils/api.calls.js"


export default {
    data: function() {
        return {
            config: {},
        }
    },
    mounted() {
        ApiCalls.getConstanceConfig()
            .then(response => {
                this.config = response.data
            })
    },
}
