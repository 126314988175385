<template>
    <div class="vld-parent" style="min-height: 300px;">
        <loading 
            :active="loading" 
            :can-cancel="false" 
            :is-full-page="false"
        />

        <loading 
            :active="updating_project" 
            :can-cancel="false" 
            :is-full-page="true"
        />

        <valuation-subject-wizard
            v-if="valuation_subject"
            v-model="valuation_subject"
            @finish="handleFinishClick"
            @toggleEditable="editable = !editable"
            :editable="editable"
            ref="wizard"
        />
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

import { Swal } from "@/components/utils/swal.alerts.js";

import ApiService from "@/components/utils/api.service.js"
import ApiCalls from "@/components/utils/api.calls.js"

import ValuationSubjectWizard from "./ValuationSubjectWizard.vue"

export default {
    props: {
        project_id: {
            type: Number,
            required: true
        },
        new_re_key: {
            type: String,
            default: null,
            required: false
        }
    },
    data() {
        return {
            valuation_subject: null,
            loading: false,
            updating_project: false,
            editable: this.new_re_key != null,
        }
    },
    components: {
        Loading,
        ValuationSubjectWizard
    },
    methods: {
        getProjectValuationSubject: function() {
            const app = this;
            app.loading = true;
            ApiService
                .get(`/amas/project/api/${this.project_id}/valuation-subject`)
                .then(response => {
                    this.valuation_subject = response.data;
                    let requests = [];
                    this.valuation_subject.real_estates.forEach((element, element_idx) => {
                        requests.push( // Add it to array so loader is only set once!
                            ApiCalls.getRealEstateData(element.re_key)
                                .then(real_estate => {
                                    element.real_estate = real_estate;
                                    app.$set(app.valuation_subject.real_estates, element_idx, element)

                                    ApiCalls.getRealEstateGeometry(element.re_key)
                                        .then(res => {
                                            element.geom = res;
                                            app.$set(app.valuation_subject.real_estates, element_idx, element)
                                        });
                                        
                                    return real_estate;
                                })
                        )
                    })
                    Promise.all(requests).then(_results => {
                        app.valuation_subject.session_list.forEach((session, session_idx) => {
                            session = app.$refs["wizard"].setSessionValuesAsync(session);
                            app.$set(app.valuation_subject.session_list, session_idx, session);
                        })
                        if(app.new_re_key) {
                            app.$refs["wizard"]
                                .addRealEstateToSubject(app.new_re_key, "new")
                                .then(_ => {
                                    app.$refs["wizard"]
                                        .getValuationSubjectEstimation(app.new_re_key)
                                        .then(_ => {
                                            app.loading = false;
                                        });
                                })
                        } else {
                            app.loading = false;
                        }
                    })                    
                })
        },
        handleFinishClick: function() {
            const app = this;
            if (this.new_re_key != null) {
                // A new project, don't show this warning
                app.updateProject();
            } else {
                Swal({
                    title: this.$t("general:are_you_sure"),   
                    text: this.$t("task_details:valuation:subject_will_be_saved_steps_rerun_changes_will_be_overriden:warning"),
                    icon: "warning",   
                    showCancelButton: true,   
                    confirmButtonColor: "#2cabe3",   
                    confirmButtonText: this.$t("general:yes_confirm"),
                    cancelButtonText: this.$t("general:no_cancel"),
                }).then(async function(val){
                    if (val.isConfirmed) {
                        app.updateProject();
                    }
                });
            }
        },
        updateProject: function() {
            this.updating_project = true;
            ApiService
                .put(`/amas/project/api/${this.project_id}/valuation-subject`, this.valuation_subject)
                .then(response => {
                    this.updating_project = false;
                    Swal({
                        title: "",
                        text: this.$t("general:save:successful"),
                        icon: "success",
                    })
                    window.location.href = `/amas/project/${response.data.task_id}`;
                })
                .catch(error => {
                    this.updating_project = false;
                    let text;
                    if(error.response.status==403) {
                        text = error.response.data.detail;
                    } else {
                        text = this.$t("general:save:admins_were_notified:error");
                    }
                    Swal({
                        title: "",
                        text: text,
                        icon: "error",
                    })
                    if(error.response.status!=403) {
                        throw error;
                    }
                })
        }
    },
    mounted() {
        this.getProjectValuationSubject();
    },
}
</script>
