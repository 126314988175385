<template>
    <div class="text-center">
        <div class="m-b-5" v-if="!session.is_completed">
            <a class="btn btn-info" type="button" @click="requestLocation()">
                Zahtevaj lokacijo
                <i v-if="status == 'in_progress'" class="fa fa-spinner fa-spin"></i>
            </a>

            <a v-if="!client_location && client_location_requested" class="btn btn-info m-l-10" type="button" @click="$emit('finished', step_no, true)">
                Nadaljuj brez lokacije
            </a>
        </div>

        <div v-if="msg">{{ msg }}</div>
        <div v-if="status == 'location_request_denied'">
            <ModeratorEnableLocation />
        </div>

        <div class="vld-parent">
            <loading :active="status == 'in_progress'" :can-cancel="false" :is-full-page="false" vl/>

            <k-simple-leaflet-map 
                v-if="client_location"
                :fullscreen="true"
                :zoom="18"
                :max-zoom="20"
                :center="client_location"
                :markers="markers"
                :height="200"
            >
            </k-simple-leaflet-map>
        </div>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js";
import KSimpleLeafletMap from "@/components/k-leaflet-map/KSimpleLeafletMap";
import mixins from "../mixins.js"
import ModeratorEnableLocation from "../instructions/ModeratorEnableLocation.vue";

export default {
    mixins: [ mixins ],
    model: {
        prop: 'session',
        event: 'change'
    },
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
    components: {
        "k-simple-leaflet-map": KSimpleLeafletMap,
        ModeratorEnableLocation,
    },
    data: function () {
        return {
            step_no: 2,

            client_location_requested: false,
            status: null,
            // in_progress
            // failed
            // success
            // location_request_denied
        };
    },
    methods: {
        requestLocation: function () {
            const app = this;
            app.client_location_requested = true;
            app.$store.state.OTsession.signal(
                {
                    type: "location",
                    data: "location_request",
                },
                (error) => {
                    if (error) {
                        app.status = "failed";
                    } else {
                        app.status = "in_progress";
                    }
                }
            );
        },
        handleLocationReported: function (event) {
            const app = this;

            if (event.data === "ERROR_REJECTED") {
                app.notify(
                    "Stranka ni dovolila deljenje lokacije",
                    "",
                    "error"
                );
                app.status = "location_request_denied";
                return;
            }

            let loc = event.data;
            if (loc.indexOf(";") > 0) {
                app.status = "success";
                let lat = parseFloat(loc.split(";")[0]);
                let lng = parseFloat(loc.split(";")[1]);
                app.session.client_gps = { "type": "Point", "coordinates": [ lng, lat ] } 
                app.$emit("change", app.session); // Emit change event to parent
                ApiService.patch(
                    `/virtual-visit/api/virtual-session/${app.session.id}`,
                    {
                        client_gps: `POINT(${lng} ${lat})`,
                    }
                )
                    .then((response) => {
                        app.$emit("finished", app.step_no);
                    })
                    .catch((error) => {
                        throw error;
                    })
                app.notify("Lokacija prejeta", "", "success");
            }
        },
        setupOTSessionListeners: function () {
            const app = this;
            if (!app.OTsession) { return; }
            app.OTsession.on("signal", (event) => {
                if (event.type == "signal:location") {
                    app.handleLocationReported(event);
                }
            });
        },
    },
    computed: {
        OTsession() {
            return this.$store.state.OTsession;
        },
        markers() {
            return this.client_location
                ? [{ coordinates: this.client_location }]
                : null;
        },
        msg() {
            let msg;
            if (this.status == "in_progress") {
                msg = this.$t("virtual_visit:location_request:in_progress");
            } else if (this.status == "failed") {
                msg = this.$t("virtual_visit:location_request:error_message");
            }
            return msg;
        },
        client_location() {
            return this.session.client_gps
                ? {
                    lat: parseFloat(this.session.client_gps.coordinates[1]),
                    lng: parseFloat(this.session.client_gps.coordinates[0]),
                }
                : null;
        }
    },
    created() {
        if (this.session.client_gps) {
            this.$emit("finished", this.step_no);
        }
    },
    watch: {
        OTsession: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.setupOTSessionListeners();
                }
            },
        },
    },
};
</script>
