

class Cookies {
    static setCookie(name, value, expiresInSeconds) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + expiresInSeconds * 1000);
        var c_value = escape(value) + ((expiresInSeconds == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = name + "=" + c_value + '; path=/';
    }
    
    static getCookie(name) {
        var parts = document.cookie.split(name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }
    
    static expireCookie(name) {
        document.cookie = encodeURIComponent(name) + "=; path=/; expires=" + new Date(0).toUTCString();
    }

    static startTimeoutChecker(cookieName, finishedCallback, timeoutCallback, timeoutMs, intervalMs=100) {
        Cookies.setCookie(cookieName, 0, timeoutMs);
        
        if(!timeoutMs) { timeoutMs = 60*1000 }
        let downloadTimer = setInterval(() => {
            let cookie = Cookies.getCookie(cookieName);
            if (typeof cookie === 'undefined') {
                finishedCallback();
                clearInterval(downloadTimer);
            }
            if (timeoutMs <= 0) {
                Cookies.expireCookie(cookieName);
                timeoutCallback();
                clearInterval(downloadTimer);
            }
            timeoutMs = timeoutMs - intervalMs;
        }, intervalMs)
    }
}

export default Cookies;