<template>
    <div class="table-responsive">
        <table class="table table-striped vld-parent">
            <loading 
                :active="loading"
                :can-cancel="false" 
                :is-full-page="false"
                :z-index="1000"
                :height="40"
            />
            <thead>
                <tr>
                    <th
                        v-for="(column, inx) in columns_joined"
                        :key="uniqueKey('header', inx)"
                        :class="{
                            'sorting': column.sortable !== false,
                            'sorting-desc': column.sortable !== false && column.field == sortKey && sortOrder == -1,
                            'sorting-asc': column.sortable !== false && column.field == sortKey && sortOrder == 1,
                        }"
                    >
                        <div
                            v-if="column.sortable !== false"
                            class="header"
                            @click="clickSortable(column)"
                        >
                            <a>{{ column.label }}</a>
                            <div class="sort_icon"></div>
                        </div>
                        <template v-else>
                            {{ column.label }}
                        </template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, row_i) in orderedItems"
                    :key="uniqueKey('row', row_i)"
                    @click="$emit('item-row-click', item)"
                    :class="getRowClass(item)"
                >
                    <td
                        v-for="(column, col_i) in columns_joined"
                        :key="uniqueKey('col', row_i, col_i)"
                    >
                        <slot
                            v-if="column.slot_name"
                            :item="item"
                            :name="column.slot_name"
                        >
                            If the slot_name is set, please provide a slot with the same name!
                        </slot>
                        <template v-else>
                            {{ getSortConversion(column.field)(item) }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import tablemixin from "./mixins/tablemixin";

export default {
    name: "BaseTable",
    mixins: [ tablemixin ],
    data() {
        return {
            sortKey: null,
            sortOrder: 1,
        }
    },
    computed: {
        orderedItems() {
            if (!this.sortKey)
                return this.items;

            const sortConversion = this.getSortConversion(this.sortKey);
            return [...this.items].sort((a, b) => {
                a = sortConversion(a);
                b = sortConversion(b);
                if (a > b || !b)
                    return 1 * this.sortOrder;
                if (a < b || !a)
                    return -1 * this.sortOrder;
                return 0;
            });
        },
    },
    methods: {
        clickSortable(column) {
            if (this.sortKey && this.sortKey == column.field) {
                if (this.sortOrder == 1)
                    this.sortOrder = -1;
                else
                    this.sortKey = null;
            } else {
                this.sortKey = column.field;
                this.sortOrder = 1;
            }
        },
    },
}
</script>

<style scoped>
    th .header {
        display: flex;
        flex-direction: row;
        gap: 2px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
    }
    th.sorting .sort_icon::after {
        content: "\f0dc";
        font-family: fontawesome;
        color: rgba(50, 50, 50, .5);
    }
    th.sorting-asc .sort_icon::after {
        content: "\f0de";
    }
    th.sorting-desc .sort_icon::after {
        content: "\f0dd";
    }
</style>
