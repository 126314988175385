<template>
  <div>
    <table class="table text-center">
        <thead>
            <tr>
                <th class="text-center">
                    <!-- Index -->
                </th>
                <th class="text-center" v-if="includeImages">
                </th>
                <th class="text-center">
                  {{ $t('general:real_estate')}}
                </th>
                <th class="text-center">
                    {{ $t('general:size')}}
                </th>
                <th class="text-center">
                    {{ $t('general:year:built')}}
                </th>
                <th class="text-center">
                    {{ $t('general:year:sold')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:sell_price')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:used_price')}}
                </th>
                <th class="text-center">

                </th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(item, index) in transactions" :key="index" :class="{'highlighted': highlightTransactionId==item.id}">
                <td class="p-5 p-r-0">
                    <div class="property-iq__index-block"><strong>{{item.idx}}</strong></div>
                </td>
                <td v-if="includeImages" class="p-5">
                    <a v-if="item.streetview_image" 
                        :href="item.streetview_image"
                        data-toggle="lightbox"
                        :data-gallery="'transaction-gallery-'+index"
                        :data-title="getLightboxTitle(item)"
                    >
                        <b-img
                            :src="item.streetview_image"
                            :height="70"
                            :width="100"
                        />
                    </a>
                    <div v-else-if="$store.state.retrievingImages.inprogress.includes(item.id)">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else-if="$store.state.retrievingImages.failed.includes(item.id)">
                        <i class="fa fa-close text-danger"></i>
                    </div>
                    <div v-else>
                        <b-img class="gray-box"
                          :src="require('@/images/home-placeholder.png')"
                          :height="70"
                          :width="100"
                        />
                    </div>
                </td>
                <td>
                    <div class="font-medium re-key" :v-tooltip="$t('widgets:modal:real_estate_details')">
                      <a class="open-modal" :href="'/market/transaction/'+item.transaction_id+'/details?force_active_app=propertyiq'">
                        {{item.address||'/'}}
                      </a>
                    </div>
                    <span class="text-muted">{{item.get_component_type_display}}</span>
                </td>
                <td>
                    <span class="font-medium">{{item.component_size||'/'|formatSize}}</span>
                    <div v-if="item.transaction_sum_parcel_sizes" class="text-muted">{{item.transaction_sum_parcel_sizes||'/'|formatSize}}
                      {{ $t('general:parcel2')}}</div>
                </td>
                <td>
                    {{item.building_year_built}}
                </td>
                <td>
                    {{item.transaction_date}}
                </td>
                <td>
                    <span class="text-info">{{item.transaction_amount|formatCurrency(0)}}</span>
                    <br>
                    <span v-if="item.transaction_amount_m2" class="text-primary">{{item.transaction_amount_m2|formatCurrencym2(0)}}</span>
                    <span v-else-if="item.is_estimated_amount" class="text-primary">{{item.estimated_amount_m2|formatCurrencym2(0)}}</span>
                    <span v-else class="text-primary">/</span>

                    <span v-if="item.component_tax"><br>{{ $t('general:with')}} {{ item.component_tax }}% {{$t('general:vat')}}</span>
                </td>

                <td  @click="showAdjustments(item)">
                  <div class="custom-cursor-pointer">
                    <span class="text-info">{{item.adjusted_price|formatCurrency(0)}}</span><i style="margin-left: 5px" class="fa fa-info-circle"></i>
                    <br>
                    <span v-if="item.adjusted_price_m2" class="text-primary">{{item.adjusted_price_m2|formatCurrencym2(0)}}</span>
                    <span v-else-if="item.is_estimated_amount" class="text-primary">{{item.estimated_amount_m2|formatCurrencym2(0)}}</span>
                  </div>
                  <div>
                    <AdjustmentsModal
                      :item="item"
                    />
                  </div>
                </td>

                <td>
                    <a v-on:click.prevent.stop="$emit('comparable-clicked', item)" href="#"
                        v-tooltip="getCheckboxIconTooltip(item.id)"
                    >
                        <i class="fa" :class="getCheckboxIconClass(item.id)"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import AdjustmentsModal from "./AdjustmentsModal.vue";

export default {
  name: "ComparableTransactionsTable",
  components: {AdjustmentsModal},
    props:  {
        transactions: {
            type: Array,
            default: () => []
        },
        comparableTransactionItemsIds: {
            type: Array,
            default: () => []
        },
        includeImages: {
            type: Boolean,
            default: false
        },
        highlightTransactionId: {
            type: String,
        }
    },
    methods: {
        showAdjustments(item){
          this.$modal.show('adjustment-details-'+ item.id)
        },
        hideAdjustmentModal(id){
          this.$modal.hide('adjustment-details-'+ id)
        },



        getCheckboxIconClass: function(item_id) {
            if (this.$store.state.failedTransactionItemsIds.includes(item_id)) {
                return "fa fa-exclamation-circle";
            }
            if (this.$store.state.inprogressTransactionItemsIds.includes(item_id)) {
                return "fa fa-spinner fa-spin";
            }
            
            if(this.comparableTransactionItemsIds.includes(item_id)) {
                return "fa-check-square-o";
            } else {
                return "fa-square-o";
            }
        },
        getCheckboxIconTooltip: function(item_id) {
            if(this.comparableTransactionItemsIds.includes(item_id)) {
                return this.$t('comparable:remove');
            } else {
                return this.$t('comparable:add');
            }
        },
        getLightboxTitle: function(item) {
            let text = `${item.idx}. `;
            if(item.address) {text += item.address + ", "}
            text += item.get_component_type_display
            return text;
        }
    }
}
</script>

<style scoped>
.highlighted {
    background-color: #ff767648;
}

</style>