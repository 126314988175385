<template>

    <div style="min-height: 100px" class="property-iq__comparable-transactions-tab vld-parent">
        <div class="panel panel-default">
            <div class="panel-heading">
                {{ $t('property_iq:history:transaction_title')}}        
            </div>
            <div v-if="transactions.length" class="panel-body">
                <p>
                    {{ $t('property_iq:history:transaction')}}
                    <a href="https://www.arvio.si/zanesljivost-podatkov-o-sklenjenih-stransakcijah/" target="_blank">{{$t("general:more")}}</a> {{$t("legal:about_data_collection")}}.
                </p>
            </div>
        
            <loading 
                :active="loading_transactions_table"
                :can-cancel="false" 
                :is-full-page="false"
            />
            <transactions-table
                v-if="transactions.length"
                :transactions="indexedTransactions"
            />
            <div v-else-if="!loading_transactions_table" class="panel-body">
                <p>
                    {{ $t('property_iq:history:transaction_error')}}
                    <a href="https://www.arvio.si/zanesljivost-podatkov-o-sklenjenih-stransakcijah/" target="_blank">{{$t("general:more")}}</a> {{$t("legal:about_data_collection")}}.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


import ApiService from "@/components/utils/api.service.js"

import ComparableTransactionsTable from "./Table.vue";


export default {
    components: {
        "transactions-table": ComparableTransactionsTable,
        Loading,
    },
    props:  {
        re_key: {
            type: String,
        },
    },
    data: function () {
        return {
            transactions: [],
            loading_transactions_table: false,
        }
    },
    computed: {
        
        indexedTransactions: function() {
            return this.transactions.map((transaction, idx) => {
                let i = {...transaction, ...{idx: idx + 1}};
                return i
            }).sort((a, b) => {
                return this._parseDate(b.transaction_date) - this._parseDate(a.transaction_date);
            })
        }
    },
    methods: {
        _parseDate: function(date_str) {
            var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            var dt = new Date(date_str.replace(pattern,'$3-$2-$1'));
            return dt;
        },
        getTransactions: function() {
            this.loading_transactions_table = true;
            ApiService
                .get("/market/api/search", {
                    params: { unit_id: this.re_key }
                })
                .then(response => {
                    this.transactions = response.data;
                    this.loading_transactions_table = false;
                })
                .catch(error => {
                    this.loading_transactions_table = false;
                    throw error;
                })
        }
    },
    mounted() {
        this.getTransactions();
    }
}
</script>