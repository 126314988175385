<template>
    <div class="vld-parent" style="min-height: 600px">

        <loading 
            :active="loading" 
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <div class="col-lg-4 m-b-20">
                <div>{{$t("task_details:manage:enter_re_key_with_new_lines")}}</div>
                <add-rekeys-textarea
                    @submit="addRealEstates"
                />
            </div>
        </div>

        <div class="row" v-if="orderedRealEstates.length > 0">
            <div class="col-md-12">
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th>{{$t("general:re_key")}}</th>
                            <th class="text-nowrap">{{$t("task_details:manage:leading")}} <help-icon slug="predmet-vrednotenja-vodilna-nepremicnina" /></th>
                            <th>{{$t("general:real_estate")}}</th>
                            <th>{{$t("task_details:manage:considered_re_type")}}</th>
                            <th>{{$t("task_details:manage:considered_size")}}</th>
                            <th class="text-center">{{$t("task_details:manage:owners")}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr 
                            v-for="(item, idx) in orderedRealEstates"
                            :key="'valuation-subject-item-'+idx"
                            :class="isRowHighlighted(item) ? 'warning' : ''"
                            @mouseover="highlightRealEstate(item.re_key)"
                            @mouseleave="highlightRealEstate(null)"
                        >
                            <!-- ID znak -->
                            <td class="text-nowrap">
                                <re-open-modal :re_key="item.re_key" />
                                <div>
                                    <span 
                                        class="text-danger small custom-cursor-pointer" 
                                        v-tooltip="$t('task_details:manage:remove_from_validation')"
                                        @click.prevent="removeRealEstate(item.id)"
                                    >
                                        {{$t("general:remove")}}
                                    </span>
                                </div>
                            </td>

                            <!-- Vodilna -->
                            <td>
                                <input type="checkbox" 
                                    :checked="item.main_property"
                                    @click="handlesetMainPropertyCheckboxClick($event, item)"
                                />
                            </td>

                            <!-- Nepremičnina -->
                            <td>
                                <div v-if="item.real_estate">
                                    <span>
                                        {{item.real_estate.summary}}
                                        <i
                                            v-if="_hasSeparateBasementsOrGarages(item.real_estate)"
                                            v-tooltip="$t('task_details:manage:building_has_seperate_re_keys_for_cellar_or_parking')"
                                            class="fa fa-building text-warning m-l-5"
                                        ></i>
                                    
                                    </span>

                                    <real-estate-additional-info
                                        :real_estate="item.real_estate"
                                        :subject="subject"
                                        :session="session"
                                        @add-real-estates="addRealEstates"
                                    />
                                </div>                        
                            </td>

                            <!-- Tip -->
                            <td>
                                <type-dropwdown
                                    v-model="item.custom_type"
                                    :default-display-if-null="item.real_estate.re_type"
                                    :isParcel="item.real_estate.is_parcel"
                                    @reset="item.custom_type=null"
                                />
                            </td>

                            <!-- Velikost -->
                            <td>
                                <div>
                                    <div style="float:left; width:90%" >
                                        <number-input-field
                                                v-model="item.custom_size"
                                                v-on:input="$emit('size-changed')"
                                                :placeholder="_getRealEstateSizeDisplay(item)"
                                                inputFieldsAddon="m2"
                                                size="sm"
                                        />
                                    </div>
                                    <div style="float:right; width:10%">
                                        <unit-size-estimate-indicator
                                            v-if="item.real_estate.unit && !item.custom_size"
                                            :unit="item.real_estate.unit" 
                                        >
                                        </unit-size-estimate-indicator>
                                    </div>
                                </div>
                            </td>

                            <!-- Lastniki -->
                            <td class="text-center">
                                <div v-if="item.real_estate && item.real_estate.legal_status==='loading'">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                                <!-- START Owners -->
                                <div v-else-if="item.real_estate && item.real_estate.legal_status && item.real_estate.legal_status.legal_file">
                                    <div v-for="(owner, owner_idx) in item.real_estate.legal_status.legal_owners" :key="'item-'+idx+'-owner-'+owner_idx">
                                        {{owner.name}}
                                        <span class="label label-rouded" 
                                            v-bind:class="owner.share.includes('1/1') ? 'label-success' : 'label-warning' "
                                        >
                                            {{owner.share}}
                                        </span>
                                    </div>
                                    <span v-tooltip="$t('task_details:manage:zk_extract:view')"  
                                        class="btn btn-xs btn-outline btn-rounded btn-info view-pdf m-t-10"
                                        v-if="item.real_estate.legal_status.legal_file"  
                                        :href="item.real_estate.legal_status.legal_file"
                                        :data-title="item.real_estate.legal_status.name"
                                    >
                                        {{$t("task_details:manage:zk_extract")}}
                                    </span>
                                </div>
                                
                                <div v-else>
                                    <span
                                        v-tooltip="$t('task_details:manage:zk_extract:get')"  
                                        class="btn btn-xs btn-outline btn-rounded btn-info"
                                        v-on:click.prevent="$emit('get-legal-status', item.re_key)"
                                    >
                                        {{$t("task_details:manage:zk_extract:get")}}
                                    </span>
                                </div>
                                <!-- END Owners -->
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

        <div class="row m-b-10" v-if="orderedRealEstates.length > 0">
            <div class="col-lg-12 text-center">
                <button
                    class="btn btn-info"
                    v-on:click.prevent="$emit('close-me')"
                >
                    {{$t("general:save")}}
                </button>
            </div>
        </div>

        <div class="row" v-if="orderedRealEstates.length > 0">
            <div class="col-lg-12">
                <valuation-subject-wizard-map
                    :real_estates="orderedRealEstates"
                    :highlighted_re_key="highlighted_re_key"
                    @add-real-estates="addRealEstates"
                    @highlight-real-estate="highlightRealEstate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ReOpenModal from "@/components/widgets/ReOpenModal.vue"
import HelpIcon from "@/components/widgets/HelpIcon";
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";

import AddRekeysTextarea from "../components/AddRekeysTextarea.vue"
import RealEstateAdditionalInfo from "../components/RealEstateAdditionalInfo.vue"
import ValuationSubjectWizardMap from "../components/map/ValuationSubjectWizardMap.vue"
import RealEstateTypeDropDown from "../components/RealEstateTypeDropDown.vue";
import VSUtils from "../components/utils.js"

import UnitSizeEstimateIndicator from "@/../apps/real_estate/vue/components/UnitSizeEstimateIndicator.vue"

export default {
    model: {
        prop: "subject",
        event: "change"
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        session: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Loading, HelpIcon,
        ReOpenModal,
        AddRekeysTextarea,
        RealEstateAdditionalInfo,
        ValuationSubjectWizardMap,
        NumberInputField,
        "type-dropwdown": RealEstateTypeDropDown,
        UnitSizeEstimateIndicator
    },
    data: function () {
        return {
            VSUtils: VSUtils,
            highlighted_re_key: null,
        }
    },
    methods: {
        addRealEstates: function(re_keys) {
            if(!Array.isArray(re_keys)) {
                re_keys = re_keys.trim().split(/,|\n/)
                re_keys = re_keys.map((re_key) => re_key.trim())
            }
            this.$emit("add-re-to-subject", re_keys, this.session.id);
        },
        removeRealEstate: function(re_id) {
            this.$emit("remove-re-from-subject", re_id);
        },
        highlightRealEstate: function(re_key) {
            this.highlighted_re_key = re_key;
        },

        _getRealEstateSizeDisplay: function(real_estate){
            return VSUtils.getRealEstateSize(real_estate);
        },
        _hasSeparateBasementsOrGarages: function(real_estate){
            return real_estate.unit && real_estate.unit.building && (real_estate.unit.building.basements_separate_ids || real_estate.unit.building.garage_or_parking_separate_ids);
        },
        isRowHighlighted: function(real_estate) {
            // Handle building re_keys and apply correct class for units.
            let simple_re_key = real_estate.re_key.split("-")[0] + "-" + real_estate.re_key.split("-")[1];
            return real_estate.re_key===this.highlighted_re_key || simple_re_key===this.highlighted_re_key;
        },
        handlesetMainPropertyCheckboxClick: function(e, real_estate){
            if(e.target.checked){
                this.$emit("set-main-property", real_estate);
            } else {
                e.preventDefault();
            }
        },
    },
    computed: {
        orderedRealEstates: function() {
            return VSUtils.getSessionRealEstates(this.subject.real_estates, this.session);
        }
    }
}
</script>
