<template>
    <div>
        <button class="btn btn-info btn-outline"
            v-bind:class="{ 'disable-click-div disabled': isDisabled }"
            v-on:click.prevent="$modal.show(modal_name)">
                <i class="fa fa-shopping-basket"></i> Dodaj v košarico
        </button>

        <Bootstrap3Modal
            :name="modal_name"
            title="Dodajanje v košarico"
        >
            <p class="text-center">
                Dodaj <strong>{{transaction_ids.length}}</strong> poslov v širšem naboru v košarico ali ustvari novo
            </p>

            <div class="vld-parent">
                <loading
                    :active="baskets_loading" 
                    :can-cancel="false" 
                    :is-full-page="false"
                    loader="bars"
                >
                </loading>
                
                <div class="row m-t-20 m-b-10">
                    <div class="col-md-12">
                        <h3 v-if="baskets.length">Izberi košarico</h3>
                        <h3 v-else>Ni obstoječih košaric</h3>
                    </div>
                </div>
                <hr class="m-t-5 m-b-5">

                <!-- START ADD NEW BASKET -->
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control" v-model="new_basket_name">
                            <p class="text-secondary p-0 m-0"><small>*Vnesi ime nove košarice</small></p>
                        </div>
                        <div class="col-md-3">
                            <button 
                                class="btn btn-info btn-sm btn-outline" 
                                v-on:click.prevent="createNewBasket()">
                                    <i class="fa fa-plus"></i> Dodaj
                            </button>
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <hr class="m-t-5 m-b-5">
                </div>
                <!-- END ADD NEW BASKET -->

                <!-- START SELECT EXISTING BASKET -->
                <div v-for="b in baskets"
                    :key="b.id">
                    <div class="row">
                        <div class="col-md-6">
                            <strong>{{ b.name }}</strong>
                            <div class="text-secondary"><small>{{ b.transaction_ids.length }} poslov</small></div>
                        </div>
                        <div class="col-md-3">
                            <button 
                                class="btn btn-info btn-sm btn-outline" 
                                v-on:click.prevent="addTransactionIdsToBasket(b)">
                                    <i class="fa fa-plus"></i> Dodaj
                            </button>
                        </div>
                        <div class="col-md-3">
                            <span v-if="added_to_baskets.includes(b.id)"
                                class="text-success p-t-20">
                                    <i class="fa fa-check"></i> Dodano
                            </span>
                            <!-- <button
                                v-if="containsAllTransactionIds(b)"
                                class="btn btn-success btn-sm">
                                    Dodano
                            </button> -->
                        </div>
                    </div>
                    <hr class="m-t-5 m-b-5">
                </div>
                <!-- END SELECT EXISTING BASKET -->
            </div>
            
            
        </Bootstrap3Modal>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

import Bootstrap3Modal from '@/components/utils/Bootstrap3Modal.vue'
import ApiService from "@/components/utils/api.service.js";

import Cookies from 'js-cookie';

export default {
    name: 'BasketAddPopover',
    components: {
        Loading,
        Bootstrap3Modal
    },
    props:  {
        basket_url: String,
        basket_list_url: String,
        transaction_ids: {
            type: Array,
        },
    },
    data: function () {
        return {
            new_basket_name: "",
            baskets: [],
            baskets_loading: false,
            modal_name: "basket-add-modal",
            added_to_baskets: []
        }
    },
    methods: {
        getBasketUrl: function(basket_id) {
            return this.basket_url.replace("12345", basket_id);
        },
        createNewBasket: function() {
            ApiService
                .post(this.basket_list_url, {
                    name: this.new_basket_name,
                    transaction_ids: this.transaction_ids
                })
                .then(response => {
                    this.new_basket_name = "";
                    this.added_to_baskets.push(response.data.id);
                    setTimeout(() => {
                        this.$modal.hide(this.modal_name);
                        this.added_to_baskets = [];
                    }, 1500);
                })
                .catch(error => {
                    console.log(error);
                    throw error;
                })
                // eslint-disable-next-line no-unused-vars
                .finally(response => {
                    this.reloadBaskets();
                })
        },
        addTransactionIdsToBasket: function(basket) {
            ApiService
                .put(this.getBasketUrl(basket.id), {
                    transaction_ids: basket.transaction_ids.concat(this.transaction_ids)
                })
                .then(response => {
                    this.added_to_baskets.push(response.data.id);
                    setTimeout(() => {
                        this.$modal.hide(this.modal_name);
                        this.added_to_baskets = [];
                    }, 1500);
                })
                .catch(error => {
                    console.log(error);
                    throw error;
                })
                // eslint-disable-next-line no-unused-vars
                .finally(response => {
                    this.reloadBaskets();
                })
        },
        reloadBaskets: function() {
            this.baskets_loading = true;
            ApiService
                .get(this.basket_list_url)
                .then(response => {
                    this.baskets = response.data
                })
                .catch(error => {
                    console.log(error);
                    throw error;
                })
                // eslint-disable-next-line no-unused-vars
                .finally(response => {
                    this.baskets_loading = false;
                })
        },
        containsAllTransactionIds: function(basket) {
            let checker = (arr, target) => target.every(v => arr.includes(v));
            return checker(basket.transaction_ids, this.transaction_ids);
        },
    },
    mounted() {
        this.reloadBaskets();
        ApiService.set_headers({
            "X-CSRFToken": Cookies.get("csrftoken")
        })
    },
    computed: {
        isDisabled: function() {
            return !this.transaction_ids || !this.transaction_ids.length
        },
    }
}
</script>
