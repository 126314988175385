var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-box p-20 p-b-5 dashboard-graph"},[_c('div',[_c('h3',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.graphTitle)+" ")]),(_vm.subtitle)?_c('div',[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.description)?_c('div',{staticClass:"text-muted text-smaller"},[_vm._v(" "+_vm._s(_vm.description)+" "+_vm._s(_vm.formatUnit(_vm.currentUnit))+" ")]):_vm._e()]),(_vm.graphs[0].data.length > 2)?_c('div',[(_vm.showGrowthOverUnits)?_c('table',[_c('tr',{staticClass:"text-muted text-smaller"},[_c('td',[_vm._v(_vm._s(_vm.$t("dashboard:last_24_months")))]),_c('td',[_vm._v(_vm._s(_vm.$t("dashboard:last_12_months")))]),_c('td',[_vm._v(_vm._s(_vm.$t("dashboard:last_quarter")))])]),_c('tr',{staticClass:"text-bold"},[_c('td',[_vm._v(_vm._s(_vm.apiData.growth.prevTwoYears)),(_vm.showGrowthPercentageUnit)?_c('span',[_vm._v(" %")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.apiData.growth.prevYear)),(_vm.showGrowthPercentageUnit)?_c('span',[_vm._v(" %")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.apiData.growth.prevUnit)),(_vm.showGrowthPercentageUnit)?_c('span',[_vm._v(" %")]):_vm._e()])])]):_vm._e(),_c('div',{class:{'chart-container': _vm.showValuesTable, 'row': true}},[_c('apexchart',{class:{'chart': _vm.showValuesTable, 'col-sm-12': !_vm.showValuesTable},attrs:{"type":_vm.graphType,"height":_vm.graphHeight,"options":_vm.chartOptions,"series":_vm.chartSeries}}),(_vm.showValuesTable)?_c('div',{staticClass:"values-table"},_vm._l((_vm.graphs[0].data),function(value,i){return _c('div',{key:i},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6"},[_vm._v(_vm._s(_vm.apiData.labels[i].replace(/\s/g, '\u00a0')))]),_c('div',{staticClass:"col-xs-6 text-right"},[_vm._v(_vm._s(value.toLocaleString("sl-SI")))])])])}),0):_vm._e()],1)]):_c('div',[_c('div',[_c('h3',{staticClass:"text-right"},[(_vm.showRelativeChange)?_c('i',{class:{
                    'ti-arrow-up text-success': _vm.showIcons && _vm.graphGrowth > 0,
                    'ti-arrow-down text-danger': _vm.showIcons && _vm.graphGrowth < 0,
                }}):_vm._e(),_vm._v(" "+_vm._s(_vm.growthDisplay)+" "),(_vm.displayUnit !== undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.displayUnit))]):_vm._e()])]),(_vm.showRelativeChange)?_c('div',{class:[{
                'text-success': _vm.graphGrowth > 0,
                'text-info': _vm.graphGrowth === 0,
                'text-danger': _vm.graphGrowth < 0,
            }, 'text-smaller']},[(_vm.graphGrowth < 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(Math.abs(_vm.apiData.growth[_vm.growthOverUnit]))+" % "+_vm._s(_vm.graphGrowthOverText)+" "+_vm._s(_vm.formatUnit(_vm.previousUnit))+" ")]):_vm._e()]),_vm._t("data_source",[(_vm.apiData.dataSource !== undefined)?_c('div',{staticClass:"data-source-info"},[(_vm.apiData.dataSource.dataSourceNote !== undefined)?_c('div',[_c('i',[_vm._v(_vm._s(_vm.$t("note")))]),_vm._v(": "+_vm._s(_vm.$t("widgets:data_source:volume_note"))+" "),_c('help-icon',{attrs:{"slug":"ekstrapolacija-stevila-transakcij"}})],1):_vm._e(),(_vm.apiData.dataSource.dataSourceNormalization !== undefined)?_c('div',[_c('i',[_vm._v(_vm._s(_vm.$t("note")))]),_vm._v(": "+_vm._s(_vm.apiData.dataSource.dataSourceNormalization)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("widgets:data_source:source"))+": "+_vm._s(_vm.apiData.dataSource.source)+", "+_vm._s(_vm.$t("widgets:data_source:arvio"))+_vm._s(_vm.$t("widgets:data_source:source3"))+" "+_vm._s(_vm.apiData.dataSource.date)+" "),_c('help-icon',{attrs:{"slug":_vm.infoSlug}})],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }