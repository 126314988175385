<template>
    <div>
        <li
            v-for="(report, index) in session.available_report_templates"
            :key="report.id"
            :id="'report-' + report.id"
            :class="[
                'list-unstyled p-b-10 p-t-10',
                index !== session.available_report_templates.length -1 ? 'border-bottom' : '',
            ]"
        >
            <p>
                {{ report.report_name }}
                <i
                    v-if="report.vv_user_signature_required"
                    class="fa fa-exclamation-circle text-warning m-r-5"
                    v-tooltip="'Obvezno'"
                ></i>
                <i
                    v-if="getDocumentsForTemplate(report.id).length > 0"
                    class="fa fa-check text-success"
                    v-tooltip="'Podpisano'"
                ></i>
            </p>
            <a
                v-if="!session.is_completed"
                class="btn btn-info"
                type="button"
                @click="sendReportToClient(report.id)"
            >
                <i :class="getReportButtonIconClass(report.id)"></i>
                {{ $t("general:send_form") }}
            </a>

            <a
                :class="[
                    'open-modal m-l-5 btn',
                    getDocumentsForTemplate(report.id).length > 0
                        ? 'btn-success'
                        : 'btn-default disabled',
                ]"
                :href="generateGetDocumentUrl(report.id)"
                id="show-report"
            >
                {{ $t("general:show") }}
            </a>
        </li>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js";
import { watch } from "vue";

export default {
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            step_no: 1,
            reports_in_progress: [],
        };
    },
    methods: {
        getDocumentsForTemplate: function (template_id) {
            return this.session.documents.filter(function (document) {
                return document.report_template === template_id && document.result;
            });
        },
        generateGetTemplateUrl: function (template_id) {
            return ApiService.constructUrl(`/virtual-visit/get_template/${this.session.session_id}/${template_id}`);
        },
        generateGetDocumentUrl: function (template_id) {
            let documents = this.getDocumentsForTemplate(template_id);
            if (documents.length === 0) { return ""; }
            let document_id = documents[0].id;
            return ApiService.constructUrl(`/virtual-visit/get_session_document/${document_id}`);
        },
        getReportButtonIconClass: function (template_id) {
            let cls = "report-spinner";
            if (this.reports_in_progress.includes(template_id)) {
                cls += " fa fa-spin fa-spinner";
            }
            return cls;
        },
        sendReportToClient: function (template_id) {
            const app = this;
            app.reports_in_progress.push(template_id);
            app.$store.state.OTsession.signal({
                type: "send-report",
                data: app.generateGetTemplateUrl(template_id),
            });
        },
        setupOTSessionListeners: function () {
            function extractNumberFromUrl(url) {
                // http://127.0.0.1:8000/virtual-visit/get_template/2_MX40NjQyMTM3Mn5-MTcyMTYzNjYyMDU5Nn5XQUpvekFDbUxSeTFRS3hKN0s4aUhnTFB-fn4/14
                const regex = /\/(\d+)$/;
                const match = url.match(regex);
                return match ? match[1] : null;
            }

            const app = this;
            if (!app.OTsession) { return; }
            app.OTsession.on("signal:report-close", (event) => {
                let reportId = extractNumberFromUrl(event.data);
                app.reports_in_progress = app.reports_in_progress.filter(
                    (item) => { return item != reportId; }
                );
            });
            app.OTsession.on("signal:report-submit", (event) => {
                let reportId = extractNumberFromUrl(event.data);
                app.reports_in_progress = app.reports_in_progress.filter(
                    (item) => { return item != reportId; }
                );
                app.$emit("reloadSessionData");
            });
        },
    },
    computed: {
        OTsession() {
            return this.$store.state.OTsession;
        },
        unsignedReports() {
            return this.session.available_report_templates.filter((report) => {
                return this.getDocumentsForTemplate(report.id).length == 0;
            });
        },
        unsignedRequiredReports() {
            return this.unsignedReports.filter((report) => {
                return report.vv_user_signature_required;
            });
        },
        signedReports() {
            return this.session.available_report_templates.filter((report) => {
                return this.getDocumentsForTemplate(report.id).length > 0;
            });
        },
        signedRequiredReports() {
            return this.signedReports.filter((report) => {
                return report.vv_user_signature_required;
            });
        },
    },
    watch: {
        OTsession: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.setupOTSessionListeners();
                }
            },
        },
        // When a new session is loaded, check if all required reports are signed
        session: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (this.unsignedRequiredReports && this.unsignedRequiredReports.length == 0) {
                    this.$emit("finished", this.step_no);
                }
            },
        },
    },
};
</script>

<style scoped>
.border-bottom {
    border-bottom: 1px solid whitesmoke;
}
</style>