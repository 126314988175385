<template>
    <wms-leaflet-map
        class="property-iq__comunal-equipment-map-div"
        :gursSessionId="$store.state.gursSessionId"
        :height="400"
        :fullscreen="true"
        :zoom="19"
        :minZoom="17"
        :maxZoom="20"
        :center="realEstate.gps"
        :polygons="polygons"
        :extraLayers="communalEquipmentLayers"
        :legend="communalEquipmentLegend"
        ref="communalEquipmentMap"
    >
        <template v-slot:popup_content_polygon="slotProps">
            <div class="p-10" style="white-space: nowrap;">{{ $t('property_iq:parcel')}} {{slotProps.polygon.property_id}}</div>
        </template>

        <template v-slot:markers>
            <l-marker
                v-if="marker"
                :lat-lng="marker.coordinates"
                :icon="house_icon"
            >
                <l-popup
                    :options="{autoPan: true}"
                >
                    <div class="p-10">{{realEstate.address}}</div>
                </l-popup>
            </l-marker>
        </template>
        
    </wms-leaflet-map>
</template>

<script>
import L from 'leaflet';

import { LPopup, LMarker } from 'vue2-leaflet';

import KWMSLeafletMap from "@/components/k-leaflet-map/KWMSLeafletMap";
import { allCommunalEquipmentList, wmsUrlPrivate } from "@/components/k-leaflet-map/utils/communal-equipment-list.js"

export default {
    components: {
        LPopup, LMarker,
        "wms-leaflet-map": KWMSLeafletMap
    },
    props: {
        realEstate: Object,
        polygons: Array,
    },
    data() {
        return {
            marker: { coordinates: this.realEstate.gps},
            communalEquipmentLayersToInclude: [
                this.$t('property_iq:equipment:electricity'),
                this.$t('property_iq:equipment:communication'),
                this.$t('property_iq:equipment:sewage'),
                this.$t('property_iq:equipment:trash'),
                this.$t('property_iq:equipment:water_infrastructure'),
                this.$t('property_iq:equipment:water_system'),
                this.$t('property_iq:equipment:gas'),
                this.$t('property_iq:equipment:heating_energy')
            ],
            house_icon: L.icon({
                iconUrl: require('@/images/house.png'),
                iconSize: [60, 60],
                iconAnchor: [30, 60],
                popupAnchor:  [0, -60]
            }),
        }
    },
    computed: {
        communalEquipmentLayers: function() {
            return allCommunalEquipmentList.filter(i => (
                this.communalEquipmentLayersToInclude.includes(i.name)
            )).map(l => (
                {
                    ...l,
                    ...{
                        "transparent": true,
                        "url": wmsUrlPrivate + `?sessionid=${this.$store.state.gursSessionId}`,
                        "type": 'overlay',
                        "visible": true,
                        "format": 'image/png',
                        "maxZoom": 19,
                    }
                }
            ))
        },
        communalEquipmentLegend: function() {
            const app = this;
            let legend = L.control({position: 'bottomleft'});
            legend.onAdd = function () {
                // Legend itself
                let communalEquipmentLegendDiv = L.DomUtil.create('div', 'm-t-10');
                app.communalEquipmentLayers.forEach( item => {
                    communalEquipmentLegendDiv.innerHTML += `
                    <div class="col-md-4">
                        <span class="leaflet-legend__icon">
                            <svg class="leaflet-icon" width="15" height="15">
                                <rect width="15" height="15" style="fill:${app.getCommunalEquipmentColor(item.name)};"/>
                            </svg>
                        </span>
                        <strong class="leaflet-legend__label"> - ${item.name}</strong>
                    </div>
                    `
                })
                app.communalEquipmentLegendDiv = communalEquipmentLegendDiv;

                // Toggle legend button
                let communalEquipmentLegendToggle = L.DomUtil.create('button', 'btn btn-xs')
                communalEquipmentLegendToggle.innerHTML += app.$t("maps:legend")
                L.DomEvent.addListener(communalEquipmentLegendToggle, 'click', function () {
                    app.communalEquipmentLegendDiv.toggleAttribute('hidden');
                });

                let div = L.DomUtil.create('div');
                div.appendChild(communalEquipmentLegendToggle);
                div.appendChild(communalEquipmentLegendDiv);

                return div;
            }
            return legend;
        }
    },
    methods: {
        getCommunalEquipmentColor: function(layerName) {

          // TODO rework this, it wont work with english translations
            // Map layerName to it's color in legend
            const colorMap = {
                "Električna energija": "#33cc33",
                "Električne komunikacije": "#00ccff",
                "Kanalizacija": "#664400",
                "Odpadki": "#697d3f",
                "Vodna infrastruktura": "#0066ff",
                "Vodovod": "blue",
                "Zemeljski plin": "yellow",
                "Toplotna energija": "#ff6600",
            }
            return colorMap[layerName];
        }
    }
}
</script>

<style>
.property-iq__comunal-equipment-map-div .leaflet-bottom.leaflet-left .leaflet-control {
    background-color: rgba(255, 255, 255, 0.473);
    margin: 0;
}
.leaflet-legend__icon,
.leaflet-legend__icon {
    vertical-align: middle;
    display: inline-block;
}
</style>
