<template>
    <div class="clusterable-map vld-parent">

        <loading
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        <l-map
            ref="map" 
            class="map"
            :options="options"
            :center="center"
        >
            <!-- <l-control-layers position="topright"></l-control-layers> -->
            <l-tile-layer
                v-for="(tilelayer, index) in tileLayers"
                :key="index"
                :name="tilelayer.name"
                :url="tilelayer.url"
                :attribution="tilelayer.attribution"
                :visible="tilelayer.visible"
                layer-type="base"
            />

            <l-control
                v-if="!getRegionFromParent && currentLevel !== startingLevel"
                :position="'topleft'"
            >
                <button class="btn" @click="getParentRegion">
                    <i class="fa fa-arrow-up"></i> {{parentHistory[currentLevel - 1].name}}
                </button>
            </l-control>
            <l-geo-json v-for="region in regions"
                :key="region.id"
                :geojson="region.geometry"
                :options="optionsGeoJSON"
                @click="getChildRegion($event, region.id, region.name)"
            />
        </l-map>
    </div>
</template>

<script>
import ApiService from '../utils/api.service';
import { LMap, LGeoJson, LControl, LTileLayer } from 'vue2-leaflet';
import { FeatureGroup } from 'leaflet';

const endingLevel = 3;

export default {
    name: "ClusterableMap",
    props: {
        re_type: {
            type: Number,
            required: true,
            default: 87,
        },
        getRegionFromParent: {
            type: Boolean,
            required: false,
            default: false,
        },
        regionFromParent: {
            type: Object,  // with id and name
            required: false,
            default() {
                return {id: 87, name: "Slovenija"};
            },
        },
        startingLevel: {
          type: Number,
            required: false,
            default: 1,
        }

    },
    components: {
        LMap,
        LGeoJson,
        LControl,
        LTileLayer
    },
    data: function() {
        return {
            tileLayers: [
                {
                    name: this.$t('maps:default'),
                    url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=d381e824-bb72-464b-a043-d6da7b42875d",
                    visible: true
                },
            ],
            regions: [],
            options: {
                zoomControl: false,
                scrollWheelZoom: false,
                dragging: false,
                doubleClickZoom: false,
                attributionControl: false,
            },
            center: {
                lat: 46.15039,
                lng: 14.91421,
            },
            optionsGeoJSON: {
                style: {  // Style options from: https://leafletjs.com/reference.html#path
                    color: "white",
                    weight: 1,
                    fillColor: "rgb(114, 117, 129, 0.4)",
                    fillOpacity: 1.0,
                }
            },
            currentLevel: this.startingLevel,
            currentRegion: this.regionFromParent,
            parentHistory: [],
            loading: false,
        }
    },
    methods: {
        _fitMapToBounds() {
            if (this.$refs.map !== undefined) {  // map must be defined in order to zoom in
                this.$nextTick().then(() => {
                    var group = new FeatureGroup();
                    this.$refs.map.mapObject.eachLayer(function(layer) {
                        if (layer.feature != undefined)
                            group.addLayer(layer);
                    });
                    this.$refs.map.mapObject.fitBounds(group.getBounds());
                });
            }
        },
        getRegion(id=undefined) {
            this.loading = true;
            ApiService
            .get("/market/analytics/api/regions", {
                params: {
                    parent_id: id !== undefined ? id : null,
                    level: this.currentLevel - (this.currentLevel === endingLevel ? 1 : 0),
                    region_type: this.re_type,
                }
            })
            .then(response => {
                if (response.data.length === 0) {
                    this.getParentRegion(); // return to parent
                    return;
                }
                this.regions = response.data;
                this._fitMapToBounds();
                this.loading = false;
            });
        },
        getChildRegion(event, id, name) {
            if (!this.getRegionFromParent && this.currentLevel < endingLevel) {
                if (this.currentRegion.id !== id) {
                    if (!this.getRegionFromParent) {
                        this.parentHistory[++this.currentLevel] = {id, name};
                        this.currentRegion = {id, name};
                    }
                    if (event !== undefined)
                        this.$refs.map.mapObject.fitBounds(event.layer.getBounds());  // zooming effect
                    this.getRegion(id);
                }
            }
        },
        getParentRegion() {
            if (!this.getRegionFromParent) {
                this.currentLevel--;
                const parent = this.parentHistory[this.currentLevel]
                this.getRegion(parent.id);
                this.currentRegion = parent;
            }
        },
    },
    mounted: function() {
        this.parentHistory[this.currentLevel] = this.currentRegion;
        this.$emit('region', this.currentRegion)
        this.getRegion();
        window.addEventListener("resize", this._fitMapToBounds)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this._fitMapToBounds)
    },
    created() {
    },
    watch: {
        currentRegion: function(newRegion) {
            this.$emit('region', newRegion)
        },
        regionFromParent: function(newRegion) {
            this.getRegion(newRegion.id);
        }
    },
}
</script>

<style>
.clusterable-map .map {
    height: 30vh !important;
    background-color: transparent;
    border-radius: 20px;
}
.clusterable-map .info-message {
    z-index: 1000;
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: medium;
}
</style>
