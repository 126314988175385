<template>
    <table class="table text-center">
        <thead>
            <tr>
                <th class="text-center">
                  {{ $t('analysis:transaction:id')}}
                </th>
                <th class="text-center">
                  {{ $t('general:transaction')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:deal_type')}}
                </th>
                <th class="text-center">
                  {{ $t('property_iq:tab:transaction_date')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:sell_price')}}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(item, index) in transactions" :key="index" >
                <td>
                    #{{item.transaction_id}}
                </td>
                <td>
                    <div class="font-medium re-key" :v-tooltip="$t('widgets:modal:real_estate_details')">
                      <a class="open-modal" :href="'/market/transaction/'+item.transaction_id+'/details'">
                        {{item.address||'/'}}
                      </a>
                    </div>
                    <span class="text-muted">{{item.get_component_type_display}}</span>
                </td>
                <td>
                    {{item.transaction_type_display}}
                </td>
                <td>
                    {{item.transaction_date}}
                </td>
                <td>
                    <span class="text-info">{{item.transaction_amount|formatCurrency(0)}}</span>
                    <br>
                    <span v-if="item.transaction_amount_m2" class="text-primary">{{item.transaction_amount_m2|formatCurrencym2(0)}}</span>
                    <span v-else-if="item.is_estimated_amount" class="text-primary">{{item.estimated_amount_m2|formatCurrencym2(0)}}</span>
                    <span v-else class="text-primary">/</span>

                    <span v-if="item.component_tax"><br>z {{ item.component_tax }}% DDV</span>
                </td>

            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "PropertyTransactionHistoryTable",
    props:  {
        transactions: {
            type: Array,
            default: () => []
        },
    },
}
</script>