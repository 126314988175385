<template>
    <!-- <tr class="vld-parent"
        @mouseover="$emit(mouseover, step.property_id)"
        @mouseout="$emit(mouseout, step.property_id)"
    > -->
    <tr class="vld-parent"
        :class="highlight ? 'bg-light' : ''"
    >
        <td>

            <loading 
                :active="is_loading"
                :can-cancel="false" 
                :is-full-page="false"
            />
            
            <re-open-modal :re_key="step.property_id" />
            <div>
                <a href="#" v-tooltip="$t('amas:analysis:pra:remove_from_access')" 
                    class="text-danger"
                    @click="$emit('remove_step', step)"
                >
                    {{ $t("general:remove") }}
                </a>
            </div>
        </td>
        <td v-if="!minimal && false">
            <div v-if="real_estate">
                {{ real_estate.re_type }}
            </div>
        </td>
        <td>
            <select v-model="step.has_public_road" class="form-control"> 
                <option v-for="(option, option_idx) in yesno_options" :key="'option-'+option_idx" v-bind:value="option.value">
                    {{option.text}}
                </option>
            </select>
        </td>
        <td>
            <div v-if="public_road">
                /
            </div>
            <div v-else>
                <select v-model="step.has_legal_access" class="form-control"> 
                    <option v-for="(option, option_idx) in yesno_options" :key="'option-'+option_idx" v-bind:value="option.value">
                        {{option.text}}
                    </option>
                </select>
            </div>
        </td>
        <td>
            <div v-if="public_road">
                /
            </div>
            <div v-else>
                <select v-model="step.has_physical_access" class="form-control"> 
                    <option v-for="(option, option_idx) in yesno_options" :key="'option-'+option_idx" v-bind:value="option.value">
                        {{option.text}}
                    </option>
                </select>
            </div>
        </td>
        <td v-if="!minimal">
            <div v-if="legal_status == 'loading'">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-if="legal_status == 'error'">
                <!-- <i class="fa fa-close text-danger"></i> -->
                /
            </div>
            <div v-else-if="legal_status">
                <div v-for="(owner, owner_idx) in getOwners(legal_status)" :key="'owner-'+owner_idx">
                    {{owner.name}}
                    <span class="label label-rouded" 
                        v-bind:class="owner.share=='1/1' ? 'label-success' : 'label-warning'"
                    >
                        {{owner.share}}
                    </span>
                </div>
                <div v-if="getAllOwners(legal_status).length > 3" class="text-center">
                    <a v-if="!show_all_owners" href="#" @click.prevent="show_all_owners = true" v-tooltip="$t('general:show_more')">...</a>
                    <a v-else href="#" @click.prevent="show_all_owners = false" v-tooltip="$t('general:hide')">...</a>
                </div>
            </div>
            <div v-else>
                /
            </div>
        </td>
        <td v-if="!minimal">
            <div v-if="legal_status == 'loading'">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-if="legal_status == 'error'">
                <!-- <i class="fa fa-close text-danger"></i> -->
                /
            </div>
            <div v-else-if="legal_status">
                <div v-for="(easement, easement_idx) in getEasements(legal_status)" :key="'easement-'+easement_idx">
                    {{easement.holder_title}}
                </div>
                <div v-if="getAllEasements(legal_status).length > 3" class="text-center">
                    <a v-if="!show_all_easements" href="#" @click.prevent="show_all_easements = true" v-tooltip="$t('general:show_more')">...</a>
                    <a v-else href="#" @click.prevent="show_all_easements = false" v-tooltip="$t('general:hide')">...</a>
                </div>
            </div>
            <div v-else>
                /
            </div>
        </td>
        <td v-if="!minimal">
            <div>
                <span v-if="legal_status && legal_status.legal_file"  
                    v-tooltip="$t('amas:analysis:pra:zk_extract:view')"  
                    class="btn btn-xs btn-outline btn-rounded btn-info view-pdf"
                    :href="legal_status.legal_file"
                    :data-title="legal_status.name"
                >
                    {{ $t("amas:analysis:pra:zk_extract") }}
                </span>
                <span v-else 
                    v-tooltip="$t('amas:analysis:pra:zk_extract:get')"  
                    class="btn btn-xs btn-outline btn-rounded btn-info"
                    v-on:click.prevent="$emit('update_legal', step)"
                >
                    {{ $t("amas:analysis:pra:zk_extract:get") }}
                </span>
            </div>
        </td>
    </tr>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";

export default {
    props: {
        step: {
            type: Object,
            required: true,
        },
        real_estate: {
            type: [String, Object],
            required: false,
        },
        public_road: {
            type: Boolean,
            default: false,
        },
        minimal: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ReOpenModal,
    },
    data: function () {
        return {
            yesno_options: [
                { text: this.$t("general:yes"), value: true },
                { text: this.$t("general:no"), value: false },
            ],
            show_all_owners: false,
            show_all_easements: false,
        }
    },
    methods: {
        getAllOwners: function(legal_status) {
            return legal_status.legal_owners;
        },
        getOwners: function(legal_status) {
            let items = this.getAllOwners(legal_status);
            if (!this.show_all_owners) {
                items = items.slice(0, 3);
            }
            return items;
        },
        getAllEasements: function(legal_status) { 
            return legal_status.legal_easements;
        },
        getEasements: function(legal_status) {
            let items = this.getAllEasements(legal_status);
            if (!this.show_all_easements) {
                items = items.slice(0, 3);
            }
            return items;
        },
    },
    computed: {
        legal_status: function () {
            if(this.real_estate) {
                return this.real_estate.legal_status;
            }
            return null;
        },
        is_loading: function() {
            return this.real_estate == 'loading';
        }
    }
}
</script>
