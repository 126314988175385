<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="esg_report"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return Promise.all([
                ApiService.get(`/amas/analysis/api/analysis/${this.session.id}/energy-report`),
                ApiService.get(`/amas/analysis/api/esg-report/analysis/${this.session.id}`),
            ])
            .then(([energyReportResponse, esgReportResponse]) => {
                let success = energyReportResponse.data.successful;
                let url = `/amas/analysis/${this.session.id}/esg-report`;
                let icon;
                if (success) {
                    icon = `
                        <a href="${url}">
                        <span class="circle circle-sm bg-success" data-toggle="tooltip" data-original-title="${this.$t("task_details:status:fetch:successful2")}">
                            <i class="fa fa-check"></i>
                        </span>
                        </a>
                    `;
                } else {
                    icon = `
                        <a href="${url}">
                        <span class="circle circle-sm bg-light" data-toggle="tooltip" data-original-title="${this.$t("ee:no_data")}">
                                <i class="fa fa-close"></i>
                            </span>
                        </a>
                    `;
                }
                return icon;
            });
        }
    },
}
</script>