<template>
    <div>
        <b-dropdown size="md" variant="link">
            
            <template #button-content>
                <span
                    class="label label-rouded"
                    :class="value == true ? 'label-success' : 'label-danger'"
                >
                    <i class="fa"
                        :class="value == true ? 'fa-check' : 'fa-close'"
                    />
                </span>
            </template>

            <b-dropdown-item @click="$emit('select', true)">
                {{$t("general:yes")}} - 
                <span class="label label-rouded label-success">
                    <i class="fa fa-check" />
                </span>
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('select', false)">
                {{$t("general:no")}} - 
                <span class="label label-rouded label-danger">
                    <i class="fa fa-close" />
                </span>
            </b-dropdown-item>

        </b-dropdown>
    </div>
</template>

<script>

export default {
    model: {
        prop: 'value',
        event: 'select'
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
    }
}
</script>
