<template>
    <div>
        <div :class="{'input-group': inputFieldsAddon || inputFieldsPrepend}">
            <span
                class="input-group-addon"
                v-if="inputFieldsPrepend"
            >
                {{ inputFieldsPrepend }}
            </span>
            <input
                type="text"
                v-model="localValue"
                @change="$emit('change', $event)"
                class="form-control input-sm"
            />
            <span
                class="input-group-addon"
                v-if="inputFieldsAddon"
            >
                {{ inputFieldsAddon }}
            </span>
        </div>
        <div v-if="validation_error" class="invalid-value">
            {{validation_error}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        formatter: {
            type: Function,
            default: (value) => value
        },
        deformatter: {
            type: Function,
            default: (value) => value
        },
        validator: {
            type: Function,
            default: (value) => ({
                value: value,
                is_valid: true
            })
        },
        inputFieldsAddon: {
            type: String
        },
        inputFieldsPrepend: {
            type: String
        },
    },
    computed: {
        localValue: {
            get() {return this.formatter(this.value)},
            set(localValue) { this.$emit('input', this.deformatter(localValue))}
        },
    },
    data: function () {
        return {
            validation_error: null,
        }
    },
    methods: {
        handleFieldInput(value) {
            this.validation_error = null;
            let validated_value = this.validator(value);
            if (validated_value.is_valid) {
                this.$emit('change', validated_value.value)
            } else {
                this.validation_error = this.$t("general:invalid:value") + ` ${value}'`;
            }
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        value: function (val) {
            this.validation_error = null;
        },
    }
}
</script>
