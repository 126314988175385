export default {
    zoom: {
        type: Number,
        default: 8
    },
    center: {
        type: [Array, Object],
        default: function() {
            return {
                lat: 46.15039,
                lng: 14.91421
            }
        }
    },
    height: {
        type: Number,
        default: 500
    },
    markers: {
        type: Array,
        default: function() {
            return []
        }
    },
    mapTypeId: {
        type: String,
    },
    options: {
        type: Object
    }
}