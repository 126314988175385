<template>
    <div>
        <div v-if="parcel">
            <table class="table table-condensed">
                <thead>
                    <th class="text-center"></th>
                    <th class="text-center">{{$t("general:re_keys")}}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{$t("real_estate:parcel")}}
                        </td>
                        <td>
                            <re-open-modal :re_key="parcel.property_id" />
                            <add-remove-button
                                :re_key="parcel.property_id"
                                :added="reKeysInSubject.includes(parcel.property_id)"
                                @add-real-estates="$emit('add-real-estates', $event)"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {{$t("real_estate:parcel:building:parts")}}
                            <div class="m-t-5" v-if="parcel.units_list.length">
                                <a type="button" class="btn btn-info btn-outline btn-xs" 
                                    @click.prevent="$emit('add-real-estates', parcel.units_list.map(i=>i.property_id))"
                                >
                                    {{$t("general:add_all")}}
                                </a>
                            </div>
                        </td>
                        <td>
                            <div v-if="!parcel.units_list.length">
                                /
                            </div>
                            <div
                                v-for="(unit, u_idx) in parcel.units_list"
                                :key="'unit-'+u_idx"
                            >
                                <re-open-modal :re_key="unit.property_id" />
                                <add-remove-button
                                    :re_key="unit.property_id"
                                    :added="reKeysInSubject.includes(unit.property_id)"
                                    @add-real-estates="$emit('add-real-estates', $event)"
                                />
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

        <div v-else class="vs--loader-div text-center">
            <!-- Height is enforced so the popup is shown in it's entirety when opened (map is moved if required) -->
            <i class="fa fa-spinner fa-spin"></i>
        </div>

    </div>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";
import AddRemoveButton from "./components/AddRemoveButton.vue";

export default {
    props: {
        parcel: {
            type: Object,
        },
        reKeysInSubject: {
            type: Array
        }
    },
    components: {
        ReOpenModal,
        AddRemoveButton
    },
}
</script>

<style>
.vs--loader-div {
    height: 200px;
    padding-top: 30%;
}
</style>
