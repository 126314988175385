<template>
    <div v-if="!session.is_completed">
        Za pričetek klica pošlji stranki naslednje
        <a class="btn btn-default btn-xs" @click="inviteClientViaSMS()">Pošlji SMS</a>
        ali
        <a class="btn btn-default btn-xs" @click="inviteClientViaEmail()">Pošlji email</a>.
        Prepričaj se, da je stranka prejela SMS oz. email.        
    </div>
</template>

<script>
import { Swal } from '@/components/utils/swal.alerts.js';
import ApiService from '@/components/utils/api.service.js';


export default {
    props: {
        session: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {}
    },
    methods: {
        inviteClientViaEmail: function () {
            let msg = `
            <br>
            Pozdravljeni,
            <br>
            za pričetek virtualnega ogleda na telefonu odprite naslednjo povezavo in dovolite dostop do kamere:
            <br><br>
            ${this.clientUrl}
            <br><br>
            Se vidimo kmalu.
            `
            Swal({
                title: "Stranki preko maila pošljite naslednje sporočilo",
                html: msg,
                confirmButtonText: "Zapri",
            })
        },
        inviteClientViaSMS: function () {
            const app = this;
            Swal({
                title: "Pošlji SMS",
                text: "Stranka bo prejela SMS s povezavo do virtualnega ogleda",
                input: "text",
                inputLabel: "Telefonska številka",
                inputPlaceholder: "031456789",
                confirmButtonText: "Pošlji",
                showCancelButton: true,
                cancelButtonText: "Prekliči",
                inputValidator: (value) => {
                    if (!value) {
                        return "Vpiši telefonsko številko";
                    }
                }
            }).then((val) => {
                if (!val.isConfirmed) {return} // Clicked "Cancel" button
                let postData = {
                    mobile: val.value,
                };
                ApiService
                    .post(`/virtual-visit/api/virtual-session/${app.session.id}/send-invite-client-sms`, postData)
                    .then(response => {
                        if (response.data.status === "FAILED") {
                            this.notify("SMS ni bil poslan", "Napak pri pošiljanju", "warn");
                        } else if (response.data.status === "INVALID") {
                            this.notify("SMS ni bil poslan", "Napačna telefonska številka", "warn");
                        } else {
                            this.notify("SMS poslan", "", "success");
                        }
                    })
                    .catch(error => {
                        let msg;
                        if (error.response.status === 403) {
                            msg = "Nimate ustreznih dovoljenj";
                        } else if (error.response.status === 400) {
                            msg = "Napačna telefonska številka";
                        }
                        this.notify("SMS ni bil poslan", msg, "warn");
                        throw error;
                    });
            })
        }
    },
    computed: {
        clientUrl() {
            if (!this.session) { return ; }
            return this.session.client_url_full;
        },
    }
}
</script>
