<template>
    <div class="row">
        <div class="col-md-4">
            <form method="post" novalidate>
                <div class="text-center">
                    <h2 v-if="factorTypeExists">
                        {{ $t('avm:factor:type:edit') }}
                    </h2>
                    <h2 v-else>
                        {{ $t('avm:factor:type:add') }}
                    </h2>
                </div>

                <div class="form-group mb-0">
                    <div class="form-group">
                        <label class="control-label requiredField">
                            {{ $t('avm:factor:type:name') }}<span class="asteriskField">*</span>
                        </label>
                        <div :class="['controls', errorMessages && errorMessages.name ? 'has-error' : '']">
                            <input 
                                type="text" 
                                v-model="factor_type.name" 
                                :class="[
                                    'textinput textInput form-control',
                                    errorMessages && errorMessages.name ? 'has-error' : ''
                                ]"
                                :disabled="disabled"
                            />
                            <small class="text-danger" v-if="errorMessages && errorMessages.name">
                                <span v-for="(t, idx) in errorMessages.name" :key="'msg-'+idx">{{ t }}</span>
                            </small>
                        </div>
                    </div>
                    <div class="form-group  mb-0">
                        <div class="form-group">
                            <label class="control-label requiredField">
                                {{ $t('avm:factor:type:price') }}
                            </label>
                            <div :class="['controls', errorMessages && errorMessages.original_value ? 'has-error' : '']">
                                <input 
                                    type="text" 
                                    v-model="factor_type.original_value"
                                    :class="['textinput textInput form-control']"
                                    required
                                    :disabled="disabled"
                                    placeholder="/"
                                />
                                <small class="text-danger" v-if="errorMessages && errorMessages.original_value">
                                    <span v-for="(t, idx) in errorMessages.original_value" :key="'msg-'+idx">{{ t }}</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pull-right p-20">
                    <button style="margin-right:5px;" v-if="factorTypeExists" type="submit" class="btn btn-danger" v-on:click.prevent="deleteFactorType()">
                        {{ $t('general:delete') }}
                    </button>
                    <button v-if="factorTypeExists" type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateFactorType()">
                        {{ $t('general:save') }}
                    </button>
                    <button v-else type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateFactorType()">
                        {{ $t('avm:factor:type:create') }}
                    </button>
                </div>

            </form>
        </div>
    </div>

</template>

<script>
import ApiService from "@/components/utils/api.service.js"

export default {
    props: {
        factor_type: Object,
        analysis_session: Number,
        disabled: {
            required: false,
            default: false
        },
    },

    computed: {
        postableFactor: function () {
            return {
                ...this.factor_type,
                analysis_session: this.analysis_session
            }
        },
        factorTypeExists: function () {
            return this.factor_type.id != null;
        },

    },
    methods: {
        validate() {
            this.errorMessages = null; // Clear error messages on validation
            let required_fields = ["name"];
            let valid = true;
            for (let field of required_fields){
                if (!this.factor_type[field]){
                    valid = false;
                    let error_msg = "To polje je obvezno";
                    if (!this.errorMessages){
                        this.errorMessages = {};
                    }
                    this.errorMessages[field] = [error_msg];
                }
            }
            return valid;
        },
        createFactorType() {
            if (!this.validate()) { return; }
            let url = this.url;
            ApiService
                .post(url, this.postableFactor)
                .then(response => {
                    this.errorMessages = null; // Clear error message on success
                    this.$emit("update-factortype-complete");
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.errorMessages = error.response.data; // Set error message
                    }
                    console.error(error);
                });
        },
        updateFactorType() {
            if (!this.validate()) { return; }
            let url = this.url + this.factor_type.id;
            ApiService
                .put(url, this.postableFactor)
                .then(response => {
                    this.errorMessages = null; // Clear error message on success
                    this.$emit("update-factortype-complete");
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.errorMessages = error.response.data; // Set error message
                    }
                    console.error(error);
                });
        },
        deleteFactorType() {
            let url = this.url + this.factor_type.id;
            ApiService
                .delete(url, this.adjustment)
                .then(response => {
                })
                .catch(error => {
                    console.error(error);
                });

            this.$emit("update-factortype-complete");
        },
        updateOrCreateFactorType() {
            if (this.factorTypeExists) {
                this.updateFactorType();
            } else {
                this.createFactorType();
            }
        },
    },
    data() {
        return {
            url: '/amas/analysis/avm/api/factor-types/',
            errorMessages: null,
        }
    },
}
</script>
