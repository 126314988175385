<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <form id="calc" class="form-group" method="GET">
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>{{ $t("mortgage_calculator:realestate_price")}}:</strong>
                            <b-form-input
                                v-model="price"
                                type="range"
                                min="5000"
                                max="1000000"
                                step="1000"
                            ></b-form-input>
                        </div>
                        <div class="col-sm-6">
                            <b-input-group append="€" class="input-group">
                                <validate-input-field
                                    v-model="price"
                                    :formatter="numberFormatter"
                                    :deformatter="numberDeFormatter"
                                    :validator="priceValidator"
                                />
                            </b-input-group>
                            <p class="red small">{{price_message}}</p>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>{{$t("mortgage_calculator:deposit")}}:</strong>
                            <b-form-input
                                v-model="down"
                                type="range"
                                min="0"
                                max="100"
                            ></b-form-input>
                        </div>
                        <div class="col-sm-6">
                            <div
                                class="row"
                            >
                            <div class="col-xs-6">
                                <b-input-group
                                    append="%"
                                    class="input-group"
                                >
                                    <validate-input-field
                                        v-model="down"
                                        :formatter="numberFormatter"
                                        :validator="downValidator"
                                        :deformatter="numberDeFormatter"
                                    />
                                </b-input-group>
                                <p class="red small">{{down_message}}</p>
                            </div>
                            <div class="col-xs-6">
                                <b-input-group
                                    class="input-group"
                                    append="€"
                                >
                                    <validate-input-field
                                        :value="down_value"
                                        @input="handleDownValueChange"
                                        :formatter="numberFormatter"
                                        :validator="downvalueValidator"
                                        :deformatter="numberDeFormatter"
                                    />
                                </b-input-group>
                                <p class="red small">{{down_value_message}}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>{{ $t("mortgage_calculator:insurance:yearly")}}: </strong>
                            <b-form-input
                                v-model="insurance"
                                type="range"
                                step="50"
                                min="0"
                                max="2000"
                            ></b-form-input>
                        </div>
                        <div class="col-sm-6">
                            <b-input-group append="€" class="input-group">
                                <validate-input-field
                                    v-model="insurance"
                                    :formatter="numberFormatter"
                                    :deformatter="numberDeFormatter"
                                    :validator="insuranceValidator"
                                    step="any"
                                />
                            </b-input-group>
                            <p class="red small">{{insurance_message}}</p>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6">
                            <strong style="font-size: 20">{{ $t("mortgage_calculator:interests")}}:</strong>
                            <b-form-input
                                v-model="interest"
                                type="range"
                                step="0.01"
                                min="0"
                                max="8"
                            ></b-form-input>
                        </div>
                        <div class="col-sm-6">
                            <b-input-group append="%" class="input-group">
                                <validate-input-field
                                    v-model="interest"
                                    :formatter="decimalFormatter"
                                    :deformatter="decimalDeFormatter"
                                    :validator="decimalValidator"
                                />
                            </b-input-group>

                            <p class="red small">{{decimal_message}}</p>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6">
                            <strong
                                >{{ $t("mortgage_calculator:duration")}}:</strong
                            >
                            <b-input-group>
                                <b-form-select
                                    v-model="years"
                                    :options="selectoptions"
                                ></b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                </form>
                <div></div>
            </div>
            <div class="col-lg-6">
                <apexchart
                    type="donut"
                    width="90%"
                    :options="chartOptions"
                    :series="donutData"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {
    InputGroupPlugin,
    FormInputPlugin,
    FormSelectPlugin,
} from "bootstrap-vue";
Vue.use(InputGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);

import ValidateInputField from "@/components/utils/ValidateInputField";

import VueApexCharts from "vue-apexcharts";

import Utils from "@/components/utils/utils.js";

export default {
    created: function () {
    },
    props: {
        initialPrice: {
            type: Number,
            default: 100000,
        },
        initialDown: {
            type: Number,
            default: 25,
        },
        initialInsurance: {
            type: Number,
            default: 150,
        },
        initialInterest: {
            type: Number,
            default: 3,
        },
        initialYears: {
            default: 20,
        },
    },
    components: {
        apexchart: VueApexCharts,
        ValidateInputField,
    },
    data: function () {
        return {
            price_message:"",
            down_message:"",
            down_value_message:"",
            decimal_message: "",
            insurance_message:"",
            price: this.initialPrice,
            down: this.initialDown,
            down_value: this.initialDown*this.initialPrice/100,
            insurance: this.initialInsurance,
            interest: this.initialInterest,
            years: this.initialYears,
            chartOptions: {
                tooltip: {
                    enabled: false,
                },
                dataLabels: { enabled: false },
                legend: {
                    position: "bottom",
                },
                labels: [this.$t("mortgage_calculator:labels:principal"), this.$t("mortgage_calculator:interests"),
                  this.$t("mortgage_calculator:insurance")],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: "22px",
                                    fontFamily: "Rubik",
                                    color: "#dfsda",
                                    offsetY: -10,
                                },
                                value: {
                                    show: true,
                                    fontSize: "36px",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val + "€";
                                    },
                                },
                                total: {
                                    show: true,
                                    label: this.$t("general:total"),
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    color: "#373d3f",
                                    fontSize: "200%",
                                    formatter: function (w) {
                                        return (
                                            w.globals.seriesTotals.reduce(
                                                (a, b) => {
                                                    return a + b;
                                                },
                                                0
                                            ) + "€"
                                        );
                                    },
                                },
                            },
                        },
                    },
                },
            },
            selectoptions: [
                {
                    value: 10,
                    text: "10 " + this.$t("general:duration:year"),
                },
                {
                    value: 15,
                    text: "15 " + this.$t("general:duration:year"),
                },
                {
                    value: 20,
                    text: "20 " + this.$t("general:duration:year"),
                },
                {
                    value: 25,
                    text: "25 " + this.$t("general:duration:year"),
                },
                {
                    value: 30,
                    text: "30 " + this.$t("general:duration:year"),
                },
            ],
        };
    },
    methods: {
        formatNumber: Utils.formatNumber,

        numberFormatter(value) {
            return this.formatNumber(parseFloat(value), 0);
        },
        numberDeFormatter(value) {
            if(this.isNumeric(value.replaceAll(".", ""))) {
                return parseInt(value.replaceAll(".", ""));
            }
            return value;
        },
        decimalFormatter(value){
            return this.formatNumber(parseFloat(value), 2);
        },
        decimalDeFormatter(value){
            if (this.isNumeric(value.replace(",", "."))){
                return parseFloat(value.replaceAll(",", "."));
            }
            return value
        },

        decimalValidator(value){
            if (this.isNumeric(value) && value>=0 && value<=8){
                this.decimal_message = "";
                return true;
            }
            this.decimal_message = this.$t("mortgage_calculator:range:decimal");
            return false;
        },

        isNumeric(val) {
            return !isNaN(val) && !isNaN(parseFloat(val))
        },

        priceValidator(value) {
            if (this.isNumeric(value) && value >= 5000 && value <= 10**6){
                this.price_message = "";
                return true;
            }
            this.price_message = this.$t("mortgage_calculator:range:price");
            return false;
        },

        downValidator(value) {
            if (this.isNumeric(value) && value >= 0 && value <= 100){
                this.down_message = "";
                return true;
            }
            this.down_message = this.$t("mortgage_calculator:range:price_ratio")
            return false;
        },
        downvalueValidator(value) {
            if (this.isNumeric(value) && value >= 0 && value <= this.price){
                this.down_value_message = "";
                return true;
            }
            this.down_value_message = this.$t("mortgage_calculator:range:down_payment") + this.price;
            return false;
        },
        insuranceValidator(value){
            if (this.isNumeric(value) && value >= 0 && value <= 2000){
                this.insurance_message = ""
                return true;
            }
            this.insurance_message = this.$t("mortgage_calculator:range:insurance")
            return false;
        },
        handleDownValueChange(value) {
            this.down = Math.round((value / this.price) * 100);
        }

    },
    watch: {
        price: function(val) {
            this.down_value = Math.round(val * (this.down / 100));
        },
        down: function(val) {
            this.down_value = Math.round(val * (this.price / 100));
        },
    },
    computed: {

        borrowed: function () {
            return this.price - Math.round(this.down*this.price/100);
        },
        base: function(){
            return Math.round(this.borrowed / this.months);
        },
        insurance_month: function(){
            return Math.round(this.insurance / 12);
        },
        interest_amount: function(){

            return Math.max(
                Math.round(
                    (this.months * this.mortgage - this.borrowed) /
                        this.months -
                        this.insurance_month
                ),
                0
            );

        },
        month_interest: function(){
            return this.interest / 1200;
        },
        months: function(){
            return this.years * 12;
        },
        mortgage: function(){
            if (this.interest == 0) {
                return Math.round(this.borrowed / this.months);
            } 
            return Math.round(
                    (this.borrowed *
                        this.month_interest *
                        (this.month_interest + 1) ** this.months) /
                        ((this.month_interest + 1) ** this.months - 1) +
                    this.insurance / 12);
        },
        donutData: function(){
            return [
                this.base,
                this.interest_amount,
                this.insurance_month,
            ];
        },

    },

};
</script>


<style>
    .red {
        color: red;
    }
    .small{
        font-size: 9px;
    }

input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>