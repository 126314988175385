<template>
    <vue-multiselect
        :value="value"
        :options="options" 
        :multiple="true" 
        :close-on-select="false" 
        :clear-on-select="false" 
        label="text" 
        track-by="value"
        placeholder=""
        :select-label="$t('general:select')"
        :selected-label="$t('general:selected')"
        :deselect-label="$t('general:remove')"
        :searchable="false"
        @input="emitValues"
    >
    </vue-multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    components: {
        "vue-multiselect": Multiselect,
    },
    props:  {
        value: {
            type: [Number, String, Array],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    methods: {
        emitValues: function(raw) {
            this.$emit('input', raw.map(i => i.value ));
        },
    },
}
</script>
