
export default {
    methods: {
        notify: function (title = "", text = "", type = "info") {
            this.$notify({
                group: "OTwarnings",
                type: type,
                title: title,
                text: text
            });
        },
    },
}
