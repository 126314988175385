<template>
    <div>
        <span v-if="lsData && lsData.legal_file"  
            v-tooltip="$t('amas:analysis:pra:zk_extract:view')"  
            class="btn btn-xs btn-outline btn-rounded btn-info view-pdf"
            :href="lsData.legal_file"
            :data-title="lsData.name"
        >
            {{ $t("amas:analysis:pra:zk_extract") }}
        </span>

        <div class="vld-parent">
            <loading 
                :active="loading" 
                :can-cancel="false" 
                :is-full-page="false"
            />

            <ul class="search-listing">
                <li>
                    <div v-if="!loading && unitsData === null">
                        <template v-if="errorMsg">
                            {{ errorMsg }}
                        </template>
                        <template v-else>
                            {{ $t("task_details:status:no_data") }}
                        </template>
                    </div>
                    <div v-else v-for="(unit, idx) in unitsData" :key="'u-'+idx" class="row m-b-20">
                        <div :class="{
                                'col-md-8': showButton,
                                'col-md-12': !showButton,
                            }">
                            <h3> 
                                <!-- {{unit}} -->

                                <i class="mdi mdi-map"></i>
                                
                                <span class="color-blue" :data-original-title="$t('real_estate:details')" rel="tooltip">
                                    {{getUnitAddress(unit)}}
                                </span>
                            </h3>

                            <span class="re-key color-green" :data-original-title="$t('real_estate:details')" rel="tooltip">
                                {{getUnitReKey(unit)}}
                            </span>

                            <div>
                                <div v-if="unit.size">{{ $t("general:area") }}: {{unit.size|formatSize}}</div>
                                <div v-if="unit.unit_label">{{ $t("general:type") }}: {{unit.unit_label}}</div>

                                <div v-if="unit.unit_label">
                                    {{ $t("task_details:manage:owners") }}: {{getUnitOwners(unit)}}
                                </div>
                            </div>
                        </div>
                        <div v-if="showButton" class="col-md-4">
                            <div @click="clickButton(unit)">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>

        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js"
import Utils from "@/components/utils/utils.js";

export default {
    props: {
        rekey: String,
    },
    components: {
        Loading,
    },
    data() {
        return {
            loading: false,
            lsData: null,
            errorMsg: null,
        }
    },
    methods: {
        formatSize: Utils.formatSize,
        getUnitsData: function() {
            this.loading = true;
            ApiService
                .get(`/amas/analysis/legal/api/${this.rekey}/`)
                .then(response => {
                    this.lsData = response.data;
                })
                .catch(error => {
                    if (error.response.data && error.response.data.message)
                        this.errorMsg = error.response.data.message;
                    else
                        this.errorMsg = this.$t("legal:fetch_re:error:{}").replace("{}", this.rekey);
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        clickButton: function(unit) {
            const data = {
                address: this.getUnitAddress(unit),
                re_key: this.getUnitReKey(unit),
                size: unit.size,
            }
            this.$emit('unit-on-parcel-clicked', data);
        },
        getUnitReKey: function(unit) {
            return this.rekey + "-" + unit.part.replace(/^\D+/g, '').replace('.', '');
        },
        getUnitAddress: function(unit) {
            return unit.address.join(" | ");
        },
        getUnitOwners: function(unit) {
            return unit.name.join(", ");
        },
    },
    computed: {
        unitsData: function() {
            if (this.lsData && this.lsData.data && Object.keys(this.lsData.data.RAW.VLASTOVNICA).length > 0) {
                return this.lsData.data.RAW.VLASTOVNICA;
            }
            return null;
        },
        showButton() {
            return this.$slots.default !== undefined;
        }
    },
    mounted() {
        this.getUnitsData();
    },
}
</script>

<style>
.color-blue {
    color: #2cabe3;
}
.color-green {
    color: #53e69d;
}
</style>
