<template>
    <div class="statistics-holder">
        <div
            v-for="(stats, index) in statistics"
            :key="index"
            :class="`badge-${stats.color}`"
            :style="`width: ${stats.value / total * 100}%`"

            v-tooltip="`${stats.title}: ${stats.value}`"
        >
            {{ stats.value }}
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderStatistics",
    props: {
        order: {
            type: Object,
            required: true,
        }
    },
    computed: {
        statistics() {
            const statuses = [
                "In preparation",
                "Submitted",
                "Update requested",
                "Accepted and closed",
            ];

            return statuses
                .filter(status => this.getOrderItems(this.order, status).length > 0)
                .map(status => {
                    const order_items = this.getOrderItems(this.order, status);
                    const firstTask = order_items[0].task;
                    return {
                        title: firstTask.status_display,
                        value: order_items.length,
                        color: (firstTask.task_status.name === 'In preparation') ?
                            "purple" : firstTask.task_status.color,
                    }
                });
        },
        total() {
            return this.statistics
                .reduce((acc, item) => acc + item.value, 0);
        }
    },
    methods: {
        getOrderItems: function(order, task_status_name=null) {
            // Return all order items that have task_status.name == task_status_name
            let order_items = order.orderitem_set;
            if (task_status_name) {
                order_items = order_items.filter(order_item => {
                    return order_item.task.task_status.name === task_status_name;
                });
            }
            return order_items;
        },
    },
}
</script>

<style scoped>
.statistics-holder {
    min-width: 150px;
    width: 100%;
    height: 18px;
    display: flex;

    border-radius: 20px;
    overflow: hidden;
    cursor: default;
}
.statistics-holder > div {
    text-align: center;
    color: #fff;
}
</style>
