<template>
    <div>
        <k-google-streetview
            v-bind="$props"
            ref="panorama"
        />
        <button 
            type="button" 
            @click="saveCurrentPano"
            class="btn btn-outline btn-info m-t-10 waves-effect waves-light"
        >
          {{ $t("google_maps:street_view:save") }}
        </button>

        <div class="m-t-30 text-center">
            <h2>{{ $t("google_maps:street_view") }}</h2>
            <a
                v-if="currentImageUrl"
                :href="currentImageUrl"
                data-toggle="lightbox"
                data-gallery="gallery"
            >
                <img class="width-100" :src="currentImageUrl"/>
            </a>
        </div>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import KGoogleStreetview from './KGoogleStreetview'
import props from './KGoogleStreetview-props'

export default {
    components: {
        KGoogleStreetview
    },
    props: {
        ...props,
        saveImageUrl: String,
        initialImageUrl: String,
        imageSize: {
            type: String,
            default: "1200x800"
        }
    },
    data: function() {
        return {
            currentImageUrl: this.initialImageUrl
        }
    },
    methods: {
        _createPanoramaLink(panoObject) {
            return "https://maps.googleapis.com/maps/api/streetview?" +
                "&size=" + this.imageSize +
                "&location=" + panoObject.position.lat() + "," + panoObject.position.lng() + 
                "&heading=" + panoObject.pov.heading + 
                "&pitch=" + panoObject.pov.pitch + 
                "&fov=" + 180 / Math.pow(2, panoObject.pov.zoom) + 
                "&key=" + "AIzaSyABpWn0UzbCu2CWcVo4VFNTG7OqJW5GwYA"
        },
        saveCurrentPano: function () {
            const app = this;
            Swal({   
                title: this.$t("general:action:are_you_sure"),
                text: this.$t("general:info:image_changed"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t("general:yes"),
                cancelButtonText: this.$t("general:no"),
            }).then(function(isConfirm){
                if (isConfirm.value) {
                    ApiService
                        .get(app.saveImageUrl, {
                            params: {
                                image_url: app._createPanoramaLink(app.panoObject),
                                target:"streetview"
                            }
                        })
                        .then(response => {
                            if(response.data["success"]==false){
                                Swal({   
                                    title: app.$t("general:error"),
                                    text: app.$t("general:info:image_changed_failed"),
                                    icon: 'error',
                                });
                            }else{
                                Swal({   
                                    title: app.$t("general:success"),
                                    text: app.$t("general:info:image_changed_success"),
                                    icon: 'success',
                                });
                                app.currentImageUrl = response.data.new_image_url;
                            }
                        })
                        .catch(error => {
                            throw error;
                        })
                }
            });
        },
    },
    computed: {
        panoObject: function(){
            return this.$refs.panorama.panoObject;
        },
    },
}
</script>
