<template>
    <div class="multiple-report-picker">
        <loading 
            :active="reportDownloading"
            :can-cancel="false" 
            :is-full-page="true"
        />
        <Multiselect
            v-model="selected"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :placeholder="'Izberite poročila, ki jih želite prenesti'"
            :select-label="$t('general:select')"
            :selected-label="$t('general:selected')"
            :deselect-label="$t('general:remove')">
            <template slot="noResult">
                {{ $t('general:search:no_results')}}
            </template>
        </Multiselect>
        <div class="m-t-10">
            <button
                :class="[{'disabled': selected.length === 0}, 'btn btn-info btn-sm btn-download']"
                @click="getReports"
            >
                Prenesi izbrana poročila <i class="fa fa-download"></i>
            </button>
        </div>
    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service';
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay'
import { Swal } from "@/components/utils/swal.alerts.js";

export default {
    name: "MultipleReportPicker",
    components: {
        Multiselect,
        Loading,
    },
    props: {
        analysis: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            optionMap: {},
            selected: [],
            options: [],
            reportDownloading: false,
        }
    },
    methods: {
        getReports() {
            if (this.selected.length === 0)
                return;
            this.reportDownloading = true;
            if (this.selected.length === 1) {
                const reportId = this.optionMap[this.selected[0]];
                ApiService
                .get(`/amas/analysis/${this.analysis}/report/download/${reportId}`, {
                    responseType: "blob"
                })
                .then(res => this.downloadReport(res.data, `Porocilo-${this.analysis}`))
                .catch(error => {
                    Swal({
                        title: "",
                        text: "Napaka pri izvozu poročila",
                        icon: "error",
                    });
                    throw error;
                })
                .finally(() => this.reportDownloading = false)
            } else {
                const reportIds = []
                for (const report of this.selected)
                    reportIds.push(this.optionMap[report]);
                ApiService
                .post(`/amas/analysis/${this.analysis}/report/downloadmultiple`, {
                    reports: reportIds
                }, {
                    responseType: "blob"
                })
                .then(res => this.downloadReport(res.data, `Analiza-${this.analysis}.zip`))
                .catch(error => {
                    Swal({
                        title: "",
                        text: "Napaka pri izvozu poročil",
                        icon: "error",
                    });
                    throw error;
                })
                .finally(() => this.reportDownloading = false)
            }
        },
        downloadReport(data, filename) {
            const href = URL.createObjectURL(data);

            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    },
    mounted() {
        ApiService
            .get("/api/v1/current-user")
            .then(response => {
                const reports = response.data.available_report_templates;
                if (reports.length > 0) {
                    for (const report of reports)
                        this.optionMap[report.report_name] = report.id
                    this.options = reports.map(report => report.report_name)
                }
            });
    },
}
</script>
