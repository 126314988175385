import ApiService from "@/components/utils/api.service.js"

export default {
    data() {
        return {
            selectOptions: {
                real_estate_type: {},
                // energy_class: {},
                // re_type: {},
                msov_type: {},
                region: {},
                occupant_type: {},
                real_estate_right: {},
                attachment_type: {},
            },
        }
    },
    methods: {
        findByKey: function(obj, kee) {
            if (kee in obj) return obj[kee];
            for(let n of Object.values(obj).filter(Boolean).filter(v => typeof v === 'object')) {
                let found = this.findByKey(n, kee);
                if (found) return found;
            }
        },
        getOptions: function(){
            return ApiService
                .options(`/streamline/api/order-item/list`)
                .then(response => {
                    let populateOptions = {};
                    Object.keys(this.selectOptions).forEach((x)=> {
                        let optionProperties = this.findByKey(response.data, x);
                        populateOptions[x] = this.parseSelectOptions(optionProperties.choices);
                        
                    })
                    this.selectOptions = populateOptions;
                });
        },
        parseSelectOptions: function(options){
            let newDict = {};
            options.map(x => newDict[x.value] = x.display_name);
            return newDict;
        },
    },
    mounted() {
        this.getOptions();
    }
};