<template>
    <div v-if="is_visible & unit.unit_type_id==5000" class="real-estate-size-estimate-indicator">
        <div v-if="unit" class="flexo">
            <span v-on:click.prevent="openEditRealEstates()">
                <a href="#">
                    <i :class="['fa', labelIconClass.class]" :style="labelIconClass.style" aria-hidden="true"></i>
                </a>
            </span>
        </div>
        
        <bootstrap3-modal
            :name="_internalid"
            width="30%" 
        >       
            <indicator-pop-up
                :unit_data="unit"          
                >
            </indicator-pop-up>
        </bootstrap3-modal>
    </div>
    
</template>

<script>
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import UnitSizeEstimateIndicatorPopUp from "./UnitSizeEstimateIndicatorPopUp.vue"
export default {
    components:{
        "bootstrap3-modal": Bootstrap3Modal,
        "indicator-pop-up": UnitSizeEstimateIndicatorPopUp
    },
    props:  {
        unit: {
            type: Object,
        },
        is_visible: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
    labelIconClass: function() {
    
            let label = {};
            if(this.unit.unit_size_estimate_rating===1) {
                label.class = "fa-check-circle" 
                label.style = "color: gray"
            } else if(this.unit.unit_size_estimate_rating===2) {
                label.class = "fa-exclamation-circle" 
                label.style = "color: #f6b26b"
            } else if(this.unit.unit_size_estimate_rating===3) {
                label.class = "fa-exclamation-circle" 
                label.style = "color: red"
            }
            else {
                label.class = "fa-question-circle" 
                label.style = "color: gray"
            }
            
            return label
        },
        _internalid: function() {
            return "sizeindicator_"+this._uid+"_id";
        },
    },
    methods: {
        closeEditRealEstates: function() {
            this.$modal.hide(this._internalid);
        },
        openEditRealEstates: function() {
            this.$modal.show(this._internalid);
        },
    }
}
</script>

<style>
.real-estate-size-estimate-indicator .vm--overlay {
    height: 100%!important;
}
.real-estate-size-estimate-indicator .vm--modal {
    margin-left: auto;
    margin-right: auto;
    left: unset!important;
    display: block!important;
    width: 30vw!important;
}
.real-estate-size-estimate-indicator .vm--modal .modal-header {
    float:center!important; 
}
</style> 
