<template>
    <div>
        <h3>{{ $t("general:instructions") }}</h3>

        <p>{{ $t("virtual_visit:instructions_start") }}</p>
        <ol>
            <li>
                {{ $t("virtual_visit:instructions_outside") }}
                <ol type="a">
                    <li>{{ $t("virtual_visit:instructions_outside_apartment") }}</li>
                    <li>{{ $t("virtual_visit:instructions_outside_house") }}</li>
                </ol>
            </li>
            <li>
                {{ $t("virtual_visit:instructions_inside") }}
                <ol type="a">
                    <li>{{ $t("virtual_visit:instructions_inside_apartment") }}</li>
                    <li>{{ $t("virtual_visit:instructions_inside_house") }}</li>
                </ol>
            </li>
            <li>
                {{ $t("virtual_visit:instructions:message") }}
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'ModeratorProcess'
}
</script>
