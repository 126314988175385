<template>
    <div>
        <!-- START Mortgage calculator -->
        <div class="row">
            <div class="col-md-12">
                <h3>{{ $t('property_iq:loan:title') }}</h3>
                <p class="p-b-30">{{ $t('property_iq:loan:description') }}</p>
                <mortgage-calculator
                    :initialPrice="initialPrice"
                />
            </div>
        </div>
        <!-- END Mortgage calculator -->

        <!-- START Affordability calculator -->
        <div class="row m-t-30">
            <div class="col-md-12">
                <h3> {{ $t('property_iq:loan:afford') }}</h3>
                <p class="p-b-30">{{ $t('property_iq:loan:afford:description') }}</p>
                <affordability-calculator />
            </div>
        </div>
        <!-- END Affordability calculator -->
    </div>
</template>

<script>
import MortgageCalculator from '@/components/mortgage-calculator/MortgageCalculator.vue';
import AffordabilityCalculator from '@/components/affordability-calculator/AffordabilityCalculator.vue';

export default {
    name: "MortgageTab",
    components: {
        MortgageCalculator,
        AffordabilityCalculator,
    },
    props: {
        initialPrice: {
            type: Number,
            required: true,
        },
    },
}
</script>
