<template>
    <div class="text-center">
        <div class="row" v-if="session.is_completed">
            <div
                :class="[
                    'col-md-6 col-md-offset-3',
                    getAlertClass
                ]"
                v-if="loadingTaskStatus || taskStatus"
            >
                <i v-if="loadingTaskStatus" class="fa fa-spinner fa-spin"></i>
                <p v-html="getStatusText"></p>
                <a
                    v-if="allowRetryTask"
                    class="btn btn-default btn-xs m-t-5" 
                    type="button"
                    @click="retryTask()"
                >
                    Ponovno zaženi <i class="fa fa-refresh"></i>
                </a>
                <a
                    v-if="allowRefreshTaskStatus"
                    class="btn btn-default btn-xs m-t-5" 
                    type="button"
                    @click="pollCheckTaskStatus()"
                >
                    Osveži status <i class="fa fa-refresh"></i>
                </a>
            </div>
        </div>

        <div v-if="!session.is_completed">
            <p>
                Za zaključek klica kliknite na spodnji gumb. Klic bo prekinjen, povezave pa ne bo možno ponovno vzpostaviti. Slike se bodo shranile in ustrezno obdelale.
            </p>
            <a
                class="btn btn-danger" 
                type="button"
                @click="confirmEndCall()"
            >
                {{ $t("virtual_visit:end_call") }}
            </a>
        </div>
        <div v-else>
            <a
                class="btn btn-info"
                type="button"
                @click="goToCallback()"
            >
                {{ $t("virtual_visit:finish_and_save") }}
            </a>
        </div>

    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service.js'
import mixins from '../mixins.js'
import { Swal } from '@/components/utils/swal.alerts.js';

export default {
    mixins: [ mixins ],
    props: {
        session: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            taskStatusPoll: null,
            taskStatus: {},
            loadingTaskStatus: false,

            automaticallyRedirect: false,
        }
    },
    methods: {
        confirmEndCall: function() {
            const app = this;
            Swal({
                title: "Ali ste prepričani?",
                text: "Klic s stranko bo prekinjen, povezave pa ne bo možno ponovno vzpostaviti. Slike se bodo shranile in ustrezno obdelale.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Da, prekini klic",
                cancelButtonText: "Ne, nadaljuj klic",
            }).then(async function(val){
                if (val.isConfirmed) {
                    app.endCall();
                }
            });
        },
        endCall: function() {
            const app = this;
            if ( app.OTsession ) {
                if (app.OTsession.connection) {
                    app.OTsession.signal({
                        type: "disconnect",
                        data: "disconnect"
                    }, (error) => {
                        if (error) {
                            // app.$parent.handleOTError(error);
                        }
                    });
                }
                app.OTsession.disconnect();
            }
            
            ApiService.get(`/virtual-visit/end_call/${app.session.session_id}`)
                .then(response => {
                    let task_id = response.data.task_id;
                    app.$emit("reloadSessionData");
                })
            window.onbeforeunload = null;
        },
        goToCallback: function() {
            window.location.href = this.session.session_end_callback;
        },
        retryTask: function() {
            const app = this;
            ApiService.get(`/virtual-visit/start-background-task/${app.session.session_id}`)
                .then(response => {
                    let task_id = response.data.task_id;
                    app.$emit("reloadSessionData");
                })
        },
        checkTaskStatus: function(task_id) {
            const app = this;
            app.loadingTaskStatus = true;
            const url = "/api/v1/celery-task-status/" + task_id;
            ApiService.get(url)
                .then(response => {
                    this.taskStatus = response.data;
                    if (response.data.state === "SUCCESS" || response.data.state === "FAILURE" || response.data.state === "UNKNOWN") {
                        app._stopLoadingTaskStatus();
                        if (this.automaticallyRedirect) {
                            setTimeout(() => {
                                this.goToCallback();
                            }, 5000); // 5-second delay
                        }
                    }
                    return response.data;
                })
                .catch(error => {
                    app._stopLoadingTaskStatus();
                    let msg = "";
                    if (error.response.status === 404) {
                        app.taskStatus = error.response.data;
                        msg = "Stanje obdelave klica ni bilo najdeno";
                    }
                    app.notify("Napaka pri pridobivanju stanja obdelave klica", msg, "warn");
                });
        },
        pollCheckTaskStatus: function(task_id) {
            const app = this;
            const pollInterval = 5000; // Poll every 5 seconds
            const maxRetries = 300;
            let retryCount = 0;

            app.taskStatus = null;
            app.checkTaskStatus(task_id);
            app.taskStatusPoll = setInterval(() => {
                if (retryCount >= maxRetries) {
                    app._stopLoadingTaskStatus()
                    return;
                }
                app.checkTaskStatus(task_id);
                retryCount++;
            }, pollInterval);
        },
        _stopLoadingTaskStatus: function() {
            this.loadingTaskStatus = false;
            clearInterval(this.taskStatusPoll);
            this.taskStatusPoll = null;
        },
    },
    computed: {
        OTsession() {
            return this.$store.state.OTsession;
        },
        getAlertClass() {
            let cls = "alert";
            let taskStatus = this.taskStatus;
            if (this.loadingTaskStatus) {
                cls += " alert-warning";
            } else if (taskStatus && taskStatus.state === "SUCCESS") {
                cls += " alert-success";
            } else if (taskStatus && (taskStatus.state === "FAILURE" || taskStatus.state === "UNKNOWN")) {
                cls += " alert-danger";
            }
            return cls
        },
        getStatusText() {
            let html = "";
            let taskStatus = this.taskStatus;
            if (this.loadingTaskStatus) {
                if (taskStatus && (taskStatus.state=='QUEUED' || taskStatus.state=='ACTIVE')) {
                    html = "Podatki klica so v obdelavi"
                } else {
                    html = "Preverjanje stanja obdelave podatkov klica"
                }
            } else {
                // if loadingTaskStatus=false
                if (taskStatus && (taskStatus.state=='SUCCESS')) {
                    html = '<i class="fa fa-check"></i><br> Podatki klica so obdelani';
                } else if (taskStatus && (taskStatus.state=='FAILURE')) {
                    html = '<i class="fa fa-exclamation-circle"></i><br> Prišlo je do napake pri obdelavi podatkov klica';
                } else if (taskStatus && (taskStatus.state=='UNKNOWN')) {
                    html = '<i class="fa fa-exclamation-circle"></i><br> Neznan status obdelave podatkov klica';
                }
            }
            return html;
            
        },
        allowRetryTask() {
            let taskStatus = this.taskStatus;
            return taskStatus && (
                taskStatus.state === 'FAILURE' ||
                taskStatus.state === 'UNKNOWN'
            )
        },
        allowRefreshTaskStatus() {
            let taskStatus = this.taskStatus;
            return !this.loadingTaskStatus && taskStatus.state != 'SUCCESS'
        },
    },
    watch: {
        session: {
            immediate: true,
            handler: function (newVal) {
                if (newVal && newVal.completed_celery_task_id) {
                    this.pollCheckTaskStatus(newVal.completed_celery_task_id);
                }
            },
        }
    },
}
</script>
