<template>
    <span class="re-key"
        :style="css_style"
        v-tooltip=" $t('widgets:modal:re_details')"
    >
        <a class="open-modal" :href="getOpenPropertyModalUrl(re_key, start_analysis_button)">
            {{re_key}}
        </a>
    </span>
</template>

<script>
export default {
    props: {
        re_key: {
            type: String,
            required: true
        },
        start_analysis_button: {
            type: Number,
            default: 0
        },
        css_style: {
            type: Object,
            default: () => {},
        },
    },
}
</script>
