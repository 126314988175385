<template>
    <div>
        <form @submit.prevent="submit">
            <div class="form-group m-b-0" :class="{'has-error': !valid}">
                <div class="input-group">
                    <textarea 
                        class="form-control"
                        :rows="rows"
                        :placeholder="$t('task_details:manage:enter_re_keys')"
                        v-model="re_keys"
                    ></textarea>
                    <span class="input-group-addon custom-cursor-pointer" @click="submit">
                        <i class="fa fa-plus"></i> {{$t("general:add")}}
                    </span>
                </div>
                <small v-if="!valid" class="form-text text-muted">
                    {{$t("task_details:manage:enter_at_least_one_re_key")}}
                </small>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        rows: {
            type: Number,
            default: 3,
        }
    },
    data: function () {
        return {
            re_keys: "",
            valid: true,
        }
    },
    methods: {
        validate: function() {
            // Just validate that 're_keys' isn't empty
            return this.re_keys != "" && this.re_keys;
        },
        submit: function() {
            if(this.validate()) {
                this.valid = true
                this.$emit('submit', this.re_keys)
                this.re_keys = ""
            } else {
                this.valid = false
            }
        }
    },
}
</script>

<style scoped>
    textarea {
        resize: none;
    }
</style>