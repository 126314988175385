<template>
    <div class="container-fluid m-0 p-0">

        <div class="col-md-3 p-l-0">
            <search-form
                v-if="formData"
                v-model="formData"
                @change="submitSearchForm"
                @locationSelected="handleLocationSelected"
                @reset="getDefaultFormData"
            />
        </div>

        <div class="col-md-9 p-0">
            <transactions-map
                :height="'950px'"
                :markers="markers"
                :mainMarker="mainMarker"
                :clickedMarkerData="this.clickedMarkerData"
                :comparableTransactionItemsIds="comparableTransactionItemsIds"
                :loadingMarkers="loadingMarkers"
                :center="mainMarker.coordinates"
                :zoom="15"
                @update:bounds="handleMapMove"
                @marker-clicked="getClickedMarkerData"
                @comparable-clicked="$emit('comparable-clicked', $event)"
                ref="map"
            >
                <template v-slot:marker-icon-content="slotProps">
                    {{ slotProps.marker.transaction_amount_gross|formatThousands }} €
                </template>
            </transactions-map>
        </div>
    </div>
</template>

<script>
import { LPopup } from 'vue2-leaflet';

import ApiService from "@/components/utils/api.service.js"

import ComparableTransactionsSearchForm from "./Form.vue";
import ComparableTransactionsTable from "../Table.vue";
import TransactionDetails from "../TransactionDetails.vue";
import PropertyIQTransactionMap from "../Map.vue"

export default {
    components: {
        LPopup,

        "transaction-details": TransactionDetails,
        "search-form": ComparableTransactionsSearchForm,
        "transactions-map": PropertyIQTransactionMap,
        "transactions-table": ComparableTransactionsTable,
    },
    props:  {
        comparableTransactionItemsIds: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object
        }
    },
    data: function () {
        return {
           formData: null,

            // Map-related props
            markers: [],
            loadingMarkers: false,
            clickedMarkerData: null
        }
    },
    methods: {
        handleLocationSelected: function(latlng){
            this.$refs.map.$refs.map.setCenter(latlng);
            this.$refs.map.$refs.map.setZoom(12);
        },
        submitSearchForm: function(){
            this.markers = [];
            this.getMarkers();
        },
        handleMapMove: function(){
            this.getMarkers();
        },
        getDefaultFormData: function() {
            ApiService
                .get(`/propertyiq/api/market/${this.$store.state.sessionId}/default-formdata`)
                .then(response => {
                    this.formData = response.data;
                    this.getMarkers();
                })
                .catch(error => {
                    throw error;
                })
        },
        getMarkers: function(){
            this.loadingMarkers = true;
            ApiService
                .post(
                    "/propertyiq/api/market/search",
                    {   
                        ...this.formData,
                        bbox: this.$refs.map.$refs.map.getBBox().toBBoxString(),
                    }
                )
                .then(response => {
                    let existingsMarkerIds = this.markers.map(i => i.id)
                    let newmarkers = response.data.filter(m => !existingsMarkerIds.includes(m.id));
                    this.markers = this.markers.concat(newmarkers);
                    this.loadingMarkers = false;
                })
                .catch(error => {
                    throw error;
                })
        },
        getClickedMarkerData: function(clickedMarker) {
            this.clickedMarkerData = null;
            ApiService
                .get("/market/api/search", {
                    params: { id: clickedMarker.id }
                })
                .then(response => {
                    this.clickedMarkerData = response.data;
                    this.clickedMarkerData.idx = clickedMarker.idx;
                })
                .catch(error => {
                    throw error;
                })
        }
    },
    mounted() {
        this.getDefaultFormData();
    },
}
</script>
