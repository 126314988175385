<template>
    <BaseInput
        :prependIcon="prependIcon"
        :showErrorMessage="customShowErrorMessage"
        @icon-click="$emit('icon-click')"
    >
        <input
            v-model="context.model"
            v-bind="context.attributes"
            :data-type="context.type"
            :type="context.type"

            @blur="updateErrors"
            @focus="clearErrors"
        />
    </BaseInput>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import baseinputmixin from "../mixins/baseinputmixin";
import BaseInput from "./BaseInput.vue";

export default {
    name: "DateTimeInput",
    mixins: [ FormulateInputMixin, baseinputmixin ],
    components: {
        BaseInput,
    },
    methods: {
        isValid() {
            if (!this.context.model)  // not empty
                return true;
            if (this.showErrorMessage)  // forced from parent
                return true;
            return true;
        },
    },
}
</script>
