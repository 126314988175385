<template>
    <form>

        <div class="row">
            <div class="col-md-12">
                <div class="text-left field-label">
                    <p><strong>{{ $t('general:real_estate')}}</strong></p>
                </div>
                <box-select-widget
                    v-model="form_data.re_class"
                    :options="re_class_options"
                    :sizeClass="'lg'"
                    @change="setFormData('re_class', $event)"
                />

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:real_estate:type')}}</strong></p>
                </div>
                <box-select-widget
                    v-model="form_data.re_type"
                    :options="getReTypeOptions"
                    :multiple="true"
                    :sizeClass="'sm'"
                    @change="handleFieldUpdated"
                />
            </div>

            <div class="col-md-12">
                <div class="text-left field-label">
                    <p><strong>{{ $t('general:price')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <formatted-input-field
                            v-model="form_data.price__gte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="€"
                            @change="handleFieldUpdated"
                        />
                    </div>

                    <div class="col-md-6">
                        <formatted-input-field
                            v-model="form_data.price__lte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="€"
                            @change="handleFieldUpdated"
                        />
                    </div>
                </div>

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:size')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <formatted-input-field
                            v-model="form_data.size__gte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="m²"
                            @change="handleFieldUpdated"
                        />
                    </div>

                    <div class="col-md-6">
                        <formatted-input-field
                            v-model="form_data.size__lte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="m²"
                            @change="handleFieldUpdated"
                        />
                    </div>
                </div>

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:duration')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <box-select-widget
                            v-model="form_data.months_last_seen"
                            :options="months_last_seen_options"
                            :sizeClass="'sm'"
                            @change="handleFieldUpdated"
                        />
                    </div>
                </div>

            </div>

            <div class="col-md-12">
                <div class="text-left field-label">
                    <p><strong>{{ $t('general:city')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <typeahead
                            v-model="location"
                            size="sm"
                            :data="searchResults"
                            @hit="locationSelected"
                            append="@"
                        />
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12 text-center">
                <hr>
                <button class="btn btn-info text-center" @click.prevent>
                  {{ $t('general:search')}}
                </button>
                <hr>
            </div>
        </div>

    </form>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import FormattedInputField from '@/components/utils/FormattedInputField.vue';
import Utils from '@/components/utils/utils.js';
import BoxSelectWidget from '@/components/widgets/BoxSelectWidget.vue';

export default {
    name: "ComparableTransactionsSearchForm",
    components: {
        "typeahead": VueBootstrapTypeahead,
        FormattedInputField,
        BoxSelectWidget
    },
    props: {
        form_data: Object,
    },
    model: {
        prop: "form_data",
        event: "change"
    },
    data: function () {
        return {
            re_class_options: [
                {
                    "text": this.$t('general:apartment'),
                    "icon": "fa fa-building",
                    "value": "appartment"
                },
                {
                    "text": this.$t('general:house'),
                    "icon": "fa fa-home",
                    "value": "house"
                },
                {
                    "text": this.$t('general:office_building'),
                    "icon": "fa fa-industry",
                    "value": "business"
                },
                {
                    "text": this.$t('property_iq:parcel'),
                    "icon": "fa fa-map",
                    "value": "parcel"
                },
                {
                    "text": this.$t('general:parking_spot'),
                    "icon": "fa fa-car",
                    "value": "parking"
                },
            ],

            location: "",
            searchResults: [],

            months_last_seen_options: [
                { value: 1, text: this.$t('comparable:active') },
                { value: 6, text: " 6 " + this.$t('comparable:active:month') },
                { value: 12, text: " 12 " + this.$t('comparable:active:month')},
                { value: 18, text: " 18 " + this.$t('comparable:active:month') },
            ],
        };
    },
    methods: {
        locationSelected(location) {
            const app = this;
            this.geoCoderService.geocode(
                { address: location },
                function (results, status) {
                    if (status == window.google.maps.GeocoderStatus.OK) {
                        app.$emit("locationSelected", {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        })
                    }
                }
            );
        },
        handleFieldUpdated() {
            this.$emit("change", this.form_data);
        },
        autocompletersInit() {
            this.autocompleteService = new window.google.maps.places.AutocompleteService();
            this.geoCoderService = new window.google.maps.Geocoder();
        },
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = [];
                return;
            }
            this.searchResults = predictions.map(
                (prediction) => prediction.description.replace(", Slovenija", "").replace(", Slovenia", "")
            );
        },

        numberFormatter(value) {
            return Utils.formatNumber(value, 0);
        },
        numberDeFormatter(value) {
            return parseInt(value.replaceAll(".", ""));
        },
        setFormData: function(field, value) {
            if(field=="re_class"){
                this.form_data["re_type"] = [];
            }
            this.form_data[field] = value;
            this.handleFieldUpdated();
        },
    },
    computed: {
        getReTypeOptions: function(){
            let re_class = this.form_data.re_class;
            let options;
            if(re_class=="house"){
                options = [this.$t('comparable:house:atri'),this.$t('comparable:house:twin'),this.$t('comparable:house:double'),this.$t('comparable:house:detached'),this.$t('comparable:house:triplet'),this.$t('comparable:house:terraced'),this.$t('comparable:other')];
            } else if (re_class=="appartment") {
                options = ["1,5-" + this.$t('comparable:apartment:rooms'),"1-"+ this.$t('comparable:apartment:rooms'),"2,5-"+ this.$t('comparable:apartment:rooms'),"2-"+ this.$t('comparable:apartment:rooms'),"3,5-"+ this.$t('comparable:apartment:rooms'),"3-"+ this.$t('comparable:apartment:rooms'),"4,5-"+ this.$t('comparable:apartment:rooms'),"4-"+ this.$t('comparable:apartment:rooms'),"5 " + this.$t('comparable:apartment:rooms_and_more'),this.$t('comparable:apartment:apartment'),this.$t('comparable:apartment:studio'),this.$t('comparable:apartment:room'),this.$t('comparable:other')]
            } else if (re_class=="business") {
                options = [this.$t('comparable:business:workshop'),this.$t('comparable:business:restaurant'),this.$t('comparable:business:combined'),this.$t('comparable:business:hardware_store'),this.$t('comparable:business:medical_office'),this.$t('comparable:business:office'),this.$t('comparable:business:service_office'),this.$t('comparable:business:warehouse'),this.$t('comparable:business:office_complex'),this.$t('comparable:business:store'),this.$t('comparable:other')]
            } else if (re_class=="parcel") {
                options = [this.$t('comparable:parcel:farm'),this.$t('comparable:parcel:field'),this.$t('comparable:parcel:unbuildable'),this.$t('comparable:parcel:for_investment'),this.$t('comparable:parcel:buildable'),this.$t('comparable:other')]
            } else if (re_class=="parking") {
                options = [this.$t('comparable:parking:spot')]
            }
            return options.map(i => ({value: i, text: i}))
        },
    },
    watch: {
        location(newValue) {
            if (newValue) {
                this.autocompleteService.getPlacePredictions(
                    {
                        input: this.location,
                        types: ["(cities)"],
                        componentRestrictions: { country: "si" },
                    },
                    this.displaySuggestions
                );
            }
        },
    },
    mounted() {
        this.autocompletersInit();
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
}

div.selected {
    background-color: #EFEFEF;
    border: 1px solid #999999;
}

.icon-selector {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 93px;
}
.icon-selector-small {
    padding: 5px;
    min-height: 40px;
}
.icon-selector i.fa{
    font-size: 23px;
}
.icon-selector p{
    font-size: 13px;
    margin: 0;
}
.icon-selector p strong{
    font-size: 14px;
    margin: 0;
}
.icon-selector:hover, .export-option:hover{
    border-color: #eee;
}
</style>