<template>
    <wms-leaflet-map
        class="property-iq__parcel-borders-map-div"
        :gursSessionId="$store.state.gursSessionId"
        :height="400"
        :fullscreen="true"
        :zoom="19"
        :minZoom="17"
        :maxZoom="20"
        :center="realEstate.gps"
        :polygons="polygons"
        ref="map"
        defaultVisibleBaseLayerName="Brez podlage"
    >
        <template v-slot:popup_content_polygon="slotProps">
            <div class="p-10" style="white-space: nowrap;">{{ $t('property_iq:parcel')}} {{slotProps.polygon.property_id}}</div>
        </template>

        <template v-slot:markers>
            <l-marker
                v-if="marker"
                :lat-lng="marker.coordinates"
                :icon="house_icon"
            >
                <l-popup
                    :options="{autoPan: true}"
                >
                    <div class="p-10">{{realEstate.address}}</div>
                </l-popup>
            </l-marker>
        </template>
        
    </wms-leaflet-map> 
</template>

<script>
import L from 'leaflet';

import { LPopup, LMarker } from 'vue2-leaflet';

import KWMSLeafletMap from "@/components/k-leaflet-map/KWMSLeafletMap";

export default {
    components: {
        LPopup, LMarker,
        "wms-leaflet-map": KWMSLeafletMap
    },
    props: {
        realEstate: Object,
        polygons: Array,
    },
    data() {
        return {
            marker: { coordinates: this.realEstate.gps},
            house_icon: L.icon({
                iconUrl: require('@/images/house.png'),
                iconSize: [60, 60],
                iconAnchor: [30, 60],
                popupAnchor:  [0, -60]
            }),
        }
    },
    mounted() {
        this.$refs.map.baseLayers = []
    }
}
</script>

<style>
.property-iq__parcel-borders-map-div .leaflet-container {
    background: white!important;
    outline: 0;
}
</style>
