<template>
    <div>
        <div class="row">
            <div class="col-md-5">
                <form class="form-group" method="GET">
                    <div class="row">
                        <div class="col-lg-10">
                            <strong>{{$t("affordability:yearly_income")}}:</strong>
                            <b-input-group append="€" class="input-group">
                                <validate-input-field v-model="income" :formatter="numberFormatter" :deformatter="numberDeFormatter" :validator="incomeValidator" />
                            </b-input-group>
                        </div>
                    </div>
                    <p class="red small">{{income_message}}</p>
                    <br>
                    <div class="row">
                        <div class="col-lg-10">
                            <strong>{{$t("affordability:deposit")}} ({{$t("affordability:savings")}}):</strong>
                            <b-input-group class="input-group" append="€">
                                <validate-input-field v-model="down" :formatter="numberFormatter" :deformatter="numberDeFormatter" :validator="downValidator" />
                            </b-input-group>
                        </div>
                    </div>
                    <p class="red small">{{down_message}}</p>
                    <br>
                    <div class="row">
                        <div class="col-lg-10">
                            <strong>{{$t("affordability:monthly_debts")}}:</strong>
                            <b-input-group append="€" class="input-group">
                                <validate-input-field v-model="debts" :formatter="numberFormatter" :deformatter="numberDeFormatter" :validator="debtValidator" />
                            </b-input-group>
                        </div>
                    </div>
                    <p class="red small">{{debt_message}}</p>
                    <br>
                    <div class="row">
                        <div class="col-lg-10">
                            <strong>{{$t("affordability:interests")}}:</strong>
                            <b-input-group append="%" class="input-group">
                                <validate-input-field v-model="interest" :formatter="decimalFormatter" :deformatter="decimalDeFormatter" :validator="interestValidator" />
                            </b-input-group>
                        </div>
                    </div>
                    <p class="red small">{{interest_message}}</p>
                    <br>
                    <div class="row">
                        <div class="col-lg-10">
                            <strong>{{$t("affordability:duration")}}:</strong>
                            <b-input-group>
                                <b-form-select v-model="years" :options="selectoptions"></b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                </form>
                <div>
                </div>
            </div>
            <div class="col-md-7 grey-border">
                <div>
                    <div class="col underlined-div">
                        <p class="text-center">{{$t("affordability:afford_a_home")}}:</p>
                        <p class="price text-center"> {{formated_price}}€</p>
                        <br><br>
                    </div>
                    <div class="row m-3">
                        <div class="col-lg-4 right-border-bold">
                            {{$t("affordability:ratio:income_debt")}}:
                            <p :class="dti<=36? 'numbers green' : dti<=42? 'numbers orange' : 'numbers red'">{{dti}}%</p>
                        </div>
                        <div class="col-lg-4 right-border-bold">
                            {{$t("affordability:loan:monthly_fee")}}:
                            <p class="numbers ">{{mortgage_formatted}}€</p>
                        </div>
                        <div class="col-lg-4">
                            {{$t("affordability:loan:left")}}:
                            <p :class="remaining<715? 'numbers red' : remaining<900?'numbers orange':'numbers green'">{{remaining}}€</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="mb-3 p-3 col-lg">
                    <div class="text-center">
                        <strong>{{$t("affordability:ratio:income_debt")}}: {{dti}}%</strong><br>
                    </div >
                    <b-form-input v-model="dti" type="range" min="0" max="50">
                    </b-form-input>
                </div>
                
                <div class="col grey-container">
                  <br>
                  <strong>{{$t("affordability:income_ratio:info")}} </strong>
                  <br> <br>
                  {{$t("affordability:income_ration:explanation")}}:

                  <br><br>
                    <strong>0-36%:</strong> {{ $t("affordability:rate:normal")}} <br>
                    <strong>37-42%:</strong> {{ $t("affordability:rate:warning")}} <br>
                    <strong>43-50%:</strong> {{ $t("affordability:rate:critical")}}
                  <br><br>

                  {{$t("affordability:loan:bs_warning")}} <a target="_blank" href="https://www.bsi.si/mediji/1413/najpogostejsa-vprasanja-in-odgovori-glede-zaostritve-ukrepov-banke-slovenije-na-podrocju-kreditiranja-prebivalstva">{{$t("property_iq:loan:bank_of_slovenia")}}</a>.


                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'


    import {
        InputGroupPlugin,
        FormInputPlugin,
        FormSelectPlugin,
    } from "bootstrap-vue";
    Vue.use(InputGroupPlugin);
    Vue.use(FormInputPlugin);
    Vue.use(FormSelectPlugin);

    import Utils from "@/components/utils/utils.js";
    import ValidateInputField from "@/components/utils/ValidateInputField";

    Vue.use(ValidateInputField);
    export default {
        data: function() {
            return {
                down_message: "",
                income_message: "",
                debt_message: "",
                interest_message:"",
                dti: 35,
                income: 2000,
                down: 25000,
                insurance: 200,
                years: 20,
                debts: 0,
                interest: 3,
                selectoptions: [{
                        value: 10,
                        text: '10 let'
                    },
                    {
                        value: 15,
                        text: '15 let'
                    },
                    {
                        value: 20,
                        text: '20 let'
                    },
                    {
                        value: 25,
                        text: '25 let'
                    },
                    {
                        value: 30,
                        text: '30 let'
                    }
                ],
            }
        },
        props: {},
        components: {
            ValidateInputField,
        },
        methods: {
            formatNumber: Utils.formatNumber,
            numberFormatter(value) {
                return this.formatNumber(parseFloat(value), 0);
            },
            numberDeFormatter(value) {
                if (this.isNumeric(value.replaceAll(".", ""))) {
                    return parseInt(value.replaceAll(".", ""));
                }
                return value;
            },
            decimalFormatter(value) {
                return this.formatNumber(parseFloat(value), 2)
            },
            decimalDeFormatter(value) {
                if (this.isNumeric(value.replace(",", "."))) {
                    return parseFloat(value.replaceAll(",", "."));
                }
                return value;
            },
            isNumeric(val) {
                return !isNaN(val) && !isNaN(parseFloat(val))
            },
            incomeValidator(value) {
                if (this.isNumeric(value) && value >= 800 && value <= 2*10 ** 4){
                    this.income_message = "";
                    return true;
                }
                this.income_message = this.$t("affordability:income:out_of_bounds_warning");
                return false;
            },
            downValidator(value) {
                if (this.isNumeric(value) && value >= 0 && value <= 10 ** 6){
                    this.down_message = "";
                    return true;
                }
                this.down_message = this.$t("affordability:income:down_payment_warning");
                return false;

            },
            debtValidator(value) {
                if (this.isNumeric(value) && value >= 0){
                    this.debt_message = ""
                    return true;
                }
                this.debt_message = this.$t("affordability:income:debt_warning")
                return false
            },
            interestValidator(value) {
                if (this.isNumeric(value) && value >= 0 && value <= 8){
                    this.interest_message = ""
                    return true;
                }
                this.interest_message = this.$t("affordability:income:interest_warning")
                return false;
            },
        },
        computed: {
            remaining: function() {
                return this.income - this.mortgage;
            },
            formated_price: function() {
                return this.formatNumber(this.price, 0);
            },
            month_interest: function() {
                return this.interest / 1200;
            },
            mortgage: function() {
                return Math.max(
                    Math.round(this.income * this.dti / 100) - this.debts, 0);
            },
            months: function() {
                return 12 * this.years;
            },
            borrowed: function() {
                return Math.round((this.mortgage * ((this.month_interest + 1) ** this.months - 1)) / (this.month_interest * (this.month_interest + 1) ** this.months));
            },
            price: function() {
                return Math.max(this.borrowed * 1 + this.down * 1, 0);
            },
            mortgage_formatted: function() {
                return this.numberFormatter(this.mortgage);
            },
        }
    };
</script>
<style scoped>
    .m-3 {
      margin: 1rem !important;
    }
    .mb-3 {
        margin-bottom: 1rem !important; 
    }
    .p-3 {
      padding: 1rem !important;
    }
    .green {
        color: green;
    }

    .orange {
        color: orange;
    }

    .red {
        color: red;
    }
    .small{
        font-size: 9px;
    }

    .underlined-div {
        border-bottom: solid;
        border-width: 1px;
        border-color: #cccccc;
    }

    .right-border-bold {
        border-right: solid;
        border-color: #cccccc;
        border-width: 1px;
    }

    p {
        font-size: 20px;
    }

    p.numbers {
        font-size: 22px;
    }

    p.price {
        font-size: 30px;
        font-weight: bold;
    }

    .grey-border {
        padding: 0;
        border-style: solid;
        border-color: #cccccc;
        border-width: 1px;
        border-radius: 5px;
    }

    .grey-container {
        background: #f8f8f8;
        padding: 15px;
        border-radius: 5px; 
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=range] {
        position: relative;
        top: 100%;
    }

    input[type=range]::-moz-range-track {
        background: linear-gradient(90deg, rgba(8, 185, 17, 1) 0%, rgba(255, 231, 0, 1) 50%, rgba(255, 0, 0, 1) 75%);
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>