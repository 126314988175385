<template>
    <table class="table text-center">
        <thead>
            <tr>
                <th
                    v-for="col in columns"
                    v-bind:key="col.id"
                    class="text-center"
                    v-bind:class="getSortedClass(col.sort_key)"
                >
                    <a href="#" v-if="sortable&&col.sort_key" v-on:click.prevent="sortBy(col.sort_key)">{{col.display_name}}</a>
                    <span v-else>{{col.display_name}}</span>
                </th>
            </tr>
        </thead>

        <tbody>
            <!-- <tr v-if="!transactions" class="text-center">
                <td colspan="4">
                    <strong>Ni transakcij</strong>
                </td>
            </tr> -->
            <template v-for="item in transactions">
                
                <results-row
                    v-bind:key="item.id"
                    :item="item"
                    :columns="columns.map(i=>i.key)"
                    :row_expanded="showAdditionalItems(item.transaction_id)"
                    @labelclicked="toggleAdditionalTransactionitems(item.transaction_id)"
                >
                    <template v-slot:wide-set-options-cell>
                        <a v-on:click.prevent.stop="$emit('comparable-clicked', false, item)" href="#">
                            <i class="fa"
                                :class="getCheckboxIconClass(item.transaction_id, false)">
                            </i>
                        </a>
                    </template>

                    <template v-slot:narrow-set-options-cell>
                        <a v-if="getPerm('market_search.market_search_comparable')" 
                            v-on:click.prevent.stop="$emit('comparable-clicked', true, item)" href="#">
                            <i class="fa"
                                :class="getCheckboxIconClass(item.transaction_id, true)">
                            </i>
                        </a>
                    </template>
                    
                </results-row>

                <tr 
                    v-if="showAdditionalItems(item.transaction_id) && getTransactionItems(item.transaction_id, item.id)=='loading'"
                    v-bind:key="item.id+'-loading-row'"
                >
                    <td :colspan="columns.map(i=>i.key).length">
                        Nalaganje ...
                    </td>
                </tr>

                <results-row
                    v-else-if="showAdditionalItems(item.transaction_id)"
                    v-for="sub_item in getTransactionItems(item.transaction_id, item.id)"
                    v-bind:key="sub_item.id"
                    :item="sub_item"
                    :columns="columns.map(i=>i.key)"
                    :hide_column_values="['transaction_id']"
                />

            </template>
            
        </tbody>
    </table>
</template>

<script>
import Vue from "vue";

import ApiService from "@/components/utils/api.service.js"
import Utils from "@/components/utils/utils.js"

import MarketSearchResultsRow from './MarketSearchResultsRow'

export default {
    props:  {
        transactions: Array,

        transaction_ids_being_processed: {
            type: Array,
            default: () => []
        },
        transaction_ids_failed: {
            type: Array,
            default: () => []
        },
        selected_comparable_transaction_ids: {
            type: Array,
            default: () => []
        },
        selected_wide_set_transaction_ids: {
            type: Array,
            default: () => []
        },
        
        // Permissions
        allowed_comparable_count: {
            type: Number,
            default: 10
        },
        perms: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    components: {
        "results-row": MarketSearchResultsRow
    },
    data: function () {
        return {
            sortable: true,
            sort_key: "",
            all_columns: [
                {   
                    key: "transaction_id",
                    display_name: "ID Posla",
                    sort_key: "transaction_id"
                },
                {   
                    key: "component_type",
                    display_name: "Predmet posla",
                    sort_key: "component_type"
                },
                {   
                    key: "re_key",
                    display_name: "ID nepremičnine",
                    sort_key: "re_key"
                },
                {   
                    key: "address",
                    display_name: "Naslov",
                    sort_key: "address"
                },
                {   
                    key: "transaction_date",
                    display_name: "Datum pogodbe",
                    sort_key: "transaction_date"
                },
                {   
                    key: "transaction_amount_gross",
                    display_name: "Cena",
                    sort_key: "transaction_amount_gross"
                },
                {
                    key: "transaction_amount_m2",
                    display_name: "Cena/m2",
                    sort_key: "transaction_amount_m2"
                },
                {   
                    key: "building_year_built",
                    display_name: "Letnica izgradnje",
                    sort_key: "building_year_built"
                },
                {   
                    key: "component_size",
                    display_name: "Pogodbena površina",
                    sort_key: "component_size"
                },
                {
                    key: "sizes",
                    display_name: "Površine",
                },
                {
                    key: "wide_set_comparable_options",
                    display_name: "Širši nabor",
                },
                {
                    key: "narrow_set_comparable_options",
                    display_name: "Izbrani primerljivi",
                    required_perm: "market_search.market_search_comparable"
                },
            ],
            
            uncollapsed_transaction_ids: [],
            additional_transaction_data: {},
        }
    },
    methods: {
        // Sorting methods
        sortBy: function(sort_key) {
            if(this.sort_key===sort_key){
                this.sort_key = "-"+sort_key;
            }else if(this.sort_key==="-"+sort_key){
                this.sort_key = null;
            } else{
                this.sort_key = sort_key;
            }
        },
        getSortedClass: function(sort_key) {
            if(!sort_key || !this.sortable) {return ""}
            let cls = "sorting";
            if (this.sort_key===sort_key) {
                cls+=" sorting-asc";
            } else if (this.sort_key==="-"+sort_key) {
                cls+=" sorting-desc";
            }
            return cls;
        },

        // Fetch and expand transaction items methods
        toggleAdditionalTransactionitems: async function(transaction_id){
            if(this.uncollapsed_transaction_ids.includes(transaction_id)) {
                Utils.removeFromArray(this.uncollapsed_transaction_ids, transaction_id);
            } else {
                Utils.addToArray(this.uncollapsed_transaction_ids, transaction_id);
            }
            if(!this.additional_transaction_data[transaction_id]) {
                Vue.set(this.additional_transaction_data, transaction_id, "loading");
                this.fetchTransactionItems(transaction_id);
            }
        },
        getTransactionItems: function(transaction_id, exclude_id){
            let items = this.additional_transaction_data[transaction_id];
            if (Array.isArray(items)) {return items.filter(item => item.id != exclude_id);}
            return items;
        },
        fetchTransactionItems: function(transaction_id) {
            ApiService
                .get("market/api/search", { params: {
                    transaction_id: transaction_id
                }})
                .then(response => {
                    Vue.set(this.additional_transaction_data, transaction_id, response.data) 
                })
                .catch(error => {
                    console.log(error);
                    // Swal.fire("Napaka", "", "error");
                })
        },
        showAdditionalItems: function(transaction_id) {
            return this.uncollapsed_transaction_ids.includes(transaction_id);
        },

        // Permitions
        getPerm: function(perm_name) {
            return this.perms.includes(perm_name);
        },

        // Add and remove from comparables
        getCheckboxIconClass: function(transaction_id, comparable) {
            if (this.transaction_ids_failed.includes(transaction_id)) {
                return "fa fa-exclamation-circle";
            }
            if (this.transaction_ids_being_processed.includes(transaction_id)) {
                return "fa fa-spinner fa-spin";
            }

            let arr;
            if (comparable) {
                arr = this.selected_comparable_transaction_ids;
            } else {
                arr = this.selected_wide_set_transaction_ids;
            }
            
            if(arr.includes(transaction_id)) {
                return "fa-check-square-o";
            } else {
                let icon = "fa-square-o";
                if (comparable && !this.allowAddComparable) {
                    icon += " blurred"
                }
                return icon
            }
        },

    },
    computed: {
        allowAddComparable: function() {
            let cnt = this.selected_comparable_transaction_ids.length + this.transaction_ids_being_processed.length;
            if (cnt >= this.allowed_comparable_count) {
                return false;
            } else {
                return true;
            }
        },

        columns: function() {
            return this.all_columns.filter(i => {
                if(i.required_perm){
                    return this.perms.includes(i.required_perm);
                }
                return true;
            })
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
    th.sorting::after {
        content: "\f0dc";
        font-family: fontawesome;
        color: rgba(50, 50, 50, .5);
        float: none;
        padding-left: 10px;
    }
    th.sorting-asc::after {
        content: "\f0de";
    }
    th.sorting-desc::after {
        content: "\f0dd";
    }
    table{
        font-size: 14px;
        font-family: Rubik, sans-serif;
        font-weight: 200;
    }
</style>