<template>
    <div>
        <base-task-status
            :session_id="session.id"
            :task_name="`document_${type}`"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            required: true,
            validator: value => {
                return ["possession_sheet", "cadastral_plan"].includes(value)
            },
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/${this.session.id}/document/exists/${this.type}.pdf`)
                .then(response => {
                    if (response.data.exists) {
                        let url = `/amas/analysis/${this.session.id}/document/list`;
                        return `
                            <a href="${url}">
                                <span class="circle circle-sm bg-success" data-toggle="tooltip" data-original-title="Na voljo" href="/abcd">
                                    <i class="fa fa-check"></i>
                                </span>
                            </a>
                        `
                    } else {
                        return `
                            <span class="circle circle-sm bg-light" data-toggle="tooltip" data-original-title="${this.$t("task_details:status:no_data")}">
                                <i class="fa fa-close"></i>
                            </span>
                        `
                    }
                })
        }
    },
}
</script>
