<template>
    <order
        :orderId="null"
        :readOnly="false"
        :showAppraisers="false"
        :showOffers="false"
        @order-submitted="$emit('order-submitted', $event)"
        @order-saved="$emit('order-saved', $event)"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
}
</script>
