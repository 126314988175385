<template>
    <div>
        <div class="col-md-6">
            <google-places-results-panel
                :title="$t('google_places:public_transport')"
                icon="fa fa-car"
                :data="data.javni_promet"
                @click="handleHeadingClick('javni_promet')"
            />

            <google-places-results-panel
                :title="$t('google_places:education')"
                icon="fa fa-book"
                :data="data.izobrazevanje"
                @click="handleHeadingClick('izobrazevanje')"
            />
        </div>

        <div class="col-md-6">
            <google-places-results-panel
                :title="$t('google_places:management_and_safety')"
                icon="fa fa-shield"
                :data="data.uprava_in_varnost"
                @click="handleHeadingClick('uprava_in_varnost')"
            />

            <google-places-results-panel
                :title="$t('google_places:shops')"
                icon="fa fa-shopping-cart"
                :data="data.trgovine"
                @click="handleHeadingClick('trgovine')"
            />
        </div>

        <button v-if="showRefreshButton" @click="updateAll" class="btn btn-info btn-xs pull-right">{{ $t("general:update")}}</button>
    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service.js'

import GooglePlacesResultsPanel from './GooglePlacesResultsPanel';

import Cookies from 'js-cookie';

export default {
    components: {
        GooglePlacesResultsPanel
    },
    props: {
        api_url: {
            type: String,
            default: ""
        },
        gps: Object,
        showRefreshButton: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            data: {},
        }
    },
    methods: {
        _loading: function(inf_type) {
            this.$set(this.data, inf_type, "loading");
        },
        handleHeadingClick: function(inf_type) {
            if(!this.data[inf_type]) {
                this.getInfrastructure(inf_type, false);
            }
        },
        updateAll: function() {
            const app = this;
            let inf_types = ["javni_promet", "izobrazevanje", "uprava_in_varnost", "trgovine"];
            // eslint-disable-next-line no-unused-vars
            inf_types.forEach(function (inf_type, _) {
                app.getInfrastructure(inf_type, true);
            });
        },
        getInfrastructure: function(inf_type, force_update){
            this._loading(inf_type);
            ApiService
                .post(
                    "/amas/real-estate/api/googleplaces",
                    {
                        inf_type: inf_type,
                        gps: this.gps,
                        force_update: force_update
                    }
                )
                .then(response => {
                    this.$set(this.data, inf_type, response.data);
                })
                .catch(error => {
                    throw error;
                })
        }
    },
    mounted() {
        ApiService.init();
        ApiService.set_headers({
            "X-CSRFToken": Cookies.get("csrftoken")
        })
    }
}
</script>
