export default {
    methods: {
        getPostopekHref(act) {
            return `https://pis.eprostor.gov.si/pis-evt-web/pages/javni-del/prostorskiakti/prostorski_akt_podrobnosti.xhtml?idPostopka=${act.POSTOPEK_EPL_ID}`;
        },
        getTranslation(key) {
            if (!key.includes("_"))
                return key;
            const [name, type] = key.split("_");

            const transName = this.$t(`spatial_acts:${name}`);
            const transType = type === "zakljucen" ? this.$t(`spatial_acts:finished`) : this.$t(`spatial_acts:in_progress`);

            return `${transName} (${transType})`;
        },
    },
}
