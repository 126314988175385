<template>
    <div>
        <a class="custom-cursor"
            v-b-toggle="'collapse-realestate-'.concat(_uid)"
            v-if="real_estate.unit || (real_estate.parcel && real_estate.parcel.units_list.length)"
        >
            {{$t("general:show")}} {{real_estate.is_parcel ? $t("real_estate:units2") : $t("general:parcel2") }}
            <i class="fa fa-angle-double-down"></i>
        </a>

        <b-collapse :id="'collapse-realestate-'.concat(_uid)" class="mt-2">
            <div v-if="real_estate.parcel && real_estate.parcel.units_list.length">
                <div type="button" class="btn btn-info btn-outline btn-xs pull-right"
                    @click.prevent="addRealEstates(real_estate.parcel.units_list.map(i=>i.property_id))"
                >
                    {{$t("general:add_all")}}
                </div>
                <ul class="custom-unstyled-list p-0 m-b-0">
                    <li v-for="(u, u_idx) in real_estate.parcel.units_list.slice(0, 5)" :key="'li-units-' + u_idx">
                        <re-open-modal :re_key="u.property_id" />
                        <add-remove-button
                            :re_key="u.property_id"
                            :added="existingReKeys.includes(u.property_id)"
                            @add-real-estates="addRealEstates(u.property_id)"
                        />
                    </li>
                </ul>

                <div v-if="real_estate.parcel.units_list.length > 3">
                    <a class="btn btn-info btn-outline btn-xs"  v-b-toggle="'collapse-realestate-expd'.concat(_uid)">
                        + {{real_estate.parcel.units_list.length - 3}} {{$t("general:others")}}
                        <i class="fa fa-angle-double-down"></i>
                    </a>
                    <b-collapse :id="'collapse-realestate-expd'.concat(_uid)" class="mt-2">
                        <ul class="custom-unstyled-list p-0">
                            <li v-for="(u, u_idx_expd) in real_estate.parcel.units_list.slice(3, real_estate.parcel.units_list.length)" :key="'li-units-expd-' + u_idx_expd">
                                <re-open-modal :re_key="u.property_id" />
                                <add-remove-button
                                    :re_key="u.property_id"
                                    :added="existingReKeys.includes(u.property_id)"
                                    @add-real-estates="addRealEstates(u.property_id)"
                                />
                            </li>
                        </ul>
                    </b-collapse>
                </div>
            </div>

            <div v-else-if="real_estate.unit">
                <div type="button" class="btn btn-info btn-outline btn-xs pull-right"
                    @click.prevent="addRealEstates(real_estate.unit.parcels_list.map(i=>i.property_id))"
                >
                    {{$t("general:add_all")}}
                </div>
                <ul class="custom-unstyled-list p-0">
                    <li v-for="(p, p_idx) in real_estate.unit.parcels_list" :key="'li-parcels-' + p_idx">
                        <re-open-modal :re_key="p.property_id" />
                        <add-remove-button
                            :re_key="p.property_id"
                            :added="existingReKeys.includes(p.property_id)"
                            @add-real-estates="addRealEstates(p.property_id)"
                        />
                    </li>
                </ul>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue"
import AddRemoveButton from "./map/components/AddRemoveButton.vue";
import VSUtils from "./utils.js"

export default {
    props: {
        real_estate: {
            type: Object,
            required: true,
        },
        subject: {
            type: Object,
            required: true,
        },
        session: {
            type: Object,
            required: true,
        },
    },
    components: {
        ReOpenModal,
        AddRemoveButton,
    },
    methods: {
        addRealEstates: function(re_keys) {
            this.$emit("add-real-estates", re_keys);
        },
    },
    computed: {
        existingReKeys: function() {
            return VSUtils.getSessionRealEstates(this.subject.real_estates, this.session).map(i => i.re_key);
        }
    },
}
</script>