<template>
    <div>
        <k-wms-leaflet-map
            :center="center"
            :markers="markers"
            :zoom="14"
            :max-zoom="14"
            :showDefaultOverlayLayers="false"
            :extra-layers="extraLayers"
            :fullscreen="true"
        >
            <template v-slot:extra_controls>
                <l-control
                    position="bottomleft"
                >
                    <img src="https://servisi.voda.hr/poplave_opasnosti/ows?service=WMS&version=1.1.1&request=GetLegendGraphic&format=image%2Fpng&width=50&height=50&layer=hr.fd.vjerojatnost-pojavljivanja" alt="Legend Graphic">
                </l-control>
            </template>
        </k-wms-leaflet-map>
    </div>
</template>

<script>
import { LControl } from 'vue2-leaflet';

import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'

const baseUrl = "https://servisi.voda.hr/poplave_opasnosti/wms?SERVICE=WMS";

export default {
    components: {
        LControl,
        "k-wms-leaflet-map": KWMSLeafletMap,
    },
    props: {
        center: {
            type: Object,
            required: true,
        }
    },
    data: function() {
        return {
            baseUrl: baseUrl,
            extraLayers: [
                // {
                //     "name": "Opasnosti od poplava",
                //     "visible": true,
                //     "format": "image/png",
                //     "layers": "hr.fd.vjerojatnost-pojavljivanja,hr.fd.opasnost-od-poplave-mala-vjerojatnost,hr.fd.opasnost-od-poplave-srednja-vjerojatnost,hr.fd.opasnost-od-poplave-velika-vjerojatnost",
                //     "styles": "",
                //     "transparent": true,
                //     "url": "https://servisi.voda.hr/poplave_opasnosti/wms?SERVICE=WMS",
                //     "type": "overlay",
                // },
                {
                    "name": "Opasnosti od poplava - mala vjerovatnost",
                    "visible": true,
                    "format": "image/png",
                    "layers": "hr.fd.opasnost-od-poplave-mala-vjerojatnost",
                    "styles": "",
                    "transparent": true,
                    "url": baseUrl,
                    "type": "overlay",
                },
                {
                    "name": "Opasnosti od poplava - srednja vjerovatnost",
                    "visible": true,
                    "format": "image/png",
                    "layers": "hr.fd.opasnost-od-poplave-srednja-vjerojatnost",
                    "styles": "",
                    "transparent": true,
                    "url": baseUrl,
                    "type": "overlay",
                },
                {
                    "name": "Opasnosti od poplava - velika vjerovatnost",
                    "visible": true,
                    "format": "image/png",
                    "layers": "hr.fd.opasnost-od-poplave-velika-vjerojatnost",
                    "styles": "",
                    "transparent": true,
                    "url": baseUrl,
                    "type": "overlay",
                },
            ]
        }
    },
    computed: {
        markers() {
            return [
                { coordinates: this.center }
            ]
        }
    },
}
</script>
