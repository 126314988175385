<template>
    <div class="transaction-map-wrapper">
        <l-map
            :zoom="zoom"
            :center="center"
            @update:bounds="$emit('update:bounds', $event)"
            :style="{
              height: height,
              minHeight: '200px'
            }"
            ref="map"
            :options="{
                attributionControl: false
            }"
        >      
            <l-control-attribution
                prefix='<a href="https://www.arvio.si/" target="_blank">Arvio</a>'
                position="bottomright"
            />

            <l-control-layers position="topright"></l-control-layers>
            <l-tile-layer
                v-for="(tilelayer, index) in tileLayers"
                :key="index"
                :name="tilelayer.name"
                :url="tilelayer.url"
                :attribution="tilelayer.attribution"
                :visible="tilelayer.visible"
                layer-type="base"
            />

            <l-control position="bottomright" v-if="loadingMarkers">
                <div>{{ $t('general:loading')}} ...</div>
            </l-control>

            <l-control
                class="details-window-control-wrapper"
                position="bottomleft"
                :disable-scroll-propagation="true"
            >   
                <div style="background: white; max-height: 400px; overflow: auto;">
                    <slot name="bottom-control-content"></slot>
                </div>
            </l-control>

            <marker-cluster
                @clusterclick="$emit('clusterclick', $event)"
                :options="{
                    showCoverageOnHover: false,
                    removeOutsideVisibleBounds: true,
                    maxClusterRadius: maxClusterRadius,
                }"
            >
                <l-marker
                    v-for="(marker, idx) in filteredMarkers"
                    :key="'marker_'+idx"
                    :lat-lng="marker.gps"
                    @click="$emit('marker-clicked', marker)"
                >
                    <slot name="marker" v-bind:marker="marker"></slot>
                </l-marker>
            </marker-cluster>

            <l-marker
                v-if="mainMarker"
                :lat-lng="mainMarker.coordinates"
                :icon="house_icon"
            >
                <l-popup
                    :options="{autoPan: true}"
                >
                    <div class="p-10">{{ $t('comparable:selected:real_estate')}}</div>
                </l-popup>
            </l-marker>

            <slot></slot>
        </l-map>
    </div>
</template>

<script>
import L from 'leaflet';

import { LMap, LTileLayer, LPopup, LMarker, LCircle, LControl, LControlLayers, LControlAttribution, LIcon } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default {
    name: "PropertyIQStadiaMarkerMap",
    components: {
        LMap, LTileLayer, LPopup, LMarker, LControl, LControlLayers, LControlAttribution, LIcon, LCircle,
        "marker-cluster": Vue2LeafletMarkerCluster,
    },
    props: {
        height: {
            type: String,
            default: '800px'
        },
        markers: {
            type: Array,
            default: () => []
        },
        loadingMarkers: {
            type: Boolean,
            default: false
        },
        center: {
            type: [Object, Array],
            default: () => [46.05108, 14.50513]
        },
        zoom: {
            type: Number,
            default: 12
        },
        maxClusterRadius: {
            type: Number,
            default: 60
        },
        mainMarker: {
            type: Object,
        },
    },
    data: function () {
        return {
            tileLayers: [
                {
                    name: this.$t('maps:default'),
                    url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=d381e824-bb72-464b-a043-d6da7b42875d",
                    // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    visible: true
                },
                {
                    name: this.$t('maps:satelite'),
                    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                    visible: false
                }
            ],
            house_icon: L.icon({
                iconUrl: require('@/images/house.png'),
                iconSize: [60, 60],
                iconAnchor: [30, 60],
                popupAnchor:  [0, -60]
            }),
        }
    },
    methods: {
        getBBox: function() {
            return this.mapObject.getBounds();
        },
        setZoom: function(zoom) {
            this.mapObject.setZoom(zoom);
        },
        setCenter: function(latlng) {
            this.mapObject.panTo(latlng);
        },
        fitBounds() {
            let markerCoords = this.markers.map(m => [m.gps.lat, m.gps.lng])
            markerCoords.push([this.mainMarker.coordinates.lat, this.mainMarker.coordinates.lng]);
            this.mapObject.fitBounds(markerCoords)
        },
        invalidateMap() {
            this.mapObject.invalidateSize();
            this.fitBounds();
        }
    },
    computed: {
        filteredMarkers: function() {
            let markers = this.markers.filter(i => i.gps);
            return markers;
        },
        mapObject: function() {
            return this.$refs.map.mapObject; 
        }
    },
    mounted() {
        const app = this;
        this.$nextTick(() => {
            L.DomEvent.addListener(document.body, 'invalidate-leaflet-maps', app.invalidateMap);
        });
    },
    beforeDestroy() {
        L.DomEvent.removeListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
    },
}
</script>

<style>
    @import '~leaflet/dist/leaflet.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.Default.css';


    .leaflet-container{
      font-family: Rubik, sans-serif;
    }
    /* Custom marker icon */
    .propertyiq-iq__marker-icon {
        width: inherit!important;
        white-space: nowrap;
    }

    /* Popup remove paddings and margins */
    .property-iq .leaflet-popup-content-wrapper {
        padding: 0;
        border-radius: 0;
    }
    .property-iq .leaflet-popup-content {
        margin: 0;
        width: inherit!important;
    }

    /* Custom market cluster colors */
    .marker-cluster-small {
        background-color: rgba(155, 155, 155, 0.5);
    }
    .marker-cluster-small div {
        background-color: rgba(155, 155, 155, 0.7);
        color: #FFF;
        font-size: 11px;
        font-weight: normal;
    }

    .marker-cluster-medium {
        background-color: rgba(145, 145, 145, 0.5);
        font-weight: bold;
    }
    .marker-cluster-medium div {
        background-color: rgba(145, 145, 145, 0.7);
        color: #FFF;
    }

    .marker-cluster-large {
        background-color: rgba(155, 155, 155, 0.5);

    }
    .marker-cluster-large div {
        background-color: rgba(155, 155, 155, 0.7);
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
    }

</style>