<template>
    <span class="help-icon" @click="openPopup">
        <i class="fa fa-question-circle"></i>
    </span>
</template>

<script>
import { Swal } from "@/components/utils/swal.alerts.js";

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ""
        },
        text: {
            type: String,
            required: true
        },
    },
    data: function() {
        return {
            updated: null,
        }
    },
    methods: {
        openPopup() {
            Swal({
                title: this.title,
                html: this.text,
                confirmButtonText: this.$t("general:close"),
            });
        }
    },
}
</script>
