<template>
    <div>
        <div v-if="unit && unit.windows_year_remodeled">
            Okna: {{unit.windows_year_remodeled}}
        </div>
        <div v-if="unit && unit.installation_year_remodeled">
            Inštalacije: {{unit.installation_year_remodeled}}
        </div>
        <div v-if="unit && unit.building && unit.building.roof_year_remodeled">
            Streha: {{unit.building.roof_year_remodeled}}
        </div>
        <div v-if="unit && unit.building && unit.building.facade_year_remodeled">
            Fasada: {{unit.building.facade_year_remodeled}}
        </div>
    </div>

</template>

<script>
export default {
    props: {
        unit: Object
    },
}
</script>
