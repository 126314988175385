<template>
    <table class="table table-condensed">
        <thead>
            <tr>
                <!-- <th class="text-center">ID upravnega akta</th> -->
                <th class="text-center">{{ $t('aa:for_property') }}</th>
                <th class="text-center">{{ $t('aa:number')}}</th>
                <th class="text-center">{{ $t('aa:executive_body')}}</th>
                <th class="text-center">{{ $t('aa:procedure')}}</th>
                <th class="text-center">{{ $t('aa:building:description')}}</th>
                <th class="text-center">{{ $t('aa:date:issued')}}</th>
                <th class="text-center">{{ $t('aa:date:legally')}}</th>
                <th class="text-center">{{ $t('aa:parcel')}}</th>
                <th class="text-center" v-if="editable">{{ $t('aa:options')}}</th>
            </tr>
        </thead>
        
        <tbody>
            <tr>
                <th :colspan="editable ? 8 : 7" class="upper border-top">{{ $t('aa:permit:construction') }}</th>
            </tr>
        </tbody>

        <administrative-acts-table-section
            v-for="(act, inx) in acts"
            :re_key="act.property_analysis.real_estate.re_key"
            :acts="retrieveActs(act, 'gradbena_dovoljenja')"
            :editable="editable"
            :key="`building_permits_${inx}`"
            @save="saveActsData(act)"
            @remove="removeAct(act, 'gradbena_dovoljenja', $event)"
        />

        <tbody>
            <tr v-if="editable">
                <td class="text-center" colspan="7">
                    <a type="button" class="btn btn-info btn-xs" @click.prevent="openAddActModal('gradbena_dovoljenja')">
                        {{$t('general:add')}} <i class="fa fa-plus"></i>
                    </a>
                </td>
            </tr>
            <tr>
                <th :colspan="editable ? 8 : 7" class="upper border-top">{{ $t('aa:permit:usage') }}</th>
            </tr>
        </tbody>

        <administrative-acts-table-section
            v-for="(act, inx) in acts"
            :re_key="act.property_analysis.real_estate.re_key"
            :acts="retrieveActs(act, 'uporabna_dovoljenja')"
            :editable="editable"
            :key="`usage_permits_${inx}`"
            @save="saveActsData(act)"
            @remove="removeAct(act, 'uporabna_dovoljenja', $event)"
        />

        <tbody>
            <tr v-if="editable">
                <td class="text-center" colspan="7">
                    <a type="button" class="btn btn-info btn-xs" @click.prevent="openAddActModal('uporabna_dovoljenja')">
                        {{$t('general:add')}} <i class="fa fa-plus"></i>
                    </a>
                </td>
            </tr>
        </tbody>

        <modal 
            :name="`add-act-modal-${_uid}`"
            height="auto"
        >
            <div class="p-20">
                <h2>{{ $t('aa:acts:add')}}</h2>
                <hr>
                <form>
                    <div class="form-group">
                        <label>{{ $t('aa:for_property') }}</label>
                        <select class="form-control" v-model="new_act.property_inx">
                            <option v-for="(act, inx) in acts" :value="inx" :key="`for_property_${inx}`">
                                {{ act.property_analysis.real_estate.re_key }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:number')}}</label>
                        <input type="text" class="form-control" v-model="new_act.STEV_ZAD">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:executive_body')}}</label>
                        <input type="text" class="form-control" v-model="new_act.NAZ_UPR_ORG">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:procedure')}}</label>
                        <input type="text" class="form-control" v-model="new_act.NAZ_UPR_POS">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:building:description')}}</label>
                        <input type="text" class="form-control" v-model="new_act.OBJ">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:date:issued')}}</label>
                        <input type="text" class="form-control" v-model="new_act.DAT_IZD">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('aa:date:legally')}}</label>
                        <input type="text" class="datepicker form-control" v-model="new_act.MAX_DAT_PRA">
                    </div>
                    <div class="pull-right p-20">
                        <button type="submit" class="btn btn-info" v-on:click.prevent="addAct()">{{ $t('general:add')}}</button>
                        <button type="submit" class="btn btn-default btn-outline" v-on:click.prevent="closeAddActModal()">{{ $t('general:cancel:btn')}}</button>
                    </div>
                </form>
            </div>
        </modal>

    </table>
</template>

<script>
import Vue from 'vue'

import ApiService from "@/components/utils/api.service.js"
import AdministrativeActsTableSection from './AdministrativeActsTableSection'

const default_new_act = {
    property_inx: 0,
    STEV_ZAD: null,
    NAZ_UPR_ORG: null,
    NAZ_UPR_POS: null,
    OBJ: null,
    DAT_IZD: null,
    MAX_DAT_PRA: null,
}

export default {
    name: "AdministrativeActsTable",
    props: {
        acts: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        AdministrativeActsTableSection
    },
    data: function () {
        return {
            
            new_act: {...default_new_act},
            new_act_type: null,
        }
    },
    methods: {
        // retrieve from results
        retrieveActs: function(act, key) {
            if(!act || !act.result) {
                return [];
            }
            return act.result[key];
        },
        // add acts
        openAddActModal: function(actType) {
            this.$modal.show('add-act-modal-' + this._uid);
            this.new_act_type = actType;
        },
        closeAddActModal: function() {
            this.$modal.hide('add-act-modal-' + this._uid);
            this.new_act_type = null;
        },
        addAct: function() {
            const inx = this.new_act.property_inx;

            Vue.set(this.acts[inx], "result", this.acts[inx].result || {});
            Vue.set(this.acts[inx].result, this.new_act_type, this.acts[inx].result[this.new_act_type] || []);

            this.acts[inx].result[this.new_act_type].push(this.new_act);

            this.new_act = {...default_new_act};
            this.closeAddActModal();
            this.saveActsData(this.acts[inx]);
        },
        saveActsData: async function(act) {
            ApiService
                .post(`/amas/analysis/analysis/api/acts/${act.property_analysis.id}`, act)
                .catch(error => {
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: 'Opozorilo',
                        text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri shranjevanju upravnih aktov",
                    });
                    throw error;
                })
        },
        removeAct: function(acts, type, idx) {
            if (acts.result === undefined)
                return;
            acts.result[type].splice(idx, 1);
            this.saveActsData(acts);
        },
    },
}
</script>

<style scoped>
.table > tbody + tbody {
    border-top-width: 1px;
}
.upper {
    text-transform: uppercase;
}
</style>
