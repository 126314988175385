<template>
    <table class="table text-center">
        <thead>
            <tr>
                <th class="text-center">
                    <!-- Index -->
                </th>
                <th class="text-center">{{$t('property_iq:location')}}</th>
                <th class="text-center">{{ $t('general:real_estate')}}</th>
                <th class="text-center">{{ $t('general:size')}}</th>
                <th class="text-center">{{ $t('general:year:built')}}</th>
                <th class="text-center">{{ $t('general:date:advert')}}</th>
                <th class="text-center">{{ $t('property_iq:title:advertised_price')}}</th>
                <th class="text-center"></th>
            </tr>
        </thead>

        <tbody v-if="listings">
            <tr v-if="!listings.length" class="text-center">
                <td colspan="6">
                  {{ $t('general:search:no_results')}}
                </td>
            </tr>
            <tr 
                v-for="listing in listings"
                v-bind:key="listing.id"
                v-bind:class="{'warning': color_listings && color_listings.includes(listing.id)}"
            >
                <td class="p-5 p-r-0">
                    <div class="property-iq__index-block"><strong>{{listing.idx}}</strong></div>
                </td>
                <td class="p-5">
                    <a v-if="listing.image" 
                        :href="listing.image"
                        data-toggle="lightbox"
                        :data-gallery="'listing-gallery-'+listing.id"
                        :data-title="'Slike nepremičnine'"
                    >
                        <b-img
                            :src="listing.image"
                            :width="100"
                        />
                    </a>
                    <div v-else>
                        <b-img class="gray-box"
                          :src="require('@/images/home-placeholder.png')"
                          :height="70"
                          :width="100"
                        />
                    </div>
                </td>
                <td>
                    <span class="font-medium">
                      <a class="open-modal" :href="'/amas/analysis/listing/'+listing.id+'/details/'">
                        {{ listing.location }}
                      </a>
                    </span>
                    <div class="text-muted">
                      {{ listing.re_type }} {{ listing.re_class|lower }}
                    </div>
                </td>
                <td>
                    <div class="font-medium" style="white-space: nowrap;">
                        {{ listing.size|formatSize }}
                    </div>
                    <div v-if="listing.land_size" class="text-muted">{{listing.land_size||'/'|formatSize}} {{ $t('general:parcel2')}}</div>

                </td>

                <td>
                    <div>
                        {{ listing.get_year_of_construction||'/' }}
                    </div>
                </td>

                <td>
                    {{ listing.created }}
                    <div class="text-muted small"><i> {{ $t('comparable:advertisement:time')}} {{ listing.age }}</i></div>
                </td>

                <td>
                    <span class="text-info" style="white-space: nowrap;">{{ listing.price|formatCurrency(0) }}</span>
                    <br>
                    <span class="text-primary" style="white-space: nowrap;">{{ listing.get_price_m2|formatCurrencym2(0) }}</span>
                </td>


                <td class="text-center">
                    <a v-on:click.prevent.stop="$emit('comparable-clicked', listing)" href="#"
                        v-tooltip="getCheckboxIconTooltip(listing.id)"
                    >
                        <i class="fa"
                            :class="getCheckboxIconClass(listing.id)">
                        </i>
                    </a>
                </td>
            </tr>
        </tbody>

        <tbody v-else class="text-center">
            <tr>
                <td colspan="6">
                    <p>{{ $t('general:loading')}}</p>
                    <i class="fa fa-spinner fa-spin"></i>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'ComparableAdvertsTable',
    components: {
    },
    props:  {
        listings: {
            type: Array,
        },
        comparableListingItemsIds: {
            type: Array,
            default: () => []
        },
        color_listings: {
            type: Array,
        }
    },
    data: function () {
        return {
        }
    },
    methods: {
        getCheckboxIconClass: function(item_id) {
            if (this.$store.state.failedAdvertItemsIds.includes(item_id)) {
                return "fa fa-exclamation-circle";
            }
            if (this.$store.state.inprogressAdvertItemsIds.includes(item_id)) {
                return "fa fa-spinner fa-spin";
            }
            
            if(this.comparableListingItemsIds.includes(item_id)) {
                return "fa-check-square-o";
            } else {
                return "fa-square-o";
            }
        },
        getCheckboxIconTooltip: function(item_id) {
            if(this.comparableListingItemsIds.includes(item_id)) {
                return "Odstrani iz primerljivih";
            } else {
                return "Dodaj v primerljive";
            }
        },
    },
    computed: {
    },
}
</script>
