<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="comparable_adverts_sale"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/listing/api/${this.session.id}/listings`)
                .then(response => {
                    let listings = response.data;
                    let icon, bg, tooltip;
                    let url = `/amas/analysis/${this.session.id}/listing/sale/list`;
                    if (listings.length > 0) {
                        icon = "fa fa-check";
                        bg = "success";
                        tooltip = this.$t("task_details:status:fetch:successful");
                    } else {
                        icon = "fa fa-close";
                        bg = "light";
                        tooltip = "Niso izbrani";
                    }
                    return `
                    <a href="${url}">
                        <span class="circle circle-sm bg-${bg}" data-toggle="tooltip" data-original-title="${tooltip}">
                            <i class="fa ${icon}"></i>
                        </span>
                    </a>
                    `
                })
        }
    },
}
</script>