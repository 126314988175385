<template>
    <div class="streamline">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import OrderList from './OrderList.vue';

const router = new VueRouter({
    mode: 'history',
    base: '/streamline/buyer/depository',
    routes: [
        { path: '', component: OrderList, name: 'orderList' },
    ]
})

export default {
    router,
    computed: {
        routes() {
            return router.options.routes
        } 
    },
}
</script>

<style scoped>
    @import '../../streamline-styles.scss';
</style>
