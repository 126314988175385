<template>
    <div>
        <div :class="{'input-group': inputFieldsAddon}">
            <input
                :id="id"
                :type="type"
                :value="value"
                :placeholder="placeholder"
                @change="handleFieldInput($event.target.value)"
                class="form-control input-sm"
            />
            <span
                class="input-group-addon"
                v-if="inputFieldsAddon"
            >
                {{ inputFieldsAddon }}
            </span>
        </div>
        <div v-if="validation_error" class="invalid-value">
            {{validation_error}}
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "value",
        event: "change"
    },
    props:  {
        value: {
            type: [Number, String]
        },
        type: {
            type: String,
            default: "text"
        },
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String
        },
        validator: {
            type: Function,
            default: (value) => ({
                value: value,
                is_valid: true
            })
        },
        inputFieldsAddon: {
            type: String
        },
    },
    data: function () {
        return {
            validation_error: null,
        }
    },
    methods: {
        handleFieldInput(value) {
            this.validation_error = null;
            let validated_value = this.validator(value);
            if (validated_value.is_valid) {
                this.$emit('change', validated_value.value)
            } else {
                this.validation_error = this.$t("general:error:value:invalid") + ` '${value}'`;
            }
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        value: function (val) {
            this.validation_error = null;
        },
    }
}
</script>

<style scoped>
.invalid-value {
    color: red;
}
</style>