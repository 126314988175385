<template>
    <div class="panel panel-default">
        <div class="panel-heading p-10" data-perform="panel-collapse" role="button" @click="$emit('click')">
            <i class="text-info" :class="icon"></i>
            {{title}}
        </div>

        <div class="panel-wrapper collapse">

            <div class="panel-body">
                <div v-if="data=='loading'" class="lds-ring"><div></div><div></div><div></div><div></div></div>

                <div v-else-if="data && Object.keys(data).length">
                    <div v-for="(items, subtype) in data" :key="subtype">
                        <h4>{{ subtype }}</h4>
                        <div v-for="item in items" :key="item.name">
                            {{item.name}}
                            <small class="text-muted"> - {{item.distance.toFixed(2).toString().replace(".",",")}} km</small>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <h5>{{ $t("google_places:infrastructure:not_found")}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        icon: String,
        data: [String, Object]
    },
}
</script>
