const wmsUrlPrivate = 'https://storitve.eprostor.gov.si/ows-auth-wms/wms';
const allCommunalEquipmentList = [
    {
        name: 'Ceste',
        layers: 'SI.GURS.KGI:CESTE_L,SI.GURS.KGI:CESTE_P,SI.GURS.KGI:CESTE_T',
    },
    {
        name: 'Električna energija',
        layers: 'SI.GURS.KGI:EL_ENERGIJA_L,SI.GURS.KGI:EL_ENERGIJA_P,SI.GURS.KGI:EL_ENERGIJA_T',
    },
    {
        name: 'Komunikacije',
        layers: 'SI.GURS.KGI:EL_KOMUNIKACIJE_L,SI.GURS.KGI:EL_KOMUNIKACIJE_P,SI.GURS.KGI:EL_KOMUNIKACIJE_T,SI.GURS.KGI:EL_KOMUNIKACIJE_CEV_L,SI.GURS.KGI:EL_KOMUNIKACIJE_KABEL_L',
    },
    {
        name: 'Kanalizacija',
        layers: 'SI.GURS.KGI:KANALIZACIJA_L,SI.GURS.KGI:KANALIZACIJA_P,SI.GURS.KGI:KANALIZACIJA_T',
    },
    {
        name: 'Letališča',
        layers: 'SI.GURS.KGI:LETALISCA_L,SI.GURS.KGI:LETALISCA_P,SI.GURS.KGI:LETALISCA_T',
    },
    {
        name: 'Nafta',
        layers: 'SI.GURS.KGI:NAFTA_L,SI.GURS.KGI:NAFTA_P,SI.GURS.KGI:NAFTA_T',
    },
    {
        name: 'Odpadki',
        layers: 'SI.GURS.KGI:ODPADKI_L,SI.GURS.KGI:ODPADKI_P,SI.GURS.KGI:ODPADKI_T',
    },
    {
        name: 'Optični priključki',
        layers: 'SI.GURS.KGI:OPT_PRIKL_1,SI.GURS.KGI:OPT_PRIKL_2,SI.GURS.KGI:OPT_PRIKL_3,SI.GURS.KGI:OPT_PRIKL_4,SI.GURS.KGI:OPT_PRIKL_5,SI.GURS.KGI:OPT_STAVBE,SI.GURS.KGI:OPT_SUBJEKT_1,SI.GURS.KGI:OPT_SUBJEKT_2,SI.GURS.KGI:OPT_SUBJEKT_3,SI.GURS.KGI:OPT_SUBJEKT_4',
    },
    {
        name: 'Pristanišča',
        layers: 'SI.GURS.KGI:PRISTANISCA_L,SI.GURS.KGI:PRISTANISCA_P,SI.GURS.KGI:PRISTANISCA_T',
    },
    {
        name: 'Toplotna energija',
        layers: 'SI.GURS.KGI:TOPLONA_ENERGIJA_L,SI.GURS.KGI:TOPLONA_ENERGIJA_P,SI.GURS.KGI:TOPLONA_ENERGIJA_T',
    },
    {
        name: 'Vodna infrastruktura',
        layers: 'SI.GURS.KGI:VODNA_INFRASTRUKTURA_L,SI.GURS.KGI:VODNA_INFRASTRUKTURA_P,SI.GURS.KGI:VODNA_INFRASTRUKTURA_T',
    },
    {
        name: 'Vodovod',
        layers: 'SI.GURS.KGI:VODOVOD_L,SI.GURS.KGI:VODOVOD_P,SI.GURS.KGI:VODOVOD_T',
    },
    {
        name: 'Zemeljski plin',
        layers: 'SI.GURS.KGI:ZEMELJSKI_PLIN_L,SI.GURS.KGI:ZEMELJSKI_PLIN_P,SI.GURS.KGI:ZEMELJSKI_PLIN_T',
    },
    {
        name: 'Žičnice',
        layers: 'SI.GURS.KGI:ZICNICE_L,SI.GURS.KGI:ZICNICE_P,SI.GURS.KGI:ZICNICE_T',
    }
]

export { allCommunalEquipmentList, wmsUrlPrivate };