<template>
    <div class="analytics-market-analysis panel panel-default">
        <loading 
            :active="showLoading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        <div class="white-box m-b-20">
            <span class="box-title">
                {{ $t("dashboard:market_analysis") }}, {{ $t("dashboard:apartments") }}, {{selectedRegion}}
                <help-icon :slug="infoSlug" />
            </span>
        </div>
        <div class="row" v-if="isOpenAIEnabled()">
            <div class="col-md-6">
                <div class="white-box">
                    <div class="box-title text-description">{{ $t("dashboard:market_analysis") }}</div>
                    <loading 
                        class="white-box"
                        :active="marketComment.length === 0"
                        :can-cancel="false" 
                        :is-full-page="false"
                    />
                    {{ marketComment }}
                </div>
            </div>
            <div class="col-md-6">
                <div class="white-box">
                    <div class="box-title text-description">{{ $t("dashboard:about_region") }}</div>
                    <loading 
                        class="white-box"
                        :active="cityDescription.length === 0"
                        :can-cancel="false" 
                        :is-full-page="false"
                    />
                    {{ cityDescription }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <dashboard-graph
                    v-if="checkShowGraph('sold_volume')"
                    :title="$t('dashboard:sales')"
                    :description="$t('dashboard:sales:description')"
                    :graphs="buildGraphData([], ['sold_volume',])"
                    :apiData="this.graphData['sold_volume']"
                    :infoSlug="infoSlug"
                />
            </div>
            <div class="col-md-4">
                <dashboard-graph
                    v-if="checkShowGraph('med_selling_price_m2')"
                    :title="$t('dashboard:prices')"
                    :description="$t('dashboard:prices_median:description')"
                    :graphs="buildGraphData([], ['med_selling_price_m2',])"
                    :apiData="this.graphData['med_selling_price_m2']"
                    :displayUnit="'€/m²'"
                    :infoSlug="infoSlug"
                />
            </div>
            <div class="col-md-4">
                <dashboard-graph
                    v-if="checkShowGraph('sum_selling_price')"
                    :title="$t('dashboard:sum_selling_price')"
                    :description="$t('dashboard:sum_selling_price:description')"
                    :graphs="buildGraphData([], ['sum_selling_price',])"
                    :apiData="this.graphData['sum_selling_price']"
                    :displayUnit="'mio\xa0€'"
                    :infoSlug="infoSlug"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dashboard-graph
                    v-if="checkShowGraph('arvio_ppi')"
                    :title="`${ $t('dashboard:price_index') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                    :graphs="buildGraphData(['line',], ['arvio_ppi',], true)"
                    :apiData="this.graphData['arvio_ppi']"
                    :showGrowthOverUnits="true"
                    displayUnit="%"
                    :infoSlug="infoSlug"
                />
                <dashboard-graph
                    v-if="checkShowGraph('sold_volume')"
                    :title="`${ $t('dashboard:sold_volume') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                    :graphs="buildGraphData(['area',], ['sold_volume',])"
                    :apiData="this.graphData['sold_volume']"
                    :showGrowthOverUnits="true"
                    :infoSlug="infoSlug"
                />
            </div>
            <!-- <div class="col-md-6">
                <dashboard-graph
                    v-if="checkShowGraph('med_selling_price_m2_advertisement')"
                    :title="`${ $t('dashboard:avg_selling_price') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                    :graphs="buildGraphData(['line',], ['med_selling_price_m2_advertisement',], true)"
                    :apiData="this.graphData['med_selling_price_m2_advertisement']"
                    :showGrowthOverUnits="true"
                    :displayUnit="'€/m²'"
                    :infoSlug="infoSlug"
                />
                <dashboard-graph
                    v-if="checkShowGraph('advertisement_count')"
                    :title="`${ $t('dashboard:advertisement_count') }, ${ $t('dashboard:apartments') }, ${selectedRegion}`"
                    :graphs="buildGraphData(['area',], ['advertisement_count',])"
                    :apiData="this.graphData['advertisement_count']"
                    :showGrowthOverUnits="true"
                    :infoSlug="infoSlug"
                />
            </div> -->
        </div>
    </div>
</template>
<script>
import ApiService from '@/components/utils/api.service';
import HelpIcon from "@/components/widgets/HelpIcon";
import DashboardGraph from '@/../apps/analytics/vue/dashboard/DashboardGraph.vue';

import DashboardMixin from '@/../apps/analytics/vue/dashboard/DashboardMixin';

export default {
    components: {
        DashboardGraph,
        HelpIcon,
    },
    mixins: [ DashboardMixin ],
    props: {
        region: {
            type: Object,
            require: true,
        },
        infoSlug: {
            type: String,
            require: false,
            default: "analytics-vir-in-metodologija-amas"
        }
    },
    data() {
        return {
            showLoading: true,
            marketComment: "",
            cityDescription: "",
        }
    },
    mounted() {
        this.getCurrentUser();
        this.getAPIData();
    },
    methods: {
        getAPIData() {
            this.showLoading = true;
            ApiService
            .get("/market/analytics/api/graphs", {
                params: {
                    region: this.region.id,
                    sold_volume: 1,
                    med_selling_price_m2: 1,
                    arvio_ppi: 1,
                    sum_selling_price: 1,
                    advertisement_count: 1,
                    med_selling_price_m2_advertisement: 1,
                }
            })
            .then(response => {
                this.graphData = {...this.graphData, ...response.data};
                this.showLoading = false;
            });
        },
        getAPIMarketComment() {
            this.marketComment = "";
            ApiService
            .get("/market/analytics/api/openai-market-comment", {
                params: {
                    region: (this.region !== undefined) ? this.region.id : 87,
                }
            })
            .then(response => {
                this.marketComment = response.data.trim();
            })
        },
        getAPICityDescription() {
            this.cityDescription = "";
            ApiService
            .get("/market/analytics/api/openai-city-description", {
                params: {
                    city: this.region.name
                }
            })
            .then(response => {
                this.cityDescription = response.data.trim();
            })
        },
    },
    watch: {
        currentUser() {
            if (this.isOpenAIEnabled()) {
                this.getAPIMarketComment();
                this.getAPICityDescription();
            }
        }
    }
}
</script>
<style>
.analytics-market-analysis .white-box {
    position: relative;
    border-radius: 2rem;
}
.analytics-market-analysis .panel, .panel-default {
    background-color: transparent !important;
}
.analytics-market-analysis .panel, .text-description {
    white-space: pre-wrap;
}
</style>