<template>
    <tr class="vld-parent">
        <td>
            <div>
                <strong>{{getSummary}}</strong>
                <a
                    href="#"
                    @click.prevent="openInputVectorModal"
                    v-if="energy_report && !isCert"
                    class="m-l-5"
                >
                    <i v-tooltip="$t('ee:tooltip:edit_data')" class="fa fa-pencil"></i>
                </a>
            </div>
            <div><re-open-modal :re_key="property_analysis.real_estate.re_key" /></div>
            
            <loading 
                :active="loading" 
                :can-cancel="false" 
                :is-full-page="false"
            />
        </td>

        <template v-if="energy_report">
            <td>
                <div v-if="isCert">
                    {{ energy_report.data.meta.report_id }}

                    <br />
                    <span v-if="energy_report && energy_report.energy_file"
                        class="btn btn-xs btn-outline btn-rounded btn-info view-pdf"
                        :href="energy_report.energy_file"
                        :data-title="energy_report.data.meta.report_id + '.pdf'"
                    >
                      {{ $t('legal:look_pdf') }}
                    </span>
                </div>
                <div v-else>
                  {{ $t('ee:not_issued')}}
                </div>
                <!-- <span 
                    class="btn btn-xs btn-rounded btn-default view-pdf" 
                    :href="energy_report.energy_file"
                >
                    Poglej PDF
                </span> -->
            </td>
            <td>
                {{getTypeDisplay}}
                <i v-if="!isCert && energy_report.input_vector_edited" class="fa fa-exclamation-circle text-warning m-l-2"
                    v-tooltip="$t('ee:input:changed')" rel="tooltip"
                ></i>
            </td>
            <td style="text-align: center">
                <span class="circle circle-sm" :style="[{display: 'inline-block'}, getEnergyClassLabelStyle]">
                    <strong>{{energy_report.data.energy_class}}</strong>
                </span>
                
            </td>
            <td>
                <div>{{ $t('ee:heating_energy') }}: {{ formatValue(energy_report.data.heating_energy, 'kWh/m2a') }}</div>
                <div>{{ $t('ee:primary_energy') }}: {{ formatValue(energy_report.data.primary_energy, 'kWh/m2a') }}</div>
                <div>{{ $t('ee:co2_emissions') }}: {{ formatValue(energy_report.data.co2_emissions, 'kg/m2a') }}</div>
            </td>
            <td>
                <div v-if="isCert">
                    <div>{{ $t('ee:issued')}}: {{energy_report.data.meta.date_created}}</div>
                    <div>{{ $t('ee:valid:until')}}: {{energy_report.data.meta.date_valid}}</div>
                </div>
                <div v-else>
                    <div>{{ $t('ee:calculation')}}: {{energy_report.created}}</div>
                </div>
            </td>
            
        </template>

        <template v-else>
            <td colspan="5" class="text-center">
              {{ $t('ee:no_data')}}
            </td>
        </template>

        <td>
            <a
                href="#"
                @click.prevent="recreateEnergyReport"
            >
                <i v-tooltip="$t('general:re_collect')" class="fa fa-refresh"></i>
            </a>
        </td>
        
        <bootstrap3-modal
            :title="$t('ee:input_data') + ' - ' + property_analysis.real_estate.re_key"
            v-if="energy_report"
            :name="modalName"
            width="50%"
        >
            <input-vector-form
                :initial_input_vector="energy_report.input_vector"
                @save="hideInputVectorModal(); updateEnergyReport($event);"
            />
        </bootstrap3-modal>
    </tr>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import ReOpenModal from "@/components/widgets/ReOpenModal.vue"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import InputVectorForm from "./InputVectorForm.vue";

import Loading from "vue-loading-overlay"

export default {
    components: {
        "bootstrap3-modal": Bootstrap3Modal,
        ReOpenModal,
        Loading,
        InputVectorForm
    },
    model: {
        prop: 'energy_report',
        event: 'change'
    },
    props: {
        energy_report: {
            type: Object,
            // required: true
        },
        property_analysis: {
            type: Object,
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        formatValue(value, unit) {
            if (!value) return "";
            // Convert value to string in case it's a number
            const valueStr = value.toString();
            // Check if the unit is already present
            if (valueStr.includes(unit)) {
                return valueStr;
            } else {
                return `${valueStr} ${unit}`;
            }
        },

        recreateEnergyReport: function() {
            this.loading = true;
            ApiService
                .get(`/amas/analysis/api/property-analysis/${this.property_analysis.id}/energy-report?recreate=1`)
                .then(response => {
                    this.$emit("change", response.data.energy_report);
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loading = false;
                });
        },
        updateEnergyReport: function(input_vector) {
            this.loading = true;
            ApiService
                .put(`/amas/analysis/api/energy-report/${this.energy_report.id}`, {
                    "input_vector": input_vector
                })
                .then(response => {
                    this.$emit("change", response.data);
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loading = false;
                });
        },
        openInputVectorModal: function() {
            this.$modal.show(this.modalName);
        },
        hideInputVectorModal: function() {
            this.$modal.hide(this.modalName);
        },
        // formatMetaDate: function(date) {
        //     let dateObject = new Date(date);
        //     return dateObject.toLocaleDateString().replaceAll("/", ".");
        // },
    },
    computed: {
        isCert: function() { return this.energy_report.estimation_type=='CERT' },
        modalName: function() { return "energy-certificate-inputvector-modal" + this.energy_report.id },
        getSummary: function() {
            return this.property_analysis.real_estate.re_type;
        },
        getTypeDisplay: function() {
            if(this.isCert) {
                let res = this.energy_report.data.meta.certificate_type == "računska" ? "Računska" : "Merjena";
                if (this.energy_report.data.meta.building_type){
                  res += ", " + this.energy_report.data.meta.building_type
                }
                return res;
            } else {
                return this.energy_report.get_estimation_type_display
            }
        },
        getEnergyClassLabelStyle: function() {
            let energy_class = this.energy_report.data.energy_class;
            if (["A", "A1", "A2"].includes(energy_class)) {
                return {"background-color": "#12803f"}
            }
            if (["B", "B1", "B2"].includes(energy_class)) {
                return {"background-color": "#4ea747"}
            }
            if (energy_class=="C") {
                return {"background-color": "#99c93a"}
            }
            if (energy_class=="D") {
                return {"background-color": "#f7eb17"}
            }
            if (energy_class=="E") {
                return {"background-color": "#febc10"}
            }
            if (energy_class=="F") {
                return {"background-color": "#f47021"}
            }
            if (energy_class=="G") {
                return {"background-color": "#ec2123"}
            }
            return {"background-color": "lightgrey"};
        },
    },
}
</script>
