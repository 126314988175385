<template>
    <div class="vsubject--map--container">
        <k-wms-leaflet-map
            :fullscreen="true"
            :zoom="19"
            :max-zoom="20"
            :center="center"
            :polygons="polygons"
            :extraLayers="extraMapLayers"
            :legend="mapLegend"
            @click="handleMapClick"
            @polygon-mouseover="handlePolygonOrMarkerMouseOver"
            @polygon-mouseout="handlePolygonOrMarkerMouseOut"
        >
            <template v-slot:markers>
                <l-marker
                    v-if="clicked_marker"
                    :lat-lng="clicked_marker.gps_point.coordinates"
                    :icon="blankMarkerIcon"
                    ref="clickmarker"
                >
                    <l-popup>
                        <popup-details-parcel
                            :parcel="clicked_marker.parcel"
                            :reKeysInSubject="reKeysInSubject"
                            @add-real-estates="$emit('add-real-estates', $event)"
                        />
                    </l-popup>
                </l-marker>

                <l-marker
                    v-for="(marker, m_idx) in subjectMarkers"
                    :lat-lng="marker.gps_point_swapped.coordinates"
                    :key="'marker'+m_idx"
                    :icon="getSubjectMarkerIcon(marker)"
                    @mouseover="handlePolygonOrMarkerMouseOver(marker.property_id)"
                    @mouseout="handlePolygonOrMarkerMouseOut(marker.property_id)"
                >
                    <l-popup>
                        <popup-details-building
                            :building="marker"
                            :reKeysInSubject="reKeysInSubject"
                            @add-real-estates="$emit('add-real-estates', $event)"
                        />
                    </l-popup>
                </l-marker>
            </template>

            <template v-slot:popup_content_polygon="slotProps">
                <popup-details-parcel
                    :parcel="slotProps.polygon.parcel"
                    :reKeysInSubject="reKeysInSubject"
                    @add-real-estates="$emit('add-real-estates', $event)"
                />
            </template>
        </k-wms-leaflet-map>
    </div>
</template>

<script>
import L from 'leaflet';
import { icon } from "leaflet";

import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'
import { LMarker, LPopup, LPolygon} from 'vue2-leaflet';

import ApiCalls from "@/components/utils/api.calls.js";
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";

import PopupDetailsParcel from "./PopupDetailsParcel.vue";
import PopupDetailsBuilding from "./PopupDetailsBuilding.vue";

export default {
    props: {
        real_estates: {
            type: Array,
            required: true
        },
        highlighted_re_key: {
            type: String,
            required: false
        }
    },
    components: {
        "k-wms-leaflet-map": KWMSLeafletMap,
        ReOpenModal, PopupDetailsParcel, PopupDetailsBuilding,
        LMarker, LPopup, LPolygon
    },
    data: function () {
        return {
            gursSessionId: null,
            clicked_marker: null,

            subject_polygons_style: {
                color: "#00753b",
                weight: 3,
                fillOpacity: 0.7
            },
            highlighted_polygons_style: {
                color: "#fffdaa",
                weight: 3,
                fillOpacity: 0.5
            },
            clicked_parcel_polygon_style: {
                color: "#fffdaa",
                weight: 3,
                fillOpacity: 0.5
            }
        }
    },
    methods: {
        handlePolygonOrMarkerMouseOver: function(re_key) {
            this.$emit("highlight-real-estate", re_key)
        },
        handlePolygonOrMarkerMouseOut: function(_re_key) {
            this.$emit("highlight-real-estate", null)
        },
        handleMapClick: async function(e) {
            this.clicked_marker = {
                gps_point: {
                    "type": "Point", "coordinates": [e.latlng.lat, e.latlng.lng]
                },
                parcel: null
            };
            this.$nextTick(() => {this.$refs.clickmarker.mapObject.openPopup();});

            ApiCalls
                .getParcelsByGps(e.latlng.lat, e.latlng.lng)
                .then(res => this.clicked_marker.parcel = res[0]);
        },
        getSubjectMarkerIcon: function(marker) {
            let color = marker.property_id===this._get_highlighted_building_re_key ? "yellow" : "blue";
            return new L.Icon({
                iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
        },

        getGursSessionId: function() {
            ApiCalls.getGursSessionId()
                .then(res => this.gursSessionId=res)
        },
    },
    computed: {
        reKeysInSubject: function() {
            return this.real_estates.map(i => i.re_key);
        },
        subjectMarkers: function() {
            return this.real_estates
                .filter(i => i.real_estate && !i.real_estate.is_parcel && i.geom)
                .map(i => i.geom);
        },
        subjectPolygons: function() {
            return this.real_estates
                .filter(i => i.real_estate && i.real_estate.is_parcel && i.geom)
                .map(i => {
                    return {
                        ...i.geom.geom_swapped,
                        ...{
                            style: i.re_key===this.highlighted_re_key ? this.highlighted_polygons_style : this.subject_polygons_style,
                            parcel: i.real_estate.parcel,
                            property_id: i.re_key
                        }
                    }
                });
        },
        clickedParcelPolygon: function() {
            if(this.clicked_marker && this.clicked_marker.parcel) {
                let parcel = this.clicked_marker.parcel;
                return {
                    ...parcel.geom_swapped,
                    ...{
                        style: this.clicked_parcel_polygon_style,
                        parcel: parcel
                    }
                }
            }
            return null;
        },
        polygons: function() {
            let polygons = this.subjectPolygons;
            if(this.clickedParcelPolygon) { polygons = polygons.concat([this.clickedParcelPolygon]) }
            return polygons;
        },
        center: function() {
            let center;
            let gps_points = this.real_estates
                .filter(i => i.real_estate && i.real_estate.gps_point)
                .sort((x, y) => {
                    // Sort by 'main_property' and 'session_id' attributes
                    if(x.session_id == y.session_id){
                        // main_property==true values first
                        return y.main_property - x.main_property;
                    } else {
                        return x.session_id - y.session_id;
                    }
                })
                .map(i => i.real_estate.gps_point)
            if(gps_points.length){
                center = {
                    lat: gps_points[0].coordinates[1],
                    lng: gps_points[0].coordinates[0],
                }
            }
            return center;
        },

        blankMarkerIcon: function() {
            // This is a blank marker icon!
            // It's here so that the parcel details work as intented, don't remove.
            var svg = ""
            var iconUrl = 'data:image/svg+xml;base64,' + btoa(svg);
            return icon({
                iconUrl: iconUrl,
            })
        },

        extraMapLayers: function() {
            return [
                // NOTE: This layer no longer seems to be available on the GURS WMS.
                // If they decide to re-add it, we can use it again.
                // {
                //     name: this.$t("leaflet_map:land_belonging_to_buildings"),
                //     visible: false,
                //     format: 'image/png',
                //     layers: 'table:ESZ_PZ',
                //     styles: 'esz_pz_raba',
                //     transparent: true,
                //     url: "https://prostor4.gov.si/ows2-m/wms?sessionid=" + this.gursSessionId,
                //     type: 'overlay',
                //     hide: this.gursSessionId ? false : true
                // }
            ]
        },

        mapLegend: function() {
            const app = this;
            let legend = L.control({position: "bottomleft"});
            legend.onAdd = function () {
                // Legend itself
                let communalEquipmentLegendDiv = L.DomUtil.create('div', 'm-t-10');
                communalEquipmentLegendDiv.innerHTML += `
                    <div class="valuation-subject-legend-div">
                    <div><i class="legend-marker-icon fa fa-2x fa-map-marker text-center"></i>${app.$t("task_details:manage:part_of_building_in_valuation_subject")}</div>
                        <div><i style="background:${app.subject_polygons_style.color}"></i>${app.$t("task_details:manage:parcel_in_valuation_subject")}</div>
                        <div><i style="background:${app.clicked_parcel_polygon_style.color}"></i>${app.$t("task_details:manage:selected_parcel")}</div>
                    </div>
                `
                return communalEquipmentLegendDiv;
            }
            return legend;
        },
        _get_highlighted_building_re_key: function() {
            if(this.highlighted_re_key) {
                return this.highlighted_re_key.split("-")[0] + "-" + this.highlighted_re_key.split("-")[1]
            } else {
                return null;
            }
        }
    },
    mounted() {
        this.getGursSessionId();
    },
}
</script>

<style>
.valuation-subject-legend-div {
    padding: 6px 8px;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    line-height: 25px;
    color: #555;
    text-align: left;
    white-space: nowrap;
}
.valuation-subject-legend-div i {
    width: 18px;
    height: 25px;
    float: left;
    margin-right: 8px;
}

.vsubject--map--container .leaflet-popup-content {
    width: 300px;
    max-height: 200px;
}
</style>