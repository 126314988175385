<template>
    <div class="equal">
        <div 
            v-for="option in options"
            :key="option.value"
            class="col-lg-4 col-2 text-center icon-selector"
            :class="[{ 'selected': isSelected(option.value) }, sizeClass]"
            @click="handleSelect(option.value)"
        > 
            <i v-if="option.icon" :class="option.icon"></i>
            <p>{{option.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Array, Number, String],
            required: true,
        },
        options: {
            type: Array,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        sizeClass: {
            type: String,
            default: 'lg'
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    data: function () {
        return {

        }
    },
    methods: {
        isSelected: function(val) {
            if(this.multiple) {
                return this.value.includes(val);
            } else {
                return this.value == val;
            }
        },
        handleSelect: function(val) {
            let emitVal;
            if(this.multiple) {
                emitVal = this.value;
                if(emitVal.includes(val)) {
                    // Remove
                    emitVal = emitVal.filter(v => v!=val);
                } else {
                    // Add
                    emitVal.push(val);
                }
            } else {
                emitVal = val;
            }
            this.$emit("change", emitVal);
        }
    },
}
</script>

<style scoped>
.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
}
.selected {
    background-color: #EFEFEF;
    border: 1px solid #999999 !important;
}
.icon-selector {
    cursor: pointer;
    border: 1px solid rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.icon-selector.sm {
    padding: 5px;
    min-height: 20px;
}
.icon-selector.md {
    padding: 5px;
    min-height: 60px;
}
.icon-selector.lg {
    padding: 10px;
    min-height: 93px;
}
.icon-selector i.fa{
    font-size: 23px;
}
.icon-selector p{
    font-size: 13px;
    margin: 0;
}
.icon-selector p{
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}
.icon-selector:hover, .export-option:hover{
    border-color: rgb(100, 100, 100);
}
</style>