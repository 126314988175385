<template>
    <bootstrap3-modal
        :title="'Deli povezavo'"
        :name="name"
        width="50%"
        :classes="['share-link-modal']"
    >
        <slot name="description"></slot>
        
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="input-group">
                    <input type="text" class="form-control" :value="url" readonly>
                    <a href="#" class="btn btn-info input-group-addon text-white" @click.prevent="copyLink" v-html="getCopyText()"></a>
                </div>
            </div>
        </div>
        
    </bootstrap3-modal>
</template>

<script>
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";

export default {
    components: {
        "bootstrap3-modal": Bootstrap3Modal,
    },
    props: {
        url: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            copied: false
        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.url).then(() => {
                this.copied = true;
            })
        },
        getCopyText() {
            if (this.copied) {
                return "Kopirano!";
            } else {
                return "Kopiraj";
            }
        }
    },
}
</script>