<template>
    <div class='unit-size-estimate-indicator-wrapper'>
        <unit-size-estimate-indicator
            v-if="unit_data"
            :unit="unit_data"
        >
        </unit-size-estimate-indicator>
    </div>
    
</template>


<script>
import ApiCalls from "@/components/utils/api.calls.js";
import UnitSizeEstimateIndicator from "./UnitSizeEstimateIndicator.vue"
export default {
    name: 'unit-size-estimate-wrapper',
    components:{
        UnitSizeEstimateIndicator
    },
    props:  {
        re_key: {
            type: String,
        },
    },
    data: function () {
        return {
            unit_data: {}
      }
    },
    methods: {
        getUnitData: function() {
            return ApiCalls.getRealEstateData(this.re_key)
                .then(real_estate => {
                    this.unit_data =  real_estate.unit;
                })
                .catch(error => {
                    throw error;
                })
        },
    
    },
    mounted() {
        this.getUnitData();
    }
}
</script>