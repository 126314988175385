<template>
  <Bootstrap3Modal :name="'adjustment-details-'+ item.id"
      width="80%"
      :title="$t('piq:adjustments:title')"
  >
    <div style="padding: 10px; padding-left: 25px; padding-right: 25px">
      <table class="table text-center">
        <thead>
        <tr>
          <th class="text-center">{{ $t('general:type') }}</th>
          <th class="text-center">{{ $t('piq:adjustments:main') }}</th>
          <th class="text-center">{{ $t('piq:adjustments:comparable') }}</th>
          <th class="text-center">%</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="adjustment in item.adjustments" :key="adjustment.id">
          <td class="font-medium">
            {{ adjustment.factor_type.name }}
            <help-icon class="pull-right" v-if="adjustment.factor_type.name_slug" :slug="adjustment.factor_type.name_slug" />
          </td>
          <td class="font-medium">{{ adjustment.factor_type.extract_original_value }}</td>
          <td>{{ adjustment.value }}</td>
          <td>{{ adjustment.adjusted_value|formatCurrency(0) }} ({{ adjustment.adjusted_perc|formatPercent(0) }})</td>
        </tr>
        </tbody>
      </table>
    </div>
  </Bootstrap3Modal>
</template>

<script>
import Loading from "vue-loading-overlay";
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import HelpIcon from "@/components/widgets/HelpIcon.vue";

export default {
  name: "AdjustmentsModal",
  components: {
    HelpIcon,
    Bootstrap3Modal
  },
  props: {
    item: {
      type: Object,
    },
  }
}
</script>