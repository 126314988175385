<template>
    <div>
        <div v-for="(order, index) in orderitems" :key="index">
            <h3>Cenitev {{ index + 1 }}
                <template v-if="!isReadOnly">-
                    <a href="javascript:void(0)" @click="removeAppraisal(index)">
                        Odstrani
                    </a>
                </template>
            </h3>

            <OrderFormRealEstateTable
                v-if="order.orderitemproperty_set"
                :properties="order.orderitemproperty_set"
                :isReadOnly="isReadOnly"
            />
        </div>

        <button
            v-if="!isReadOnly"
            @click="addAppraisal"
            class="btn btn-info full-width m-t-5"
            :disabled="isReadOnly"
        >
            + Dodaj cenitev
        </button>
    </div>
</template>

<script>
import OrderFormRealEstateTable from "./OrderFormRealEstateTable.vue";

export default {
    name: "OrderFormAppraisalTable",
    components: {
        OrderFormRealEstateTable,
    },
    props: {
        orderitems: {
            type: Array,
            required: true,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        removeAppraisal(index) {
            if (this.isReadOnly)
                return;
            this.orderitems.splice(index, 1);
        },
        addAppraisal() {
            if (this.isReadOnly)
                return;
            this.orderitems.push({
                orderitemproperty_set: [{
                    "re_key": "",
                    "real_estate_type": 1,
                    "address": "",
                    "country": "",
                }],
            });
        },
    }
}
</script>
