<template>
    <div class="property-iq vld-parent">
        <loading 
            :active="!session_data"
            :can-cancel="false" 
            :is-full-page="true"
        />
        <loading 
            :active="reportDownloading"
            :can-cancel="false" 
            :is-full-page="true"
            class="report-downloading-overlay"
        >
            <svg style="width:800px; height:100%;">
                <image style="width:800px" xlink:href="@/images/report-builder.gif" />
            </svg>
        </loading>

        <div class="row" v-if="session_data">
            <!-- LEFT COLUMN -->
            <div class="col-md-4 col-xs-12">
                <div class="white-box">

                    <div class="pull-right m-t-5">
                           <button
                                class="btn btn-info"
                                @click="handleDownloadReportRequest"
                            >
                                {{$t("general:download_report:btn")}}
                            </button>

                            <bootstrap3-modal
                                :title=" $t('property_iq:title:choose_report')"
                                name="report-download-picker-modal"
                                width="80%"
                            >
                                <report-download-picker
                                    :choices="avaliableReports"
                                    @submit="downloadReport"
                                />
                            </bootstrap3-modal>

                            <form method="GET" ref="downloadReportForm"/>
                    </div>
                    
                    <!-- Tabs navigation -->
                    <ul class="nav nav-tabs tabs customtab">
                        <li class="tab" :class="{'active': !session_data.main_image}">
                            <a href="#default-map" data-toggle="tab">{{ $t("property_iq:location")}}</a>
                        </li>
                        <li class="tab" :class="{'active': session_data.main_image}">
                            <a href="#custom-image" data-toggle="tab">{{ $t("general:image")}}</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        
                        <div class="tab-pane" :class="{'active': !session_data.main_image}" id="default-map">
                            <k-google-map
                                :height="300"
                                :zoom="18"
                                :center="session_data.main_property.real_estate.gps"
                                :markers="[{coordinates: session_data.main_property.real_estate.gps}]"
                                map-type-id="satellite"
                                :options="{
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUi: false,
                                    controlSize: 25
                                }"
                            />
                        </div>

                        <div class="tab-pane text-center vld-parent" :class="{'active': session_data.main_image}" id="custom-image">
                            
                            <loading 
                                :active="uploadingSessionImage"
                                :can-cancel="false" 
                                :is-full-page="false"
                            />
                            
                            <a v-if="session_data.main_image" :href="session_data.main_image.image" data-toggle="lightbox" data-gallery="session-images-gallery">
                                <b-img
                                    :src="session_data.main_image.image"
                                    :fluid-grow="true"
                                    center
                                />
                            </a>
                            <div class="well file-upload">
                                <file-upload 
                                    :buttonText=" $t('general:upload:image')"
                                    @file-selected="handleSessionImageUpload"
                                >
                                    <button class="btn btn-sm btn-default">{{ $t('general:upload:image')}}</button>
                                </file-upload>
                            </div>
                        </div>

                    </div>

                    <!-- Povzetek -->
                    <hr>
                    <div class="row text-center m-t-10">
                        <div class="col-md-6 b-r"><strong>{{ $t("property_iq:address")}}</strong>
                            <p>{{session_data.main_property.real_estate.address||'/'}}</p>
                        </div>
                        <div class="col-md-6"><strong>{{ $t('property_iq:real_estate:re_key')}}</strong>
                            <p>{{session_data.main_property.real_estate.re_key}}</p>
                        </div>
                    </div>
                    <hr>

                    <!-- Podatki o nepremičnini -->
                    <div class="row text-center">
                        <div class="col-md-12">
                            <div class="real-estate-details-title">
                                <a
                                    class="btn btn-info btn-outline open-modal btn-xs pull-right"
                                    :href="`/amas/analysis/${this.session_data.id}/document/inspection-form/edit`"
                                    v-if="enableInspectionForm"
                                >
                                    {{ $t("general:edit") }}
                                    <i class="fa fa-edit"></i>
                                </a>
                                <strong>{{ $t("property_iq:real_estate:details")}}</strong>
                            </div>
                            <real-estate-details-summary
                                :real_estate="session_data.main_property.real_estate"
                                :analysis_session="session_data"
                            />
                        </div>
                    </div>
                    <hr>

                    <!-- Bar widgets -->
                    <div class="row text-center">
                        <div class="col-md-12 vld-parent">
                            <loading
                                :active.sync="loadingSessionData"
                                :can-cancel="false"
                                :is-full-page="false"
                                class="z-index-1"
                            />
                            <bar-widget
                                :title=" $t('property_iq:title:avg_sale_price')"
                                :value="session_data.appraised_value|formatCurrency(0)"
                                :secondaryValue="session_data.appraised_value_m2|formatCurrencym2(0)"
                                :barValues="getComparableTransactionPrices"
                            />
                        </div>
                        <div class="col-md-12 vld-parent">
                            <loading 
                                :active.sync="loadingSessionData"
                                :can-cancel="false" 
                                :is-full-page="false"
                                class="z-index-1"
                            />
                            <bar-widget
                                :title=" $t('property_iq:title:avg_advertised_price')"
                                :value="session_data.adverts_value|formatCurrency(0)"
                                :barValues="getComparableListingsPrices"
                            />
                        </div>
                    </div>

                </div>
            </div>

            <!-- RIGHT COLUMN -->
            <div class="col-md-8 col-xs-12">
                <div class="white-box">
                    <!-- Tabs navigation -->
                    <ul class="nav nav-tabs tabs customtab">
                        <li class="active tab">
                            <a href="#transactions" data-toggle="tab"> <span class="visible-xs"><i class="fa fa-exchange"></i></span> <span class="hidden-xs">{{ $t('property_iq:tabs:similar_sales')}}</span> </a>
                        </li>
                        <li class="tab">
                            <a href="#listings" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-list-alt"></i></span> <span class="hidden-xs"> {{ $t('property_iq:tabs:similar_adverts')}}</span> </a>
                        </li>
                        <li class="tab" v-if="enableRegionAnalyticsDashboardTab">
                            <a href="#analyticsdashboard" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-line-chart"></i></span> <span class="hidden-xs">{{ $t('general:amas_region_analytics_dashboard') }}</span> </a>
                        </li>
                        <li class="tab">
                            <a href="#realestatedetails" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-home"></i></span> <span class="hidden-xs">Podatki o nepremičnini</span> </a>
                        </li>
                        <li class="tab">
                            <a href="#microlocation" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-location-arrow"></i></span> <span class="hidden-xs">{{ $t('property_iq:tabs:microlocation')}}</span> </a>
                        </li>
                        <li class="tab" v-if="enableLegalTab">
                            <a href="#legal" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-legal"></i></span> <span class="hidden-xs">{{ $t('property_iq:tabs:legal')}}</span> </a>
                        </li>
                        <li class="tab">
                            <a href="#mortgage" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="fa fa-pie-chart"></i></span> <span class="hidden-xs">{{ $t('property_iq:tabs:loan')}}</span> </a>
                        </li>
                    </ul>

                    <!-- START Tabs content -->
                    <div class="tab-content">

                        <!-- START Transactions tab -->
                        <div class="tab-pane active" id="transactions">
                            <transactions-tab
                                :re_key="session_data.main_property.real_estate.re_key"
                                :comparableTransactions="comparableTransactions"
                                :mainMarker="mainMarker"
                                @comparable-clicked="handleComparableTransactionClicked"
                            />
                        </div>
                        <!-- END Transactions tab -->


                        <!-- START Listings tab -->
                        <div class="tab-pane" id="listings">
                            <adverts-tab
                                :comparableListings="comparableListings"
                                :mainMarker="mainMarker"
                                @comparable-clicked="handleComparableAdvertClicked"
                            />
                        </div>
                        <!-- END Listings tab -->
                        
                        <!-- START Market Analysis tab -->
                        <div class="tab-pane vld-parent" style="min-height:100px;" id="analyticsdashboard" v-if="enableRegionAnalyticsDashboardTab">
                            <loading 
                                :active="!analytics_region"
                                :can-cancel="false" 
                                :is-full-page="false"
                            />
                            <analytics-dashboard-region v-if="analytics_region" :region="analytics_region" infoSlug="analytics-vir-in-metodologija-propertyiq" />
                        </div>
                        <!-- END Market Analysis tab -->

                        <!-- START Real estate details -->
                        <div class="tab-pane" id="realestatedetails">
                            <real-estate-details-tab
                                :enableInspectionForm="enableInspectionForm"
                                :session="session_data"
                            />
                        </div>
                        <!-- END Real estate details -->

                        <!-- START Microlocation tab -->
                        <div class="tab-pane" id="microlocation">
                            <microlocation-tab
                                :realEstate="session_data.main_property.real_estate"
                            />
                        </div>
                        <!-- END Microlocation tab -->

                        <!-- START Legal tab -->
                        <div class="tab-pane" id="legal" v-if="enableLegalTab">
                            <legal-tab />
                        </div>
                        <!-- END Legal tab -->

                        <!-- START Mortgage tab -->
                        <div class="tab-pane" id="mortgage">
                            <mortgage-tab
                                :initialPrice="session_data.appraised_value"
                            />
                        </div>
                        <!-- END Mortgage tab -->

                    </div>
                    <!-- END Tabs content -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue"

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import FileUpload from '@/components/utils/FileUpload';
import ApiService from '@/components/utils/api.service.js'
import Utils from '@/components/utils/utils.js'
import Cookies from '@/components/utils/cookies.js'
import KGoogleMap from '@/components/k-google-map/KGoogleMap'
import BarWidget from '@/components/widgets/BarWidget.vue';
import Bootstrap3Modal from '@/components/utils/Bootstrap3Modal.vue'

import RealEstateDetailsSummary from '@/../apps/real_estate/vue/components/RealEstateDetailsSummary.vue'

// Tabs
import AnalyticsDashboardRegion from  '@/../apps/analytics/vue/dashboard/AnalyticsDashboardRegion.vue';
import RealEstateDetailsTab from './tabs/real_estate_details_tab/Tab.vue'
import MicrolocationTab from './tabs/microlocation_tab/Tab.vue'
import TransactionsTab from './tabs/transactions_tab/Tab.vue'
import AdvertsTab from './tabs/adverts_tab/Tab.vue'
import LegalTab from './tabs/legal_tab/Tab.vue'
import MortgageTab from './tabs/mortgage_tab/Tab.vue'

// PropertyIQ Components
import ReportDownloadPicker from './components/ReportDownloadPicker.vue'

import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        inprogressTransactionItemsIds: [],
        failedTransactionItemsIds: [],
        retrievingImages: {
            inprogress: [],
            failed: []
        },

        inprogressAdvertItemsIds: [],
        failedAdvertItemsIds: [],

        gursSessionId: "",
        sessionId: null,
        currentUser: null
    },
    mutations: {
        inprogressTransactionItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.inprogressTransactionItemsIds, item_id);
        },
        inprogressTransactionItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.inprogressTransactionItemsIds, item_id);
        },
        failedTransactionItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.failedTransactionItemsIds, item_id);
        },
        failedTransactionItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.failedTransactionItemsIds, item_id);
        },

        // Retrieving images
        inprogressRetrievingImagesAdd(state, item_id) {
            Utils.removeFromArray(state.retrievingImages.failed, item_id);
            Utils.addToArray(state.retrievingImages.inprogress, item_id);
        },
        inprogressRetrievingImagesRemove(state, item_id) {
            Utils.removeFromArray(state.retrievingImages.inprogress, item_id);
        },
        failedRetrievingImagesAdd(state, item_id) {
            Utils.removeFromArray(state.retrievingImages.inprogress, item_id);
            Utils.addToArray(state.retrievingImages.failed, item_id);
        },
        failedRetrievingImagesRemove(state, item_id) {
            Utils.removeFromArray(state.retrievingImages.failed, item_id);
        },

        // Adverts
        inprogressAdvertItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.inprogressAdvertItemsIds, item_id);
        },
        inprogressAdvertItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.inprogressAdvertItemsIds, item_id);
        },
        failedAdvertItemsIdsAdd(state, item_id) {
            Utils.addToArray(state.failedAdvertItemsIds, item_id);
        },
        failedAdvertItemsIdsRemove(state, item_id) {
            Utils.removeFromArray(state.failedAdvertItemsIds, item_id);
        },

        // gursSessionId
        setGursSessionId(state, val) {
            state.gursSessionId = val;
        },
        // gursSessionId
        setSessionId(state, val) {
            state.sessionId = val;
        },
        // currentUser
        setCurrentUser(state, val) {
            state.currentUser = val;
        },
    },
    actions: {
        fetchCurrentUser(context) {
            ApiService
                .get("/api/v1/current-user")
                .then(response => {
                    context.commit("setCurrentUser", response.data);
                })
                .catch(error => {
                    throw error;
                })
        }
    }
})

export default {
    store: store,
    components: {
        FileUpload,
        KGoogleMap,
        RealEstateDetailsSummary,
        BarWidget,
        Loading,
        "bootstrap3-modal": Bootstrap3Modal,

        // Tabs
        RealEstateDetailsTab,
        MicrolocationTab,
        TransactionsTab,
        AdvertsTab,
        AnalyticsDashboardRegion,
        LegalTab,
        MortgageTab,

        // PropertyIQ Components
        ReportDownloadPicker,
    },
    props: {
        sessionId: {
            type: Number,
            required: true
        },
        gursSessionId: {
            type: String
        },
    },
    data: function () {
        return {
            session_data: null,
            loadingSessionData: false,
            uploadingSessionImage: false,
            reportDownloading: false,
            comparableTransactions: [],
            comparableListings: [],
            analytics_region: null,
        }
    },
    methods: {
        getSessionAnalyticsRegion: function() {
            ApiService
                .get(`/amas/analysis/analysis/api/${this.sessionId}/analytics-region`, {params: {level: 2}})
                .then(response => {
                    this.analytics_region = response.data;
                })
        },
        handleComparableTransactionClicked: function(item) {
            this.$store.commit("inprogressTransactionItemsIdsAdd", item.id);
            if(this.comparableTransactions.map(i => i.sale_object.id).includes(item.id)) {
                ApiService
                    .delete(`/amas/analysis/avm/api/${this.sessionId}/transactions/manage`, {
                        params: { sale_item_id: item.id }
                    })
                    // eslint-disable-next-line no-unused-vars
                    .then(response => {
                        this.comparableTransactions = this.comparableTransactions.filter(i => i.sale_object.id != item.id);
                        this.getSessionData();
                    })
                    .catch(error => {
                        this.$store.commit("failedTransactionItemsIdsAdd", item.id);
                        throw error;
                    })
                    .then(() => {
                        this.$store.commit("inprogressTransactionItemsIdsRemove", item.id);
                    })
            } else {
                ApiService
                    .post(`/amas/analysis/avm/api/${this.sessionId}/transactions/manage`, null, {
                        params: { sale_item_id: item.id }
                    })
                    .then(response => {
                        Utils.addToArray(this.comparableTransactions, response.data);
                        this.getSessionData();
                        this.pollGetTransactionImages(response.data.id);
                    })
                    .catch(error => {
                        this.$store.commit("failedTransactionItemsIdsAdd", item.id);
                        throw error;
                    })
                    .then(() => {
                        this.$store.commit("inprogressTransactionItemsIdsRemove", item.id);
                    })
            }
        },
        pollGetTransactionImages: function(item_id) {
            let objIndex = this.comparableTransactions.findIndex((obj => obj.id == item_id));
            let saleObjectId = this.comparableTransactions[objIndex].sale_object.id; // transaction.sale_object.id
            this.$store.commit("inprogressRetrievingImagesAdd", saleObjectId);
            let i = 0;
            let poll = setInterval(() => {
                if(i < 5) {
                    i += 1;
                    ApiService
                        .get(`/amas/analysis/avm/api/${this.sessionId}/transaction/${item_id}`)
                        .then(response => {
                            if(response.data.map_image || response.data.streetview_image){
                                this.comparableTransactions[objIndex].map_image = response.data.map_image;
                                this.comparableTransactions[objIndex].streetview_image = response.data.streetview_image;
                                clearInterval(poll);
                                this.$store.commit("inprogressRetrievingImagesRemove", saleObjectId);
                            }
                        })
                        .catch(error => {
                            this.$store.commit("failedRetrievingImagesAdd", saleObjectId);
                            throw error;
                        })
                } else {
                    // Timeout reached
                    this.$store.commit("failedRetrievingImagesAdd", saleObjectId);
                    clearInterval(poll);
                }
            }, 2000)
        },
        handleComparableAdvertClicked: function(item) {
            this.$store.commit("inprogressAdvertItemsIdsAdd", item.id);
            if(this.comparableListings.map(i => i.id).includes(item.id)) {
                ApiService
                    .get(`/amas/analysis/listing/api/${this.sessionId}/manage`, {
                        params: { action: "remove", listing_id: item.id}
                    })
                    // eslint-disable-next-line no-unused-vars
                    .then(response => {
                        this.comparableListings = this.comparableListings.filter(i => i.id != item.id);
                        this.getSessionData();
                    })
                    .catch(error => {
                        this.$store.commit("failedAdvertItemsIdsAdd", item.id);
                        throw error;
                    })
                    .then(() => {
                        this.$store.commit("inprogressAdvertItemsIdsRemove", item.id);
                    })
            } else {
                ApiService
                    .get(`/amas/analysis/listing/api/${this.sessionId}/manage`, {
                        params: { action: "add", listing_id: item.id}
                    })
                    .catch(error => {
                        this.$store.commit("failedAdvertItemsIdsAdd", item.id);
                        throw error;
                    })
                    .then(() => {
                        this.$store.commit("inprogressAdvertItemsIdsRemove", item.id);
                        this.getSessionComparableListings();
                        this.getSessionData();
                    })
            }
        },
        handleSessionImageUpload: function(img) {
            this.uploadingSessionImage = true;
            let formData = new FormData();
            formData.append('image', img);
            ApiService
                .post(`/amas/analysis/analysis/api/${this.sessionId}/upload-main-image`, formData)
                .then(response => {
                    this.session_data = response.data;
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.uploadingSessionImage = false;
                });
        },
        getSessionData: function(){
            this.loadingSessionData = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.sessionId}`)
                .then(response => {
                    this.session_data = response.data;
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loadingSessionData = false;
                })
        },
        getSessionComparableTransaction: function(){
            ApiService
                .get(`/amas/analysis/analysis/api/${this.sessionId}/transactions`, { params: {
                    comparable: true
                }})
                .then(response => {
                    this.comparableTransactions = response.data;
                })
                .catch(error => {
                    throw error;
                })
        },
        getSessionComparableListings: function(){
            ApiService
                .get(`/amas/analysis/listing/api/${this.sessionId}/listings`)
                .then(response => {
                    this.comparableListings = response.data;
                })
                .catch(error => {
                    throw error;
                })
        },
        handleDownloadReportRequest: function() {
            if(this.avaliableReports && this.avaliableReports.length){
                if(this.avaliableReports.length===1){
                    // Download the only avaliable report
                    this.downloadReport(this.avaliableReports[0].id);
                } else {
                    this.$modal.show('report-download-picker-modal');
                }
            } else {
                alert("Na voljo ni nobenih poročil.")
            }
        },
        downloadReport: function(report_id) {
            this.reportDownloading = true;
            Cookies.startTimeoutChecker(
                "DownloadCompleteChecker",
                () => {this.reportDownloading = false},
                () => {throw "Report download timed out"},
            )
            let url = `/amas/analysis/${this.sessionId}/report/download/${report_id}`;
            this.$refs.downloadReportForm.action = url;
            this.$refs.downloadReportForm.submit();
        }
    },
    computed: {
        getComparableTransactionPrices: function(){
            return this.comparableTransactions.map(t => t.sale_object.transaction_amount);
        },
        getComparableListingsPrices: function(){
            return this.comparableListings.map(i => i.price);
        },
        mainMarker: function(){
            return { coordinates: this.session_data.main_property.real_estate.gps }
        },
        enableInspectionForm: function() {
            // enable inspection form if user has permissions and if real estate is a house or an apartment
            if (!["APPARTMENT_GROUP", "HOUSE_GROUP"].includes(this.session_data.main_type_group))
                return false;
            return this.$store.state.currentUser && this.$store.state.currentUser.all_permissions_list.includes('analysis.inspection_form_edit');
        },
        enableLegalTab: function() {
            return this.$store.state.currentUser && this.$store.state.currentUser.all_permissions_list.includes('legal.legal_status_enabled');
        },
        enableRegionAnalyticsDashboardTab: function() {
            return this.$store.state.currentUser 
                && this.$store.state.currentUser.all_permissions_list.includes('analytics.analytics_dashboard_region')
                && this.session_data && this.session_data.main_property && this.session_data.main_property.real_estate.unit &&this.session_data.main_property.real_estate.unit.is_appartment;
        },
        avaliableReports: function() {
            if(this.$store.state.currentUser) {
                return this.$store.state.currentUser.available_report_templates;
            }
            return [];
        }
    },
    mounted() {
        this.getSessionData();
        this.getSessionComparableTransaction();
        this.getSessionComparableListings();
        this.getSessionAnalyticsRegion();
        this.$store.commit("setSessionId", this.sessionId);
        this.$store.commit("setGursSessionId", this.gursSessionId);
        this.$store.dispatch("fetchCurrentUser");
    },
}
</script>

<style scoped>
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .file-upload {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .real-estate-details-title {
        position: relative;
    }
    .real-estate-details-title > a {
        position: absolute;
        top: 0;
        right: 0;
    }
</style>

<style>
    .z-index-1 {
        z-index: 1;
    }
    .property-iq .report-downloading-overlay .vld-icon {
        height: 60%;
    }
    .property-iq .leaflet-popup-tip-container {
        display: none!important;
    }
    .property-iq .dashboard-graph {
        border: 1px solid whitesmoke;
    }

    #default-map{
        border-radius: 1em;
        overflow: hidden;
    }

    /* Index block */
    .property-iq__index-block {
        background-color: #ff7676;
        color: white;
        padding: 5px;
    }
    /* Vertical aling cell content */
    td.align-middle {
        vertical-align: middle !important;
    }
    .property-iq__index-block{
      min-width: 24px;
    }


    .propertyiq-iq__marker-icon-content {
        background-color: #2cabe3;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        text-align: center;
    }


    .propertyiq-iq__marker-icon-content.comparable {
        background-color: #ff7676;
        margin-top: -10px;
        min-width: 35px;
        border-radius: 0px;
    }

    .propertyiq-iq__marker-icon-content.comparable:after {
      content: " ";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
      border-top: 10px solid #ff7676;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: none;
    }


    .propertyiq-iq__marker-icon-content.clicked {
        /*-webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.54);*/
        /*-moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.54);*/
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.685);
    }

</style>