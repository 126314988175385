<template>
<tbody>
    <tr class="section-title-row">
        <td>
            {{ $t("task_details:other") }}
        </td>

        <td
            v-for="(session, idx) in subject.session_list"
            :key="'session-other-section-td-'+idx"
            class="text-center"
        >
        </td>
    </tr>


    <tr>
        <td>
            {{ $t("task_details:other_section:price_zone") }}
            <help-icon slug="predmet-vrednotenja-price-zone"/>
        </td>

        <td
            v-for="(session, idx) in subject.session_list"
            :key="'session-main-types-td-'+idx"
        >
            <div class="input-wrapper-div center-block text-center">
                <DropDownSelectInputField
                    v-if="editable"
                    v-model="session.custom_price_zone"
                    :choices="price_zone_options[session.id]"
                    :default-display-if-null="$t('general:automatic_selection')"
                />
                <div v-else class="text-info text-semibold">
                    {{ session.custom_price_zone || $t("general:automatic_selection") }}
                </div>
            </div>
        </td>
    </tr>
</tbody>
</template>

<script>
import HelpIcon from "@/components/widgets/HelpIcon";

import DropDownSelectInputField from "../components/DropDownSelectInputField.vue";
import VSUtils from "../components/utils.js"
import ApiService from "@/components/utils/api.service";

export default {
    model: {
        prop: "subject",
        event: "change"
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    components: {
        HelpIcon,
        DropDownSelectInputField,
    },
    data() {
        return {
            VSUtils: VSUtils,
            price_zone_options: {}
        }
    },
    methods: {
        getSessionPriceZoneOptions: function (session) {
            // this.price_zone_options[session.id] = [
            //     {
            //         "value": "MALEŠNICA SJEVER",
            //         "text": "MALEŠNICA SJEVER"
            //     },
            //     {
            //         "value": "MALEŠNICA STENJEVEC",
            //         "text": "MALEŠNICA STENJEVEC"
            //     },
            //     {
            //         "value": "ŠPANSKO",
            //         "text": "ŠPANSKO"
            //     },
            // ]

          if(!session.coordinates){
            return
          }

          return ApiService
              .get(`/amas/analysis/avm/api/closest-price-zone`, {
                params: {
                  transaction_type: this.transaction_type,
                  gps_point: session.coordinates,
                }
              })
              .then(response => {
                if (session == null) {
                  return
                }

                if (session.custom_price_zone == null) {
                  session.custom_price_zone = response.data.custom_price_zone
                }
                this.price_zone_options[session.id] = response.data.avaliable_price_zones
              })
        },
        getPriceZoneOptions: function () {
            this.subject.session_list.filter(s => s.coordinates).forEach((session) => {
                this.getSessionPriceZoneOptions(session)
            });
        }
    },
    mounted() {
        this.getPriceZoneOptions();
    },
}
</script>
