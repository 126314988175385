<template>
    <span
        v-if="has_link"
        style="display: inline-block;"
        :href="href"
        :class="`open-modal mark-row custom-cursor-pointer circle circle-sm display-inline bg-${levelColor(kpi_value)}`"
        data-iframe="true" data-modal-width="95%" data-modal-height="90%" data-toggle="tooltip"
    >
        {{kpi_value}}
    </span>
    <span
        v-else
        style="display: inline-block;"
        :class="`circle circle-sm display-inline bg-${levelColor(kpi_value)}`"
    >
        {{kpi_value}}
    </span>
</template>

<script>
export default {
    name: "IconKPI",
    props: {
        has_link: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: "",
        },
        kpi_value: {
            type: String,
            required: true,
        },
    },
    methods: {
        levelColor: function(letter){
            if (letter === "A"){
                return "success"
            } else if(letter === "B"){
                return "warning"
            } else if(letter === "C"){
                return "danger"
            } else{
                return "light"
            }
        },
    },
}
</script>
