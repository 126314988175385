<template>
    <span :class="['label label-rouded', cssClass]">
        <strong>{{label}}</strong>
        <span class="hidden">{{score}}</span>
    </span>
</template>

<script>
export default {
    props: {
        score: {
            type: Number,
            default: 18
        },
    },
    computed: {
        cssClass: function() {
            if(this.score >= 90) {
                return "label-success";
            } else if(this.score > 60) {
                return "label-warning";
            } else {
                return "label-danger";
            }
        },
        label: function() {
            if (this.score >= 90) {
              return "A";
            } else if (this.score > 60) {
              return "B";
            } else {
              return "C";
            }
        },
    },
}
</script>