<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="legal_status"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/analysis/api/${this.session.id}/legal-status`)
                .then(response => {
                    let session_status = response.data.session_status;
                    let url = `/amas/analysis/${this.session.id}/legal`;
                    return `
                    <a href="${url}">
                    <span class="circle circle-sm bg-${session_status.css}" data-toggle="tooltip" data-original-title="${this.$t("task_details:status:risk_level")}">
                        ${session_status.rating}
                    </span>
                    </a>
                    `
                })
        }
    },
}
</script>