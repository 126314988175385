<template>
    <marker-map
        :markers="markers"
        :height="height"
        :circles="circles"
        :center="center"
        :zoom="zoom"
        :maxClusterRadius="maxClusterRadius"
        :loadingMarkers="loadingMarkers"
        :mainMarker="mainMarker"
        @marker-clicked="handleMarkerClicked"
        @update:bounds="$emit('update:bounds', $event)"
        @clusterclick="handleClusterClick"
        ref="map"
    >
        <!-- Marker extra slots - popup and icon -->
        <template v-slot:marker="slotProps">
            <l-icon
                class-name="propertyiq-iq__marker-icon"
                :icon-anchor="[8.3, 9]"
                :popup-anchor="[0, -9]"
                :ref="'markerIcon'+slotProps.marker.idx"
            >
                <div
                    class="propertyiq-iq__marker-icon-content"
                    :class="{
                        'clicked': clickedMarkerData && slotProps.marker.id==clickedMarkerData.id,
                        'comparable': comparableListingItemsIds.includes(slotProps.marker.id)
                    }"
                >
                    <slot name="marker-icon-content" v-bind:marker="slotProps.marker">
                        <strong>{{slotProps.marker.idx}}</strong>
                    </slot>
                </div>
            </l-icon>

            <l-popup
                :options="{autoPan: true}"
            >
                <advert-details
                    :data="clickedMarkerData"
                    :isSelected="clickedMarkerData && comparableListingItemsIds.includes(clickedMarkerData.id)"
                    @comparable-clicked="$emit('comparable-clicked', $event)"
                />
            </l-popup>
        </template>

        <l-feature-group ref="features">
            <l-circle
                v-for="(c, idx) in circles"
                :lat-lng="c.gps"
                :radius="500"
                :key="'circle_'+idx"
            />
        </l-feature-group>

    </marker-map>
</template>

<script>
import { LPopup, LIcon, LCircle, LFeatureGroup, LControl } from 'vue2-leaflet';

import PropertyIQStadiaMarkerMap from "../../components/StadiaMarkerMap.vue"
import AdvertsDetails from "./AdvertsDetails.vue";

export default {
    name: "PropertyIQAdvertsMap",
    components: {
        LPopup, LIcon, LCircle, LFeatureGroup, LControl,
        
        "advert-details": AdvertsDetails,
        "marker-map": PropertyIQStadiaMarkerMap,
    },
    props: {
        height: {
            type: String,
            default: '800px'
        },
        markers: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object,
        },
        clickedMarkerData: {
            type: Object,
        },
        comparableListingItemsIds: {
            type: Array,
            default: () => []
        },
        loadingMarkers: {
            type: Boolean,
            default: false
        },
        center: {
            type: [Object, Array],
            default: () => [46.05108, 14.50513]
        },
        zoom: {
            type: Number
        },
        maxClusterRadius: {
            type: Number
        }
    },
    data() {
        return {
            circles: []
        }
    },
    methods: {
        handleClusterClick: function(e){
            this.circles = [{gps: e.latlng}];
        },
        handleMarkerClicked: function(event) {
            // this.mapObject.panTo(event.gps);
            this.$emit('marker-clicked', event);
        }
    },
    computed: {
        mapObject: function() {
            return this.$refs.map.mapObject; 
        },
        // circles: function() {
        //     if(this.clickedMarkerData) {
        //         return [{gps: {lat: this.clickedMarkerData.gps_point.coordinates[1], lng: this.clickedMarkerData.gps_point.coordinates[0]}}]
        //     }
        //     return []
        //     if(!this.markers) {
        //         return [];
        //     }
        //     return this.markers.filter((item, idx, self) =>
        //         idx === self.findIndex((t) => (
        //             t.gps.lat == item.gps.lat && t.gps.lng == item.gps.lng)
        //         )
        //     )
        // }
    },
    watch: {
        clickedMarkerData(val) {
            if(val && val.gps_point && val.gps_point.coordinates) {
                this.circles = [{gps: {lat: val.gps_point.coordinates[1], lng: val.gps_point.coordinates[0]}}]
            }
        }
    }
}
</script>
