<template>
    <order
        :orderId="orderId"
        :readOnly="true"
        :showOffers="false"
        :showAppraisers="true"
        :showProcurementForTeamSelection="false"
        :showOrderItemsList="true"
        :orderItemDetails_readOnly="true"
        :orderItemDetails_actionsEnabled="true"
        :orderItemDetails_amasAnalysisEnabled="false"
        @order-submitted="$emit('order-submitted')"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
    props: {
        orderId: {
            type: [ String, Number ],
            required: true
        },
    },
}
</script>
