<template>
    <span class="data-source-info">
        {{ $t("widgets:data_source:source")}} {{display}}{{ $t("widgets:data_source:source2")}}<span v-if="updated">{{ $t("widgets:data_source:source3")}} {{updated}}</span>
    </span>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"

export default {
    props: {
        dataKey: {
            type: String,
            default: null,
        },
        convertKey: {
            type: String,
            default: null,
        },
        lastUpdated: {
            type: Boolean,
            default: false
        },
        forceToday: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            converted: null,
            updated: null,
        }
    },
    methods: {
        getLastUpdated() {
            ApiService
                .get(`/api/v1/data-source/last-updated`, {
                    params: {key: this.dataKey}
                })
                .then(response => {
                    this.updated = response.data;
                })
                .catch(error => {
                    throw error;
                })
        },
        getConvertedKey() {
            const params = this.lastUpdated ? {
                key: this.convertKey,
                updated: true,
            } : {
                key: this.dataKey,
            }
            ApiService
                .get(`/api/v1/data-source/convert`, { params })
                .then(response => {
                    this.converted = response.data.display;
                    this.updated = response.data.updated;
                })
                .catch(error => {
                    throw error;
                })
        }
    },
    computed: {
        display() {
            if (this.converted !== null)
                return this.converted;
            return this.dataKey;
        }
    },
    mounted() {
        if (this.convertKey !== null) {
            this.getConvertedKey();
        } else if (this.lastUpdated) {
            if(this.forceToday) {this.updated = new Date().toLocaleDateString('de-DE')}
            else {this.getLastUpdated()}
        }
    },
}
</script>
