<template>
<div :style="style" class="vue-histogram-slider-wrapper">

    <span v-if="display_histogram">
        <svg :id="id" class="vue-histogram-view" v-bind:class="{ hidden: !data }"></svg>
    
        <div class="text-center" style="height: 110px; position: relative;" v-bind:class="{ hidden: data }">
        {{ loading_data_text }}
        <div class="center"><i class="fa fa-spinner fa-spin"></i></div>
        </div>
    </span>

    <div class="slider-wrapper">

        <input type="text" :id="histogramId" :name="histogramId" value />

        <div v-if="input_fields">
            <div class="form-group row m-0">

                <div class="col-xs-5 col-md-3 p-0">
                    <div class="input-group">
                        <input :id="histogramId+'_input_from'" type="text" 
                            v-model="internal_from" @change="_handle_input" 
                            class="form-control input-sm"
                        >
                        <span class="input-group-addon" v-if="input_fields_addon">
                            {{input_fields_addon}}
                        </span>
                    </div>
                </div>

                <div class="col-xs-2 col-md-6 text-center"></div>

                <div class="col-xs-5 col-md-3 p-0">
                    <div class="input-group">
                        <input :id="histogramId+'_input_from'" type="text" 
                            v-model="internal_to" @change="_handle_input" 
                            class="form-control input-sm"
                        >
                        <span class="input-group-addon" v-if="input_fields_addon">
                            {{input_fields_addon}}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>

var $ = require('jquery')

import './range-slider'
import props from './props'
import * as d3Scale from 'd3-scale'
import * as d3Array from 'd3-array'
import * as d3Select from 'd3-selection'
import * as d3Trans from 'd3-transition'

export default {
name: 'HistogramSlider',

props,

data() {
    const randomId = Math.random()
    .toString(36)
    .substr(2)

    return {
    id: `vue-histogram-${randomId}`,
    histogramId: `histogram-slider-${randomId}`,

    bins_loading: true,

    internal_from: this.from,
    internal_to: this.to,

    }
},

computed: {
    style() {
    let style = `
        --primary-color: ${this.primaryColor};
        --label-color: ${this.labelColor};
        --holder-color: ${this.holderColor};
        --handle-color: ${this.handleColor};
        --grid-text-color: ${this.gridTextColor};
        --line-height: ${this.lineHeight}px;
        --font-family: ${this.fontFamily};
        --font-size: ${this.fontSize};
        --hist-slider-gap: ${-36 + this.histSliderGap}px;
        --handle-size: ${this.handleSize}px;
    `
    if(this.width){
        style += `width: ${this.width}px;`
    }
    return style
    },
    isTypeSingle() {
    return this.type == 'single'
    },
    colors() {
    return () => this.primaryColor
    }
},

methods: {
    update({ from, to }) {
    this._set_fromto({ from, to })
    if (this.ionRangeSlider) {
        this.ionRangeSlider.update({ from, to })
        this._update_bar_color({ from, to })
    }
    },
    _handle_input() {
    this.update({ from:this.internal_from, to:this.internal_to })
    },
    _set_fromto({ from, to }) {
    this.internal_from = from;
    this.internal_to = to;
    },
    _update_bar_color(val) {
    var transition = d3Trans.transition().duration(this.transitionDuration)
    d3Trans
        .transition(transition)
        .selectAll(`.vue-histogram-slider-bar-${this.id}`)
        .attr('fill', d => {
        if (this.isTypeSingle) {
            return d.x0 < val.from ? this.colors(d.x0) : this.holderColor
        } else {
            return d.x0 <= val.to && d.x0 >= val.from ? this.colors(d.x0) : this.holderColor
        }
        })
    },
    render_histogram() {
    const width = this.width - 20
    var svg, histogram, x, y, hist, bins, min, max
    min = this.min  || this.internal_from
    max = this.max || this.internal_to
    // x scale for time
    x = d3Scale
        .scaleLinear()
        .domain([min, max])
        .range([0, width])
        .clamp(true)

    // y scale for histogram
    y = d3Scale.scaleLinear().range([this.barHeight, 0])

    svg = d3Select
        .select(`#${this.id}`)
        .html('')
        .attr('width', width)
        .attr('height', this.barHeight)

    hist = svg.append('g').attr('class', 'histogram')

    const updateHistogram = () => {
        let transition = d3Trans.transition().duration(this.transitionDuration)
        
        hist.selectAll(`.vue-histogram-slider-bar-${this.id}`).remove()

        // group data for bars
        if(this.data){
        histogram = d3Array
            .bin()
            .domain(x.domain())
            .thresholds(width / (this.barWidth + this.barGap))

        bins = histogram(this.data);
        bins = bins.map(i => {
            return {h: i.length, x0: i.x0, x1: i.x1}
        });
        } else if (this.bins) {
        // Bins were provided by the user
        bins = this.bins;
        }

        y.domain([0, d3Array.max(bins, d => d.h)])

        hist
        .selectAll(`.vue-histogram-slider-bar-${this.id}`)
        .data(bins)
        .enter()
        .insert('rect', 'rect.overlay')
        .attr('class', `vue-histogram-slider-bar-${this.id}`)
        .attr('x', d => x(d.x0))
        .attr('y', d => y(d.h))
        .attr('rx', this.barRadius)
        .attr('width', this.barWidth)
        .transition(transition)
        .attr('height', d => this.barHeight - y(d.h))
        .attr('fill', d => (this.isTypeSingle ? this.holderColor : this.colors(d.x0)))

        setTimeout(
        () => this._update_bar_color(this.ionRangeSlider.result),
        this.transitionDuration + 10
        )
    }
    updateHistogram()
    },
    render_slider() {
    if (this.ionRangeSlider) {
        this.ionRangeSlider.destroy()
    }
    
    var min, max
    min = this.min
    max = this.max
    this.histSlider = $(`#${this.histogramId}`).ionRangeSlider({
        skin: "flat",
        min: min,
        max: max,
        from: this.internal_from,
        to: this.internal_to,
        type: this.type,
        grid: this.grid,
        step: this.step,
        from_fixed: this.fromFixed,
        to_fixed: this.toFixed,
        hide_min_max: this.hideMinMax,
        hide_from_to: this.hideFromTo,
        force_edges: this.forceEdges,
        drag_interval: this.dragInterval,
        grid_num: this.Number,
        block: this.block,
        keyboard: this.keyboard,
        prettify: this.prettify,
        max_postfix: this.max_postfix,
        postfix: this.postfix,
        onUpdate: val => {
            this._set_fromto({from:val.from, to:val.to})
            this.$emit('change', val)
        },
        onFinish: val => {
            if (!this.updateColorOnChange) {
            this._update_bar_color(val)
            }
            this._set_fromto({from:val.from, to:val.to})
            this.$emit('change', val)
        },
        onChange: val => {
            if (this.updateColorOnChange) {
            this._update_bar_color(val)
            }
            this._set_fromto({from:val.from, to:val.to})
        }
        })

        this.ionRangeSlider = this.histSlider.data('ionRangeSlider')
    },
},

mounted() {
    this.render_slider();
    if(this.data || this.bins){
    this.render_histogram();
    }
},

destroyed() {
    this.ionRangeSlider.destroy()
},

watch: {
    data: function (val) {
    if(val) {
        this.render_histogram();
    }
    },
    bins: function (val) {
    if(val) {
        this.render_histogram();
    }
    },
}
}
</script>

<style>
@import './range-slider.css';
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

.slider-wrapper {
    width: 100%;
    margin-top: var(--hist-slider-gap);
}

.vue-histogram-slider-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.vue-histogram-slider-bar {
    pointer-events: none;
}

.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-bar,
.irs--flat .irs-from, .irs--flat .irs-to,
.irs--flat .irs-single{
    background-color: #2cabe3;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #2cabe3;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.42);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.42);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.42);
    z-index: 10;
}

.hidden {
    display: none;
}
.text-center {
    text-align: center;
}
.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>
