<template>
<div class="comparable-finder--form container-fluid p-0">

    <div class="row">

        <!-- TRANSACTION_TYPE -->
        <div v-if="!hide_search_options.includes('analysis.comparable_search__by_rent_or_sale')" class="col-md-4">
            <div class="label-wrapper">
                <p><strong>{{$t('analysis:transaction:type')}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <select-input-field
                    v-model="form_data.rent_or_sale"
                    :options="[
                        {text: 'Prodaja', value: 'sale'},
                        {text: 'Oddaja', value: 'rent'},
                    ]"
                    size="sm"
                    :disabled="true"
                />
                <select-input-field
                    v-model="form_data.property_type"
                    :options="[
                        {text: this.$t(`real_estate:parcels`), value: 'parcel'},
                        {text: this.$t('real_estate:units'), value: 'unit'},
                    ]"
                    size="sm"
                />
            </div>
        </div>

        <!-- TRANSACTION DATES -->
        <div v-if="!hide_search_options.includes('analysis.comparable_search__transaction_dates')" class="col-md-4">
            <div class="label-wrapper">
                <p><strong>{{$t("general:duration")}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <date-select-widget
                    v-model="form_data.transaction_date__from"
                />
                <date-select-widget
                    v-model="form_data.transaction_date__to"
                />
            </div>
        </div>

        <!-- LOCATION -->
        <div class="col-md-4">
            <div class="label-wrapper">
                <p><strong>{{$t("analysis:ct:location_radius_EMV_model")}} <help-icon slug="iskalnik-primerljivih-model-emv" /></strong></p>
            </div>
            <div class="input-fields-wrapper" v-if="!hide_search_options.includes('analysis.comparable_search__location')">
                <number-input-field
                    v-model="form_data.radius"
                    placeholder="max"
                    inputFieldsAddon="m"
                    size="sm"
                />
                <select-input-field
                    v-if="!hide_search_options.includes('analysis.comparable_search__property_model_type')"
                    v-model="form_data.valuation_zone__model"
                    :options="valuation_zone_model_options"
                    size="sm"
                />
            </div>
        </div>

        <!-- UNIT SIZE -->
        <div class="col-md-4" v-if="_isUnit && !hide_search_options.includes('analysis.comparable_search__unit_size')">
            <div class="label-wrapper">
                <p><strong>{{$t("real_estate:unit:size")}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <number-input-field
                    v-model="form_data.unit_size__from"
                    placeholder="min"
                    inputFieldsAddon="m²"
                    size="sm"
                />
                <number-input-field
                    v-model="form_data.unit_size__to"
                    placeholder="max"
                    inputFieldsAddon="m²"
                    size="sm"
                />
            </div>
        </div>

        <!-- PARCEL SIZE -->
        <div class="col-md-4" v-if="_isParcel && !hide_search_options.includes('analysis.comparable_search__parcel_size')">
            <div class="label-wrapper">
                <p><strong>{{$t("real_estate:parcel:size")}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <number-input-field
                    v-model="form_data.parcel_size__from"
                    placeholder="min"
                    inputFieldsAddon="m²"
                    size="sm"
                />
                <number-input-field
                    v-model="form_data.parcel_size__to"
                    placeholder="max"
                    inputFieldsAddon="m²"
                    size="sm"
                />
            </div>
        </div>

        <!-- UNIT YEAR BUILT -->
        <div class="col-md-4" v-if="_isUnit && !hide_search_options.includes('analysis.comparable_search__year_built')">
            <div class="label-wrapper">
                <p><strong>{{$t("general:year:built")}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <number-input-field
                    v-model="form_data.building_year_built__from"
                    placeholder="min"
                    size="sm"
                    thousandsSeparator=""
                    :min="0"
                    :max="2025"
                />
                <number-input-field
                    v-model="form_data.building_year_built__to"
                    placeholder="max"
                    size="sm"
                    thousandsSeparator=""
                    :min="0"
                    :max="2025"
                />
            </div>
        </div>

        <!-- TRANSACTION AMOUNT -->
        <div class="col-md-4" v-if="!hide_search_options.includes('analysis.comparable_search__transaction_amount')">
            <div class="label-wrapper">
                <p><strong>{{$t("general:price")}}</strong></p>
            </div>
            <div class="input-fields-wrapper">
                <number-input-field
                    v-model="form_data.transaction_amount__from"
                    placeholder="min"
                    inputFieldsAddon="€"
                    size="sm"
                    :min="0"
                />
                <number-input-field
                    v-model="form_data.transaction_amount__to"
                    placeholder="max"
                    inputFieldsAddon="€"
                    size="sm"
                    :min="0"
                />
            </div>
        </div>
    </div>

    <div class="row m-t-20 comparable-finder--form--additional-fields">
        <div class="col-sm-12">
            <!-- 
                REAL ESTATE SECTION 
            -->
            <!-- TIP NEPREMIČNINE -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__real_estate_type')">
                <expandable-input
                    :label="$t('general:real_estate:type')"
                    :display-value="getDisplayValue(form_data.property_type=='unit' ? form_data.unit_types : form_data.parcel_types, realestate_types_options)"
                >
                    <multi-select-field
                        :value="realestateTypesJson"
                        :options="realestate_types_options"
                        @input="
                            handleMultiselectInput($event, form_data.property_type=='unit' ? 'unit_types' : 'parcel_types');
                            handleRealEstateTypeChange($event);
                        "
                    />
                </expandable-input>
            </span>
            <!-- TIP STAVBE -->
            <span v-if="_isUnit && !hide_search_options.includes('analysis.comparable_search__unit_type')">
                <expandable-input
                    :label="$t('real_estate:building:type')"
                    :display-value="getDisplayValue(form_data.building_types, building_types_options_sl)"
                >
                    <multi-select-field
                        :value="buildingTypesJson"
                        :options="building_types_options_sl"
                        @input="handleMultiselectInput($event, 'building_types')"
                    />
                </expandable-input>
            </span>
            <!-- APARTMENT COUNT -->
            <span v-if="_isUnit && !hide_search_options.includes('analysis.comparable_search__apartment_count')">
                <expandable-input
                    :label="$t('real_estate:building:num_of_apartments_in_building')"
                    :display-value="getDisplayValue(form_data.appartment_count, appartment_count_options)"
                >
                    <select-input-field
                        v-model="form_data.appartment_count"
                        :options="appartment_count_options"
                        size="sm"
                    />
                </expandable-input>
            </span>
            <!-- CONSTRUCTION PHASE -->
            <span v-if="_isUnit && !hide_search_options.includes('analysis.comparable_search__construction_phase') && form_data.rent_or_sale === 'sale'">
                <expandable-input
                    :label="$t('real_estate:building:construction_phase')"
                    :display-value="getDisplayValue(form_data.construction_phase__isnull, construction_phase__isnull_options)"
                >
                    <select-input-field
                        v-model="form_data.construction_phase__isnull"
                        :options="construction_phase__isnull_options"
                        size="sm"
                    />
                </expandable-input>
            </span>
            <!-- VELIKOST DS -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__max_size')">
                <expandable-input
                    :label="$t('real_estate:building:max_sum_unit_sizes')"
                    :display-value="form_data.transaction_sum_unit_sizes__lte != null ? String(form_data.transaction_sum_unit_sizes__lte) + ' m²' : null"
                >
                    <number-input-field
                        v-model="form_data.transaction_sum_unit_sizes__lte"
                        placeholder="max"
                        size="sm"
                        inputFieldsAddon="m²"
                    />
                </expandable-input>
            </span>
            <!-- NAMENSKE RABE PARCELE -->
            <span v-if="_isParcel && !hide_search_options.includes('analysis.comparable_search__parcel_dedicated_usage')">
                <expandable-input
                    label="Namenske rabe"
                    :display-value="getDisplayValue(form_data.parcel_dedicated_usages, parcel_dedicated_usages_options)"
                >
                    <multi-select-field
                        :value="parcelDedicatesUsagesJson"
                        :options="parcel_dedicated_usages_options"
                        @input="handleMultiselectInput($event, 'parcel_dedicated_usages')"
                    />
                </expandable-input>
            </span>
            <!-- SALE SHARE -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__sale_share')">
                <expandable-input
                    :label="$t('analysis:ct:share_of_sales')"
                    :display-value="getDisplayValue(form_data.sale_share, sale_share_options)"
                >
                    <select-input-field
                        v-model="form_data.sale_share"
                        :options="sale_share_options"
                        size="sm"
                    />
                </expandable-input>
            </span>
            <span v-if="!hide_search_options.includes('analysis.comparable_search__valuation_zone')">
                <expandable-input
                    :label="$t('real_estate:value_levels')"
                    :display-value="getDisplayValue(form_data.valuation_zone__levels, valuation_zone__levels_options)"
                >
                    <multi-select-field
                        :value="valuationZoneLevelsJson"
                        :options="valuation_zone__levels_options"
                        @input="handleMultiselectInput($event, 'valuation_zone__levels')"
                    />
                </expandable-input>
            </span>
        </div>

        <div class="col-sm-12 m-t-10">
            <!-- 
                TRANSACTION SECTION 
            -->
            <!-- TRANSACTION TYPE -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__transaction_type')">
                <expandable-input
                    :label="$t('avm:transaction:type')"
                    :display-value="getDisplayValue(form_data.transaction_types, transaction_types_options)"
                >
                    <multi-select-field
                        :value="transactionTypesJson"
                        :options="transaction_types_options"
                        @input="handleMultiselectInput($event, 'transaction_types')"
                    />
                </expandable-input>
            </span>
            <!-- TRANSACTION VOLUME -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__transaction_volume')">
                <expandable-input
                    :label="$t('analysis:transaction:volume')"
                    :display-value="getDisplayValue(form_data.transaction_volume__lte, transaction_volume__lte_options)"
                >
                    <select-input-field
                        v-model="form_data.transaction_volume__lte"
                        :options="transaction_volume__lte_options"
                        size="sm"
                    />
                </expandable-input>
            </span>
        </div>

        <div class="col-sm-12 m-t-10">
            <!-- 
                CLEANING SECTION 
            -->
            <!-- EXCLUDE OUTLIERS -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__exclude_outliers')">
                <expandable-input
                    :label="$t('analysis:ct:exclude_outliers')"
                    :display-value="getDisplayValue(form_data.exclude_outliers, exclude_outliers_options)"
                >
                    <select-input-field
                        v-model="form_data.exclude_outliers"
                        :options="exclude_outliers_options"
                        size="sm"
                    />
                </expandable-input>
            </span>
            <!-- LOW QUALITY -->
            <span v-if="!hide_search_options.includes('analysis.comparable_search__low_quality')">
                <expandable-input
                    :label="$t('analysis:ct:low_quality_transactions')"
                    :display-value="getDisplayValue(form_data.low_quality, low_quality_options)"
                >
                    <select-input-field
                        v-model="form_data.low_quality"
                        :options="low_quality_options"
                        size="sm"
                    />
                </expandable-input>
            </span>

            <span v-if="!hide_search_options.includes('analysis.comparable_search__consider_spatial_plan_area')">
                <expandable-input
                    :label="$t('general:construction_zones')"
                    :display-value="getDisplayValue(form_data.consider_spatial_plan_area, consider_spatial_plan_area_options)"
                >
                    <select-input-field
                        v-model="form_data.consider_spatial_plan_area"
                        :options="consider_spatial_plan_area_options"
                        size="sm"
                    />
                </expandable-input>
            </span>

          <span v-if="!hide_search_options.includes('analysis.price_zone_permission')">
                <expandable-input
                    :label="$t('analysis:ct:filter_by_price_zone')"
                    :display-value="getDisplayValue(form_data.price_zone__in, price_zone_options)"
                >
                <multi-select-field
                    v-if="!hide_search_options.includes('analysis.price_zone_permission')"
                    :options="price_zone_options"
                    @input="handleMultiselectInput($event, 'price_zone__in')"
                    :value="priceZoneJson"
                />
                </expandable-input>
            </span>
        </div>
    </div>

    <div class="row m-t-20 comparable-finder--form--buttons">
        <div class="col-sm-12">
            <div class="text-center m-t-10">
                <button type="submit" class="btn btn-info m-r-10" v-on:click.prevent="$emit('submit')">
                    {{$t("general:search")}}
                </button>
                <button type="submit" class="btn btn-link text-muted" v-on:click.prevent="$emit('reset')">
                    {{$t("avm:reset_to_arvio")}}
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";
import DateSelectWidget from "@/components/widgets/DateSelectWidget.vue";
import SelectInputField from "@/components/utils/inputs/SelectInputField.vue";
import HelpIcon from "@/components/widgets/HelpIcon";

import ExpandableInput from "./custom_inputs/_ExpandableInput.vue";
import MultiSelectField from "./custom_inputs/_MultiSelectField.vue";

import CFUtils from "../components/utils.js"
import ApiService from "@/components/utils/api.service";

export default {
    components: {
        
        MultiSelectField,

        NumberInputField,
        DateSelectWidget,
        SelectInputField,
        ExpandableInput,

        HelpIcon,
    },
    model: {
        prop: "form_data",
        event: "change"
    },
    props:  {
        form_data: {
            type: Object,
            required: true,
        },
      hide_search_options: {
          type: Array,
          default: () => []
      },
      main_property_gps: {
            type: Array,
            default: () => []
      },
    },
    data: function () {
        return {
            price_zone_options: [
                { value: null, text: this.$t("price_zone:all_zones")}
            ],

            valuation_zone_model_options: [
                {value: null, text: "Vsi modeli"},
                {value: "STA", text: "STA"},
                {value: "HIS", text: "HIS"},
                {value: "GAR", text: "GAR"},
                {value: "PPP", text: "PPP"},
                {value: "PPL", text: "PPL"},
                {value: "SDP", text: "SDP"},
                {value: "IND", text: "IND"},
                {value: "INP", text: "INP"},
                {value: "KDS", text: "KDS"},
                {value: "TUR", text: "TUR"},
                {value: "KME", text: "KME"},
                {value: "GOZ", text: "GOZ"},
                {value: "STZ", text: "STZ"},
                {value: "DRZ", text: "DRZ"},
                {value: "PNB", text: "PNB"},
                {value: "PNE", text: "PNE"},
                {value: "PNP", text: "PNP"},
            ],
            sale_share_options: [
                {value: null, text: this.$t("analysis:ct:shares:whichever")},
                {value: 1, text: "1/1"},
                {value: -1, text: this.$t("analysis:ct:shares:other")},
            ],
            exclude_outliers_options: [
                {value: false, text: this.$t("analysis:ct:transactions:all")},
                {value: true, text: this.$t("analysis:ct:transactions:eliminated")},
            ],
            low_quality_options: [
                {value: null, text: this.$t("analysis:ct:transactions:all")},
                {value: false, text: this.$t("analysis:ct:transactions:eliminated")},
            ],
            consider_spatial_plan_area_options: [
                {value: null, text: this.$t("general:consider")},
                {value: true, text: this.$t("general:dont_consider")},
            ],
            transaction_volume__lte_options: [
                {value: null, text: this.$t("analysis:ct:transactions:all")},
                {value: 1, text: this.$t("analysis:ct:transactions:simple")},
                {value: 2, text: this.$t("analysis:ct:two_re")},
                {value: 5, text: this.$t("analysis:ct:to_five_re")},
                {value: 10, text: this.$t("analysis:ct:to_ten_re")},
            ],
            appartment_count_options: [
                {value: null, text: this.$t("analysis:ct:transactions:all")},
                {value: "<=1", text: this.$t("analysis:ct:one_apartment")},
                {value: "<=10", text: this.$t("analysis:ct:to_ten_apartments")},
                {value: "<=20", text: this.$t("analysis:ct:to_twenty_apartments")},
                {value: ">=20", text: this.$t("analysis:ct:over_twenty_apartments")},
            ],
            construction_phase__isnull_options: [
                {value: null, text: this.$t("analysis:ct:buildings:all")},
                {value: true, text: this.$t("analysis:ct:buildings:finished")},
                {value: false, text: this.$t("analysis:ct:buildings:unfinished")},
            ],
        }
    },
    methods: {
        get_price_zone_options: function(){
          if(!this.main_property_gps) {
            return;
          }

            return ApiService
                .get(`/amas/analysis/avm/api/price-zone-options`, { params: {
                    transaction_type: this.transaction_type,
                    gps_point: this.gps_point_for_api,
                }})
                .then(response => {
                    this.price_zone_options = response.data.avaliable_price_zones;
                    this.price_zone_options.unshift({ value: null, text: this.$t("price_zone:all_zones")});
                    return
                })
        },

        handleFieldUpdated: function(){
            this.$emit("change", this.form_data);
        },
        getDisplayValue: function(val, options) {
            if (!Array.isArray(val)) { val = [val] }
            let arr = [];
            options.forEach(opt => {
                if(val.includes(opt.value)) {arr.push(opt.text)}
            });
            let res = arr[0];
            if( arr.length > 1 ) {
                res += ` + ${arr.length - 1} ${this.$t("general:others")}`;
            } 
            return res;
        },
        handleMultiselectInput: function(values, field) {
            let old_values = this.form_data[field];
            if (values.includes(null) && old_values!=null && !old_values.includes(null)) {
                // Null value was selected
                values = null;
            } else if (values.length > 0) {
                // Remove null value
                values = values.filter(i => i != null);
            }
            if (Array.isArray(values) && values.length===0) {
                // Last element was removed
                values = null;
            }
            this.form_data[field] = values;
        },
        handleRealEstateTypeChange: function(_values) {
            this.form_data.valuation_zone__model = null;
        }
    },
    computed: {
        gps_point_for_api: function() {
          if(this.main_property_gps && this.main_property_gps.length > 0){
            return {
              'lat': this.main_property_gps[0],
              'lng': this.main_property_gps[1]
            }
          }

          return {
              'lat': -1,
              'lng': -1
          }
        },

        _isUnit: function() {
            return this.form_data.property_type=='unit';
        },
        _isParcel: function() {
            return this.form_data.property_type=='parcel';
        },

        // TRANSACTION TYPES
        transactionTypesJson: function() {
            return CFUtils.multiselectValueToJson(this.form_data.transaction_types, this.transaction_types_options)
        },
        transaction_types_options: function() {
            let res = this.$store.state.transactionTypeChoices["sale"].map(i => {
                return { value:i[0] , text: i[1] }
            });
            return [{value: null , text: `-- ${this.$t("general:all_types")} --`}].concat(res);
        },

        // REALESTATE TYPES
        realestateTypesJson: function() {
            let values = this.form_data.property_type=='unit' ? this.form_data.unit_types : this.form_data.parcel_types;
            return CFUtils.multiselectValueToJson(values, this.realestate_types_options)
        },
        realestate_types_options: function() {
            let choices = this.form_data.property_type=='unit' ? this.$store.state.unitTypeChoices : this.$store.state.parcelTypeChoices;
            let res = choices.map(i => {
                return { value:i[0] , text: i[1] }
            });
            return [{value: null , text: this.$t("general:all_types")}].concat(res);
        },

        priceZoneJson: function(){
          return CFUtils.multiselectValueToJson(this.form_data.price_zone__in, this.price_zone_options)
        },

        // BUILDING TYPES
        buildingTypesJson: function() {
            return CFUtils.multiselectValueToJson(this.form_data.building_types, this.building_types_options_sl)
        },
        building_types_options_sl: function() {
            return [
                { value: null, text: this.$t("general:all_types")},
                { value: "vinska klet, zidanica", text: this.$t("real_estate:building:type:cellar") },
                { value: "krajna", text: this.$t("real_estate:building:type:corner") },
                { value: "vmesna", text: this.$t("real_estate:building:type:intermediate") },
                { value: "samostoječa", text: this.$t("real_estate:building:type:detached") },
            ]
        },
        building_types_options_hr: function() {
            return [
                { value: null, text: this.$t("general:all_types")},
                { value: "Stan/apartman", text: this.$t("real_estate:building:type:hr:apartman") },
                { value: "Stambena zgrada - obiteljska kuća", text: this.$t("real_estate:building:type:hr:stambena_zgrada") },
                { value: "Poslovna zgrada", text: this.$t("real_estate:building:type:hr:poslovna_zgrada") },
                { value: "Gospodarska zgrada", text: this.$t("real_estate:building:type:hr:gospodarska") },
                { value: "Višestambena zgrada", text: this.$t("real_estate:building:type:hr:visestambena") },
                { value: "Stambena zgrada - kuća za povremeni boravak", text: this.$t("real_estate:building:type:hr:borvak") },
                { value: "Garaža", text: this.$t("real_estate:building:type:hr:garage") },
                { value: "Parkirno - garažno mjesto", text: this.$t("real_estate:building:type:hr:parking") },
                { value: "Vanjsko - parkirno mjesto", text: this.$t("real_estate:building:type:hr:parking_vanjsko") },
                { value: "Skladište", text: this.$t("real_estate:building:type:hr:storage") },
                { value: "Spremište", text: this.$t("real_estate:building:type:hr:spremiste") },
                { value: "Različite nekretnine", text: this.$t("real_estate:building:type:hr:razlicne_nekretnine") },
                { value: "Ostalo", text: this.$t("real_estate:building:type:hr:ostalo") },
                { value: "Ruševine", text: this.$t("real_estate:building:type:hr:rusevina") },
            ]
        },

        // VALUATION ZONE LEVELS
        parcelDedicatesUsagesJson: function() {
            return CFUtils.multiselectValueToJson(this.form_data.parcel_dedicated_usages, this.parcel_dedicated_usages_options)
        },
        parcel_dedicated_usages_options: function() {
            return [
                {value: null, text: "Vse rabe"},
                {value: 1, text: "območja stavbnih zemljišč"},
                {value: 2, text: "območja stanovanj"},
                {value: 3, text: "stanovanjske površine"},
                {value: 4, text: "stanovanjske površine za posebne namene"},
                {value: 5, text: "površine podeželskega naselja"},
                {value: 6, text: "površine počitniških hiš"},
                {value: 7, text: "območja centralnih dejavnosti"},
                {value: 8, text: "osrednja območja centralnih dejavnosti"},
                {value: 9, text: "druga območja centralnih dejavnosti"},
                {value: 10, text: "območja proizvodnih dejavnosti"},
                {value: 11, text: "površine za industrijo"},
                {value: 12, text: "gospodarske cone"},
                {value: 13, text: "površine z objekti za kmetijsko proizvodnjo"},
                {value: 14, text: "posebna območja"},
                {value: 15, text: "površine za turizem"},
                {value: 16, text: "površine drugih območij"},
                {value: 17, text: "športni centri"},
                {value: 18, text: "območja zelenih površin"},
                {value: 19, text: "površine za oddih, rekreacijo in šport"},
                {value: 20, text: "parki"},
                {value: 21, text: "površine za vrtičkarstvo"},
                {value: 22, text: "druge urejene zelene površine"},
                {value: 23, text: "pokopališča"},
                {value: 24, text: "območja prometne infrastrukture"},
                {value: 25, text: "površine cest"},
                {value: 26, text: "površine železnic"},
                {value: 27, text: "letališča"},
                {value: 28, text: "helioporti"},
                {value: 29, text: "pristanišča"},
                {value: 30, text: "ostale prometne površine"},
                {value: 31, text: "območja komunikacijske infrastrukture"},
                {value: 32, text: "območja energetske strukture"},
                {value: 33, text: "območja okoljske infrastrukture"},
                {value: 34, text: "območja za potrebe obrambe v naselju"},
                {value: 35, text: "površine razpršene poselitve"},
                {value: 36, text: "razpršena gradnja"},
                {value: 37, text: "območja kmetijskih zemljišč"},
                {value: 38, text: "najboljša kmetijska zemljišča"},
                {value: 39, text: "druga kmetijska zemljišča"},
                {value: 40, text: "območja gozdnih zemljišč"},
                {value: 41, text: "gozdna zemljišča"},
                {value: 42, text: "območja voda"},
                {value: 43, text: "območja površinskih voda"},
                {value: 44, text: "celinske vode"},
                {value: 45, text: "morje"},
                {value: 46, text: "območje vodne infrastrukture"},
                {value: 47, text: "območja drugih zemljišč"},
                {value: 48, text: "območja mineralnih snovi"},
                {value: 49, text: "površine nadzemnega pridobivalnega prostore"},
                {value: 50, text: "površine podzemnega pridobivalnega prostore s površinskim vplivom, ki omogoča drugo rabo"},
                {value: 51, text: "območje za potrebe varstva pred naravnimi in drugimi nesrečami"},
                {value: 52, text: "območja za potrebe obrambe zunaj naselij"},
                {value: 53, text: "ostala območja"},
            ]
        },

        // VALUATION ZONE LEVELS
        valuationZoneLevelsJson: function() {
            return CFUtils.multiselectValueToJson(this.form_data.valuation_zone__levels, this.valuation_zone__levels_options)
        },
        valuation_zone__levels_options: function() {
            let max_level = 25;
            let options = [{ value: null, text: this.$t("general:all_levels")}];
            options = options.concat([...Array(max_level).keys()].map(i => {return {
                value: i+1, text: String(i+1)
            }}));
            return options;
        },
    },
    mounted() {
        this.get_price_zone_options();
    },
    watch: {
      main_property_gps: function(newVal, oldVal){
        if(newVal){
          this.get_price_zone_options();
        }

      }
    },
}
</script>

<style>
.comparable-finder--form .label-wrapper > p{
    margin: 10px 0 0 0;
}
.comparable-finder--form .input-fields-wrapper {
    display: flex;
    flex-direction: row;
} .comparable-finder--form .input-fields-wrapper > div {
    width: 100%;
} .comparable-finder--form .input-fields-wrapper input.mx-input {
    height: 30px;
}
.comparable-finder--form--additional-fields div > span {
    margin-right: 10px;
}
</style>