<template>
    <gmap-map
        :center="center"
        :zoom="zoom"
        :mapTypeId="mapTypeId"
        :style="getStyle"
        ref="map"
        :options="options"
    >
        <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.coordinates"
            :clickable="true"
            :draggable="false"
        />
    </gmap-map>
</template>

<script>
import props from './KGoogleMap-props'

export default {
    props: props,
    computed: {
        getStyle: function(){
            return {
                "height": this.height + "px"
            }
        },
        googleMapObject: function(){
            return this.$refs.map.$mapObject;
        }
    },
}
</script>
