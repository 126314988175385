<template>
    <div class="area-selector-container" @mousemove="move" @mousedown="mouseDown" @mouseup="mouseUp">
        <transition name="screenshot">
            <div class="flash" v-if="tookScreenShot"></div>
        </transition>

        
        <div class="overlay" :class="{ 'highlighting' : mouseIsDown }" :style="{ borderWidth: borderWidth }"></div>

        <div class="crosshairs" :class="{ 'hidden' : isDragging }" :style="{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }"></div>
        
        <div class="borderedBox" :class="{ 'hidden': !isDragging }" :style="{ left: boxLeft + 'px', top: boxTop + 'px', width: boxEndWidth + 'px', height: boxEndHeight + 'px' }"></div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            mouseIsDown: false,
            isDragging: false, 
            tookScreenShot: false, // After the mouse is released
            
            // Used to calculate where to start showing the dragging area
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            
            borderWidth: "",
            
            // Handling the positioning of the crosshairs
            crossHairsLeft: 0,
            crossHairsTop: 0,
            
            // The box that contains the border and all required numbers.
            boxTop: 0,
            boxLeft: 0,
            boxEndWidth: 0,
            boxEndHeight: 0,
            
            windowHeight: 0,
            windowWidth: 0
        };
    },
    methods: {
        move: function (e) {
            
            this.crossHairsTop = e.clientY;
            this.crossHairsLeft = e.clientX;
            
            
            /* 
            * Change how the borderWidth is being calculated based on the x and y values.
            * Calculate the box with the 1px border's positioning and width
            */
            if (this.mouseIsDown) {
                this.isDragging = true;

                var endY = e.clientY,
                    endX = e.clientX,
                    startX = this.startX,
                    startY = this.startY,
                    windowWidth = this.windowWidth,
                    windowHeight = this.windowHeight;
                
                // Calculating the values differently depending on how the user start's dragging.
                if (endX >= startX && endY >= startY) {

                    this.borderWidth = startY + "px " + (windowWidth - endX) + "px " + (windowHeight - endY) + "px " + startX + "px";
                    
                    this.boxTop = startY;
                    this.boxLeft = startX;
                    this.boxEndWidth = endX - startX;
                    this.boxEndHeight = endY - startY;
                    
                
                } else if (endX <= startX && endY >= startY) {
                
                    this.borderWidth = startY + "px " + (windowWidth - startX) + "px " + (windowHeight - endY) + "px " + endX + "px";
                    
                    this.boxLeft = endX;
                    this.boxTop = startY;
                    this.boxEndWidth = startX - endX;
                    this.boxEndHeight = endY - startY;
                    
                } else if (endX >= startX && endY <= startY) {
                    this.borderWidth = endY + "px " + (windowWidth - endX) + "px " + (windowHeight - startY) + "px " + startX + "px";

                    this.boxLeft = startX;
                    this.boxTop = endY;
                    this.boxEndWidth = endX - startX;
                    this.boxEndHeight = startY - endY;
                    
                } else if (endX <= startX && endY <= startY) {
                
                    this.boxLeft = endX;
                    this.boxTop = endY;
                    this.boxEndWidth = startX - endX;
                    this.boxEndHeight = startY - endY;
                    
                    this.borderWidth = endY + "px " + (windowWidth - startX) + "px " + (windowHeight - startY) + "px " + endX + "px";
                    
                }
            }
            
        },
        
        mouseDown: function (e) {
            this.borderWidth = this.windowWidth + "px " + this.windowHeight + "px"; 
            
            this.startX = e.clientX;
            this.startY = e.clientY;
            
            this.mouseIsDown = true;
            this.tookScreenShot = false;
        },
        
        mouseUp: function (e) {
            let zoom_level = Math.round(window.devicePixelRatio * 100) / 100;
            this.borderWidth = 0;
            if (this.isDragging) {
                // Don't take the screenshot unless the mouse moved somehow.
                this.tookScreenShot = true;
                this.$emit("select-area", {
                    top: (this.boxTop + window.scrollY ) * zoom_level,
                    left: this.boxLeft * zoom_level,
                    width: this.boxEndWidth * zoom_level,
                    height: this.boxEndHeight * zoom_level
                });
            }
            this.isDragging = false;
            this.mouseIsDown = false;
        },
    },
    mounted() {
        this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        
        // To recalculate the width and height if the screen size changes.
        const app = this;
        window.onresize = function () {
            app.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            app.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        };
        
    },
};
</script>

<style scoped>
.area-selector-container {
    cursor: crosshair !important;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2147483644;
}
.overlay.highlighting {
    background: none;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
}

.crosshairs {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2147483645;
}
.crosshairs::before,
.crosshairs::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    border: none !important;
}
.crosshairs::before {
    left: -100%;
    top: -100%;
    border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.crosshairs::after {
    left: 0px;
    top: 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.borderedBox {
    border: 1px solid #fff;
    position: absolute;
}

.flash {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: #fff;
    z-index: 2147483646;

    opacity: 1;

    animation-delay: 0.2s;
    animation-name: fade-out;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
</style>