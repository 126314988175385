<template>
    <div>
        <div v-if="parcel">
            <table class="table table-condensed hr-search-map--parcel-details--table">
                <thead>
                    <tr>
                        <th class="text-center" colspan="2">
                            <re-open-modal :re_key="parcel.property_id" :start_analysis_button="1" />
                            
                            <a 
                                class="btn btn-xs btn-default btn-outline open-modal"
                                :href="getOpenPropertyModalUrl(parcel.property_id, 1)"
                            >
                                {{$t("general:open")}}
                            </a>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(val, key) in getRawData" :key="'td-'+key">
                        <td>
                            {{ key }}
                        </td>
                        <td>
                            {{ val }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="vs--loader-div text-center">
            <!-- Height is enforced so the popup is shown in it's entirety when opened (map is moved if required) -->
            <i class="fa fa-spinner fa-spin"></i>
        </div>

    </div>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";

export default {
    props: {
        parcel: {
            type: Object,
        },
    },
    components: {
        ReOpenModal,
    },
    computed: {
        getRawData() {
            if (!this.parcel) return;
            
            let keyMap = {
                "MATICNI_BROJ_KO": "Katastarska općina",
                "BROJ_CESTICE": "Broj ćestice",
                "OPISNA_ADRESA": "Adresa",
                "POVRSINA_ATRIBUTNA": "Površina (atributna)",
                "POVRSINA_GRAFICKA": "Površina (grafička)",
            }

            let inputObject = this.parcel.raw_data.properties;
            const filteredObject = Object.keys(inputObject).reduce((acc, key) => {
                if (inputObject[key] !== null && key in keyMap) {
                    let newKey = keyMap[key];
                    acc[newKey] = inputObject[key];
                }
                return acc;
            }, {});
            return filteredObject;

        }
    }
}
</script>

<style>
.vs--loader-div {
    height: 500px;
    padding-top: 30%;
}

table.hr-search-map--parcel-details--table tr td {
    padding: 0!important;
    padding-right: 10px!important;
}
</style>
