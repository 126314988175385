<template>
    <div class="text-center container-fluid p-0 vld-parent">

        <loading :active="deleting_image" :can-cancel="false" :is-full-page="false" />


        <div v-if="!session.is_completed">
            <a
                class="btn btn-info m-r-5" 
                type="button"
                @click="captureImage()"
                :disabled="image_capture_status=='in_progress'"
            >
                {{ $t("virtual_visit:capture_image") }}
                <i v-if="image_capture_status=='in_progress'" class="fa fa-spinner fa-spin"></i>
            </a>

            <a
                class="btn btn-default" 
                type="button"
                @click="switchClientCamera()"
            >
                {{ $t("virtual_visit:switch_camera") }}
            </a>
        </div>

        <div v-if="session.images.length" class="row m-0 m-t-5">
            <div v-for="(image, idx) in orderedImages" class="col-md-4 p-5" :key="'image_'+idx">
                <div class="vv-call--image-container">
                    <img
                        :src="image.image"
                    />
                    <i
                        class="fa fa-close text-danger delete-image" 
                        v-tooltip="'Izbriši sliko'"
                        @click="deleteImage(image.pk)"
                    />
                </div>
            </div>
        </div>

        <div v-if="config.VIRTUAL_VISIT_ADDITIONAL_INSTRUCTIONS" class="text-left">
            <ModeratorProcess />
        </div>

    </div>
</template>

<script>
import configmixin from '@/mixins/configmixin';
import ModeratorProcess from '../instructions/ModeratorProcess.vue';
import ApiService from '@/components/utils/api.service.js'

export default {
    mixins: [configmixin],
    model: {
        prop: 'session',
        event: 'change'
    },
    props: {
        session: {
            type: Object,
            required: true
        },
    },
    components: {
        ModeratorProcess
    },
    data: function () {
        return {
            step_no: 3,

            images: [],
            image_capture_status: null,
                // in_progress
                // failed
            deleting_image: false
        }
    },
    methods: {
        switchClientCamera: function() {
            const app = this;
            app.OTsession.signal({
                type: "camera",
                data: "camera_switch"
            }, (error) => {
                if (error) {
                    // pass
                }
            });
        },
        captureImage: function() {
            const app = this;

            // Check amount of current images
            let maxImageCount = app.config ? app.config.VIRTUAL_VISIT_MAX_IMAGE_COUNT : 30;
            if (maxImageCount && app.session.images.length >= maxImageCount) {
                alert("Maksimalno število slik je " + maxImageCount.toString());
                return;
            }

            app.image_capture_status = "in_progress";

            app.OTsession.signal({
                type: "textMessage",
                data: "captureImage"
            }, (error) => {
                if (error) {
                    // pass
                }
            });

            // timeout for when unsucessful image capture 
            setTimeout(function(){
                app.image_capture_status = null;
            }, 6000);
        },
        deleteImage: function(image_pk) {
            const app = this;
            app.deleting_image = true;

            ApiService
                .delete(`/virtual-visit/api/virtual-session-image/${image_pk}`)
                .then(response => {
                    if (response.status != 204) {
                        throw new Error("Failed to delete image");
                    }
                    app.session.images = app.session.images.filter(image => image.pk != image_pk);
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    app.deleting_image = false;
                });
        },
        setupOTSessionListeners: function() {
            const app = this;
            if (!app.OTsession) { return; }
            app.OTsession.on("signal", (event) => {
                if(event.type == "signal:imageUrl") {
                    app.image_capture_status = null;
                }
            });
        },
    },
    computed: {
        OTsession() {
            return this.$store.state.OTsession;
        },
        orderedImages() {
            // In reverse order, so that the newest images are shown first
            return this.session.images.slice().reverse();
        },
    },
    watch: {
        OTsession: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.setupOTSessionListeners();
                }
            },
        },
    },
    mounted() {
        this.$emit("finished", this.step_no);
    }
}
</script>


<style>
.vv-call--image-container {
    position: relative;
}
.vv-call--image-container img {
    width: 100%;
}
.vv-call--image-container i.delete-image {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);
    padding: 3px;
    border-radius: 10px;
}
</style>