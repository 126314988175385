<template>
    <div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Naziv košarice</th>
                        <th>Število poslov</th>
                        <th>Ustvarjena</th>
                        <th>Možnosti</th>
                    </tr>
                </thead>

                <tbody v-if="baskets_loading" class="text-center">
                    <tr>
                        <td colspan="4">
                            <p>Nalaganje</p>
                            <i class="fa fa-spinner fa-spin"></i>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr v-if="!baskets.length" class="text-center">
                        <td colspan="4">
                            <strong>Ni shranjenih košaric</strong>
                        </td>
                    </tr>
                    <tr 
                        v-for="basket in baskets"
                        v-bind:key="basket.id"
                    >
                        <td>
                            <KEditable 
                                v-model="basket.name"
                                @close="saveBasket(basket)">
                            </KEditable>
                        </td>
                        <td>
                            <span v-if="basket.transaction_ids">{{basket.transaction_ids.length}}</span>
                        </td>

                        <td>
                            {{ basket.created }}
                        </td>

                        <td>
                            <a class="btn btn-info btn-outline btn-sm"
                                data-toggle="tooltip" data-original-title="Odpri košarico"
                                :href="getBasketRedirectUrl(basket.id)">
                                    <i class="fa fa-search"></i> Prikaži
                            </a>

                            <a class="btn btn-link btn-sm text-danger"
                                data-toggle="tooltip" data-original-title="Izbriši košarico"
                                @click.prevent="askForDeleteBasket(basket.id)"
                                href="#">
                                    <i class="fa fa-remove"></i> Izbriši
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import KEditable from "@/components/k-editable/KEditable";
import ApiService from "@/components/utils/api.service.js";
import { Swal } from "@/components/utils/swal.alerts.js";

import Cookies from 'js-cookie';

export default {
    name: 'BasketList',
    props:  {
        basket_url: String,
        basket_list_url: String,
        basket_redirect_url: String,
    },
    components: {
        KEditable
    },
    data: function () {
        return {
            baskets: [],
            baskets_loading: false,
        }
    },
    methods: {
        getBasketUrl: function(basket_id) {
            return this.basket_url.replace("12345", basket_id);
        },
        getBasketRedirectUrl: function(basket_id) {
            return this.basket_redirect_url.replace("12345", basket_id);
        },

        getBaskets: function() {
            this.baskets_loading = true;
            ApiService
                .get(this.basket_list_url, {})
                .then(response => {
                    this.baskets = response.data;
                })
                .catch(error => {
                    Swal.fire(
                        "Pridobivanje košaric ni uspelo 12!", 
                        "Osvežite stran in poskusite ponovno. Če se napaka ponavlja obvestite skrbnika strani na podpora@arvio.si.", 
                        "error"
                    );
                    throw error;
                })
                .then(() => {
                    this.baskets_loading = false;
                })
        },

        askForDeleteBasket: function(basket_id) {
            const app = this;
            Swal({
                title: "Ali ste prepričani?",   
                text: "Košarica bo izbrisana",
                icon: "warning",   
                showCancelButton: true,   
                confirmButtonColor: "#DD6B55",   
                confirmButtonText: "Da, izbriši košarico!",
                cancelButtonText: "Ne, prekliči",
            }).then(async function(val){
                if (val.isConfirmed) {
                    app.deleteBasket(basket_id);
                } else {     
                    Swal("Preklicano", "", "error");
                } 
            });
        },
        deleteBasket: function(basket_id) {
            ApiService
                .delete(this.getBasketUrl(basket_id), {})
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    this.getBaskets();
                })
                .catch(error => {
                    Swal(
                        "Brisanje košarice ni uspelo!",
                        "Osvežite stran in poskusite ponovno. Če se napaka ponavlja obvestite skrbnika strani na podpora@arvio.si.", 
                        "error"
                    );
                    throw error;
                })
        },
        saveBasket: function(basket) {
            ApiService
                .put(
                    this.getBasketUrl(basket.id),
                    basket
                )
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    this.getBaskets();
                })
                .catch(error => {
                    Swal(
                        "Shranjevanje košarice ni uspelo!", 
                        "Osvežite stran in poskusite ponovno. Če se napaka ponavlja obvestite skrbnika strani na podpora@arvio.si.", 
                        "error"
                    );
                    throw error;
                })
        },
    },
    mounted() {
        this.getBaskets();
        ApiService.set_headers({
            "X-CSRFToken": Cookies.get("csrftoken")
        })
    },
}
</script>
