<template>
    <div>
        <h3 class="box-title">{{$t("dashboard:current_news")}}</h3>
        <div>
            <feed-item v-for="item in filteredArray"
                v-bind:key="item.id"
                v-bind:item="item"
                v-bind:moderationEnabled="moderationEnabled"
            />
        </div>
        <div class="clear"></div>
        <div class="text-center m-t-20">
            <div v-on:click="morePosts" class="btn btn-info btn-sm btn-outline"> {{$t("general:show_more")}}</div>
        </div>
    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service.js'
import FeedItem from './FeedItem.vue'

export default {
    name: "NewsFeed",
    components: {
        FeedItem,
    },
    props: {
        enableMoreOnBottom: {
            type: Boolean,
            default: true,
        },
        initialPerPage: {
            type: Number,
            default: 5
        }
    },
    data: function() {
        return {
            news: [],
            perPage: this.initialPerPage,
            moderationEnabled: false,
        }
    },
    methods: {
        getPosts() {
            ApiService
            .get("/market/analytics/api/news-list")
            .then(response => {
                this.news = response.data.results;
            })
            .catch(response => {
                console.error(response);
            });
        },
        morePosts() {
            if (!this.news) {
                this.getPosts();
            } else {
                this.perPage += 5;
            }
        },
        scrollLoad(){
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            if (this.enableMoreOnBottom && bottomOfWindow) {
                this.morePosts();
            }
        },
        fetchUserPermissions() {
            ApiService
            .get("/api/v1/current-user")
            .then(response => {
                this.moderationEnabled = response.data.all_permissions_list.includes('legal.legal_status_enabled');
            })
            .catch(error => {
                throw error;
            });
        },
    },
    mounted: function() {
        this.fetchUserPermissions();      
        this.getPosts();
    },
    created: function () {
        window.addEventListener('scroll', this.scrollLoad);
    },
    computed: {
        filteredArray() {
            let filtered = []
            if(this.news) {
                filtered =  this.news.slice(0, this.perPage)
            }
            const highlited = [0, 6, 7, 13];
            filtered.forEach((item, inx) => {
                const actualInx = inx % 17;
                if(highlited.includes(actualInx))
                    item.highlight = true;
            });
            return filtered;
        }
    }
}

</script>