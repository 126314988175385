<template>
    <div>
        <loading 
            :active="creating_project" 
            :can-cancel="false" 
            :is-full-page="true"
        />

        <valuation-subject-wizard
            v-model="valuation_subject"
            @finish="createProject"
            ref="wizard"
        />
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import ValuationSubjectWizard from "./ValuationSubjectWizard.vue"

export default {
    props: {
        re_key: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            valuation_subject: {
                "project": null,
                "session_list": [],
                "real_estates": [],
            },
            creating_project: false
        }
    },
    components: {
        Loading,
        ValuationSubjectWizard
    },
    methods: {
        createProject: function(valuation_subject) {
            this.creating_project = true;
            ApiService
                .post(`/amas/project/api/valuation-subject-wizard-create`, valuation_subject)
                .then(response => {
                    this.creating_project = false;
                    window.location.href = `/amas/project/${response.data.task_id}`;
                })
                .catch(error => {
                    this.creating_project = false;
                    Swal({   
                        title: this.$t("general:warning"),
                        text: this.$t("task_details:analysis_failed_please_try_again"),
                        icon: "warning",
                    })
                    throw error;
                })
        }
    },
    mounted() {
        const app = this;
        app.$refs["wizard"]
            .addRealEstateToSubject(app.re_key, "new")
            .then(_ => {
                this.$refs["wizard"].getValuationSubjectEstimation(app.re_key);
            })
    },
}
</script>
