<template>
        <modal
            :name="name"
            :scrollable="true"
            height="auto"
            style="z-index:1030;"
            @before-open="beforeOpen"
            @before-close="beforeClose"
            :width="width"
            :classes="classes"
        >
            <div class="modal-content">

                <div class="modal-header text-center">
                    <button type="button" class="close" @click="$modal.hide(name)">X</button>
                    <h2 class="m-0">
                        <template v-if="title">{{title}}</template>
                        <slot v-else name="header"></slot>
                    </h2>
                </div>

                <div class="modal-body">

                    <slot></slot>

                </div>
            </div>
        </modal>
</template>

<script>
export default {
    name: 'Bootstrap3Modal',
    props:  {
        name: String,
        title: String,
        width: String,
        classes: {
            type: Array,
            default: () => []
        },
    },
    data: function () {
        return {
        }
    },
    methods: {
        beforeOpen: function() {
            // This is for compatibility with jQuery bootstrap3 modals.
            document.body.classList.add("modal-open");
            document.body.style.overflow = "hidden";
        },
        beforeClose: function() {
            // This is for compatibility with jQuery bootstrap3 modals.
            // document.body.classList.remove("modal-open");
            // document.body.style.overflow = null;
            let ev = new Event('disableBodyScroll');
            document.dispatchEvent(ev);
        },
        // beforeOpen: function() {
        //     document.body.classList.add("modal-open");
        // },
        // beforeClose: function() {
        //     document.body.classList.remove("modal-open");
        // }
    },
}
</script>
