<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="comparable_transactions_sale"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/analysis/api/${this.session.id}/appraisal-comparable-recap`)
                .then(response => {
                    this.$store.commit("updateAppraisalRecap", {
                        session_id: this.session.id,
                        task_status: response.data
                    });
                    let data = response.data;
                    
                    
                    let url = `/amas/analysis/${this.session.id}/transaction/sale/search`;
                    
                    if (!this.disabled) {
                        let tooltip = this.$t("task_details:status:reliablity_level");
                        return `
                                <a href="${url}">
                                    <span
                                        class="circle circle-sm bg-${data.transactions_confidence_score_css}"
                                        data-toggle="tooltip" data-original-title="${tooltip}"
                                    >
                                        ${data.transactions_confidence_score}
                                    </span>
                                </a>
                                `
                    }
                    else {
                        let tooltip = this.$t("comparable:enekretnine:tooltip");
                        return `
                                <a>
                                    <span
                                        class="disabled circle circle-sm bg-info"
                                        data-toggle="tooltip" data-original-title="${tooltip}"
                                    >
                                    ?
                                    </span>
                                </a>
                                `
                    }
                })
        }
    },
}
</script>