<template>
    <tbody>
        <tr class="section-title-row">
            <td>
                {{$t("task_details:sizes_types:areas")}}
            </td>
            <td
                v-for="(session, idx) in subject.session_list"
                :key="'session-type-and-size-header-td-'+idx"
                class="text-center"
            >
                <div class="row">
                    <div class="col-xs-6 text-right">
                        <div class="right-block">{{$t("task_details:sizes_types:building")}}</div>
                    </div>
                    <div class="col-xs-6 text-left">
                        <div class="left-block">{{$t("task_details:sizes_types:parcels")}}</div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:sizes_types:dominant_type")}}
                <help-icon slug="predmet-vrednotenja-prevladujoc-tip-nepremicnine" />
            </td>

            <td
                v-for="(session, idx) in subject.session_list"
                :key="'session-main-types-td-'+idx"
            >
                <div class="row">
                    
                    <!-- Main type - Units -->
                    <div class="col-xs-6">
                        <div class="input-wrapper-div right-block text-right">
                            <type-dropwdown
                                v-if="editable && sessionRealEstates(session, false).length > 0"
                                v-model="session.custom_main_unit_type"
                                :default-display-if-null="_getDefaultMainTypeDisplay(session, false)"
                                :isParcel="false"
                            />
                            <div v-else-if="sessionRealEstates(session, false).length > 0" class="text-right text-primary text-semibold">
                                {{_getMainTypeDisplay(session, false)}}
                            </div>
                            <div v-else class="text-right">
                                -
                            </div>
                        </div>
                    </div>

                    <!-- Main type - Parcels -->
                    <div class="col-xs-6">
                        <div class="input-wrapper-div left-block text-left">
                            <type-dropwdown
                                v-if="editable && sessionRealEstates(session, true).length > 0"
                                v-model="session.custom_main_parcel_type"
                                :default-display-if-null="_getDefaultMainTypeDisplay(session, true)"
                                :isParcel="true"
                            />
                            <div v-else-if="sessionRealEstates(session, true).length > 0" class="text-left text-primary text-semibold">
                                {{_getMainTypeDisplay(session, true)}}
                            </div>
                            <div v-else class="text-left">
                                -
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:sizes_types:revised_area")}}
                <help-icon slug="korigirana-povrsina-splosno" />
            </td>

            <td
                v-for="(session, idx) in subject.session_list"
                :key="'session-sum-sizes-td-'+idx"
            >
                <div class="row">

                    <!-- Sum sizes - Units -->
                    <div class="col-xs-6">
                        <div class="input-wrapper-div right-block">
                            <number-input-field
                                v-if="editable && sessionRealEstates(session, false).length > 0"
                                v-model="session.custom_total_unit_size"
                                :placeholder="_getSizeDisplay(session, false)"
                                inputFieldsAddon="m2"
                                size="sm"
                            />
                            <div v-else-if="sessionRealEstates(session, false).length > 0" class="text-right text-info text-semibold" style="font-size: 15px; text-color: #898989">
                                {{_getSizeDisplay(session, false)|formatSize(2)}}
                            </div>
                            <div v-else class="text-right">
                                -
                            </div>
                        </div>
                    </div>

                    <!-- Sum sizes - Parcels -->
                    <div class="col-xs-6">
                        <div class="input-wrapper-div left-block">
                            <number-input-field
                                v-if="editable && sessionRealEstates(session, true).length > 0"
                                v-model="session.custom_total_parcel_size"
                                :placeholder="_getSizeDisplay(session, true)"
                                inputFieldsAddon="m2"
                                size="sm"
                            />
                            <div v-else-if="sessionRealEstates(session, true).length > 0" class="text-left text-info text-semibold" style="font-size: 15px; text-color: #898989">
                                {{_getSizeDisplay(session, true)|formatSize(2)}}
                            </div>
                            <div v-else class="text-left">
                                -
                            </div>
                        </div>
                    </div>

                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import HelpIcon from "@/components/widgets/HelpIcon";
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";

import RealEstateTypeDropDown from "../components/RealEstateTypeDropDown.vue";
import VSUtils from "../components/utils.js"

export default {
    model: {
        prop: "subject",
        event: "change"
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    components: {
        HelpIcon,
        NumberInputField,
        "type-dropwdown": RealEstateTypeDropDown,
    },
    data () {
        return {
            VSUtils: VSUtils,
        }
    },
    methods: {
        _getMainTypeDisplay: function(session, is_parcel) {
            let main_type_display;
            let custom_main_type = is_parcel ? session.custom_main_parcel_type : session.custom_main_unit_type;
            if (custom_main_type) {
                main_type_display = VSUtils._mapRealEstateTypeIdToDisplay(custom_main_type, is_parcel);
            } else {
                main_type_display = this._getDefaultMainTypeDisplay(session, is_parcel);
            }
            return main_type_display;
        },
        _getDefaultMainTypeDisplay: function(session, is_parcel) {
            let real_estates = this.sessionRealEstates(session, is_parcel);
            return VSUtils.getDefaultMainType(real_estates, is_parcel).display;
        },
        _getSumSizes: function(session, is_parcel) {
            let real_estates = this.sessionRealEstates(session, is_parcel);
            return VSUtils.getSumRealEstateSizes(real_estates);
        },
        _getSizeDisplay: function(session, is_parcel) {
            let size_display;
            if(is_parcel) {
                size_display = session.custom_total_parcel_size ? session.custom_total_parcel_size : this._getSumSizes(session, is_parcel);
            } else {
                size_display = session.custom_total_unit_size ? session.custom_total_unit_size : this._getSumSizes(session, is_parcel);
            }
            return parseFloat(size_display).toFixed(2);
        },
        sessionRealEstates: function(session, is_parcel) {
            let real_estates = VSUtils.getSessionRealEstates(this.subject.real_estates, session);
            if(is_parcel) {
                real_estates = VSUtils.getParcelRealEstates(real_estates);
            } else {
                real_estates = VSUtils.getUnitRealEstates(real_estates);
            }
            return real_estates;
        }
    },
}
</script>

<style>
.input-wrapper-div {
    max-width: 300px;
}
.input-wrapper-div input:focus::placeholder {
  color: transparent;
}

.left-block { 
  display: block;
  margin-left: 0;
  margin-right: auto;
}
.right-block {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
</style>
