<template>
    <date-picker
        :value="value"
        @input="$emit('input', $event)"
        format="DD.MM.YYYY"
        value-type="timestamp"
        class="arvio-datepicker"
    ></date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/sl';

export default {
    components: {
        DatePicker
    },
    props: {
        value: {
            type: Number,
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
}
</script>

<style>
.arvio-datepicker {
    width: 100%;
}
.arvio-datepicker .mx-input {
    font-size: 12px;
}

/* Don't hide icons on hover */
.arvio-datepicker:hover .mx-icon-clear {
    display: none;
}
.arvio-datepicker .mx-input-wrapper:hover .mx-icon-clear+.mx-icon-calendar {
    display: block;
}
</style>