export default {
    multiselectValueToJson: function(values, options) {
        /*
        Maps array value to array of objects, for purpose of MultiSelect's internal workings.
        [1, 2, 3] => [{value: 1, text: "Ena"}, {value: 2, text: "Dva"}]
        */
        if (values==null) { values = [null] }

        let mapped = options.map(item => ({ [item.value]: item.text }) );
        let res = Object.assign({}, ...mapped );
        return values.map(val => {
            return {
                value: val,
                text: res[val],
            }
        });
    },
}
