<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="region_analytics_dashboard"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            return ApiService
                .get(`/amas/analysis/analysis/api/${this.session.id}/analytics-region`)
                .then(response => {
                    let url = `/amas/analysis/${this.session.id}/analytics-dashboard`;
                    return `
                        <a href="${url}">
                            <span class="circle circle-sm bg-success" data-toggle="tooltip" data-original-title="Na voljo" href="/abcd">
                                <i class="fa fa-check"></i>
                            </span>
                        </a>
                    `
                })
        }
    },
}
</script>