<template>
    <marker-map
        :height="height"
        :markers="markers"
        :center="center"
        :zoom="zoom"
        :maxClusterRadius="maxClusterRadius"
        :loadingMarkers="loadingMarkers"
        :mainMarker="mainMarker"
        @marker-clicked="handleMarkerClicked"
        @update:bounds="$emit('update:bounds', $event)"
        ref="map"
    >
        <!-- Marker extra slots - popup and icon -->
        <template v-slot:marker="slotProps">
            <l-icon
                class-name="propertyiq-iq__marker-icon"
                :icon-anchor="[10, 0]"
                :popup-anchor="[0, 0]"
            >
                <div
                    class="propertyiq-iq__marker-icon-content"
                    :class="{
                        'clicked': clickedMarkerData && slotProps.marker.id==clickedMarkerData.id,
                        'comparable': comparableTransactionItemsIds.includes(slotProps.marker.id)
                    }"
                >
                    <slot name="marker-icon-content" v-bind:marker="slotProps.marker">
                        <strong>{{slotProps.marker.idx}}</strong>
                    </slot>
                </div>
            </l-icon>

            <l-popup
                :options="{autoPan: false}"
            >
                <transaction-details
                    :data="clickedMarkerData"
                    :isSelected="clickedMarkerData && comparableTransactionItemsIds.includes(clickedMarkerData.id)"
                    @comparable-clicked="$emit('comparable-clicked', $event)"
                />
            </l-popup>
        </template>

    </marker-map>
</template>

<script>
import { LPopup, LIcon, LControl } from 'vue2-leaflet';

import PropertyIQStadiaMarkerMap from "../../components/StadiaMarkerMap.vue"
import TransactionDetails from "./TransactionDetails.vue";

export default {
    name: "PropertyIQTransactionMap",
    components: {
        LPopup, LIcon, LControl,
        
        "transaction-details": TransactionDetails,
        "marker-map": PropertyIQStadiaMarkerMap,
    },
    props: {
        height: {
            type: String,
            default: '800px'
        },
        markers: {
            type: Array,
            default: () => []
        },
        mainMarker: {
            type: Object,
        },
        clickedMarkerData: {
            type: Object,
        },
        comparableTransactionItemsIds: {
            type: Array,
            default: () => []
        },
        loadingMarkers: {
            type: Boolean,
            default: false
        },
        center: {
            type: [Object, Array],
            default: () => [46.05108, 14.50513]
        },
        zoom: {
            type: Number
        },
        maxClusterRadius: {
            type: Number
        }
    },
    computed: {
        mapObject: function() {
            return this.$refs.map.mapObject; 
        }
    },
    methods: {
        handleMarkerClicked: function(event) {
            this.mapObject.panTo(event.gps);
            this.$emit('marker-clicked', event);
        }
    }
}
</script>
