<template>
    <a
        v-tooltip="added ? $t('task_details:valuation:in_subject') : $t('task_details:valuation:add_to_subject')"
        :class="textClass"
        @click.prevent="handleClick"
    > 
        <i :class="added ? 'fa fa-check' : 'fa fa-plus'"/>
    </a>
</template>

<script>
export default {
    props: {
        re_key: {
            type: String,
        },
        added: {
            type: Boolean,
        }
    },
    methods: {
        handleClick: function() {
            if(!this.added) {
                this.$emit('add-real-estates', this.re_key)
            }
        }
    },
    computed: {
        textClass: function() {
            return ['custom-cursor', this.added ? 'text-success' : 'text-info']
        }
    }
}
</script>
