<template>
    <div>
        <!-- START Maps -->
        <div class="row">
            <div class="col-md-12">
                <h3>{{ $t("property_iq:tabs:microlocation") }}</h3>
            </div>

            <div class="col-lg-6 col-md-12">
                <h5>{{ $t('property_iq:maps:sewage')}}</h5>
                <communal-equipment-map
                    :polygons="parcelsPolygons"
                    :realEstate="realEstate"
                />
            </div>

            <div class="col-lg-6 col-md-12">
                <h5>{{ $t('property_iq:maps:parcel_border')}}</h5>
                <parcel-borders-map
                    :polygons="parcelsPolygons"
                    :realEstate="realEstate"
                />
            </div>
        </div>
        <!-- END Maps -->

        <hr class="m-t-40 m-b-20" />

        <!-- START Maps -->
        <div class="row m-t-20">
            <div class="col-md-12">
                <h3>{{ $t("general:in_vicinity") }}</h3>
                <google-places-results
                    :gps="realEstate.gps"
                    :showRefreshButton="false"
                />
            </div>
        </div>
        <!-- END Maps -->
    </div>
</template>

<script>
import ApiService from '@/components/utils/api.service.js'

import GooglePlacesResults from '@/components/google-places/GooglePlacesResults.vue'
import CommunalEquipmentMap from "./maps/CommunalEquipmentMap.vue";
import ParcelBordersMap from "./maps/ParcelBordersMap.vue";


export default {
    name: "MicrolocationTab",
    components: {
        CommunalEquipmentMap,
        ParcelBordersMap,
        GooglePlacesResults,
    },
    props: {
        realEstate: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            parcelsPolygons: [],
        }
    },
    methods: {
        getParcelGeometry: function(re_key) {
            ApiService
                .get(`/amas/real-estate/api/real-estate/${re_key}/geometry`)
                .then(response => {
                    const poly = response.data.geom_swapped;
                    poly.property_id = response.data.property_id;
                    this.parcelsPolygons.push(poly);
                })
                .catch(error => {
                    throw error;
                })
        },
    },
    watch: {
        "realEstate.unit.building.on_parcels": function (newVal) {
            if (newVal) {
                this.parcelsPolygons = [];
                newVal.forEach(re_key => {
                    this.getParcelGeometry(re_key)
                });
            }
        },
    }
}
</script>
