<template>
    <div>
        <div class="energy-class-section">
            <div :class="isEnergySelected('A1')">A1</div>
            <div :class="isEnergySelected('A2')">A2</div>
            <div :class="isEnergySelected('B1')">B1</div>
            <div :class="isEnergySelected('B2')">B2</div>
            <div :class="isEnergySelected('C')">C</div>
            <div :class="isEnergySelected('D')">D</div>
            <div :class="isEnergySelected('E')">E</div>
            <div :class="isEnergySelected('F')">F</div>
        </div>
    
        <div class="row m-t-20">
            <div class="col-md-6">
                <div><b>Potrebna toplota za ogrevanje</b></div>
                <div>{{ heatingEnergy }} kWh</div>
            </div>
            <div class="col-md-6">
                <div><b>Dovedena energija</b></div>
                <div>{{ installedEnergy }} kWh</div>
            </div>
        </div>

        <div class="row m-t-20">
            <div class="col-md-6">
                <div><b>Primarna energija</b></div>
                <div>{{ primaryEnergy }} kWh</div>
            </div>
            <div class="col-md-6">
                <div><b>CO₂ emisije</b></div>
                <div>{{ co2Emissions }} kg/m²</div>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "EnergyMeter",
    props: {
        energyClass: {
            type: String,
            required: true,
        },
        heatingEnergy: {
            type: Number,
            required: true,
        },
        installedEnergy: {
            type: Number,
            required: true,
        },
        primaryEnergy: {
            type: Number,
            required: true,
        },
        co2Emissions: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            default: "Original",
        },
    },
    methods: {
        isEnergySelected(energyClass) {
            return {
                "energy-selected": this.energyClass === energyClass,
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.energy-class-section {
    display: flex;
    flex-direction: row;

    margin-top: 20px;

    & > div {
        flex: 1;
        text-align: center;
        color: white;
        background-color: rgb(172, 64, 57);
        padding: 3px 8px;

        &:nth-child(1) {
            background-color: rgb(106, 158, 101);
        }
        &:nth-child(2) {
            background-color: rgb(145, 179, 105);
        }
        &:nth-child(3) {
            background-color: rgb(200, 211, 111);
        }
        &:nth-child(4) {
            background-color: rgb(232, 222, 107);
        }
        &:nth-child(5) {
            background-color: rgb(221, 186, 96);
        }
        &:nth-child(6) {
            background-color: rgb(189, 120, 71);
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .energy-selected {
        outline: 2px solid #2cabe3 !important;
        outline-offset: -2px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: -12px;
            left: 50%;
            width: 0;
            height: 0;
            border: solid transparent;
            border-top-color: #2cabe3;
            border-width: 10px;
            margin-left: -10px;
        }
    }
}
</style>
