var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-map-wrapper"},[_c('l-map',{ref:"map",style:({
          height: _vm.height,
          minHeight: '200px'
        }),attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":{
            attributionControl: false
        }},on:{"update:bounds":function($event){return _vm.$emit('update:bounds', $event)}}},[_c('l-control-attribution',{attrs:{"prefix":"<a href=\"https://www.arvio.si/\" target=\"_blank\">Arvio</a>","position":"bottomright"}}),_c('l-control-layers',{attrs:{"position":"topright"}}),_vm._l((_vm.tileLayers),function(tilelayer,index){return _c('l-tile-layer',{key:index,attrs:{"name":tilelayer.name,"url":tilelayer.url,"attribution":tilelayer.attribution,"visible":tilelayer.visible,"layer-type":"base"}})}),(_vm.loadingMarkers)?_c('l-control',{attrs:{"position":"bottomright"}},[_c('div',[_vm._v(_vm._s(_vm.$t('general:loading'))+" ...")])]):_vm._e(),_c('l-control',{staticClass:"details-window-control-wrapper",attrs:{"position":"bottomleft","disable-scroll-propagation":true}},[_c('div',{staticStyle:{"background":"white","max-height":"400px","overflow":"auto"}},[_vm._t("bottom-control-content")],2)]),_c('marker-cluster',{attrs:{"options":{
                showCoverageOnHover: false,
                removeOutsideVisibleBounds: true,
                maxClusterRadius: _vm.maxClusterRadius,
            }},on:{"clusterclick":function($event){return _vm.$emit('clusterclick', $event)}}},_vm._l((_vm.filteredMarkers),function(marker,idx){return _c('l-marker',{key:'marker_'+idx,attrs:{"lat-lng":marker.gps},on:{"click":function($event){return _vm.$emit('marker-clicked', marker)}}},[_vm._t("marker",null,{"marker":marker})],2)}),1),(_vm.mainMarker)?_c('l-marker',{attrs:{"lat-lng":_vm.mainMarker.coordinates,"icon":_vm.house_icon}},[_c('l-popup',{attrs:{"options":{autoPan: true}}},[_c('div',{staticClass:"p-10"},[_vm._v(_vm._s(_vm.$t('comparable:selected:real_estate')))])])],1):_vm._e(),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }