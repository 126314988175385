<template>
    <div>
        <div v-if="real_estate.unit" class="row">

            <div class="col-md-6">
                <h4>{{ $t('real_estate:unit:info')}}</h4>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tbody>
                            <tr v-if="real_estate.is_deleted" class="danger">
                                <td colspan="2">{% re_is_deleted_block real_estate %}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:address')}}</td>
                                <td>
                                    <span v-if="real_estate.unit.address">{{real_estate.unit.address.full_address||'/'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:cadastral_registration_status')}}</td>
                                <td>{{real_estate.unit.get_cadastral_registration_value_display||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:actual_use')}}</td>
                                <td>{{real_estate.unit.unit_type||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:net_size')}}</td>
                                <td>{{real_estate.unit.floor_size||'/'|formatSize(2)}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('analysis:ct:revised_size')}}</td>
                                <td>{{real_estate.unit.revised_size||'/'|formatSize(2)}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:gross_size')}}</td>
                                <td>{{real_estate.unit.net_unit_size||'/'|formatSize(2)}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:story_numbers')}}</td>
                                <td>{{real_estate.unit.all_floor_numbers||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:main_entrance_floor_no')}}</td>
                                <td>{{real_estate.unit.main_entrance_floor_no||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:position')}}</td>
                                <td>{{real_estate.unit.position||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:floor')}}</td>
                                <td>{{real_estate.unit.floor_no||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:windows_remodeled')}}</td>
                                <td>{{real_estate.unit.windows_year_remodeled||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:installation_remodeled')}}</td>
                                <td>{{real_estate.unit.installation_year_remodeled||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:unit:elevator')}}</td>
                                <td>{{real_estate.unit.has_elevator|yesnoicon}}</td>
                            </tr>
                            
                            <!-- <tr>
                                <td>Število sob</td>
                                <td>{{real_estate.unit.total_rooms||'/'}}</td>
                            </tr> -->
                            
                            <tr>
                                <td>{{ $t('general:room2')}}</td>
                                <td>
                                    {{ real_estate.unit.list_rooms.join(', ') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="col-md-6">
                <h4>{{ $t('real_estate:building:info')}}</h4>
                <div class="table-responsive" v-if="real_estate.unit.building">
                    <table class="table table-striped">
                        <tbody>
                            <tr v-if="real_estate.unit.building.is_deleted " class="danger">
                                <td colspan="2">{% re_is_deleted_block real_estate.unit.building %}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:address')}}</td>
                                <td>
                                    <span v-if="real_estate.unit.address">{{real_estate.unit.address.full_address||'/'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:story_no')}}</td>
                                <td>{{real_estate.unit.building.total_floors||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:story_no:first')}}</td>
                                <td>{{real_estate.unit.building.bottom_floor_no||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:apartment_no')}}</td>
                                <td>{{real_estate.unit.building.total_appartments||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:office_no')}}</td>
                                <td>{{real_estate.unit.building.total_business_units||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('ee:fields:building_type')}}</td>
                                <td>{{real_estate.unit.building.building_type||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('ee:fields:year_build')}}</td>
                                <td>{{real_estate.unit.building.year_built||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('ee:fields:year_roof')}}</td>
                                <td>{{real_estate.unit.building.roof_year_remodeled||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('ee:fields:year_facade')}}</td>
                                <td>{{real_estate.unit.building.facade_year_remodeled||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:construction')}}</td>
                                <td>{{real_estate.unit.building.construction_material||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:water_pipes')}}</td>
                                <td>{{real_estate.unit.building.has_water_supply|yesnoicon}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:electricity')}}</td>
                                <td>{{real_estate.unit.building.has_electricity|yesnoicon}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:sewage')}}</td>
                                <td>{{real_estate.unit.building.has_sewage|yesnoicon}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:gas')}}</td>
                                <td>{{real_estate.unit.building.has_gas|yesnoicon}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:gross_size')}}</td>
                                <td>{{real_estate.unit.building.gross_size||'/'|formatSize(0)}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:height')}}</td>
                                <td>{{real_estate.unit.building.get_height||'/'}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:building:on_parcel')}}</td>
                                <td>
                                    <div v-for="(re_key, index) in real_estate.unit.building.on_parcels" :key="index">
                                        <span class="re-key" data-toggle="tooltip" :data-original-title=" $t('widgets:modal:real_estate_details')" rel="tooltip">
                                            <a class="open-modal"
                                                :href="'/amas/real_estate/'+re_key+'/details/1/amas'"
                                            >
                                                {{re_key}}
                                            </a>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

        <div v-else-if="real_estate.parcel" class="row">
            <div class="col-md-12">
                <h4>{{ $t('real_estate:parcel:about')}}</h4>
                <div class="col-md-6">
                    <table class="table table-striped">
                        <tbody>
                            <tr v-if="real_estate.is_deleted" class="danger">
                                <td colspan="2">{% re_is_deleted_block real_estate %}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:type')}}</td>
                                <td>{{real_estate.parcel.parcel_type}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:muncipality')}}</td>
                                <td>{{real_estate.parcel.municipality}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:muncipality:kataster')}}</td>
                                <td>{{real_estate.parcel.cadastral_municipality}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:muncipality:kataster:id')}}</td>
                                <td>{{real_estate.parcel.cadastral_municipality_id}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:id')}}</td>
                                <td>{{real_estate.parcel.parcel_number}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:area')}}</td>
                                <td>{{real_estate.parcel.size|formatSize(0)}}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('real_estate:parcel:land_quality')}}</td>
                                <td>{{real_estate.parcel.land_quality||'/'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-6">
                    <table class="table table-striped">
                        <tbody>

                            <tr v-if="real_estate.parcel.dedicated_usages">
                                <td>{{ $t('real_estate:parcel:usage')}}</td>
                                <td>
                                    <ul class="custom-unstyled-list">
                                        <li v-for="(dedicated_usage, idx) in real_estate.parcel.dedicated_usages" :key="'dedicated_usage_'+idx">
                                            {{dedicated_usage.inline_display }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr v-if="real_estate.parcel.actual_usages">
                                <td>{{ $t('real_estate:parcel:usage:actual')}}</td>
                                <td>
                                    <ul class="custom-unstyled-list">
                                        <li v-for="(actual_usage, idx) in real_estate.parcel.actual_usages" :key="'actual_usage_'+idx">
                                            {{actual_usage.inline_display}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            <tr v-if="real_estate.parcel.units">
                                <td>{{ $t('real_estate:parcel:building:parts')}}</td>
                                <td>
                                    <div v-for="(unit, idx) in real_estate.parcel.units" :key="'parcel_unit_'+idx">
                                        <span class="re-key" data-toggle="tooltip" data-original-title="Podrobnosti o nepremičnini" rel="tooltip">
                                            <a class="open-modal"
                                                :href="'/amas/real_estate/'+unit.property_id+'/details/1/amas'"
                                            >
                                                {{unit.property_id}}
                                            </a>
                                        </span>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
</div>
</template>

<script>
export default {
    props: {
        real_estate: {
            type: Object,
            required: true
        }
    },
}
</script>
