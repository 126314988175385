<template>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <div class="text-center">
        <p>
          {{ $t('avm:weight:edit')}}:
        </p>
      </div>
      <form method="post" novalidate>
        <div class="text-center">
          <div id="div_id_value" class="form-group" >
            <label for="id_value" class="control-label">
              {{ $t('avm:weight:weight')}}
            </label>
            <div class="controls">
              <input type="text" v-model="transaction.weight_raw" maxlength="255" class="textinput textInput form-control" id="id_value" :disabled="disabled"/>
            </div>
          </div>
        
          <div class="pull-right p-20">
            <button type="submit" class="btn btn-info" v-on:click.prevent="updateTransactionWeight()" >
              {{ $t('avm:weight:adjust')}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";


export default {
  props: {
    transaction: Object,
    disabled: {
            required: false,
            default: false
    },
  },

  computed: {


  },
  methods: {
      
      updateTransactionWeight(){
          let url = this.url + this.transaction.id;
          ApiService
          .put(url, this.transaction)
          .then(response => {
            this.$emit("update-weight-complete");
            })
          .catch(error => {
              Swal({
                    title: "",
                    text: this.$t('avm:error:msg'),
                    icon: "error",
                    })
              throw error;
          });
        },

  },
  data() {
    return {
      url: '/amas/analysis/avm/api/transaction-weight/',

    };
  },
};
</script>
