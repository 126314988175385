<template>
    <div>
        <!-- R dropdown -->
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <b-form-select
                    v-model="selected"
                    :options="choices"
                    value-field="id"
                    text-field="report_name"
                    class="form-control"
                >
                </b-form-select>
            </div>
        </div>
        
        <div class="text-center m-t-20">
            <button
                class="btn btn-info m-r-10"
                v-tooltip="$t('property_iq:customization:edit')"
                @click="onCustomizationClick"
            >
                {{ $t('user_profile:export_settings') }}
            </button>

            <button
                class="btn btn-info"
                @click="onSubmit"
            >
                {{ $t('general:download:btn')}}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        choices: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        onCustomizationClick() {
            window.open(`/profile/edit/export?callback_url=${window.location.href}`, "_blank");
        },
        onSubmit() {
            if (this.selected)
                this.$emit('submit', this.selected);
        },
    },
    mounted() {
        if (this.choices.length > 0) {
            // tries to select a default report which is named "PropertyIQ ... PDF"
            const defaultReport = this.choices.find(choice => /PropertyIQ.*PDF/.test(choice.report_name));
            if (defaultReport) {
                this.selected = defaultReport.id;
            } else {
                this.selected = this.choices[0].id;
            }
        }
    },
}
</script>
