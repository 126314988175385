<template>
    <div>
        <div :class="{
            'input-group': prependIcon,
            'invalid-input': showErrorMessage,
        }">
            <div v-if="prependIcon" class="input-group-addon" @click="$emit('icon-click')">
                <i :class="prependIcon"></i>
            </div>
            <slot></slot>
        </div>
        <div v-if="showErrorMessage" class="input-error-message">
            <i class="ti ti-pencil"></i> {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import baseinputmixin from "../mixins/baseinputmixin";

export default {
    name: 'BaseInput',
    mixins: [ baseinputmixin ],
}
</script>
