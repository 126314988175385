import Vue from "vue";
import Utils from "./utils";

Vue.filter("capitalize", function (s) {
    if (typeof s !== 'string') return "";
    return s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1);
});

Vue.filter("lower", function (s) {
    if (typeof s !== 'string') return "";
    return s.toLowerCase();
});

Vue.filter("formatNumber", function (...args) {
    return Utils.formatNumber(...args)
});

Vue.filter("formatSize", function (...args) {
    return Utils.formatSize(...args)
});

Vue.filter("formatMeter", function (...args) {
    return Utils.formatMeter(...args)
});

Vue.filter("formatCurrency", function (...args) {
    return Utils.formatCurrency(...args)
});

Vue.filter("formatCurrencym2", function (...args) {
    return Utils.formatCurrencym2(...args)
});

Vue.filter("formatThousands", function (...args) {
    return Utils.formatThousands(...args)
});

Vue.filter("formatPercent", function (...args) {
    return Utils.formatPercent(...args)
});
Vue.filter("yesnoicon", function (value) {
    let res = "-"
    if(value == true || value == 1 || value == "1"){
        res = "Da"
    } else if(value == false || value == 0 || value == "0"){
        res = "Ne"
    }
    return res;
});