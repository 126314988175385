<template>
    <order
        :orderId="orderId"
        :readOnly="true"
        :showSubmitOfferOptions="false"
        :showOrderItemsList="true"
        :customOrderItemsTableColumns="customOrderItemsTableColumns"
        :showAppraisers="true"
        :showProcurementForTeamSelection="false"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
    props: {
        orderId: {
            type: [ String, Number ],
            required: true
        },
    },
    data() {
        return {
            customOrderItemsTableColumns: [
                // Define which columns to Hide in orderItems table
                {
                    field: "appraiser",
                    hidden: true,
                },
                {
                    field: "kpi_avm_value_difference",
                    hidden: true,
                },
                {
                    field: "kpi_legal_status",
                    hidden: true,
                },
                {
                    field: "kpi_public_road_access",
                    hidden: true,
                },
            ],
        }
    }
}
</script>
