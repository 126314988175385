<template>
    <div>
        <table class="table table-condensed">
            <thead>
                <th class="text-center"></th>
                <th class="text-center">{{$t("general:re_keys")}}</th>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {{$t("real_estate:building")}}
                    </td>
                    <td>
                        <span class="re-key">{{building.property_id}}</span>
                    </td>
                </tr>

                <tr v-if="building.units_list.length">
                    <td>
                        {{$t("real_estate:units")}}
                        <div class="m-t-5">
                            <a type="button" class="btn btn-info btn-outline btn-xs" 
                                @click.prevent="$emit('add-real-estates', building.units_list.map(i=>i.property_id))"
                            >
                                {{$t("general:add_all")}}
                            </a>
                        </div>
                    </td>
                    <td>
                        <div
                            v-for="(unit, u_idx) in building.units_list"
                            :key="'building-'+building.property_id+'-unit-'+u_idx"
                        >
                            <re-open-modal :re_key="unit.property_id" />
                            <add-remove-button
                                :re_key="unit.property_id"
                                :added="reKeysInSubject.includes(unit.property_id)"
                                @add-real-estates="$emit('add-real-estates', $event)"
                            />
                        </div>
                    </td>
                </tr>

                <tr v-if="building.parcels_list.length">
                    <td>
                        {{$t("real_estate:building:on_parcel")}}
                        <div class="m-t-5">
                            <a type="button" class="btn btn-info btn-outline btn-xs" 
                                @click.prevent="$emit('add-real-estates', building.parcels_list.map(i=>i.property_id))"
                            >
                                {{$t("general:add_all")}}
                            </a>
                        </div>
                    </td>
                    <td>
                        <div
                            v-for="(parcel, p_idx) in building.parcels_list"
                            :key="'building-'+building.property_id+'-parcel-'+p_idx"
                        >
                            <re-open-modal :re_key="parcel.property_id" />
                            <add-remove-button
                                :re_key="parcel.property_id"
                                :added="reKeysInSubject.includes(parcel.property_id)"
                                @add-real-estates="$emit('add-real-estates', $event)"
                            />
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";
import AddRemoveButton from "./components/AddRemoveButton.vue";

export default {
    props: {
        building: {
            type: Object,
        },
        reKeysInSubject: {
            type: Array
        }
    },
    components: {
        ReOpenModal,
        AddRemoveButton
    },
}
</script>
