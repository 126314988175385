<template>
    <div class="vld-parent" style="min-height: 100px;">
        <loading 
            :active="isLoading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        <div class="row">
            <div class="col-md-6">

                <!-- First column -->
                <div class="table-responsive">
                    <table class="table table-striped custom-table">
                        <tbody>
                            <tr v-for="(value, index) of inspection_form_pairs" :key="index">
                                <template v-if="value.first">
                                    <td>{{ value.first[0] }}</td>
                                    <td>{{ value.first[1] }}</td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Second column -->
            <div class="col-md-6">
                <div class="table-responsive">
                    <table class="table table-striped custom-table">
                        <tbody>
                            <tr v-for="(value, index) of inspection_form_pairs" :key="index">
                                <template v-if="value.second">
                                    <td>{{ value.second[0] }}</td>
                                    <td>{{ value.second[1] }}</td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"

import Loading from 'vue-loading-overlay';

export default {
    name: "InspectionFormTable",
    components: {
        Loading,
    },
    props: {
        session_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            inspection_form: {},
            isLoading: false,
        }
    },
    computed: {
        inspection_form_pairs() {
            const entries = Object.entries(this.inspection_form);
            const pairs = []
            for (let index = 0; index <= entries.length; index += 2) {
                pairs.push({
                    first: entries[index],
                    second: index + 1 != entries.length ? entries[index + 1] : null,
                });
            }
            return pairs;
        }
    },
    methods: {
        getReadableInspectionFormData() {
            this.isLoading = true;
            ApiService.get(`/amas/analysis/${this.session_id}/document/inspection-form/readable`)
                .then(res => {
                    this.inspection_form = res.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
    mounted() {
        this.getReadableInspectionFormData();
    },
}
</script>
