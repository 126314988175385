<template>
    <div>
        <p>{{ $t("virtual_visit:location_access_browser:error_message") }}</p>
        <p>{{ $t("virtual_visit:call_participant_location_access_instructions:message") }}</p>
        <ol>
            <li>
                <a target="_blank" href="https://podpora.arvio.si/docs/virtualni-ogled/resitve/dovoli-spletni-strani-dostop-do-lokacije/">
                    {{ $t("virtual_visit:allow_location_access") }}
                </a>
            </li>
            <li>
                <a target="_blank" href="https://podpora.arvio.si/docs/virtualni-ogled/resitve/ponastavi-sejo-virtualnega-ogleda/">
                    {{ $t("virtual_visit:reset_virtual_visit_session") }}
                </a>
            </li>
            <li>
                <a target="_blank" href="https://podpora.arvio.si/docs/virtualni-ogled/resitve/omogoci-brskalniku-dostop-do-lokacije/">
                    {{ $t("virtual_visit:check_browser_location_access") }}
                </a>
            </li>
            <li>
                <a target="_blank" href="https://podpora.arvio.si/docs/virtualni-ogled/resitve/ponastavi-sejo-virtualnega-ogleda/">
                    {{ $t("virtual_visit:reset_virtual_visit_session") }}
                </a>
            </li>
            <li>
                <a target="_blank" href="https://podpora.arvio.si/docs/virtualni-ogled/resitve/r1-1-uporaba-priporocenega-brskalnika/#osve%C5%BEi-stran">
                    {{ $t("virtual_visit:use_recommended_browser") }}
                </a>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'ModeratorEnableLocation'
}
</script>
