export const urlGeneratorMixin = {
    methods: {
        getOpenPropertyModalUrl: function(property_id, start_analysis_button=0) {
            return `/amas/real_estate/${property_id}/details/${start_analysis_button}/amas`;
        },
        getAnalysisSessionUrl: function(session_id) {
            return `/amas/analysis/${session_id}`;
        },
    }
}
