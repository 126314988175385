<template>
    <div>
        <BaseInput
            :prependIcon="prependIcon"
            :errorMessage="elementErrorMessage"
            :showErrorMessage="customShowErrorMessage"
            @icon-click="$emit('icon-click')"
        >
            <input
                v-model="context.model"
                v-bind="context.attributes"
                :data-type="context.type"
                :type="context.type"

                :min="minValue"
                :max="maxValue"
                @blur="updateErrors"
                @focus="clearErrors"
            />
        </BaseInput>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import baseinputmixin from "../mixins/baseinputmixin";
import BaseInput from "./BaseInput.vue";

export default {
    name: "DateInput",
    mixins: [ FormulateInputMixin, baseinputmixin ],
    components: {
        BaseInput,
    },
    props: {
        fromToday: {
            type: Boolean,
            default: false,
        },
        toToday: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        minValue() {
            if (this.fromToday)
                return new Date().toISOString().split('T')[0];
            return this.min;
        },
        maxValue() {
            if (this.toToday)
                return new Date().toISOString().split('T')[0];
            return this.max;
        },
        elementErrorMessage() {
            if (this.maxValue && this.minValue)
                return `Vnesite datum med ${this.getReadableDate(this.minValue)} in ${this.getReadableDate(this.maxValue)}`;
            if (this.maxValue)
                return `Vnesite datum do ${this.getReadableDate(this.maxValue)}`;
            if (this.minValue)
                return `Vnesite datum od ${this.getReadableDate(this.minValue)}`;
            return "Vnesite pravilen datum";
        },
    },
    methods: {
        getReadableDate(date) {
            const dateObj = new Date(date);
            return `${dateObj.getDate()}. ${dateObj.getMonth() + 1}. ${dateObj.getFullYear()}`;
        },
        isValid() {
            if (!this.context.model)  // not empty
                return true;
            if (this.showErrorMessage)  // forced from parent
                return true;
            const isWithinMinValue = this.minValue ? this.context.model >= this.minValue : true;
            const isWithinMaxValue = this.maxValue ? this.context.model <= this.maxValue : true;
            return isWithinMinValue && isWithinMaxValue;
        },
    },
}
</script>
