<template>
    <div class="transaction-map-wrapper" @click="mapOpenFullscreen">
        <LMap
            :zoom="12"
            :center="center"
            @update:bounds="handleMapMove"
            @update:zoom="handleZoomChange"
            style="height: 300px;"
            ref="map"
            :options="{
                attributionControl: false
            }"
        >      
            <LControlAttribution
                prefix='<a href="https://www.arvio.si/" target="_blank">Arvio</a>'
                position="bottomright"
            />

            <l-control-layers position="topright"></l-control-layers>
            <LTileLayer
                v-for="tilelayer in tilelayers"
                :key="tilelayer.name"
                :name="tilelayer.name"
                :url="tilelayer.url"
                :attribution="tilelayer.attribution"
                :options="{ maxZoom: maxZoom, minZoom: minZoom }"
                layer-type="base"
            />

            <l-wms-tile-layer
                v-for="(layer, index) in wmsLayers"
                :key="'wms-layer-'+index"
                :base-url="layer.url"
                :name="layer.name"
                :layers="layer.layers"
                :visible="layer.visible"
                :layer-type="layer.type"
                :transparent="layer.transparent"
                :format="layer.format"
                :styles="layer.styles"
                :options="{ maxZoom: maxZoom, minZoom: 10 }"
            />

            <LControl
                id="toggle-comparables-control-wrapper"
                position="topleft"
            >   
                <div style="background: white; padding: 5px;">
                    <div class="radio">
                        <input type="radio" name="radio" id="radio-all" value="option-radio-all" checked v-on:change="show_search_results=true">
                        <label for="radio-all">{{$t("analysis:ct:transactions:all")}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="radio" id="radio-comparables" value="option-radio-comparables" v-on:change="show_search_results=false">
                        <label for="radio-comparables">{{$t("analysis:ct:selected_comparable")}}</label>
                    </div>
                </div>
            </LControl>

            <LControl
                id="details-window-control-wrapper"
                position="bottomleft"
                :disable-scroll-propagation="true"
            >   
                <div v-if="show_details && selected_item" style="background: white; max-height: 400px; overflow: auto;">
                    <slot name="details-control" v-bind:selected_item="selected_item" v-bind:loading_selected_item="loading_selected_item">
                    </slot>
                </div>
            </LControl>

            <LControl position="bottomright" v-if="loading_markers">
                <div>{{$t("general:loading")}} ...</div>
            </LControl>

            <LMarker
                v-if="main_marker"
                :lat-lng="main_marker"
                :icon="icon"
            >
                <l-popup
                    :options="{autoPan: true}"
                >
                    <div>{{$t("avm:real_estate:appraised")}}</div>
                </l-popup>
            </LMarker>

            <l-circle
                v-if="search_radius"
                :lat-lng="main_marker"
                :radius="search_radius"
            />
            
            <MarkerCluster
                :options="{
                    showCoverageOnHover: false,
                    removeOutsideVisibleBounds: true,
                    maxClusterRadius: 60
                }"
            >
                <LMarker
                    v-for="marker in filteredMarkers"
                    :key="marker.id"
                    :lat-lng="marker.gps"
                    :icon="icon"
                    v-on:click="handleMarkerClick(marker)"
                >  
                    <LIcon
                        class-name="arvio-marker-icon"
                        :icon-anchor="[10, 10]"
                    >
                        <div class="arvio-marker-icon__content"
                            v-bind:class="[marker.colorClass, {
                                'selected': selected_item && marker.id==selected_item.id,
                                'in-comparable': marker.comparable===true,
                                'in-wide-set': marker.comparable===false,
                            }]"
                        ></div>
                    </LIcon>
                </LMarker>
            </MarkerCluster>
        </LMap>
    </div>
</template>

<script>
import L from 'leaflet';

import { LMap, LTileLayer, LWMSTileLayer, LMarker, LControl, LControlAttribution, LIcon, LControlLayers, LPopup, LCircle } from 'vue2-leaflet';

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

import ApiService from "@/components/utils/api.service.js"

import countrymixin from '@/mixins/countrymixin';

export default {
    mixins: [ countrymixin ],
    components: {
        LMap,
        LTileLayer,
        "l-wms-tile-layer": LWMSTileLayer,
        LMarker,
        LControl,
        LControlAttribution,
        LIcon,
        LPopup,
        LControlLayers,
        LCircle,
        "MarkerCluster": Vue2LeafletMarkerCluster
    },
    props: {
        markers: {
            type: Array,
            default: () => []
        },
        main_marker: {
            type: Array,
        },
        loading_markers: {
            type: Boolean,
            default: false
        },
        center: {
            type: Array,
            default: () => [46.05108, 14.50513]
        },
        maxZoom: {
            type: Number,
            default: 18
        },
        minZoom: {
            type: Number,
            default: 11
        },
        search_radius: {
            type: Number,
        },
        api_url: String
    },
    data: function () {
        return {
            tilelayers: [
                {
                    name: this.$t("leaflet_map:satelite_view"),
                    visible: true,
                    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                },
                {
                    name: this.$t("leaflet_map:normal_view"),
                    visible: false,
                    url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
                },
            ],

            icon: L.icon({
                iconUrl: require('@/images/house.png'),
                iconSize: [60, 60],
                iconAnchor: [30, 60],
                popupAnchor:  [0, -60]
            }),

            show_details: false,
            selected_item: null,
            loading_selected_item: false,

            show_search_results: true,
        }
    },
    methods: {
        handleMarkerClick: function(marker){
            this.loading_selected_item = true;
            ApiService
                .get("/market/api/search", {
                    params: {
                        id: marker.id
                    }
                })
                .then(response => {
                    this.selected_item = {...response.data, ...{
                        comparable: marker.comparable,
                        score: marker.score,
                    }};
                    this.loading_selected_item = false;
                    this.show_details = true;
                })
        },
        handleMapMove: function(event){
            this.$emit("update:bounds", event);
        },
        handleZoomChange: function(event){
            this.$emit("update:zoom", event);
        },
        getBBox: function() {
            if(this.$refs.map) {
                return this.$refs.map.mapObject.getBounds();
            }
            return null;
        },
        setZoom: function(zoom) {
            this.$refs.map.mapObject.setZoom(zoom);
        },
        panTo: function(center) {
            this.$refs.map.mapObject.panTo(center);
        },
        invalidateMap() {
            this.mapObject.invalidateSize();
        },

        mapOpenFullscreen: function() {
            if(!this.mapObject.isFullscreen()) {
                this.mapObject.toggleFullscreen();
            }
        },
        mapExitFullscreen: function() {
            if (this.mapObject.isFullscreen()) {
                this.mapObject.toggleFullscreen();
            }
        },
    },
    computed: {
        filteredMarkers: function() {
            // Markers need to have "gps" field
            let res = this.markers.filter(i => i.gps);
            if(this.show_search_results == false) {
                res = res.filter(i => !(i.comparable == null));
            }
            return res;
        },
        mapObject: function() {
            return this.$refs.map.mapObject; 
        },

        wmsLayers: function() {
            if (!this.isCountryPluginLoaded)
                return [];
            const layers = this.getCountryPlugin("WMS_LAYERS_COMPARABLE_SEARCH");
            return layers;
        },
    },
    mounted() {
        const app = this;
        L.DomEvent.addListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
        this.$nextTick(() => {
            app.mapObject.invalidateSize(true)
            // Fix scrolling on details window.
            L.DomEvent.on(
                L.DomUtil.get("details-window-control-wrapper"),
                "mousewheel",
                L.DomEvent.stopPropagation
            );
            app.mapObject.addControl(new L.Control.Fullscreen(
                {
                    title: {
                        'false': this.$t("leaflet_map:title:full_screen"),
                        'true': this.$t("leaflet_map:title:exit_full_screen")
                    },
                    position: 'topright'
                }
            ));
            app.mapObject.on('fullscreenchange', function () {
                if (app.mapObject.isFullscreen()) {
                    // console.log('entered fullscreen');
                } else {
                    // console.log('exited fullscreen');
                    app.show_details = false;
                    app.panTo(app.center);
                }
            });
        });
    },
    beforeDestroy() {
        L.DomEvent.removeListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
    },
}
</script>

<style>
    @import '~leaflet/dist/leaflet.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>

<style>
    /* Fullscreen on wrapper click */
    .transaction-map-wrapper {
        position: relative;
        cursor: pointer;
    }
    .transaction-map-wrapper div.vue2leaflet-map {
        opacity: 0.6;
    }
    .transaction-map-wrapper:hover div.vue2leaflet-map {
        opacity: 1;
    }
    /* Leaflet controls full-width */
    .transaction-map-wrapper #details-window-control-wrapper {
        width: 99%;
        margin-left: 0.5%;
        margin-right: 0.5%;
    }
    .transaction-map-wrapper .leaflet-left{
        width: 100%;
    }

    .sale-item-external-marker {
        background-color: purple !important;
    }
    .rent-item-external-marker {
        background-color: green !important;
    }
    /* Custom market icon */
    .arvio-marker-icon__content {
        background-color: #2cabe3;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
        -moz-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
        box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
    }
    .arvio-marker-icon__content.selected {
        border: 4px solid #FFF !important;
        background-color: #42bff7;
    }
    .arvio-marker-icon__content.in-comparable {
        background-color: #53e69d;
        border: 3px solid #53e69d;


    }
    .arvio-marker-icon__content.in-wide-set {
        background-color: #ffc36d;
        border: 3px solid #ffc36d;
    }

    /* Custom market cluster colors */

    .marker-cluster div{
        color: #FFF;
        font-weight: bold;
    }
    .marker-cluster-small {
        background-color: #1a76c766;
    }
    .marker-cluster-small div {
        background-color: #1a76c7;
        font-size: 10px;
    }

    .marker-cluster-medium {
        background-color: #2b5cda66;
    }
    .marker-cluster-medium div {
        background-color: #2b5cda;
    }

    .marker-cluster-large {
        background-color: #2352c766;
    }
    .marker-cluster-large div {
        background-color: #2352c7;
        font-size: 13px;
    }
</style>