<template>
    <tr v-bind:class="{ 'bg-grey': row_expanded }">
        <td>
            <div v-if="!nested_transaction" class="flexo">
                <span>
                        #{{item.transaction_id}}
                </span>
                <a
                    href="#" 
                >
                    <span
                        v-if="item.transaction_volume > 1"
                        class="label label-rouded label-info"
                        data-toggle="tooltip"
                        :data-original-title="$t('analysis:transaction:contains_more_re')"
                        v-on:click.prevent="$emit('labelclicked', item.transaction_id)"
                    >
                        {{item.transaction_volume}}
                        <!-- <i class="fa"
                            v-bind:class="row_expanded ? 'fa-caret-up' : 'fa-caret-down'">
                        </i> -->
                    </span>
                </a>
            </div>
        </td> 
        <td>
            <div class="font-medium re-key" :v-tooltip="$t('widgets:modal:real_estate_details')">
                <a class="open-modal" :href="'/market/transaction/'+item.transaction_id+'/details'">
                    {{ getTitle(item) }}
                </a>
            </div>
            <span class="text-muted">{{item.get_component_type_display}}</span>
        </td>
        <td>
            <span v-if="!nested_transaction">
                {{item.transaction_type_display}}
            </span>
        </td>
        <td>
            <span v-if="!nested_transaction">
                {{item.transaction_date}}
            </span>
        </td>
        <td>
            <span v-if="!nested_transaction">
                <span class="text-info">{{item.transaction_amount|formatCurrency(0)}}</span>
                <br>
                <span v-if="item.transaction_amount_m2" class="text-primary">{{item.transaction_amount_m2|formatCurrencym2(0)}}</span>
                <span v-else-if="item.is_estimated_amount" class="text-primary">{{item.estimated_amount_m2|formatCurrencym2(0)}}</span>
                <span v-else class="text-primary">/</span>

                <span v-if="item.component_tax"><br>z {{ item.component_tax }}% DDV</span>
            </span>
        </td>
               

    </tr>
</template>

<script>
export default {
    name: "PropertyTransactionHistoryTable",
    props:  {
        item: Object,
        row_expanded: {
            type: Boolean,
            default: false
        },
        nested_transaction: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        getTitle(item) {
            if (item.parcel) {
                return "Zemljišče " + item.cadastral_municipality.charAt(0).toUpperCase() + item.cadastral_municipality.slice(1).toLowerCase();
            } else {
                return item.address || "/";
            }
        }
    }
}
</script>