<template>
    <div>
        <p class="text-center">
          {{ $t('ee:input_data:description')}}
        </p>
        <form class="form-group form-inline">
            <div v-for="(definition, field) in field_definition" :key="'input-vector-field'+field" class="m-b-10">

                <label :for="'input-field-'+field" class="m-r-10">{{definition.label}}: </label>

                <select
                    v-if="definition.type=='select'"
                    v-model="input_vector[field]"
                    class="form-control"
                > 
                    <option 
                        v-for="(choice, choices_idx) in definition.choices" 
                        :value="choice"
                        :key="field+'-option-'+choices_idx"
                    >
                        {{choice}}
                    </option>
                </select>

                <input
                    v-else
                    v-model="input_vector[field]"
                    :type="definition.type||'string'"
                    class="form-control"
                    :id="'input-field-'+field"
                />

                <small v-if="definition.help_text" class="form-text text-muted">{{definition.help_text}}</small>
            </div>
        </form>
        <div class="text-center">
            <button @click.prevent="$emit('save', input_vector)" class="btn btn-info">{{ $t('general:save')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initial_input_vector: {
            type: Object,
        },
    },
    data() {
        return {
            input_vector: {...this.initial_input_vector},

            unit_position_valid_options: [
                null,
                this.$t('ee:building_position:ground'),
                this.$t('ee:building_position:attic'),
                this.$t('ee:building_position:floor'),
                this.$t('ee:building_position:basement'),
                this.$t('ee:other'),
            ],
            building_type_choices: [
                null,
                this.$t('ee:building_type:cellar'),
                this.$t('ee:building_type:detached'),
                this.$t('ee:building_type:corner'),
                this.$t('ee:building_type:intermediate'),
            ],
            building_construction_material_choices: [
                null,
                this.$t('ee:building_construction:metal'),
                this.$t('ee:building_construction:rock'),
                this.$t('ee:building_construction:wood'),
                this.$t('ee:building_construction:brick'),
                this.$t('ee:building_construction:concrete'),
                this.$t('ee:building_construction:other'),
                this.$t('ee:building_construction:prefabricated'),
                this.$t('ee:building_construction:mix'),
            ],
        }
    },
    methods: {
        getLabel(field) {
            if (field in this.field_definition) {
                return this.field_definition[field].label;
            }
            return field;
        },
        getHelpText(field) {
            if (field in this.field_definition) {
                return this.field_definition[field].help_text;
            }
            return null;
        }
    },
    computed: {
        field_definition() {
            return {
                unit_story_no: {
                    label: this.$t('ee:fields:floor'),
                    type: "number"
                },
                unit_windows_year_remodeled: {
                    label: this.$t('ee:fields:year_window'),
                    type: "number"
                },
                unit_installation_year_remodeled: {
                    label: this.$t('ee:fields:installation'),
                    type: "number"
                },
                unit_has_elevator: {
                    label: this.$t('ee:fields:elevator'),
                    type: "checkbox"
                },
                building_total_floors: {
                    label: this.$t('ee:fields:floor_n'),
                    type: "number"
                },
                building_total_appartments: {
                    label: this.$t('ee:fields:apartment_n'),
                    type: "number"
                },
                building_year_built: {
                    label: this.$t('ee:fields:year_build'),
                    type: "number"
                },
                building_roof_year_remodeled: {
                    label: this.$t('ee:fields:year_roof'),
                    type: "number"
                },
                building_facade_year_remodeled: {
                    label: this.$t('ee:fields:year_facade'),
                    type: "number"
                },
                unit_floor_size: {
                    label: this.$t('ee:fields:area'),
                    type: "number"
                },
                unit_position: {
                    label: this.$t('ee:fields:position'),
                    type: "select",
                    choices: this.unit_position_valid_options
                },
                // unit_valuation_zone_level: {
                //     label: "Vrednostna raven v kateri leži stavba",
                //     type: "number"
                // },
                building_building_type: {
                    label: this.$t('ee:fields:building_type'),
                    type: "select",
                    choices: this.building_type_choices
                },
                building_construction_material: {
                    label: this.$t('ee:fields:material_type'),
                    type: "select",
                    choices: this.building_construction_material_choices
                },
                building_has_water_supply: {
                    label: this.$t('ee:fields:water'),
                    type: "checkbox"
                },
                building_has_electricity: {
                    label: this.$t('ee:fields:electricity'),
                    type: "checkbox"
                },
                building_has_sewage: {
                    label: this.$t('ee:fields:sewage'),
                    type: "checkbox"
                },
                building_parcels_size: {
                    label: this.$t('ee:fields:parcel_size'),
                    type: "number"
                },
            }
        }
    }
}
</script>
