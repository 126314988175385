<template>
    <div class="investment-checkbox-section">
        <div class="checkbox checkbox-info">
            <input
                v-model="localSelectedInvestment.selected"
                @change="updateSelectedInvestment"
                type="checkbox"
                class="task-done"
                :id="investment.label"
                :disabled="!investment.options || investment.options.length === 0"
            >
            <label :for="investment.label">
                <i :class="investment.icon"></i>
                {{ investment.label }}
            </label>

            <div v-if="selectedInvestment.selected && investment.options && investment.options" class="investment-dropdowns">
                <div>
                    Ogrevanje prej
                    <select v-model="selectedInvestment.optionOriginal" class="form-control">
                        <option
                            v-for="option in possibleInvestment.optionsOriginal"
                            :key="option.id"
                            :value="option.id"
                            :selected="option.id == possibleInvestment.optionOriginalDefault"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </div>
                <div>
                    Moč črpalke
                    <select v-model="selectedInvestment.optionRecalculated" class="form-control">
                        <option
                            v-for="option in possibleInvestment.options"
                            :key="option.id"
                            :value="option.id"
                            :selected="option.id == possibleInvestment.optionDefault"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PossibleInvestment",
    props: {
        investment: {
            type: Object,
            default: () => {}
        },
        selectedInvestment: {
            type: Object,
            default: () => {},
        },
        possibleInvestment: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            localSelectedInvestment: this.selectedInvestment,
        }
    },
    watch: {
        selectedInvestment(newVal) {
            this.localSelectedInvestment = newVal;
        }
    },
    methods: {
        updateSelectedInvestment() {
            this.$emit('update:selectedInvestment', this.localSelectedInvestment);
        }
    },
}
</script>

<style lang="scss" scoped>
.investment-checkbox-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .investment-dropdowns {
        display: flex;
        gap: 10px;
        margin-top: 5px;

        div, select {
            width: 100%;
            text-overflow: ellipsis;
        }
    }

    label {
        user-select: none;
    }

    .checkbox:has(input[disabled]) {
        cursor: not-allowed;

        input, label {
            cursor: not-allowed;
        }
    }
}
</style>
