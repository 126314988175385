export default {
    zoom: {
        type: Number,
        default: 8
    },
    position: {
        type: [Array, Object],
        default: function() {
            return {
                lat: 46.15039,
                lng: 14.91421
            }
        }
    },
    source: {
        type: String,
        default: "outdoor"
    },
    height: {
        type: Number,
        default: 500
    },
}