<template>
    <div class="white-box p-20 p-b-5 dashboard-graph">
        <!-- Heading -->
        <div>
            <h3 class="box-title">
                {{graphTitle}}
            </h3>
            <div v-if="subtitle">
                {{subtitle}}
            </div>
            <div v-if="description" class="text-muted text-smaller">
                {{description}} {{formatUnit(currentUnit)}}
            </div>
        </div>
        <!-- Graph display -->
        <div v-if="graphs[0].data.length > 2">
            <table v-if="showGrowthOverUnits">
                <tr class="text-muted text-smaller">
                    <td>{{ $t("dashboard:last_24_months") }}</td>
                    <td>{{ $t("dashboard:last_12_months") }}</td>
                    <td>{{ $t("dashboard:last_quarter") }}</td>
                </tr>
                <tr class="text-bold">
                    <td>{{apiData.growth.prevTwoYears}}<span v-if="showGrowthPercentageUnit"> %</span></td>
                    <td>{{apiData.growth.prevYear}}<span v-if="showGrowthPercentageUnit"> %</span></td>
                    <td>{{apiData.growth.prevUnit}}<span v-if="showGrowthPercentageUnit"> %</span></td>
                </tr>
            </table>
            <div :class="{'chart-container': showValuesTable, 'row': true}">
                <apexchart :class="{'chart': showValuesTable, 'col-sm-12': !showValuesTable}" :type="graphType"
                    :height="graphHeight" :options="chartOptions" :series="chartSeries" />
                <!-- Table display -->
                <div v-if="showValuesTable" class="values-table">
                    <div v-for="(value, i) in graphs[0].data" :key="i">
                        <div class="row">
                            <div class="col-xs-6">{{apiData.labels[i].replace(/\s/g, '\u00a0')}}</div>
                            <div class="col-xs-6 text-right">{{value.toLocaleString("sl-SI")}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Number display -->
        <div v-else>
            <div>
                <h3 class="text-right">
                    <i v-if="showRelativeChange" :class="{
                        'ti-arrow-up text-success': showIcons && graphGrowth > 0,
                        'ti-arrow-down text-danger': showIcons && graphGrowth < 0,
                    }" />
                    {{growthDisplay}}
                    <span v-if="displayUnit !== undefined"> {{displayUnit}}</span>
                </h3>
            </div>
            <div
                v-if="showRelativeChange"
                :class="[{
                    'text-success': graphGrowth > 0,
                    'text-info': graphGrowth === 0,
                    'text-danger': graphGrowth < 0,
                }, 'text-smaller']">
                <span v-if="graphGrowth < 0">-</span>{{Math.abs(apiData.growth[growthOverUnit])}} %
                {{graphGrowthOverText}} {{formatUnit(previousUnit)}}
            </div>
        </div>
        <slot name="data_source">
        <div v-if="apiData.dataSource !== undefined" class="data-source-info">
            <div v-if="apiData.dataSource.dataSourceNote !== undefined">
                <i>{{ $t("note") }}</i>: {{ $t("widgets:data_source:volume_note") }}
                <help-icon slug='ekstrapolacija-stevila-transakcij' />
            </div>
            <div v-if="apiData.dataSource.dataSourceNormalization !== undefined">
                <i>{{ $t("note") }}</i>: {{ apiData.dataSource.dataSourceNormalization }}
            </div>
            {{ $t("widgets:data_source:source") }}: {{ apiData.dataSource.source }},
            {{ $t("widgets:data_source:arvio") }}{{ $t("widgets:data_source:source3") }} {{apiData.dataSource.date}}
            <help-icon :slug="infoSlug" />
        </div>
        </slot>

    </div>
</template>
<script>
import HelpIcon from "@/components/widgets/HelpIcon.vue";
import Utils from "@/components/utils/utils.js";
import VueApexCharts from "vue-apexcharts";

export default {
    name: "DashboardGraph",
    props: {
        title: {
            type: String,
            default: undefined,
        },
        subtitle: {
            type: String,
            default: undefined,
        },
        description: {
            type: String,
            default: undefined,
        },
        graphs: {
            type: Array,
            required: true,
        },
        apiData: {
            type: Object,  // contains: labels, prevYearLabel and growth (to reduce amount of props)
            required: true,
        },
        showGrowthOverUnits: {
            type: Boolean,
            default: false,
        },
        growthOverUnit: {
            type: String,
            default: "prevUnit",
            validator(value) {
                return ["prevUnit", "prevTwoYears", "prevYear"].includes(value)
            }
        },
        growthOverText: {
            type: String,
            default: undefined,
        },
        displayUnit: {
            type: String,
            default: undefined,
        },
        group: {
            type: String,
            default: undefined,
        },
        showValuesTable: {
            type: Boolean,
            default: false,
        },
        showRelativeChange: {
            type: Boolean,
            default: true,
        },
        showGrowthPercentageUnit: { // If "%" sign in growth display should be shown
            type: Boolean,
            default: true,
        },
        decimalPlaces: {
            type: Number,
            required: false,
        },
        infoSlug: {
            type: String,
            required: false,
            default: "vir-in-metodologija"
        },
        forceGraphHeight: {
            type: String,
            required: false,
        },
        parentHeightOffset: {
            type: Number,
            required: false,
            default: 15,
        },
        showIcons: {
            type: Boolean,
            required: false,
            default: true,
        },
        customChartOptions: {
            type: Object,
            required: false,
            default: () => { return {} }
        },
    },
    components: {
        apexchart: VueApexCharts,
        HelpIcon,
    },
    computed: {
        graphTitle() {
            return this.title.replace(" ", "\xa0");
        },
        graphGrowthOverText() {
            if (this.growthOverText !== undefined)
                return this.growthOverText;
            return this.$t("general:as_per");
        },
        colors() {
            if (this.graphType === "area")
                return ["#B4DED7", "#60C8B6", "#727581",]
            return ["#60C8B6", "#B4DED7", "#89D3C7", "#727581", "#95A7B0", "#7DB7BC",]
        },
        strokeWidth() {
            if (["bar"].includes(this.graphType))
                return 0
            else if (["treemap", "donut", "pie"].includes(this.graphType))
                return 2;
            return this.graphs.map(graph => {
                if (["bar", "area"].includes(graph.type))
                    return 0;
                return 5;
            })
        },
        chartOptions() {
            let defaultChartOptions = {
                markers: {
                    // size: 1, // Adjust the size of the dots
                },
                colors: this.colors,
                chart: {
                    type: this.graphType,
                    stacked: false,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    group: this.$props.group,
                    id: this.$props.title,
                    parentHeightOffset: this.parentHeightOffset
                },
                stroke: {
                    width: this.strokeWidth,
                    curve: "smooth"
                },
                fill: {
                    type: "solid"
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%",
                    },
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    color: "#000000"
                                }
                            }
                        }
                    }
                },
                labels: this.apiData.labels,
                dataLabels: {
                    enabled: ["treemap"].includes(this.graphType)
                },
                xaxis: {
                    type: "catergory",
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    labels: {
                        formatter: this.addDisplayUnit,
                        minWidth: 1,
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: this.addDisplayUnit
                    }
                },
                legend: {
                    show: !["pie", "donut"].includes(this.graphType),
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                },
            }
            return {
                ...defaultChartOptions,
                ...this.customChartOptions,
            }
        },
        graphGrowth() {
            return this.apiData.growth[this.$props.growthOverUnit];
        },
        currentUnit() {
            return this.$props.apiData.labels[this.$props.apiData.labels.length - 1]
        },
        previousUnit() {
            if (this.$props.growthOverUnit === "prevYear")
                return this.$props.apiData.prevYearLabel;
            return this.$props.apiData.labels[this.$props.apiData.labels.length - 2]
        },
        growthDisplay() {
            let val = this.graphs[0].data[this.graphs[0].data.length - 1]
            if (val !== undefined)
                if ( this.decimalPlaces != null ) {
                    val = this.formatNumber(val, this.decimalPlaces);
                } else {
                    val = val.toLocaleString("sl-SI");
                }
            return val;
        },
        graphType() {
            if (this.$props.graphs.length === 1)
                return this.$props.graphs[0].type
            return "line"
        },
        chartSeries() {
            if (this.graphType === "treemap") {
                return [{
                    "type": "treemap",
                    "data": this.graphs[0].data.map((value, i) => {      
                        return {
                            x: this.apiData.labels[i],
                            y: value
                        }
                })}]
            }
            else if (["donut", "pie"].includes(this.graphType))
                return this.graphs[0].data;
            return this.graphs;
        },
        graphHeight() {
            if ( this.forceGraphHeight ) {
                return this.forceGraphHeight;
            } else {
                if (["donut", "pie"].includes(this.graphType))
                    return "220px";
                return "100%";
            }
        },
    },
    methods: {
        formatNumber: Utils.formatNumber,
        addDisplayUnit(value) {
            if (this.$props.displayUnit === undefined)
                return value;
            return `${value} ${this.$props.displayUnit}`;
        },
        formatUnit(value) {
            if (value === undefined)
                return "";
            
            const MONTHS = {
                "01": this.$t("months:january"),
                "02": this.$t("months:february"),
                "03": this.$t("months:march"),
                "04": this.$t("months:april"),
                "05": this.$t("months:may"),
                "06": this.$t("months:june"),
                "07": this.$t("months:july"),
                "08": this.$t("months:august"),
                "09": this.$t("months:september"),
                "10": this.$t("months:october"),
                "11": this.$t("months:november"),
                "12": this.$t("months:december"),
            }
            let year, unit;
            if (value.includes("M")) {
                [year, unit] = value.split("M");
                return `${MONTHS[unit]} ${year}`;
            }
            if (value.includes("Q"))
                if (value.includes("-"))
                    [year, unit] = value.split("-Q");
                else
                    [year, unit] = value.split("Q");
            return `${unit}. ${ this.$t("general:quarter") } ${year}`;
        },
    }
}
</script>

<style lang="scss">
.dashboard-graph {
    border-radius: 2rem;
    height: 100%;
    
    h3 {
        $border: 4px;
        margin-top: $border;
        margin-bottom: $border;
    }
    td {
        border-right: 2px solid whitesmoke;
        padding-left: 7px;
        padding-right: 7px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            border: 0;
        }
    }
    .text-smaller {
        font-size: 12px;
    }
    .text-bold {
        font-weight: bold;
    }
    .data-source-info {
        font-style: unset;
        font-size: 10px;
        padding: 5px 0;
    }
    .chart-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        .chart {
            flex: 1;
            min-width: 220px;
            width: 100%;
        }
        .values-table {
            flex: 1;
            max-width: 200px;
            min-width: 150px;
        }
    }
}
.dashboard-graph h3.box-title {
    margin: 0;
}
</style>