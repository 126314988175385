<template>

    <b-form-input
        type="text"
        v-model="localValue"
        @change="$emit('change', $event)"
        :state="valid?null:false"
        :class="valid?'grey':'red-border'"
    />

</template>

<script>
export default {
    props: ["value", "formatter", "deformatter", "validator"],
    watch:{
        localValue: function(){
            if(this.validator && this.validator(this.value)){
                    this.valid = true;
                } //for range input
        },

    },
    computed: {
        localValue: {
            get() {
                return this.formatter(this.value)
            },
            set(localValue) {
                let val = this.deformatter(localValue);
                if(this.validator && this.validator(val)) {
                    this.valid = true;
                    this.$emit('input', val)
                } else {
                    this.valid = false;
                }
                return val;
            }
        },
    },
    data: function () {
        return {
            valid: true,
        }
    }
}
</script>
<style scoped>
    .grey{
    }
    .red-border{
        border-color: red;
    }

</style>