<template>
    <tbody>
        <tr class="section-title-row">
            <td
                :colspan="subject.session_list.length + 1"
            >
                {{$t("general:summary")}}
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:recap:estimated_value")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'vs-recap-appraisal-value-td-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
            >
                <div 
                    class="text-primary text-semibold" style="font-size: 16px;"
                >
                    <div v-if="isLoading(session.id)">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else-if="getAppraisalRecap(session.id)">
                        {{getAppraisalRecap(session.id).appraisal_comp_value||"/"|formatCurrency}}
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:recap:estimated_value_per_m2")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'vs-recap-appraisal-value-m2-td-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
            >
                <div 
                    class="text-info text-semibold" style="font-size: 16px;"
                >
                    <div v-if="isLoading(session.id)">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else-if="getAppraisalRecap(session.id)">
                        {{getAppraisalRecap(session.id).appraisal_comp_value_m2||"/"|formatCurrencym2}}
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
export default {
    model: {
        prop: 'subject',
        event: 'change' 
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        getTaskStatus: function(session_id) {
            let task_status = this.$store.getters.getSingleSessionTaskStatus(session_id);
            return task_status ? task_status.comparable_transactions_sale : null;
        },
        getAppraisalRecap: function(session_id) {
            return this.$store.getters.getSessionAppraisalRecap(session_id);
        },
        isLoading: function(session_id) {
            let appraisal_recap = this.getAppraisalRecap(session_id);
            if (appraisal_recap){
                if (appraisal_recap.appraisal_comp_value) {
                    return false;
                }
                if(appraisal_recap == "loading") {
                    return true;
                }
            }
            if (this.getTaskStatus(session_id) && (this.getTaskStatus(session_id)=="loading" || this.getTaskStatus(session_id).status_code=="STARTED")) {
                return true;
            }
            return false;
        },
    },
}
</script>
