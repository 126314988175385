<template>
    <tbody>
        <tr 
            v-for="(permit, index) in extendedActs"
            :key="permit.STEV_ZAD"
        >
            <td class="text-center">
                {{ re_key }}
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.STEV_ZAD"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.STEV_ZAD}}</span>
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.NAZ_UPR_ORG"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.NAZ_UPR_ORG}}</span>
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.NAZ_UPR_POS"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.NAZ_UPR_POS}}</span>
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.OBJ"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.OBJ}}</span>
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.DAT_IZD"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.DAT_IZD}}</span>
            </td>
            <td class="text-center">
                <k-editable
                    v-if="editable"
                    v-model="permit.MAX_DAT_PRA"
                    default-if-none="/"
                    @close="$emit('save', $event)">
                </k-editable>
                <span v-else>{{permit.MAX_DAT_PRA}}</span>
            </td>
            <td class="text-center">
                <div v-for="parcel in permit.parcels_same_stev_zad.slice(0, 3)" :key="parcel.re_key">
                    <a class="open-modal no-wrap" :href="getOpenPropertyModalUrl(parcel.re_key)">
                        {{ nonBreakableReKey(parcel.re_key) }}
                    </a>
                </div>
                <div v-if="permit.parcels_same_stev_zad.length > 3">
                    <div v-if="permit.showMore">
                        <div v-for="parcel in permit.parcels_same_stev_zad.slice(3)" :key="parcel.re_key">
                            <a class="open-modal no-wrap" :href="getOpenPropertyModalUrl(parcel.re_key)">
                                {{ nonBreakableReKey(parcel.re_key) }}
                            </a>
                        </div>
                    </div>
                    <button class="btn btn-xs btn-outline btn-rounded btn-info m-t-5" @click="permit.showMore = !permit.showMore">
                        {{ permit.showMore ? $t('general:show_less') : $t('general:show_more') }}
                    </button>
                </div>
            </td>
            <td class="text-center" v-if="editable">
                <span v-tooltip="$t('aa:tooltip:remove_from_access')" 
                    class="btn btn-xs btn-outline btn-rounded btn-danger m-b-10"
                    v-on:click.prevent="$emit('remove', index)">
                        {{$t('general:remove')}}
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import KEditable from "@/components/k-editable/KEditable";

export default {
    props: {
        acts: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: true,
        },
        re_key: {
            type: String,
            required: true,
        },
    },
    components: {
        KEditable
    },
    data() {
        return {
            extendedActs: [],
        }
    },
    mounted() {
        this.extendedActs = this.acts.map(act => {
            return {
                ...act,
                showMore: false,
            };
        });
    },
    methods: {
        nonBreakableReKey(re_key) {
            // replace - with non breakable hyphen and / with non breakable slash
            return re_key.replace(/-/g, '-\u00AD').replace(/\//g, '/\u00AD');
        },
    },
}
</script>

<style scoped>
.border-top {
    border-top-width: 2px;
}
.upper {
    text-transform: uppercase;
}
.no-wrap {
    white-space: nowrap;
}
</style>
