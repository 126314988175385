<template>
    <table class="table text-center">
        <thead>
            <tr>
                <th class="text-center">
                  {{ $t('analysis:transaction:id')}}
                </th>
                <th class="text-center">
                  {{ $t('general:transaction')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:deal_type')}}
                </th>
                <th class="text-center">
                  {{ $t('property_iq:tab:transaction_date')}}
                </th>
                <th class="text-center">
                    {{ $t('property_iq:tab:sell_price')}}
                </th>
            </tr>
        </thead>

        <tbody>
            <template v-for="item in transactions">
                <table-row
                    v-bind:key="item.id"
                    :item="item"
                    :row_expanded="showAdditionalItems(item.transaction_id)"
                    :nested_transaction="false"
                    @labelclicked="toggleAdditionalTransactionitems(item.transaction_id)"
                >
                </table-row>     

                <tr 
                    v-if="showAdditionalItems(item.transaction_id) && getTransactionItems(item.transaction_id, item.id)=='loading'"
                    v-bind:key="item.id+'-loading-row'"
                >
                    <td :colspan="4">
                        {{ $t('general:loading')}}
                    </td>
                </tr>
            
                <table-building-row
                    v-else-if="showAdditionalItems(item.transaction_id)"
                    v-for="sub_item in getTransactionItems(item.transaction_id, item.id)"
                    v-bind:key="sub_item.id"
                    :item="sub_item"
                    :nested_transaction="true"
                    class="bg-light-grey"
                >  
                </table-building-row>
                
            </template>
        </tbody>
    </table>
</template>

<script>


import "vue-loading-overlay/dist/vue-loading.css";

import Vue from "vue";

import Utils from "@/components/utils/utils.js"

import TableExtendedRow from './TableExtendedRow.vue';

export default {
    name: "PropertiesTransactionHistoryTable",
    props:  {
        transactions: {
            type: Array,
            default: () => []
        },
        all_transactions: {
            type: Array,
            default: () => []
        },
    },
    data: function () {
        return {
            uncollapsed_transaction_ids: [],
            additional_transaction_data: {},
        }
    },
    components: {
        "table-row": TableExtendedRow
    },
    methods: {

        toggleAdditionalTransactionitems: async function(transaction_id){
            if(this.uncollapsed_transaction_ids.includes(transaction_id)) {
                Utils.removeFromArray(this.uncollapsed_transaction_ids, transaction_id); 
            } else {
                Utils.addToArray(this.uncollapsed_transaction_ids, transaction_id);
            }
            if(!this.additional_transaction_data[transaction_id]) {
                // Vue.set(this.additional_transaction_data, transaction_id, "loading");
                this.setSubTransactions(transaction_id);
            }
        },

        setSubTransactions: function (transaction_id) {
            let items = this.all_transactions                                   
                            .filter(item => item.transaction_id == transaction_id);
            Vue.set(this.additional_transaction_data, transaction_id, items);
        },

        getTransactionItems: function(transaction_id, exclude_id){
            let items = this.additional_transaction_data[transaction_id];
            if (Array.isArray(items)) {return items.filter(item => item.id != exclude_id);}
            return items;
        },
        showAdditionalItems: function(transaction_id) {
            return this.uncollapsed_transaction_ids.includes(transaction_id);
        }    
    }
}
</script>