<template>
    <div class="transaction-map-wrapper">
        <LMap
            :zoom="12"
            :center="center"
            @update:bounds="handleMapMove"
            @update:zoom="handleZoomChange"
            style="height: 800px;"
            ref="map"
            :options="{
                attributionControl: false
            }"
        >      
            <LControlAttribution
                prefix='<a href="https://www.arvio.si/" target="_blank">Arvio</a>'
                position="bottomright"
            />

            <l-control-layers position="topright"></l-control-layers>
            <LTileLayer
                v-for="tilelayer in tilelayers"
                :key="tilelayer.name"
                :name="tilelayer.name"
                :url="tilelayer.url"
                :attribution="tilelayer.attribution"
                :options="{ maxZoom: maxZoom, minZoom: minZoom }"
                layer-type="base"
            />

            <LControl
                id="details-window-control-wrapper"
                position="bottomleft"
                :disable-scroll-propagation="true"
            >   
                <div v-if="show_details && selected_item" style="background: white; max-height: 400px; overflow: auto;">
                    <slot name="details-control" v-bind:selected_item="selected_item">
                    </slot>
                </div>
            </LControl>

            <LControl position="bottomright" v-if="loading_markers">
                <div>Nalaganje ...</div>
            </LControl>

            <MarkerCluster
                :options="{
                    showCoverageOnHover: false,
                    removeOutsideVisibleBounds: true,
                    maxClusterRadius: 60
                }"
            >
                <LMarker
                    v-for="marker in filteredMarkers"
                    :key="marker.id"
                    :lat-lng="marker.gps"
                    :icon="icon"
                    v-on:click="handleMarkerClick(marker)"
                >  
                    <LIcon
                        class-name="arvio-marker-icon"
                        :icon-anchor="[10, 10]"
                    >
                        <div class="arvio-marker-icon__content"
                             v-bind:class="[marker.colorClass, {
                             'selected': selected_item && marker.id==selected_item.id,
                             'in-comparable':
                                selected_comparable_transaction_ids.includes(getTransactionIdFromId(marker.id)),
                             'in-wide-set':
                                selected_wide_set_transaction_ids.includes(getTransactionIdFromId(marker.id)) & !selected_comparable_transaction_ids.includes(getTransactionIdFromId(marker.id)),
                             }]"
                        ></div>
                    </LIcon>
                </LMarker>
            </MarkerCluster>
        </LMap>
    </div>
</template>

<script>
import L from 'leaflet';

import { LMap, LTileLayer, LMarker, LControl, LControlAttribution, LIcon, LControlLayers } from 'vue2-leaflet';

import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

import ApiService from "@/components/utils/api.service.js"

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl,
        LControlAttribution,
        LIcon,
        LControlLayers,
        "MarkerCluster": Vue2LeafletMarkerCluster
    },
    props: {
        markers: {
            type: Array,
            default: () => []
        },
        loading_markers: {
            type: Boolean,
            default: false
        },
        center: {
            type: Array,
            default: () => [46.05108, 14.50513]
        },
        selected_comparable_transaction_ids: {
            type: Array,
            default: () => []
        },
        selected_wide_set_transaction_ids: {
            type: Array,
            default: () => []
        },
        maxZoom: {
            type: Number,
            default: 18
        },
        minZoom: {
            type: Number,
            default: 11
        },
        api_url: String
    },
    data: function () {
        return {
            tilelayers: [
                {
                    name: "Satelitski pogled",
                    visible: true,
                    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                },
                {
                    name: "Navaden pogled",
                    visible: false,
                    url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
                },
            ],

            icon: L.icon({
                iconUrl: require('@/images/house.png'),
                iconSize: [60, 60],
                iconAnchor: [30, 60],
                popupAnchor:  [0, -60]
            }),

            show_details: false,
            selected_item: null
        }
    },
    methods: {
        handleMarkerClick: function(marker){
            ApiService
                .get(this.api_url,
                    {
                        params: {
                            id: marker.id
                        }
                })
                .then(response => {
                    this.selected_item = response.data;
                })
                .catch(error => {
                    throw error;
                })
            this.show_details = true;
        },
        handleMapMove: function(event){
            this.$emit("update:bounds", event);
        },
        handleZoomChange: function(event){
            this.$emit("update:zoom", event);
        },
        getBBox: function() {
            if(this.$refs.map) {
                return this.$refs.map.mapObject.getBounds();
            }
            return null;
        },
        setZoom: function(zoom) {
            this.$refs.map.mapObject.setZoom(zoom);
        },
        getMarkerDisplay: function(marker) {
            return marker.id.split("-")[0]
        },
        getTransactionIdFromId: function(id) {
            if(id[0]==="-"){
                return parseInt("-" + parseInt(id.split("-")[1]));
            } else {
                return parseInt(id.split("-")[0]);
            }
        },
        invalidateMap() {
            this.mapObject.invalidateSize();
        }
    },
    computed: {
        filteredMarkers: function() {
            // Markers need to have "gps" field
            return this.markers.filter(i => i.gps);
        },
        mapObject: function() {
            return this.$refs.map.mapObject; 
        }
    },
    mounted() {
        const app = this;
        L.DomEvent.addListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
        this.$nextTick(() => {
            app.mapObject.invalidateSize(true)
            // Fix scrolling on details window.
            L.DomEvent.on(
                L.DomUtil.get("details-window-control-wrapper"),
                "mousewheel",
                L.DomEvent.stopPropagation
            );
        });
    },
    beforeDestroy() {
        L.DomEvent.removeListener(document.body, 'invalidate-leaflet-maps', this.invalidateMap);
    },
}
</script>

<style>
    @import '~leaflet/dist/leaflet.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.css';
    @import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>

<style>
    /* Leaflet controls full-width */
    .transaction-map-wrapper #details-window-control-wrapper {
        width: 99%;
        margin-left: 0.5%;
        margin-right: 0.5%;
    }
    .transaction-map-wrapper .leaflet-left{
        width: 100%;
    }

    .sale-item-external-marker {
        background-color: purple !important;
    }
    .rent-item-external-marker {
        background-color: green !important;
    }
    /* Custom market icon */
    .arvio-marker-icon__content {
        background-color: #2cabe3;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        -webkit-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
        -moz-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
        box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.54);
    }
    .arvio-marker-icon__content.selected {
        border: 4px solid #FFF !important;
        background-color: #42bff7;
    }
    .arvio-marker-icon__content.in-comparable {
        background-color: #53e69d;
        border: 3px solid #53e69d;


    }
    .arvio-marker-icon__content.in-wide-set {
        background-color: #ffc36d;
        border: 3px solid #ffc36d;
    }

    /* Custom market cluster colors */

    .marker-cluster div{
        color: #FFF;
        font-weight: bold;
    }
    .marker-cluster-small {
        background-color: #1a76c766;
    }
    .marker-cluster-small div {
        background-color: #1a76c7;
        font-size: 10px;
    }

    .marker-cluster-medium {
        background-color: #2b5cda66;
    }
    .marker-cluster-medium div {
        background-color: #2b5cda;
    }

    .marker-cluster-large {
        background-color: #2352c766;
    }
    .marker-cluster-large div {
        background-color: #2352c7;
        font-size: 13px;
    }
</style>