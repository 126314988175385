<template>
    <span>
        <span v-if="!isReady">
            <a class="btn btn-inline btn-info btn-outline btn-rounded btn-sm disabled">
                {{label}}
                <i class="fa fa-spinner fa-spin"></i>
            </a>
        </span>
        <span v-else-if="data.length==0" v-tooltip="'Ni dovolj podatkov'">
            <a class="btn btn-inline btn-info btn-outline btn-rounded btn-sm disabled">
                {{label}}
            </a>
        </span>
        <span v-else>
            <a :id="id" class="btn btn-inline btn-info btn-outline btn-rounded btn-sm">
                {{label}}
                <span>{{internal_from}} - {{internal_to}}</span>
            </a>
        
            <b-popover
                :target="id"
                placement="bottom"
                custom-class="p-20"
                :show.sync="popoverShow"
                @show="$emit('show', $event)"
            >
                <p class="text-center"><strong>{{label}}</strong></p>
                <histogram-slider
                    :width="250"
                    :bar-height="100"
                    :bar-radius="0"
                    :data="data"
                    :from="internal_from"
                    :to="internal_to"
                    :min="min"
                    :max="max"
                    :drag-interval="true"
                    @change="$emit('change', $event)"
                />

                <div class="text-center m-t-20">
                    <a class="btn btn-xs btn-info btn-outline" v-on:click="closePopover">Zapri</a>
                    <slot></slot>
                </div>
            </b-popover>
        </span>
    </span>
</template>

<script>
import { BPopover } from "bootstrap-vue"

import ApiService from "@/components/utils/api.service.js"
import HistogramSlider from '@/components/histogram-slider/HistogramSlider.vue'

import Cookies from 'js-cookie';

export default {
    components: {
        BPopover,
        HistogramSlider
    },
    props: [
        'label', 'from', 'to', 'form_data', 'name', 'data_source'
    ],
    data: function () {
        return {
            data: null,
            min: null,
            max: null,

            internal_from: this.from,
            internal_to: this.to,

            popoverShow: false
        }
    },
    computed: {
        isReady: function() {
            if( this.data ){
                return true;
            }
            return false;
        },
        id: function() {
            return this.name+"_id";
        },
    },
    methods: {
        closePopover() {
            this.popoverShow = false
        },
        getHistogramValues: function() {
            ApiService
                .post("/market/api/values",
                    this.form_data,
                    {
                        params: {
                            attr: this.data_source,
                            apply_extra_filters: false
                        }
                    }
                )
                .then(response => {
                    if(response.data.success==true){
                        this.data = response.data.result;
                        this.min = Math.min.apply(Math, this.data);
                        this.max = Math.max.apply(Math, this.data);
                        if(!this.internal_from) {this.internal_from = this.min}
                        if(!this.internal_to) {this.internal_to = this.max}
                    } else {
                        console.log("Get histogram data failed!, " + response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                    throw error;
                })
        },
    },
    async mounted() {
        ApiService.set_headers({
            "X-CSRFToken": Cookies.get("csrftoken")
        })
        this.getHistogramValues();
    }
}
</script>
