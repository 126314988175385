<template>
  <div class="row vld-parent" style="background-color: #EFEFEF; text-align: left">
    
    <b-form v-if="internalAdjustment" method="post" novalidate>    
      <b-col cols="6" md="6" v-bind:class="{blurred: isManualOnly}"> 
         <h4>
          <b-form-radio v-model="internalAdjustment.adjustment_mode"  name="adjustment_mode" value="1"><p class="m-l-10" >{{$t("avm:arvio_avm_adjustment")}}</p></b-form-radio>
         </h4>
        <h5>{{ $t('avm:factor:adjustment:arvio')}}: {{autoAdjustedValueDisplay}} </h5>
        <p v-html="explanation"></p>
        <img style="width: 100%; object-fit: scale-down;" v-bind:src="internalAdjustment.image_adjustment_explanation">
      </b-col>
      <b-col cols="6" md="6" >
        <h4>
          <b-form-radio v-model="internalAdjustment.adjustment_mode"  name="adjustment_mode" value="2"><p class="m-l-10" >{{$t("avm:custom_adjustment")}}</p></b-form-radio>
       </h4>

          <div v-bind:class="{'blurred': isAdjustmentAuto}" > 
            <p> {{ $t('avm:factor:adjustment:type')}}: <strong>{{ adjustment.factor_type.name }}</strong></p>
        
            <div class="form-group">
                <label for="id_value" class="control-label">
                    {{ $t('avm:factor:adjustment:comparable_price')}}
                </label>
                <base-input-field
                    v-model="internalAdjustment.value"
                    :disabled="disabled"
                />
                <small class="text-danger" v-if="errorMessages && errorMessages.value">
                    <span v-for="(t, idx) in errorMessages.value" :key="'msg-'+idx">{{ t }}</span>
                </small>
            </div>

            <div class="row ">
                <div class="form-group col-md-8 mb-0">
                    <label
                        for="id_adjustment_raw"
                        class="control-label requiredField"
                    >
                        {{ $t('avm:factor:adjustment:price')}}<span class="asteriskField">*</span>
                    </label>
                    <number-input-field
                        v-model="internalAdjustment.adjustment_raw"
                        :disabled="disabled"
                    />
                    <small class="text-danger" v-if="errorMessages && errorMessages.adjustment_raw">
                        <span v-for="(t, idx) in errorMessages.adjustment_raw" :key="'msg-'+idx">{{ t }}</span>
                    </small>
                </div>
              <div class="form-group col-md-4 mb-0">
                <div class="">
                  <label
                    for="id_adjustment_type"
                    class="control-label requiredField"
                  >
                    {{ $t('general:unit')}}<span class="asteriskField">*</span>
                  </label>
                  <div class="controls">
                    <select v-model="internalAdjustment.adjustment_type" class="select form-control" id="id_adjustment_type" :disabled="disabled">
                      <option value="PER" selected="">%</option>
                      <option value="AMT">EUR</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="id_comment" class="control-label requiredField">
                {{ $t('avm:factor:adjustment:explanation')}}
              </label>
              <div class="controls">
                <textarea v-model="internalAdjustment.comment" cols="40" rows="10" class="textarea form-control" required="" id="id_comment" :disabled="disabled"
                ></textarea>
              </div>
            </div>


          </div>
              <div class="pull-right p-20">
                <button v-if="adjustmentExists" type="submit" class="btn btn-danger" v-on:click.prevent="deleteAdjustment()" >
                  {{ $t('general:delete')}}
                </button>

                <button  v-if="this.adjustmentExists" style="margin-left:5px;" type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateAdjustment()" >
                  {{ $t('general:save')}}
                </button>
                <button  v-else style="margin-left:5px;" type="submit" class="btn btn-info" v-on:click.prevent="updateOrCreateAdjustment()" >
                  {{ $t('avm:factor:adjustment:create')}}
                </button>
            </div>
      </b-col>
    </b-form>

  </div>
</template>

<script>
import axios from "axios";

import ApiService from "@/components/utils/api.service.js";
import ApiCalls from "@/components/utils/api.calls.js"
import Utils from '@/components/utils/utils.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import { Swal } from "@/components/utils/swal.alerts.js";
import BaseInputField from "@/components/utils/inputs/BaseInputField.vue";
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";

export default {
  components: {
      Loading,
      BaseInputField,
      NumberInputField,
  },
  props: {
    adjustment: Object,
    disabled: {
            required: false,
            default: false
    },
  },
  data() {
    return {
      url: '/amas/analysis/avm/api/adjustment-factors/',
      loading: false,
      internalAdjustment: null,
      explanation: null,
      errorMessages: null,
    };
  },
  watch: {
    adjustment(newAdjustment){
      this.createInternalAdjustment(newAdjustment);
      this.getExplanation();

    },
    loading: function (newLoad){
      this.$emit("adjustment-factor-type-loading", newLoad); 
    }
  },
  mounted () {
      this.createInternalAdjustment(this.adjustment);
      this.getExplanation();
  },
  computed: {

    autoAdjustedValueDisplay: function (){
      if (this.internalAdjustment.adjustment_type === "AMT") {
        return Utils.formatCurrency(this.internalAdjustment.auto_adjusted_value, 2);
      } else {
        return Utils.formatPercent(this.internalAdjustment.auto_adjusted_value, false, 2);
      }
    },
    adjustmentExists: function () {
      return this.adjustment.id != null;
    },
    isManualOnly: function (){
        return (this.adjustment.factor_type && this.adjustment.factor_type.analysis_session)
    },
    isAdjustmentAuto: function() {
      return (this.internalAdjustment.adjustment_mode == "1")
    },
    postableAdjustment: function() {
      return {
        ...this.internalAdjustment,
        factor_type: this.adjustment.factor_type.id,
        transaction: this.adjustment.transaction.id,
      }
    },
  },
  methods: {
      createInternalAdjustment(adjustment){
          let internal = {...adjustment};
          if (!internal.id){
              internal.adjustment_mode = 2;
              internal.adjustment_type = "PER";
              internal.adjustment_raw = "0.00";
              this.internalAdjustment = internal;
              this.loading = true;
              // ugly but triggers watch for loading twice
              setTimeout(() => { this.loading=false; }, 500);
          }else{
              this.getAdjustment();
          }

      },
      mapperAdjustmentType(adjType){
          if (adjType === "AMT") {
            return "€";
          } else {
            return "%";
          }
      },
      createAdjustment(){
        let url = this.url;
        this.loading = true;
        ApiService
        .post(url, this.postableAdjustment)
        .then(response => {
          this.postableAdjustment = response.data
          this.$emit("update-complete", this.postableAdjustment);
          this.$root.$emit('adjustment-created', this.postableAdjustment);
          this.loading = false;

        })
        .catch(error => {
            Swal({
                  title: "",
                  text: this.$t('avm:error:msg'),
                  icon: "error",
                    })
            throw error;
        });
      },
    validateAdjustment() {
        this.errorMessages = null; // Clear error messages on validation

        let required_fields = ["value", "adjustment_raw"];
        let valid = true;
        for (let field of required_fields){
            let val = this.internalAdjustment[field];
            if (val === null || val === ""){
                valid = false;
                let error_msg = "To polje je obvezno";
                if (!this.errorMessages){
                    this.errorMessages = {};
                }
                this.errorMessages[field] = [error_msg];
            }
        }
        return valid;
    },
    updateAdjustment(){
        if (!this.validateAdjustment()) {
            Swal({
                title: "",
                text: "Vnesite vse obvezne podatke.",
                icon: "error",
            })
            return;
        }
          let url = this.url + this.adjustment.id;
          this.loading = true;
          ApiService
          .put(url, this.postableAdjustment)
          .then(response => {
            this.$emit("update-complete");
            this.$root.$emit('update-complete', this.postableAdjustment);
            this.loading = false;
          })
          .catch(error => {
              Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
              throw error;
          });
        },
      deleteAdjustment(){
            let url = this.url + this.adjustment.id;
            this.loading = true;
            ApiService
            .delete(url, this.adjustment)
            .then(response => {
              this.$emit("update-complete");
              this.loading = false;
              })
            .catch(error => {
                Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
                throw error;
            });

      },
    getAdjustment(){
        if (this.getAdjustmentcancelToken) {
            this.getAdjustmentcancelToken.cancel("Canceling previous region requests!");
        }
        this.getAdjustmentcancelToken = axios.CancelToken.source();

          this.loading = true;
          let url = this.url + this.adjustment.id;
            ApiService
            .get(url, {
                cancelToken: this.getAdjustmentcancelToken.token,
            })
            .then(response => {
                this.internalAdjustment = response.data;
                this.internalAdjustment.adjustment_raw = this.formatAdjustmentNumber(this.internalAdjustment.adjustment_raw);
                this.loading = false;
            
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    // console.log("Request canceled: ", error.message);
                } else {
                    Swal({
                        title: "",
                        text: this.$t('avm:error:msg'),
                        icon: "error",
                    })
                    this.loading=false;
                    throw error;
                }
            });
      },
      getExplanation(){
        ApiCalls
        .getWeDocs(this.adjustment.factor_type.name_slug)
        .then(response => {
            this.explanation = response.data
        })
        .catch(error => {
            if (error.response.status != 404){
                throw error;
            }
        });
      },
      updateOrCreateAdjustment(){
        if (this.adjustmentExists){
          this.updateAdjustment();
        } else {
          this.createAdjustment();
        }
      },
      
      formatAdjustmentNumber(num) {
        let formattedOld = num.toFixed(4);
        let formattedNew = num.toFixed(2);
        // transition to fixed 2 number from fixed 4
        // if num is 0.1234 returns 0.1234
        // if num is 0.12 returns 0.12
        if (formattedOld === (formattedNew+"00")){
          return formattedNew;
        }else{
          return formattedOld;
        }
      },
  }
  
};
</script>
<style scoped>

.blurred {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    pointer-events: none;
    color: #AAAAAA !important;
    opacity: 0.5;
}
</style>
