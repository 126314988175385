import ApiService from '@/components/utils/api.service';

export default {
    data() {
        return {
            graphData: {},
            baseGraphData: undefined,
            baseRegion: undefined,
            currentUser: undefined,
        }
    },
    computed: {
        selectedRegion() {
            if (this.region === undefined)
                return "";
            if (this.region.name === "Osrednja Slovenija brez LJ")
                return "Osrednja Slovenija brez Ljubljane";
            if (this.region.name === "Slovenia")
                return "Slovenija";
            return this.$t(this.region.name);
        },
    },
    methods: {
        getCurrentUser() {
            ApiService
            .get("/api/v1/current-user")
            .then(response => {
                this.currentUser = response.data;
            })
        },
        /**
         * Returns an array of options for drawing on graph
         * @param {Array} types types of graphs to be drawn (line, bar, area)
         * @param {String} apiValueName GET parameter, eg. sold_volume
         * @param {Boolean} addBase adds value for country
         */
        buildGraphData(types, apiValueName, addBase=false) {
            if (types.length === 0)  // show only number
                return [{
                    type: 'line',
                    name: this.region ? this.selectedRegion: "",
                    data: this.graphData[apiValueName[0]].data.slice(-2),
                }]
            const res = apiValueName.map((apiValue, i) => {
                return {
                    type: types[i],
                    name: this.region ? this.selectedRegion: "",
                    data: this.graphData[apiValue].data,
                }
            });
            if (addBase && this.baseRegion && this.selectedRegion !== this.baseRegion.name)
                res.unshift({  // unshift to push base at beginning so it has the same color
                    type: types[0],
                    name: this.baseRegion.name,
                    data: this.baseGraphData[apiValueName[0]].data,
                });
            return res;
        },
        checkShowGraph(apiValueName) {
            return this.graphData[apiValueName] &&
                this.graphData[apiValueName].data.reduce((a, b) => a + b, 0) !== 0  // sum of data is 0 -> hide
        },
        isOpenAIEnabled() {
            return this.currentUser && this.currentUser.all_permissions_list.includes("userprofile.openai_services_enabled");
        },
    }
}