<template>
    <div class="streamline--apexchart--container vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        
        <div><h3>{{title}}</h3></div>
        <apexchart
            width="100%"
            height="250"
            type="bar"
            :options="chartOptions"
            :series="series"
        />
    </div>
    
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "StatisticsApexChart",
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        // values: {
        //     type: Array,
        //     required: true,
        // },
        // labels: {
        //     type: Array,
        //     required: false,
        // },
        title: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            series: [{
                name: 'PRODUCT A',
                data: [44, 55, 41, 67, 22, 43]
            }, {
                name: 'PRODUCT B',
                data: [13, 23, 20, 8, 13, 27]
            }, {
                name: 'PRODUCT C',
                data: [11, 17, 15, 15, 21, 14]
            }, {
                name: 'PRODUCT D',
                data: [21, 7, 25, 13, 22, 8]
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }
                ],
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
                },
                xaxis: {
                    type: 'datetime',
                    categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
                        '01/05/2011 GMT', '01/06/2011 GMT'
                    ],
                },
                legend: {
                    show: false,
                },
                fill: {
                    opacity: 1
                }
            },
        }
    },
}
</script>
