<template>
    <div class="hr-search--map--container">

        <k-wms-leaflet-map
            :zoom="zoom"
            :center="center"
            :polygons="polygons"
            :markers="allMarkers"
            :legend="mapLegend"
            @click="handleMapClick"
            :polygonPopupEnabled="false"
            :fullscreen="true"
            :maxZoom="20"
            ref="map"
        >

            <template v-slot:markers>
                <l-marker
                    v-if="clicked_marker"
                    :lat-lng="clicked_marker.gps_point.coordinates"
                    ref="clickmarker"
                >
                    <l-popup>
                        <ParcelDetails
                            :parcel="clicked_parcel_data"
                        />
                    </l-popup>
                </l-marker>

                <l-marker
                    v-for="(marker, m_idx) in addressMarkers"
                    :lat-lng="marker.coordinates"
                    :key="'marker'+m_idx"
                    :icon="addressMarkerIcon"
                >
                    <l-popup>
                        <a :href='"?query="+marker.full_address'>{{ marker.full_address }}</a>
                    </l-popup>
                </l-marker>
            </template>

        </k-wms-leaflet-map>

    </div>
</template>

<script>
import L from 'leaflet';

import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'
import { LMarker, LPopup, LPolygon} from 'vue2-leaflet';

import ApiCalls from "@/components/utils/api.calls.js";
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";

import ParcelDetails from  "./ParcelDetails.vue";

export default {
    props: {
        center: {
            type: Object,
            default: () => {
                return {
                    lat: 44.99,
                    lng: 13.757
                }
            }
        },
        zoom: {
            type: Number,
            default: 17,
        },
        addressMarkers: {
            type: Array,
            default: () => []
        },
        parcelPolygons: {
            type: Array,
            default: () => []
        },
    },
    components: {
        "k-wms-leaflet-map": KWMSLeafletMap,
        ReOpenModal,
        LMarker, LPopup, LPolygon,

        ParcelDetails,
    },
    data: function () {
        return {
            clicked_marker: null,
            clicked_parcel_data: null,
        }
    },
    methods: {
        handleMapClick: async function(e) {
            this.clicked_marker = {
                gps_point: {
                    type: "Point", coordinates: [e.latlng.lat, e.latlng.lng]
                },
            };
            this.clicked_parcel_data = null;

            this.$nextTick(() => {this.$refs.clickmarker.mapObject.openPopup();});
            ApiCalls
                .getParcelsByGpsFromOSS(e.latlng.lat, e.latlng.lng)
                .then(res => {
                    this.clicked_parcel_data = res[0];
                })
                .catch(() => {
                    this.$refs.clickmarker.mapObject.closePopup();
                })
        },

        fitBounds() {
            let group = new L.featureGroup();
            this.leafletMapObject.eachLayer(function (layer) {
                if(layer instanceof L.Circle || layer instanceof L.Polygon || layer instanceof L.Marker ) {
                    group.addLayer(layer);
                }
            });
            if( group.getBounds().isValid() ){
                this.leafletMapObject.fitBounds( group.getBounds(), {padding: [50, 50]} );
            }
        }
    },
    computed: {
        clickedParcelPolygon: function() {
            if( this.clicked_parcel_data ) {
                return {
                    ...this.clicked_parcel_data.geom_swapped,
                    ...{
                        style: {
                            color: "#fffdaa",
                            weight: 2,
                            fillOpacity: 0.5
                        }
                    }
                }
            }
            return null;
        },
        styledParcelPolygons: function() {
            return this.parcelPolygons.map(p => {
                return {
                    ...p,
                    ...{
                        style: {
                            color: "#fffdaa",
                            edgeColor: "red",
                            weight: 2,
                            fillOpacity: 0.5,
                            stroke: true
                        }
                    }
                }
            })
        },
        polygons: function() {
            let polygons = this.styledParcelPolygons;
            if ( this.clickedParcelPolygon ) {
                polygons = polygons.concat([this.clickedParcelPolygon]);
            }
            return polygons;
        },
        allMarkers: function() {
            return this.addressMarkers;
        },

        // Icons
        addressMarkerIcon: function() {
            let color = "yellow";
            return new L.Icon({
                iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });
        },

        // Legend
        mapLegend: function() {
            const app = this;
            let legend = L.control({position: "bottomleft"});
            legend.onAdd = function () {
                // Legend itself
                let legendDiv = L.DomUtil.create('div', 'm-t-10');
                legendDiv.innerHTML += `
                    <div class="map-legend-div">
                        <div><i class="legend-marker-icon fa fa-2x fa-map-marker text-center" style="color:yellow;"></i>${app.$t("general:address")}</div>
                    </div>
                `
                return legendDiv;
            }
            return legend;
        },

        // LeafletMapObject
        leafletMapObject: function(){
            return this.$refs.map.leafletMapObject;
        },
    },
    mounted() {
        const app = this;
        this.$nextTick(() => {
            app.fitBounds();
        });
    },  
}
</script>

<style>
.map-legend-div {
    padding: 6px 8px;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    line-height: 25px;
    color: #555;
    text-align: left;
    white-space: nowrap;
}
.map-legend-div i {
    width: 18px;
    height: 25px;
    float: left;
    margin-right: 8px;
}

.hr-search--map--container .leaflet-popup-content {
    width: 300px;
    max-height: 200px;
}
</style>