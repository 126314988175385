<template>
    <div :class="{
        'input-group-sm': size==='sm'
    }">
        <select class="select form-control form-control-sm" v-model="localValue" :disabled="disabled">
            <option
                v-for="(opt, opt_idx) in options"
                :value="opt.value"
                selected=""
                :key="'opt-' + opt_idx"
            >
                {{opt.text}}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        size: {
            type: String,
        },
        options: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        },
    },
}
</script>
