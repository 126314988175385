<template>
    <div class="wrapper">
        <div v-if="data">

            <div class="popup-content popup-header">
                {{data.get_component_type_display}}
            </div>

            <div class="popup-content popup-body">
                <p>
                    {{data.address}}
                </p>
                <div class="text-left">
                    <table class="data">
                        <tr>
                            <td>{{ $t('general:contract:size')}}</td>
                            <td class="value">{{data.component_size||'/'|formatSize}}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('general:year:built')}}</td>
                            <td class="value">{{data.building_year_built||'/'}}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('general:year:sold')}}</td>
                            <td class="value">{{data.transaction_date||'/'}}</td>
                        </tr>
                    </table>
                </div>
                <h2>
                    {{data.transaction_amount_gross|formatCurrency(0)}}
                    <div v-if="data.transaction_tax" class="text-muted ddv">z {{data.transaction_tax}}% {{ $t('general:vat')}}</div>
                </h2>
                <h2 class="smaller">
                    <span v-if="data.transaction_amount_m2">
                        {{data.transaction_amount_m2|formatCurrencym2(0)}}
                    </span>
                    <span v-else-if="data.is_estimated_amount">
                        {{data.estimated_amount_m2|formatCurrencym2(0)}}
                    </span>
                    <span v-else>
                        /
                    </span>
                </h2>

                <hr>
                <div class="text-center">
                    <button class="btn btn-xs text-center" v-on:click.prevent="$emit('comparable-clicked', data)">
                        <span v-if="isBeingProcessed"><i class="fa fa-spinner fa-spin"></i></span>
                        <span v-else-if="isFailed"><i class="fa fa-exclamation-circle"></i></span>
                        <span v-else-if="isSelected">{{ $t('comparable:remove')}}</span>
                        <span v-else>{{ $t('comparable:add')}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TransactionDetails",
    props:  {
        data: {
            type: Object
        },
        isSelected: {
            type: Boolean
        }
    },
    computed: {
        isBeingProcessed: function() {
            return this.$store.state.inprogressTransactionItemsIds.includes(this.data.id);
        },
        isFailed: function() {
            return this.$store.state.failedTransactionItemsIds.includes(this.data.id);
        }
    }
}
</script>


<style scoped>
    hr {
        margin: 5px;
    }
    h2 {
        line-height: 1;
    }
    .popup-body p{
        font-size: 13px;
        padding-bottom: 5px;
    }
    .value{
        font-weight: bold;
        text-align: center;
    }
    .data{
        width: 100%;
    }
    .smaller{
        font-size: 12px;
    }
    .data td{
        padding-top: 5px;
        border-bottom: #F0F0EE 1px solid;
    }
    .ddv{
        font-size: 10px;
    }
    .wrapper {
        /* max-height: 200px; */
        width: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        background: white;

    }
    .popup-content {
        padding: 6px;
        text-align: center;
    }
    .popup-content p {
        margin: 0;
    }
    .popup-header {
        background-color: #2cabe3;
        color:white;
        font-weight: bolder;
        padding-right: 20px;
        padding-left: 20px;
    }
</style>