<template>
    <div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width:100px">{{ $t('general:location')}}</th>
                        <th>{{ $t('general:size')}}</th>
                        <th style="width:60%">{{ $t('ad:description')}}</th>
                        <th>{{ $t('general:price')}}</th>
                        <th>{{ $t('general:year:built')}}</th>
                        <th>{{ $t('aa:options')}}</th>
                    </tr>
                </thead>

                <tbody v-if="listings">
                    <tr v-if="!listings.length" class="text-center">
                        <td colspan="6">
                          {{ $t('general:search:no_results')}}
                        </td>
                    </tr>
                    <tr 
                        v-for="listing in listings"
                        v-bind:key="listing.id"
                        v-bind:class="{'warning': color_listings && color_listings.includes(listing.id)}"
                    >
                        <td>
                            {{ listing.location }}
                            <span 
                                v-if="listing.image" data-toggle="tooltip" 
                                data-original-title="Slike nepremičnine" rel="tooltip"
                            >
                                <a class="open-modal"
                                    :href="listing.image_list_gallery_url">
                                        <i class="fa fa-image"></i>
                                </a>
                            </span>
                        </td>
                        <td>
                            <div class="font-medium" style="white-space: nowrap;">
                                {{ formatSize(listing.size) }}
                            </div>
                            <div v-if="listing.re_type">
                                {{ listing.re_type }}
                            </div>
                            <div v-else>
                                {{ listing.get_real_estate_type_display }}
                                <span v-id="listing.get_real_estate_subtype_display">{{ listing.get_real_estate_subtype_display }}</span>
                            </div>
                        </td>

                        <td>
                            <div class="limit-text-5-rows" v-html="listing.desc"></div>
                            <i> {{ $t('ad:date:published')}} {{ listing.created }}, {{ $t('ad:published:duration')}} {{ listing.age }}</i>
                        </td>

                        <td>
                            <span class="text-info" style="white-space: nowrap;">{{ formatCurrency(listing.price) }}</span>
                            <br>
                            <span class="text-primary" style="white-space: nowrap;">{{ formatCurrencyOrNullm2(listing.get_price_m2) }}</span>
                        </td>

                        <td>
                            <div class="font-medium">
                                {{ listing.get_year_of_construction }}
                            </div>
                        </td>


                        <td class="text-center">
                            <slot name="options_cell" v-bind:listing="listing"></slot>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else class="text-center">
                    <tr>
                        <td colspan="6">
                            <p>{{ $t('general:loading')}}</p>
                            <i class="fa fa-spinner fa-spin"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Utils from "../../../../vue_src/components/utils/utils.js";

export default {
    name: 'AdvertSearchResultTable',
    components: {
    },
    props:  {
        listings: {
            type: Array,
        },
        color_listings: {
            type: Array,
        }
    },
    data: function () {
        return {
        }
    },
    methods: {
        listingImagesUrl: function() {},
        formatSize: Utils.formatSize,
        formatCurrency: Utils.formatCurrency,
        formatCurrencyOrNullm2: Utils.formatCurrencyOrNullm2
    },
    computed: {
    },
}
</script>

<style scoped>

</style>