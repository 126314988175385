<template>
    <select
        :value="value"
        @input="handleInput"
        class="form-control"
        style="font-size: 12px; height: 30px"
    > 
        <option
            v-if="!selectFirst"
            :value="null"
        >
            {{defaultDisplayIfNull}} ({{$t("general:default")}})
        </option>
        <option 
            v-for="(option, option_idx) in choices" 
            :value="option.value"
            :key="'option-'+option_idx"
        >
            {{option.text}}
        </option>
    </select>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        choices: {
            default: () => [],
            // Example: [{value: "1", text: "One"}, {value: "2", text: "Two"}]
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        defaultDisplayIfNull: {
            required: true
        },
    },
    methods: {
        handleInput: function(event) {
            let val = event.target.value;
            if (val==="") {val = null}
            this.$emit("input", val);
        }
    },
}
</script>
