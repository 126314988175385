<template>
    <tbody class="vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />
        <tr class="section-title-row">
            <td :colspan="subject.session_list.length + 1">
                {{ $t("task_details:analysis_metadata") }}
            </td>
        </tr>
        <tr>
            <td>{{ $t("task_details:created_by_process") }}</td>
            <td
                v-for="(session, session_idx) in subject.session_list"
                :key="'created-by-process-td-session-'+session_idx"
            >
                <div class="text-center">
                    <div class="input-wrapper-div center-block">
                        <DropDownSelectInputField
                            v-if="createdByProcessChoices.length > 0"
                            :value="session.created_by_process"
                            defaultDisplayIfNull="AMAS"
                            :choices="createdByProcessChoices"
                            @input="onCreatedByProcessChange($event, session)"
                        />
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import ApiService from "@/components/utils/api.service";
import DropDownSelectInputField from "../components/DropDownSelectInputField.vue"

export default {
    name: "AnalysisMetadataSection",
    components: {
        DropDownSelectInputField
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            loading: false,
            createdByProcessChoices: [],
        }
    },
    methods: {
        fetchCreatedByProcessChoices() {
            this.loading = true;
            ApiService.get('/amas/analysis/analysis/api/created-by-process')
                .then(response => {
                    this.createdByProcessChoices = Object.entries(response.data).map(([key, value]) => {
                        return {
                            value: key,
                            text: value
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onCreatedByProcessChange(value, session) {
            session.created_by_process = value;
            this.$emit('input', this.subject);
        },
    },
    mounted() {
        this.fetchCreatedByProcessChoices();
    },
}
</script>
