<template>
    <table class="table table-condensed">
        <thead>
            <tr>
                <th class="text-center">{{ $t('aa:number')}}</th>
                <th class="text-center">{{ $t('aa:executive_body')}}</th>
                <th class="text-center">{{ $t('aa:procedure')}}</th>
                <th class="text-center">{{ $t('aa:building:description')}}</th>
                <th class="text-center">{{ $t('aa:date:issued')}}</th>
                <th class="text-center">{{ $t('aa:date:legally')}}</th>
                <th class="text-center">{{ $t('aa:parcel')}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(permit, inx) in acts" :key="`aa_${inx}`">
                <td class="text-center">
                    {{ permit.STEV_ZAD }}
                </td>
                <td class="text-center">
                    {{ permit.NAZ_UPR_ORG }}
                </td>
                <td class="text-center">
                    {{ permit.NAZ_UPR_POS }}
                </td>
                <td class="text-center">
                    {{ permit.OBJ }}
                </td>
                <td class="text-center">
                    {{ permit.DAT_IZD }}
                </td>
                <td class="text-center">
                    {{ permit.MAX_DAT_PRA }}
                </td>
                <td class="text-center">
                    <div v-for="parcel in permit.parcels_same_stev_zad.slice(0, 3)" :key="parcel.re_key">
                        <a class="open-modal no-wrap" :href="getOpenPropertyModalUrl(parcel.re_key)">
                            {{ nonBreakableReKey(parcel.re_key) }}
                        </a>
                    </div>
                    <div v-if="permit.parcels_same_stev_zad.length > 3">
                        <div v-if="permit.showMore">
                            <div v-for="parcel in permit.parcels_same_stev_zad.slice(3)" :key="parcel.re_key">
                                <a class="open-modal no-wrap" :href="getOpenPropertyModalUrl(parcel.re_key)">
                                    {{ nonBreakableReKey(parcel.re_key) }}
                                </a>
                            </div>
                        </div>
                        <button class="btn btn-xs btn-outline btn-rounded btn-info m-t-5" @click="permit.showMore = !permit.showMore">
                            {{ permit.showMore ? $t('general:show_less') : $t('general:show_more') }}
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "AdministrativeActsPopupTable",
    props: ['acts'],
    methods: {
        nonBreakableReKey(re_key) {
            // replace - with non breakable hyphen and / with non breakable slash
            return re_key.replace(/-/g, '-\u00AD').replace(/\//g, '/\u00AD');
        },
    },

}
</script>

<style scoped>
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 7px 4px;
}
</style>
