var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-map-wrapper"},[_c('LMap',{ref:"map",staticStyle:{"height":"800px"},attrs:{"zoom":12,"center":_vm.center,"options":{
            attributionControl: false
        }},on:{"update:bounds":_vm.handleMapMove,"update:zoom":_vm.handleZoomChange}},[_c('LControlAttribution',{attrs:{"prefix":"<a href=\"https://www.arvio.si/\" target=\"_blank\">Arvio</a>","position":"bottomright"}}),_c('l-control-layers',{attrs:{"position":"topright"}}),_vm._l((_vm.tilelayers),function(tilelayer){return _c('LTileLayer',{key:tilelayer.name,attrs:{"name":tilelayer.name,"url":tilelayer.url,"attribution":tilelayer.attribution,"options":{ maxZoom: _vm.maxZoom, minZoom: _vm.minZoom },"layer-type":"base"}})}),_c('LControl',{attrs:{"id":"details-window-control-wrapper","position":"bottomleft","disable-scroll-propagation":true}},[(_vm.show_details && _vm.selected_item)?_c('div',{staticStyle:{"background":"white","max-height":"400px","overflow":"auto"}},[_vm._t("details-control",null,{"selected_item":_vm.selected_item})],2):_vm._e()]),(_vm.loading_markers)?_c('LControl',{attrs:{"position":"bottomright"}},[_c('div',[_vm._v("Nalaganje ...")])]):_vm._e(),_c('MarkerCluster',{attrs:{"options":{
                showCoverageOnHover: false,
                removeOutsideVisibleBounds: true,
                maxClusterRadius: 60
            }}},_vm._l((_vm.filteredMarkers),function(marker){return _c('LMarker',{key:marker.id,attrs:{"lat-lng":marker.gps,"icon":_vm.icon},on:{"click":function($event){return _vm.handleMarkerClick(marker)}}},[_c('LIcon',{attrs:{"class-name":"arvio-marker-icon","icon-anchor":[10, 10]}},[_c('div',{staticClass:"arvio-marker-icon__content",class:[marker.colorClass, {
                         'selected': _vm.selected_item && marker.id==_vm.selected_item.id,
                         'in-comparable':
                            _vm.selected_comparable_transaction_ids.includes(_vm.getTransactionIdFromId(marker.id)),
                         'in-wide-set':
                            _vm.selected_wide_set_transaction_ids.includes(_vm.getTransactionIdFromId(marker.id)) & !_vm.selected_comparable_transaction_ids.includes(_vm.getTransactionIdFromId(marker.id)),
                         }]})])],1)}),1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }